import React, { ReactElement } from 'react';
import { Box, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { BuildingListings, PriceHistory } from '../../../services/apiTypes';
import { currencyFormat } from '../../../services/currencyFormat';
import { ArrowIcon } from '../../../icons/icons';
import dayjs from 'dayjs';

export type ListingRowType = 'rentals' | 'sales';

export type ListingRowProps = {
  data?: BuildingListings;
  isHeader?: boolean;
  type: ListingRowType;
};

const dateFormat = 'DD/MM/YY';

const PriceHistoryList = ({
  priceHistory,
}: {
  priceHistory: PriceHistory[];
}) => {
  // Omit last item as it is the original price in overal price history
  const originalPrice = priceHistory[priceHistory.length - 1];
  const history = priceHistory.slice(0, priceHistory.length - 1).reverse();

  return (
    <VStack align={'start'} spacing={1}>
      {history.map(({ price, time }, i) => {
        const difference =
          (i === 0 ? originalPrice.price : history[i - 1].price) - price;
        return (
          <HStack spacing={1} key={price + time + i}>
            <Text
              as={'span'}
              display={'inline-flex'}
              alignItems={'center'}
              color={difference < 0 ? 'pbRed.500' : 'pbGreen.400'}
              fontWeight={'bold'}
            >
              <ArrowIcon transform={`rotate(${difference < 0 ? 180 : 0}deg)`} />
              {currencyFormat(difference < 0 ? difference * -1 : difference)}
            </Text>
            <Text as={'span'}>{dayjs(time * 1000).format(dateFormat)}</Text>
          </HStack>
        );
      })}
    </VStack>
  );
};

// TODO: needs refactor: see LowerSectionTable
export const ListingRow = ({
  data,
  isHeader,
  type,
}: ListingRowProps): ReactElement => {
  const rowStyle: StackProps = !isHeader
    ? {
        border: '1px solid',
        borderColor: 'pbGray.100',
        p: { base: 3, lg: 5 },
        borderRadius: 'lg',
        mb: 2,
        minH: { base: 0, lg: 74 },
      }
    : {
        color: 'pbBlue.100',
        mb: 3,
        px: { base: 3, lg: 5 },
      };

  return (
    <HStack
      fontSize={'14px'}
      letterSpacing={'-0.58px'}
      w={'unset'}
      maxW={'unset'}
      {...rowStyle}
    >
      <Box w={154} flex={'1 0 154px'}>
        {isHeader ? (
          'Contact'
        ) : (
          <VStack spacing={0} align={'start'}>
            <Text fontWeight={'bold'}>{data?.contact_info?.company}</Text>
            <Text color={'pbBlue.100'}>
              {data?.contact_info?.name ||
                data?.contact_info?.email ||
                data?.contact_info?.phone_number}
            </Text>
          </VStack>
        )}
      </Box>
      <Box w={109} flex={'1 0 109px'}>
        {isHeader ? 'Apartment' : data?.unit}
      </Box>
      <Box w={58} flex={'1 0 58px'}>
        {isHeader ? 'Bd' : data?.bathrooms}
      </Box>
      <Box w={65} flex={'1 0 65px'}>
        {isHeader ? 'Ba' : data?.bathrooms}
      </Box>
      <Box w={137} flex={'1 0 137px'}>
        {isHeader ? 'Room Count' : data?.rooms}
      </Box>
      <Box w={98} flex={'1 0 98px'}>
        {isHeader ? 'Sqft' : data?.size}
      </Box>
      <Box w={131} flex={'1 0 150px'}>
        {isHeader ? 'Price' : data?.price && currencyFormat(data.price)}
        {!isHeader && data?.price_history && (
          <PriceHistoryList priceHistory={data?.price_history} />
        )}
      </Box>
      {type === 'sales' && (
        <>
          <Box w={131} flex={'1 0 131px'}>
            {isHeader ? 'CC' : currencyFormat(data?.cc)}
          </Box>
          <Box w={131} flex={'1 0 131px'}>
            {isHeader ? 'RET' : currencyFormat(data?.monthly)}
          </Box>
          <Box w={131} flex={'1 0 131px'}>
            {isHeader
              ? 'Total'
              : data?.price &&
                currencyFormat((data?.cc || 0) + (data?.monthly || 0))}
          </Box>
        </>
      )}
      <Box w={81} flex={'1 0 81'}>
        {isHeader
          ? '$/Sqft'
          : data?.price_per_sqft && currencyFormat(data.price_per_sqft)}
      </Box>
      <Box w={107} flex={'1 0 107px'}>
        {isHeader
          ? type === 'sales'
            ? 'Commission'
            : 'Concession'
          : type === 'sales'
          ? data?.commission
          : data?.concession}
      </Box>
      <Box w={203} flex={'1 0 203px'}>
        {isHeader
          ? 'Open House'
          : data?.open_house.map((h) => <Text key={h}>{h}</Text>)}
      </Box>
      <Box w={101} flex={'1 0 101px'}>
        {isHeader
          ? 'Listed Date'
          : data?.time_published &&
            dayjs(data.time_published * 1000).format(dateFormat)}
      </Box>
      <Box w={88} flex={'1 0 88px'}>
        {isHeader ? 'Listed Type' : data?.ad_type}
      </Box>
    </HStack>
  );
};
