import { Box, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { DotBadge } from './DotBadge';

interface TabLabelProps {
  tabName: string;
  showBadge?: boolean;
}

export const TabLabel = ({
  tabName,
  showBadge = false,
}: TabLabelProps): ReactElement => (
  <Box position={'relative'} px={3}>
    <Text>{tabName}</Text>
    {showBadge && <DotBadge position={'absolute'} top={0} right={0} />}
  </Box>
);
