import { Skeleton, VStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const ApplicationListSkeleton = (): ReactElement => (
  <VStack spacing={4}>
    <Skeleton height={'87px'} width={'100%'} rounded={'lg'} />
    <Skeleton height={'87px'} width={'100%'} rounded={'lg'} />
    <Skeleton height={'87px'} width={'100%'} rounded={'lg'} />
  </VStack>
);
