import { AgentProfileState } from 'contexts/AgentProfileContext';
import { UseQueryResult, useMutation, useQuery } from 'react-query';
import { DealSheet, DealSheetsPostParams } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';
import { objectToURLSearchParams } from 'services/objectToURLSearchParams';

type DealSheetsParams = Omit<AgentProfileState, 'year' | 'month' | 'search'> & {
  agent_id?: string;
  status?: string;
  search?: string;
};

export const useDealSheets = (
  params: DealSheetsParams,
): UseQueryResult<DealSheet[]> => {
  const dealsParams = objectToURLSearchParams<DealSheetsParams>(params);

  return useQuery<DealSheet[]>(
    ['deal-sheets'],
    () => pbAgentFetcher(`v1/deal-sheets?${dealsParams.toString()}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDealSheetsPost = () =>
  useMutation(({ body }: { body: DealSheetsPostParams }) =>
    pbAgentFetcher('v1/deal-sheets', {
      method: 'POST',
      body: JSON.stringify(body),
    }),
  );
