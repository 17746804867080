import { Box, Skeleton, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useEffect } from 'react';
import { ThreadCard } from './ThreadCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';
import { useMailbox } from 'contexts/MailboxContext';
import { MailboxLocationState } from 'types/MailboxRouteParams';
import { ThreadContextProvider } from 'contexts/ThreadContext';
import { ClearTrash } from './ClearTrash';
import { findDraftByDraftMessageId } from 'services/draftsDb';
import { useModal } from 'contexts/ModalContext';
import { GmailMessageModal } from 'components/Global/GmailMessageModal';

export const Inbox = (): ReactElement => {
  const { tab, clientType, clientId } = useParams<ClientRouteParams>();
  const location = useLocation<MailboxLocationState>();
  const { openModal, defaultModalProps } = useModal();
  const history = useHistory();

  const {
    data: {
      threads,
      isLoadingThreads,
      currentFolder,
      isLoadingNextPage,
      canGoToNextPage,
      hasPreviousPageData,
      searchValue,
      drafts,
    },
    actions: { goToPreviousPage, refetchThreads },
  } = useMailbox();

  const threadsToDisplay = threads.filter((thread) =>
    thread.messages.some((message) =>
      message.label_ids.includes(currentFolder.toUpperCase()),
    ),
  );

  const showSkeleton = isLoadingThreads || isLoadingNextPage;
  const canLoadMoreItemsToPage =
    threadsToDisplay.length < 10 && canGoToNextPage;

  useEffect(() => {
    if (showSkeleton || tab === 'messages') {
      return;
    }
    if (!threadsToDisplay.length) {
      hasPreviousPageData && goToPreviousPage();
    }

    if (canLoadMoreItemsToPage) {
      refetchThreads();
    }
  }, [
    canLoadMoreItemsToPage,
    goToPreviousPage,
    hasPreviousPageData,
    refetchThreads,
    showSkeleton,
    tab,
    threadsToDisplay.length,
  ]);

  const isInSent = currentFolder === 'sent';

  if (!threadsToDisplay?.length && !isLoadingThreads) {
    return (
      <Box p={5}>
        <Text>
          {searchValue !== ''
            ? 'No messages match given criteria'
            : `No messages in ${currentFolder.toUpperCase()} folder`}
        </Text>
      </Box>
    );
  }

  const handleThreadClick = async (id: string) => {
    if (currentFolder === 'draft') {
      const draft = await findDraftByDraftMessageId(id);
      const gmailDraft = drafts.find((draft) => draft.id === id);

      if (draft?.messageId === '' || !draft) {
        return openModal(
          <GmailMessageModal
            {...defaultModalProps}
            initObject={{
              to:
                draft?.to_email?.split(', ') ||
                gmailDraft?.to_email.split(', ') ||
                [],
              to_name:
                draft?.to_name?.split(', ') ||
                gmailDraft?.to_name?.split(', ') ||
                [],
              subject: draft?.subject || gmailDraft?.subject || '',
              body: draft?.body || gmailDraft?.email_chat || '',
            }}
            draftMessageId={id}
          />,
        );
      }

      return history.push({
        pathname: `/mailbox/${currentFolder}/${draft?.threadId}`,
        state: {
          ...location.state,
          messageToOpen: draft?.messageId,
          shouldOpenForm: true,
        },
      });
    }

    const path = tab
      ? `/clients/${clientType}/${clientId}/${tab}/${id}`
      : `/mailbox/${currentFolder}/${id}`;
    return history.push({ pathname: path, state: location.state });
  };

  return (
    <>
      {currentFolder === 'trash' && threadsToDisplay.length > 0 && (
        <ClearTrash />
      )}
      <VStack align={'stretch'} spacing={3}>
        {threadsToDisplay?.map((thread) => {
          const {
            id,
            subject,
            snippet,
            messages,
            has_attachments,
            from_user,
            from_name,
            seen,
          } = thread;

          const lastSentMessageIndex = messages.indexOf(
            messages
              .filter((message) => message.label_ids.includes('SENT'))
              .reverse()[0],
          );

          const threadLastMessage =
            messages[isInSent ? lastSentMessageIndex : 0];

          const cardData = isInSent
            ? {
                name: `To: ${
                  threadLastMessage.to_name || threadLastMessage.to_email
                }`,
                avatar: undefined,
                snippetText: threadLastMessage.excerpt,
                subjectText: threadLastMessage.subject,
              }
            : {
                name: from_name,
                avatar: from_user?.photo,
                snippetText: snippet,
                subjectText: subject,
              };

          return (
            <Skeleton key={id} isLoaded={!showSkeleton} rounded={'lg'}>
              <ThreadContextProvider thread={thread}>
                <ThreadCard
                  avatar={cardData.avatar}
                  hasAttachment={has_attachments > 0}
                  subject={cardData.subjectText}
                  snippet={cardData.snippetText}
                  isUnread={isInSent ? false : seen !== 1}
                  name={cardData.name}
                  onClick={() => handleThreadClick(id)}
                />
              </ThreadContextProvider>
            </Skeleton>
          );
        })}
        {canLoadMoreItemsToPage && (
          <Skeleton rounded={'lg'} minH={'64px'} width={'100%'} />
        )}
      </VStack>
    </>
  );
};
