// Have to append brackets `[]` to key of array typed param,
// to flag them for PHP body parser (i think so? - only this way it works)
export const stringifyParamsAccordingToAPI = (
  searchParams: URLSearchParams,
): string => {
  const newParams = new URLSearchParams(searchParams);
  const entries = [...newParams.entries()];

  // Get duplicated keys
  const duplicates: string[] = [];
  const tempArray = [...entries.map((e) => e[0])].sort();
  tempArray.forEach(
    (a, i) =>
      i + 1 < tempArray.length - 1 &&
      a === tempArray[i + 1] &&
      !duplicates.includes(a) &&
      duplicates.push(a),
  );

  // Add new keys with brackets []
  entries.forEach(
    ([key, value]) =>
      duplicates.includes(key) && newParams.append(`${key}[]`, value),
  );

  // Delete old params without brackets
  duplicates.forEach((key) => newParams.delete(key));

  return newParams.toString();
};
