import { Flex, Text } from '@chakra-ui/layout';
import { CloseIcon } from 'icons/icons';
import React, { FC } from 'react';

export type LicenseBoxProps = {
  text: string;
};

const LicenseBox: FC<LicenseBoxProps> = ({ text }: LicenseBoxProps) => {
  return (
    <Flex
      borderRadius={'md'}
      bgColor={'pbGray.60'}
      px={5}
      minW={'100%'}
      minH={'58px'}
      justify={'space-between'}
    >
      <Text fontSize={'14px'} pt={'5px'} pb={'6px'}>
        {text}
      </Text>
      <CloseIcon mt={'18px'} boxSize={'16px'} />
    </Flex>
  );
};

export default LicenseBox;
