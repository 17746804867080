import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';

interface ThreadMutationParams {
  threadId: string;
}

interface UseGmailTrashReturnType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearTrash: UseMutationResult<Response, unknown, any, unknown>;
  deleteThreadPermanently: UseMutationResult<
    Response,
    unknown,
    ThreadMutationParams,
    unknown
  >;
}

export const useGmailTrash = (): UseGmailTrashReturnType => {
  const queryClient = useQueryClient();

  const clearTrash = useMutation(
    ['gmail-trash', 'clear-trash'],
    () =>
      pbAgentFetcher('v1/gmail-trash', {
        method: 'delete',
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries('gmail-labels');
        queryClient.resetQueries([
          'gmail-threads',
          { label: 'TRASH', query: '' },
        ]);
      },
    },
  );

  const deleteThreadPermanently = useMutation(
    ['gmail-trash', 'delete-thread-permanently'],
    ({ threadId }: ThreadMutationParams) =>
      pbAgentFetcher(`v1/gmail-threads/${threadId}/permanently`, {
        method: 'delete',
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries('gmail-labels');
        queryClient.invalidateQueries([
          'gmail-threads',
          { label: 'TRASH', query: '' },
        ]);
      },
    },
  );

  return {
    clearTrash,
    deleteThreadPermanently,
  };
};
