import { Image, ImageProps, Skeleton } from '@chakra-ui/react';
import { omit } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { mediaServer } from '../../services/mediaServer';

interface ImageWithPlaceholderProps extends ImageProps {
  fallbackTranscode?: string;
}

/**
 * This component will first display a skeleton,
 * then if the image from src prop was loaded successfully,
 * it will be displayed. If not, then the default "no-photo.jpg"
 * fallback will be displayed.
 *
 * Why?:
 * Displaying just the fallback while the proper photo is loading
 * is misleading, especially on slower connections, because user
 * might think that nothing more is loading.
 */
export const ImageWithPlaceholder = ({
  fallbackTranscode = 'wadmin',
  ...props
}: ImageWithPlaceholderProps): ReactElement => {
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore I don't want to pick/omit hundreds of props
  const skeleton = <Skeleton flexShrink={0} {...omit(props, 'src')} />;

  // When there's no src, then onLoad and onError callbacks
  // won't be fired.
  useEffect(() => {
    if (!props.src) {
      setLoading(false);
    }
  }, [props.src]);

  return (
    <Image
      fallback={
        <Image
          fallback={skeleton}
          {...props}
          src={
            loading ? undefined : mediaServer('no-photo.jpg', fallbackTranscode)
          }
        />
      }
      onLoad={() => setLoading(false)}
      onError={() => setLoading(false)}
      {...props}
    />
  );
};
