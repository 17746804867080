import { Flex, StackProps } from '@chakra-ui/react';
import React, { FC } from 'react';

const SectionContainer: FC<StackProps> = ({
  children,
  ...rest
}: StackProps) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      align={'flex-start'}
      justifyContent={'stretch'}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default SectionContainer;
