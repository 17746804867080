import { SearchParams, SuggestContactsResponse } from '../../../apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';

export const defaultValues: SearchParams = {
  limit: 10,
  offset: 0,
  sort_by: 'last_active',
  sort_dir: 'desc',
};

export const useSuggestContacts = (
  searchParams?: SearchParams,
): UseQueryResult<SuggestContactsResponse[]> => {
  const queryParams = new URLSearchParams(
    objectToUrlParams({ ...defaultValues, ...searchParams }),
  );

  return useQuery<SuggestContactsResponse[]>(
    ['suggest-contacts', queryParams.toString()],
    () => pbAgentFetcher(`v1/suggest-contacts?${queryParams.toString()}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};
