import { ButtonProps, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React, { ReactElement } from 'react';

export const RemoveButton = (props: ButtonProps): ReactElement => {
  return (
    <IconButton
      pos={'absolute'}
      right={{ base: -1, md: '1px' }}
      top={{ base: -1, md: '1px' }}
      minW={0}
      w={{ base: 4, lg: 5 }}
      h={{ base: 4, lg: 5 }}
      px={1}
      py={1}
      aria-label={'Remove'}
      icon={<CloseIcon width={'10px'} />}
      {...props}
    />
  );
};
