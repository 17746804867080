import {
  Box,
  BoxProps,
  SimpleGrid,
  SimpleGridProps,
  StackProps,
  Text,
  TextProps,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { ComponentProps, Key, ReactElement, ReactNode } from 'react';

export const Label = ({ children, ...props }: TextProps): ReactElement => (
  <Text
    color={'pbBlue.100'}
    fontWeight={300}
    fontSize={{ base: 12, md: 14 }}
    letterSpacing={{ base: 'initial', md: 'tighter' }}
    {...props}
  >
    {children}
  </Text>
);

interface ValueProps extends BoxProps {
  hasPossibleTooltip?: boolean;
}

export const Value = ({
  children,
  hasPossibleTooltip,
  ...props
}: ValueProps): ReactElement => (
  <Box
    textOverflow={'clip'}
    fontSize={{ base: 12, md: 14 }}
    w={'100%'}
    maxH={hasPossibleTooltip ? 5 : 'initial'}
    color={'pbBlack.100'}
    {...props}
  >
    {hasPossibleTooltip ? (
      <Tooltip label={children} aria-label={'A tooltip'}>
        {children}
      </Tooltip>
    ) : (
      children
    )}
  </Box>
);
export const Column = ({ children, ...props }: StackProps): ReactElement => (
  <VStack align={'start'} lineHeight={1.4} spacing={0} {...props}>
    {children}
  </VStack>
);

type LabeledValueProps = {
  label: ReactNode;
  value: ReactNode;
  isBold?: boolean;
  hasPossibleTooltip?: boolean;
} & ComponentProps<typeof Column>;

export const LabeledValue = ({
  value,
  label,
  isBold,
  hasPossibleTooltip,
  ...rest
}: LabeledValueProps): ReactElement => (
  <Column {...rest}>
    <Label>{label}</Label>

    {Array.isArray(value) && value.length > 0 && !!value ? (
      value.map((v) => (
        <Value fontWeight={isBold ? 'bold' : 'normal'} key={v as Key}>
          {v}
        </Value>
      ))
    ) : (
      <Value
        fontWeight={isBold ? 'bold' : 'normal'}
        overflow={'hidden'}
        hasPossibleTooltip={hasPossibleTooltip}
      >
        {value || 'N/A'}
      </Value>
    )}
  </Column>
);

export const LabeledValuesGrid = (props: SimpleGridProps): ReactElement => {
  return <SimpleGrid columns={4} gap={{ base: 2, md: 4, xl: 8 }} {...props} />;
};
