import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface NumberBadgeProps extends FlexProps {
  number?: number;
  isColorReverted?: boolean;
}

export const NumberBadge = ({
  number = 0,
  isColorReverted = false,
  color = 'pbBlue.500',
  ...props
}: NumberBadgeProps): ReactElement => (
  <Flex
    color={isColorReverted ? 'white' : color}
    borderColor={color}
    backgroundColor={isColorReverted ? color : 'transparent'}
    display={'inline-flex'}
    as={'span'}
    justifyContent={'center'}
    alignItems={'center'}
    h={'18px'}
    borderRadius={'9px'}
    fontSize={10}
    borderWidth={1}
    px={1}
    ml={2}
    fontWeight={'bold'}
    {...props}
  >
    {number}
  </Flex>
);
