import { UseQueryResult, useQuery } from 'react-query';
import { ListingClientInfo } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

interface UseListingClientsInfoParams {
  listingId: string;
  clientIds: string[];
}

export const useListingClientsInfo = ({
  listingId,
  clientIds,
}: UseListingClientsInfoParams): UseQueryResult<ListingClientInfo[]> =>
  useQuery<ListingClientInfo[]>(
    ['listing-clients-info', listingId, clientIds],
    () =>
      pbAgentFetcher(
        `v1/search-listings-clients-info/${listingId}?client_ids=${clientIds.join(
          ',',
        )}`,
      ),
    {
      staleTime: 1 * 60 * 1000, // 1 minute
    },
  );
