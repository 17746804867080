import {
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Skeleton,
  Stack,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { LabeledFormField } from 'components/Form/FormField';
import { ActionModal } from 'components/Global/ActionModal';
import { SectionDivider } from 'components/Global/SectionDivider';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAForm } from 'services/hooks/api/aform/useAForm';
import { ModalProps } from 'types/ModalProps';
import { find } from 'lodash';
import { Report, ReportValue } from 'services/apiTypes';
import { DayPicker } from '../../Global/DayPicker';
import dayjs from 'dayjs';
import formatDate from 'react-day-picker/moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  UpdatedFields,
  useAFormPost,
} from 'services/hooks/api/aform/useAFormMutation';
import generateSuccessToast from 'services/generateToast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const { object, date, string, ref } = yup;

type EditRentalInfoModalProps = ModalProps & {
  application_form_id: string;
};

const findFieldValue = (
  report: Report[],
  fieldToFind: string,
): ReportValue | string | null =>
  find(report, (r: Report) => r.field.field === fieldToFind)?.value || null;

const applicationSchema = object({
  lease_start_date: date()
    .transform((value) => formatDate.parseDate(value))
    .min(dayjs(), 'Lease start date must be in the future'),
  lease_end_date: date()
    .transform((value) => formatDate.parseDate(value))
    .min(
      ref('lease_start_date'),
      'Lease end date must be after lease start date',
    ),
  landlord_phone: string().length(10, 'Invalid phone number length'),
});

const EditRentalInfoModal = ({
  onClose,
  isOpen,
  application_form_id,
}: EditRentalInfoModalProps): ReactElement => {
  const toast = useToast();
  const labelMinWidth = '170px';
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(applicationSchema),
  });
  const petsMeasurementsLabelWidth = useBreakpointValue({
    base: labelMinWidth,
    md: '70px',
  });
  const datesLabelWidth = useBreakpointValue({
    base: labelMinWidth,
    md: '170px',
  });
  const { data, isLoading: isFormLoading } = useAForm(application_form_id);
  const [petsType, setPetsType] = useState('');
  const { mutateAsync: updateFields, isLoading: isUpdating } = useAFormPost();

  const { setValue, setError, resetField, getValues, handleSubmit } = methods;

  useEffect(() => {
    if (!data) return;
    const { building_address, unit, report } = data;

    setValue('building_address', building_address);
    setValue('apartment_no', unit);
    setValue('lease_start_date', findFieldValue(report, 'lease_start_date'));
    setValue('lease_end_date', findFieldValue(report, 'lease_end_date'));
    setValue('monthly_rent', `${findFieldValue(report, 'monthly_rent')}`);
    setValue('occupants', findFieldValue(report, 'occupants'));
    setValue('landlord_name', findFieldValue(report, 'landlord_name'));
    setValue('landlord_phone', findFieldValue(report, 'landlord_phone'));
    setValue('landlord_address', findFieldValue(report, 'landlord_address'));
    setValue('pets_dogs_breeds', findFieldValue(report, 'pets_dogs_breeds'));
    setValue('pets_dogs_weight', findFieldValue(report, 'pets_dogs_weight'));

    const petsType = () => {
      if (findFieldValue(report, 'pets_dogs') === 'true') {
        return 'dogs';
      }
      if (findFieldValue(report, 'pets_cats') === 'true') {
        return 'cats';
      }
      return '';
    };

    setPetsType(petsType());
  }, [data, methods, setValue]);

  if (isFormLoading) {
    return <Skeleton height={'100%'} width={'100%'} />;
  }

  if (!data) {
    return <div>Something went wrong, try again</div>;
  }

  const onPetTypeChange = (type: string) => {
    resetField('pets_dogs_breeds', { defaultValue: null });
    resetField('pets_dogs_weight', { defaultValue: null });
    setPetsType(type);
  };

  const onSubmit = async () => {
    const updatedFields: UpdatedFields = [];
    Object.entries({
      ...getValues(),
      pets_dogs: petsType === 'dogs' ? 'true' : null,
      pets_cats: petsType === 'cats' ? 'true' : null,
    })
      .filter(([key, value]) => value !== findFieldValue(data.report, key))
      .forEach(([key, value]) => {
        updatedFields.push({ field: key, value });
      });

    await updateFields({ id: application_form_id, data: updatedFields });
    toast(generateSuccessToast('Application updated successfully'));
    onClose();
  };

  const handleLeaseStartDateChange = (date: Date) => {
    setError('lease_start_date', { message: '' });
    setValue('lease_start_date', dayjs(date));
  };

  const handleLeaseEndDateChange = (date: Date) => {
    setError('lease_end_date', { message: '' });
    setValue('lease_end_date', dayjs(date));
  };

  return (
    <ActionModal
      onSubmit={handleSubmit(onSubmit)}
      isOpen={isOpen}
      isLoading={isUpdating}
      onClose={onClose}
      title={'Edit rental info'}
      submitLabel={'Save'}
      size={'5xl'}
    >
      <FormProvider {...methods}>
        <VStack spacing={6} alignItems={'stretch'}>
          <LabeledFormField
            name={'building_address'}
            label={'Building Address'}
            placeholder={'Building Address'}
            labelMinWidth={labelMinWidth}
            requiredMessage={'Building Address is required'}
            required
          />

          <LabeledFormField
            name={'apartment_no'}
            label={'Apartment No'}
            placeholder={'Apartment Number'}
            labelMinWidth={labelMinWidth}
          />

          <Stack
            spacing={{ base: 6, md: 6 }}
            direction={{ base: 'column', md: 'row' }}
            alignItems={'center'}
          >
            <Flex alignItems={'center'} width={{ base: '100%', md: '55%' }}>
              <FormLabel
                htmlFor={'lease_start_date'}
                minWidth={datesLabelWidth}
              >
                Lease Start Date
              </FormLabel>
              <DayPicker
                dayPickerProps={{
                  locale: 'en',
                  localeUtils: MomentLocaleUtils,
                }}
                formatDate={(date) =>
                  formatDate.formatDate(date, 'MMMM Do, YYYY', 'en')
                }
                parseDate={(str) => formatDate.parseDate(str)}
                format={'MMMM Do, YYYY'}
                placeholder={'Lease Start Date'}
                style={{
                  marginLeft: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
                inputProps={{
                  fontWeight: 'normal',
                  height: '50px',
                  name: 'lease_start_date',
                }}
                value={dayjs(methods.getValues('lease_start_date')).format(
                  'MMMM Do, YYYY',
                )}
                onDayChange={handleLeaseStartDateChange}
              />
            </Flex>

            <Flex
              alignItems={'center'}
              width={{ base: '100%', md: '55%' }}
              position={'relative'}
            >
              <FormLabel htmlFor={'lease_end_date'} minWidth={datesLabelWidth}>
                Lease End Date
              </FormLabel>
              <DayPicker
                dayPickerProps={{
                  locale: 'en',
                  localeUtils: MomentLocaleUtils,
                }}
                formatDate={(date) =>
                  formatDate.formatDate(date, 'MMMM Do, YYYY', 'en')
                }
                parseDate={(str) => formatDate.parseDate(str)}
                format={'MMMM Do, YYYY'}
                placeholder={'Lease End Date'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
                inputProps={{
                  fontWeight: 'normal',
                  height: '50px',
                  py: 2,
                  ml: 0,
                  name: 'lease_end_date',
                }}
                value={dayjs(methods.getValues('lease_end_date')).format(
                  'MMMM Do, YYYY',
                )}
                onDayChange={handleLeaseEndDateChange}
              />
            </Flex>
          </Stack>

          <LabeledFormField
            name={'monthly_rent'}
            label={'Monthly Rent'}
            placeholder={'Monthly Rent'}
            labelMinWidth={labelMinWidth}
          />

          <LabeledFormField
            name={'occupants'}
            label={'Occupants'}
            placeholder={'None'}
            labelMinWidth={labelMinWidth}
          />

          <Flex
            spacing={{ base: 6, md: 10 }}
            direction={{ base: 'row', md: 'row' }}
            flexWrap={{ base: 'wrap', md: 'nowrap' }}
            alignItems={'center'}
          >
            <FormLabel minW={labelMinWidth} display={{ base: 'inline-flex' }}>
              Pets
            </FormLabel>

            <ButtonGroup
              spacing={2}
              display={{ base: 'inline-flex' }}
              mb={{ base: 6, md: 0 }}
              width={'55%'}
            >
              <Button
                minH={'50px'}
                aria-label={'dogs'}
                variant={petsType === 'dogs' ? 'blue' : 'solid'}
                onClick={() => onPetTypeChange('dogs')}
              >
                Dogs
              </Button>
              <Button
                minH={'50px'}
                aria-label={'cats'}
                variant={petsType === 'cats' ? 'blue' : 'solid'}
                onClick={() => onPetTypeChange('cats')}
              >
                Cats
              </Button>
            </ButtonGroup>

            {petsType === 'dogs' && (
              <Stack
                spacing={4}
                ml={{ base: 0, md: 16 }}
                direction={{ base: 'column', md: 'row' }}
                width={'100%'}
              >
                <LabeledFormField
                  name={'pets_dogs_breeds'}
                  label={'Breeds'}
                  placeholder={'None'}
                  labelMinWidth={petsMeasurementsLabelWidth}
                  isDisabled={petsType !== 'dogs'}
                />
                <LabeledFormField
                  name={'pets_dogs_weight'}
                  label={'Weight'}
                  placeholder={'None'}
                  labelMinWidth={petsMeasurementsLabelWidth}
                  isDisabled={petsType !== 'dogs'}
                />
              </Stack>
            )}
          </Flex>
        </VStack>

        <SectionDivider />

        <VStack spacing={6} alignItems={'stretch'}>
          <Stack
            spacing={{ base: 6, md: 10 }}
            direction={{ base: 'column', md: 'row' }}
          >
            <LabeledFormField
              name={'landlord_name'}
              label={'Landlord Name'}
              placeholder={'Landlord Name'}
              labelMinWidth={labelMinWidth}
            />
            <LabeledFormField
              name={'landlord_phone'}
              label={'Landlord Phone'}
              placeholder={'Landlord Phone'}
              labelMinWidth={labelMinWidth}
            />
          </Stack>

          <LabeledFormField
            name={'landlord_address'}
            label={'Landlord Address'}
            placeholder={'Landlord Address'}
            labelMinWidth={labelMinWidth}
          />
        </VStack>
      </FormProvider>
    </ActionModal>
  );
};

export default EditRentalInfoModal;
