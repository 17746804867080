import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { objectToUrlParams } from 'services/objectToUrlParams';
import { GmailAttachmentsResponse } from './apiTypes';

interface AttachmentsQueryParams {
  page_token?: string;
  q?: string;
  label?: string;
}

export const useGmailAttachments = (
  params?: AttachmentsQueryParams,
  options?: UseQueryOptions<GmailAttachmentsResponse>,
): UseQueryResult<GmailAttachmentsResponse> => {
  const threadsParams = new URLSearchParams(objectToUrlParams({ ...params }));

  return useQuery<GmailAttachmentsResponse>(
    ['gmail-attachments', { label: params?.label, query: params?.q }],
    () => pbAgentFetcher(`v1/gmail-attachments?${threadsParams.toString()}`),
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};
