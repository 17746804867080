import React from 'react';
import { Dispatch, FC, createContext, useContext, useReducer } from 'react';
import { AgentProfile } from 'services/hooks/api/agent-profile/apiTypes';
import { ChildrenProps } from 'types/ChildrenProps';
import { AgentProfileAction } from './actions';
import { agentProfileReducer } from './reducer';

export type AgentProfileState = {
  modifiedAgent?: Partial<AgentProfile>;
};

export type AgentProfileContextProps = {
  state: AgentProfileState;
  dispatch: Dispatch<AgentProfileAction>;
};

export const AgentProfileContext = createContext<AgentProfileContextProps>({
  state: {},
  dispatch: () => {
    throw new Error('dispatch withing context is not defined');
  },
});

export const AgentProfileContextProvider: FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [state, dispatch] = useReducer(agentProfileReducer, {
    modifiedAgent: undefined,
  });

  return (
    <AgentProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </AgentProfileContext.Provider>
  );
};

export const useAgentProfileContext = (): AgentProfileContextProps => {
  const context = useContext<AgentProfileContextProps>(AgentProfileContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};
