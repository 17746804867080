import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import React, { ChangeEvent, ReactElement } from 'react';
import { CloseIcon, SearchGrayIcon } from 'icons/icons';

interface SearchInputProps extends InputGroupProps {
  onChange: (v: ChangeEvent<HTMLInputElement>) => void;
  canBeClosed?: boolean;
  onClose?: () => void;
}

export const SearchInput = ({
  placeholder = 'Search',
  onChange,
  canBeClosed,
  onClose,
  ...props
}: SearchInputProps): ReactElement => (
  <InputGroup maxWidth={'340px'} {...props}>
    <InputLeftElement pointerEvents={'none'}>
      <SearchGrayIcon boxSize={'4'} />
    </InputLeftElement>
    <Input placeholder={placeholder} onChange={onChange} variant={'grayed'} />
    {canBeClosed && (
      <InputRightElement>
        <CloseIcon onClick={onClose} _hover={{ cursor: 'pointer' }} />
      </InputRightElement>
    )}
  </InputGroup>
);

export const ExpandableSearchInput = ({
  onChange,
  ...props
}: SearchInputProps): ReactElement => {
  const [isExpanded, setIsExpanded] = useBoolean(false);
  return isExpanded ? (
    <Box pos={'absolute'} zIndex={1} width={'100%'}>
      <SearchInput
        onChange={onChange}
        {...props}
        canBeClosed
        onClose={setIsExpanded.off}
        maxW={'none'}
      />
    </Box>
  ) : (
    <IconButton
      variant={'ghost'}
      bg={'transparent'}
      icon={<SearchGrayIcon boxSize={'4'} />}
      onClick={setIsExpanded.on}
      color={'gray.300'}
      aria-label={'Search'}
    />
  );
};
