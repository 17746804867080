import React, { ReactElement, ReactNode } from 'react';
import { Main } from '../components/Global/Main';
import { PageHeading } from '../components/Global/PageHeading';
import { PageTitle } from '../components/Global/PageTitle';
import { Box, BoxProps, Button, SimpleGrid, VStack } from '@chakra-ui/react';
import { RoundedBox } from '../components/Global/RoundedBox';
import {
  AllInboxIcon,
  AttachIcon,
  ClientIcon,
  PencilIcon,
  SentIcon,
  StarIcon,
  TrashIcon,
} from '../icons/icons';
import { MailboxHeading } from '../components/Mailbox/MailboxHeading';
import { MailboxNavItem } from '../components/Mailbox/MailboxNavItem';
import { Inbox } from '../components/Mailbox/Inbox';
import { ThreadView } from '../components/Mailbox/ThreadView';
import { useGmailLabels } from '../services/hooks/api/gmail/useGmailLabels';
import { GmailMessageModal } from '../components/Global/GmailMessageModal';
import { useModal } from 'contexts/ModalContext';
import { InboxPagination } from 'components/Global/Pagination';
import { Route, Switch } from 'react-router-dom';
import { AttachmentLibraryContextProvider } from 'contexts/AttachmentLibraryContext';
import AttachmentLibrary from 'components/Mailbox/AttachmentsLibrary';
import { useGmailAttachments } from 'services/hooks/api/gmail/useGmailAttachments';

const NavIcon = ({ as, ...props }: BoxProps) => (
  <Box as={as} w={25} h={25} {...props} />
);

export type GmailThreadsLabels =
  | 'INBOX'
  | 'DRAFT'
  | 'SENT'
  | 'IMPORTANT'
  | 'STARRED'
  | 'UNREAD'
  | 'TRASH'
  | 'SPAM'
  | 'ATTACHMENTS';

export type MailboxNavItemProps = {
  icon: ReactNode;
  label: ReactNode;
  description: string;
  param?: GmailThreadsLabels;
};

const nav: MailboxNavItemProps[] = [
  {
    label: 'All Inbox',
    description: 'All your messages in one place',
    icon: <NavIcon as={AllInboxIcon} />,
    param: 'INBOX',
  },
  {
    label: 'Starred',
    description: 'Selected messages',
    icon: <NavIcon as={StarIcon} />,
    param: 'STARRED',
  },
  {
    label: 'Sent',
    description: 'All your messages in one place',
    icon: <NavIcon as={SentIcon} />,
    param: 'SENT',
  },
  {
    label: 'Drafts',
    description: 'Finish your messages',
    icon: <NavIcon as={PencilIcon} />,
    param: 'DRAFT',
  },
  {
    label: 'Trash',
    description: 'Deleted messages',
    icon: <NavIcon as={TrashIcon} />,
    param: 'TRASH',
  },
  {
    label: 'Attachment Library',
    description: 'Pictures, Documents, Tables',
    icon: <NavIcon as={AttachIcon} />,
    param: 'ATTACHMENTS',
  },
];

export const Mailbox = (): ReactElement => {
  const { openModal, defaultModalProps } = useModal();
  const { data, isLoading } = useGmailAttachments();
  const labels = useGmailLabels();

  let attachmentsCount = 0;

  if (data && !isLoading) {
    attachmentsCount = data.messages.reduce((acc, message) => {
      return acc + message.attachments.length;
    }, 0);
  }

  return (
    <Main display={'flex'} flexDir={'column'} flexGrow={1}>
      <PageHeading justifyContent={'space-between'}>
        <PageTitle>Mailbox</PageTitle>

        <Button
          fontSize={12}
          leftIcon={<ClientIcon />}
          variant={'blueLight'}
          onClick={() =>
            openModal(<GmailMessageModal {...defaultModalProps} />)
          }
        >
          New Message
        </Button>
      </PageHeading>

      <SimpleGrid
        templateColumns={{
          base: '100%',
          lg: '380px minmax(0, 1fr)',
        }}
        gap={{ base: 3, xl: 5 }}
        minH={'750px'}
      >
        <RoundedBox isSide marginAlignment={'right'} maxH={'650px'}>
          <VStack spacing={3} align={'stretch'}>
            {nav.map(({ label, description, icon, param }) => {
              let labelCount = labels?.data?.find(
                (d) => d.id === param,
              )?.threadsTotal;

              const isFolderDisabled =
                labels?.data &&
                labels.data.find((d) => d.id === param)?.threadsTotal === 0;

              if (param === 'ATTACHMENTS') {
                labelCount = attachmentsCount;
              }
              return (
                <MailboxNavItem
                  param={param}
                  key={`${label} ${description}`}
                  label={label}
                  description={description}
                  icon={icon}
                  count={labelCount}
                  isDisabled={isFolderDisabled}
                />
              );
            })}
          </VStack>
        </RoundedBox>

        <Box display={'flex'} flexDir={'column'}>
          <RoundedBox height={'100%'}>
            <Switch>
              <Route path={'/mailbox/attachments'}>
                <AttachmentLibraryContextProvider>
                  <AttachmentLibrary />
                </AttachmentLibraryContextProvider>
              </Route>
              <Route path={'/mailbox/:param'} exact>
                <MailboxHeading />
                <Inbox />
              </Route>
              <Route path={'/mailbox/:folder/:threadId?'}>
                <ThreadView />
              </Route>
            </Switch>
          </RoundedBox>
          <Route path={'/mailbox/:param'} exact>
            <InboxPagination />
          </Route>
        </Box>
      </SimpleGrid>
    </Main>
  );
};
