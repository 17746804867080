import { Heading, Skeleton, Text, VStack } from '@chakra-ui/react';
import React, { ComponentProps, ReactElement } from 'react';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';
import { InfoRow, TextElementType } from 'components/Global/InfoRow';
import { currencyFormat } from '../../services/currencyFormat';
import { Card } from './Card';
import { StringOrNumber } from '../../services/apiTypes';
import getHasBedroomCount from 'services/hasBedroomsCount';

type ApartmentTileProps = {
  imageSrc?: string;
  title: string;
  price: StringOrNumber;
  beds?: StringOrNumber;
  baths?: StringOrNumber;
  openHouseInfo?: string;
  /** Is the listing a rental? */
  rental?: boolean;
} & ComponentProps<typeof Card>;

export const ApartmentTile = ({
  imageSrc,
  title,
  price,
  openHouseInfo,
  baths,
  beds,
  ...rest
}: ApartmentTileProps): ReactElement => {
  const hasBedroomsCount = getHasBedroomCount(beds?.toString() || '');

  const infoRowElements: TextElementType[] = [
    {
      number: currencyFormat(price),
    },
  ];

  if (beds) {
    infoRowElements.push({
      number: beds,
      label: hasBedroomsCount ? 'Bd' : '',
    });
  }

  if (baths) {
    infoRowElements.push({
      number: baths,
      label: 'Ba',
    });
  }

  return (
    <Card p={3} align={'center'} {...rest}>
      <ImageWithPlaceholder
        borderRadius={'base'}
        src={imageSrc}
        fallbackTranscode={'wlist'}
        mr={{ base: 2, md: 4 }}
        w={20}
        minW={20}
        h={20}
        objectFit={'cover'}
        objectPosition={'center'}
        fit={'cover'}
      />
      <VStack align={'start'} spacing={0}>
        <Heading
          as={'h4'}
          fontSize={{ base: 13, md: 14, lg: 16 }}
          mb={1}
          color={rest.isActive ? 'pbBlue.500' : undefined}
        >
          {title}
        </Heading>

        <InfoRow
          elements={infoRowElements}
          dotMx={{ base: 1, md: 2 }}
          dotColor={'pbGray.250'}
          color={rest.isActive ? 'pbGray.200' : undefined}
        />

        {openHouseInfo && (
          <Text
            color={rest.isActive ? 'pbGray.200' : 'pbBlue.100'}
            lineHeight={1}
            fontSize={{ base: 12, md: 14 }}
            mt={0}
          >
            <Text as={'span'}>OH: </Text>
            <Text as={'span'} fontWeight={'bold'}>
              {openHouseInfo}
            </Text>
          </Text>
        )}
      </VStack>
    </Card>
  );
};

export const ApartmentTileSkeleton = (): ReactElement => (
  <Card p={3}>
    <Skeleton mr={4} borderRadius={'base'} width={'80px'} height={'80px'} />
    <Skeleton h={'80px'} flexGrow={1} />
  </Card>
);
