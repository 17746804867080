import React, { ReactElement } from 'react';
import { Box, HStack, SimpleGrid } from '@chakra-ui/react';
import { AmexIcon, CalendarIcon, MastercardIcon, VisaIcon } from 'icons/icons';
import InputEditor from '../../PersonalDetails/shared/InputEditor';
import SectionHeading from '../../PersonalDetails/shared/SectionHeading';
import SavedCard from './SavedCard';
import { TwoInputsRow } from '../../PersonalDetails/shared/TwoInputsRow';

const CreditCards = (): ReactElement => {
  //TODO
  const onSubmit = () => undefined;

  return (
    <SimpleGrid
      templateColumns={{
        base: 'minmax(0, 1fr)',
        xl: '500px minmax(0, 1fr)',
      }}
      gap={5}
    >
      <Box maxW={'500px'}>
        <SectionHeading>Credit Card Payments</SectionHeading>

        <InputEditor
          disableHover
          placeholder={'Credit Card Number'}
          onSubmit={onSubmit}
          rightIcon={<VisaIcon boxSize={'28px'} />}
        />

        <TwoInputsRow mt={2}>
          <InputEditor
            disableHover
            placeholder={'Expiration'}
            onSubmit={onSubmit}
            rightIcon={<CalendarIcon boxSize={'16px'} />}
          />
          <InputEditor disableHover placeholder={'CVV'} onSubmit={onSubmit} />
        </TwoInputsRow>
      </Box>

      <Box>
        <SectionHeading>My Cards</SectionHeading>

        <HStack overflowX={'scroll'}>
          <SavedCard
            cardType={VisaIcon}
            isActive
            cardLastDigits={5468}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={AmexIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={MastercardIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={MastercardIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={MastercardIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={MastercardIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={MastercardIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
          <SavedCard
            cardType={MastercardIcon}
            cardLastDigits={2500}
            cardExpiration={'04/22'}
          />
        </HStack>
      </Box>
    </SimpleGrid>
  );
};

export default CreditCards;
