import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { Client } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

export const useRestoreClient = (): UseMutationResult<
  Client,
  unknown,
  void,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (email) =>
      pbAgentFetcher(`v1/clients/${email}`, {
        method: 'post',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['clients']);
      },
    },
  );
};
