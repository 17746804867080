import { Box, Flex, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import { AppointmentModel, ViewState } from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  DayView,
  Scheduler,
} from '@devexpress/dx-react-scheduler-material-ui';
import dayjs from 'dayjs';
import { colors } from '../../services/theme/colors';
import { borderRadius } from '../../services/theme';

interface EventsListProps {
  selectedDay?: Date;
  events?: AppointmentModel[];
  time?: unknown;
}

const Appointment = ({
  children,
  ...restProps
}: Appointments.AppointmentProps) => {
  return (
    <Box
      as={Appointments.Appointment}
      style={{
        // Some styles has to be overwriten in this way
        background:
          restProps.data.status === 'confirmed'
            ? colors.pbBlue['510']
            : `repeating-linear-gradient( -70deg, ${colors.pbPurple['505']}, ${colors.pbPurple['505']} 20px, ${colors.pbPurple['510']} 20px, ${colors.pbPurple['510']} 40px)`,
        borderRadius: borderRadius.md,
        height: 'auto',
        border: 0,
        overflow: 'visible',
        width: '95%',
        right: '0',
      }}
      color={
        restProps.data.status === 'confirmed' ? 'pbBlue.500' : 'pbPurple.500'
      }
      _before={{
        content: '""',
        pos: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        bg: 'white',
        zIndex: -1,
      }}
      _after={{
        content: '""',
        pos: 'absolute',
        left: -2,
        top: -2,
        right: 0,
        bottom: -2,
        bg: 'white',
        zIndex: -1,
      }}
      minH={'100%'}
      {...restProps}
    >
      <Flex direction={'column'} minH={'100%'} justifyContent={'space-between'}>
        {children}
        {restProps.data.status !== 'confirmed' && (
          <Text
            color={
              restProps.data.status === 'confirmed'
                ? 'pbBlue.800'
                : 'pbPurple.800'
            }
            textAlign={'right'}
            p={2}
            fontSize={12}
          >
            {restProps.data.status}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

const AppointmentContent = ({
  ...restProps
}: Appointments.AppointmentContentProps) => {
  return (
    <Flex p={2} direction={'column'}>
      <Text
        fontSize={14}
        letterSpacing={'tight'}
        color={'inherit'}
        fontWeight={'bold'}
      >
        {restProps.data.title}
      </Text>

      <Text
        fontSize={12}
        letterSpacing={'tighter'}
        color={
          restProps.data.status === 'confirmed' ? 'pbBlue.800' : 'pbPurple.800'
        }
      >
        {`${dayjs(restProps.data.startDate).format('h:mm')} - ${dayjs(
          restProps.data.endDate,
        ).format('h:mm a')}`}
      </Text>
    </Flex>
  );
};

export const EventsList = ({
  selectedDay,
  events,
}: EventsListProps): ReactElement => {
  return (
    <Box
      sx={{
        '[class*="ordinaryLeftPanelBorder"],[class*="HeaderBorder"]': {
          border: 0,
        },
        '[class*="TicksLayout"]': {
          display: 'none',
        },
      }}
    >
      <Scheduler data={events} height={400} firstDayOfWeek={2}>
        <ViewState currentDate={selectedDay} />

        <DayView
          startDayHour={0}
          endDayHour={24}
          cellDuration={60}
          dayScaleLayoutComponent={() => null}
          dayScaleEmptyCellComponent={() => null}
          timeScaleLayoutComponent={({ ...props }) => {
            return (
              <Box
                as={DayView.TimeScaleLayout}
                {...props}
                style={{
                  width: 38,
                }}
                sx={{
                  '[class*="timeScaleContainer"]': {
                    width: '100% !important',
                  },
                }}
              />
            );
          }}
          timeTableCellComponent={({ ...props }) => {
            return (
              <DayView.TimeTableCell
                {...props}
                style={{
                  height: '80px',
                }}
              />
            );
          }}
          timeScaleLabelComponent={({ ...props }) => {
            return props.time ? (
              <Text
                style={{
                  position: 'relative',
                  top: 1,
                  height: '80px',
                  lineHeight: 1,
                  paddingTop: 10,
                  fontSize: 12,
                  color: colors.pbBlue['200'],
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  zIndex: -1,
                }}
              >
                {dayjs(props.time).format('Ha')}
              </Text>
            ) : null;
          }}
        />

        <Appointments
          appointmentComponent={Appointment}
          appointmentContentComponent={AppointmentContent}
        />
      </Scheduler>
    </Box>
  );
};
