import { UseQueryResult, useQuery } from 'react-query';
import { SuggestAgentsResponse } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';
import { objectToUrlParams } from 'services/objectToUrlParams';

type SuggestAgentsParams = {
  offset?: number;
  limit?: number;
  search?: string;
  sort_by?: string;
  sort_dir?: string;
};

const defaultParams: SuggestAgentsParams = {
  offset: 0,
  limit: 10,
  search: '',
  sort_by: 'first_name',
  sort_dir: 'asc',
};

export const useSuggestAgents = (
  params?: SuggestAgentsParams,
): UseQueryResult<SuggestAgentsResponse[]> => {
  const urlParams = new URLSearchParams(
    objectToUrlParams({ ...defaultParams, ...params }),
  );

  return useQuery<SuggestAgentsResponse[]>(
    ['suggest-agents', urlParams.toString()],
    () => pbAgentFetcher(`v1/suggest-agents?${urlParams.toString()}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};
