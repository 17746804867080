import React, { ReactElement, SyntheticEvent } from 'react';
import { Flex, FormLabel, Textarea, VStack, useToast } from '@chakra-ui/react';
import { useGmailMessages } from 'services/hooks/api/gmail/useGmailMessages';
import { ActionModal } from './ActionModal';
import { useGmailBody } from '../../services/hooks/api/gmail/useGmailBody';
import { ModalProps } from 'types/ModalProps';
import { ToFieldWithCCField } from 'components/Form/ToFieldWithCCField';
import { NewMessageAttachments } from './NewMessageAttachments';
import { BaseInput } from 'components/Form/BaseInput';
import { GmailMessagesPostBody } from 'services/apiTypes';
import { useIdleTimer } from 'react-idle-timer';
import useDraftUpsert from 'services/hooks/useDraftUpsert';
import { deleteDraftById, findDraftByDraftMessageId } from 'services/draftsDb';
import { useDraftsDelete } from 'services/hooks/api/gmail/useDraftsDelete';

interface GmailMessageModalProps extends ModalProps {
  modalTitle?: string;
  draftMessageId?: string;
  initObject?: GmailMessagesPostBody;
}

export const GmailMessageModal = ({
  onClose,
  isOpen,
  modalTitle = 'New Message',
  draftMessageId,
  initObject = {
    to: [''],
  },
}: GmailMessageModalProps): ReactElement => {
  const toast = useToast();
  const { upsertDraft, isSaving } = useDraftUpsert(true);

  useIdleTimer({
    timeout: 2 * 1000,
    onIdle: () => (!isSaving ? upsertDraft(body, draftMessageId) : null),
    debounce: 500,
  });

  const {
    state: [body, setBody],
    onAttachmentRemove,
    onFileInputChange,
    onRecipientChange,
    onCcChange,
    onResetState,
  } = useGmailBody(initObject);

  const { mutate: sendEmail, isLoading: isSendingEmail } = useGmailMessages();
  const { mutateAsync: deleteDraft } = useDraftsDelete();

  const onSubmit = async () => {
    sendEmail(
      { body },
      {
        onSuccess: () => {
          toast({
            title: 'Success',
            description: `Your message to ${
              body.to.join(', ') || body.to_name
            } has been sent`,
            status: 'success',
            position: 'bottom-right',
            duration: 5000,
            isClosable: true,
          });
          onClose();
        },
      },
    );

    if (draftMessageId) {
      const draft = await findDraftByDraftMessageId(draftMessageId);

      if (draft) {
        await deleteDraft({ draftId: draft.draftId });
        await deleteDraftById(draft.draftId);
      }
    }
  };

  const onChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.currentTarget;
    setBody((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    onResetState();
    onClose();
  };

  return (
    <ActionModal
      isOpen={isOpen}
      isDisabled={isSendingEmail || !body.subject || !body.to.length}
      onClose={handleClose}
      onSubmit={onSubmit}
      isLoading={isSendingEmail}
      title={modalTitle}
      size={'6xl'}
      submitLabel={'Send'}
    >
      <VStack spacing={6} align={'stretch'}>
        <ToFieldWithCCField
          toValue={body.to_name}
          ccValue={body.cc_name}
          onToChange={onRecipientChange}
          onCcChange={onCcChange}
        />

        <Flex>
          <FormLabel minW={140}>Subject</FormLabel>
          <BaseInput
            placeholder={'Subject'}
            onChange={onChange}
            name={'subject'}
            value={body.subject}
          />
        </Flex>

        <Flex>
          <FormLabel minW={140}>Message</FormLabel>
          <Textarea
            placeholder={'Message'}
            onChange={onChange}
            name={'body'}
            value={body.body}
            resize={'vertical'}
          />
        </Flex>

        <NewMessageAttachments
          attachments={body.attachments}
          onAttachmentRemove={onAttachmentRemove}
          onFileInputChange={onFileInputChange}
        />
      </VStack>
    </ActionModal>
  );
};
