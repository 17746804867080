import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { VStack, useBoolean } from '@chakra-ui/react';
import { RadioRow } from 'components/Form/RadioRow';
import { ClientsMutationInput, StringOrNumber } from 'services/apiTypes';
import { useFormContext } from 'react-hook-form';
import FormField, { EmailField } from 'components/Form/FormField';
import { isEmailValid } from 'services/validation';
import { useEmailExists } from 'services/hooks/api/validation/useEmailExists';

import { capitalizeFirstLetter } from 'services/capitalizeFirstLetter';

interface ClientDetailsFormProps {
  client?: ClientsMutationInput;
  onInputChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onRadioChange: (e: StringOrNumber) => void;
  isEditing?: boolean;
  adType?: string;
}

const ClientDetailsForm: React.FC<ClientDetailsFormProps> = ({
  onRadioChange,
  adType,
  isEditing = false,
}: ClientDetailsFormProps) => {
  const { watch, setValue, clearErrors, resetField } = useFormContext();
  const [existingClientVisible, setExistingClientVisible] = useBoolean(false);
  const [fieldsDisabled, setFieldsDisabled] = useBoolean(false);

  const emailToCheck = isEmailValid(watch('email')) ? watch('email') : '';
  const { data } = useEmailExists(emailToCheck, {
    onSuccess: (data) => {
      if (data.exists) {
        setExistingClientVisible.on();
      }
    },
  });

  const existingClient = data?.user;

  const resetAllFieldsExceptEmail = useCallback(
    () =>
      ['first_name', 'last_name', 'phone'].forEach((field) =>
        resetField(field),
      ),
    [resetField],
  );
  const handleSetExistingClient = () => {
    // \n is used to prevent the input from being rejected in validation
    setValue('email', existingClient?.email, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('first_name', existingClient?.first_name || '\n', {
      shouldValidate: true,
    });
    setValue('last_name', existingClient?.last_name || '\n', {
      shouldValidate: true,
    });
    setValue('phone', existingClient?.phone || '\n');
    clearErrors();
    setExistingClientVisible.off();
    setFieldsDisabled.on();
  };

  useEffect(() => {
    const watchEmail = watch('email');
    if (!watchEmail || !fieldsDisabled) {
      return;
    }
    if (watchEmail !== existingClient?.email && fieldsDisabled) {
      resetAllFieldsExceptEmail();
      setExistingClientVisible.off();
      setFieldsDisabled.off();
    }
  }, [
    existingClient?.email,
    fieldsDisabled,
    resetAllFieldsExceptEmail,
    setExistingClientVisible,
    setFieldsDisabled,
    watch,
  ]);

  return (
    <VStack spacing={6}>
      {!isEditing && (
        <RadioRow
          labelStyles={{ minWidth: 50, marginRight: 20 }}
          isDisabled={isEditing}
          label={'Type'}
          onChange={onRadioChange}
          value={capitalizeFirstLetter(adType || '')}
          options={['Rental', 'Sale']}
        />
      )}

      <EmailField
        shouldSuggestExistingUser={isEditing ? false : true}
        isDisabled={isEditing}
        fieldsDisabled={fieldsDisabled}
        onExistingClientClick={handleSetExistingClient}
        existingClient={existingClient}
        existingClientVisible={existingClientVisible}
        placeholder={'Email Address'}
        name={'email'}
      />

      <FormField
        inputType={'text'}
        name={'first_name'}
        required={true}
        requiredMessage={'First name is required'}
        placeholder={'First name'}
        isDisabled={fieldsDisabled}
      />

      <FormField
        inputType={'text'}
        name={'last_name'}
        required={true}
        requiredMessage={'Last name is required'}
        placeholder={'Last name'}
        isDisabled={fieldsDisabled}
      />

      <FormField
        inputType={'tel'}
        name={'phone'}
        placeholder={'Phone'}
        isDisabled={fieldsDisabled}
      />
    </VStack>
  );
};

export default ClientDetailsForm;
