import React, { ReactElement } from 'react';
import { HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useGeolocation } from '../../../services/hooks/useGeolocation';
import { useWeather } from '../../../services/hooks/useWeather';
import { ImageWithPlaceholder } from '../../Global/ImageWithPlaceholder';

export const Weather = (): ReactElement => {
  const location = useGeolocation();
  const { weather, loading } = useWeather(location);
  const icon = weather?.weather[0].icon || '04d';

  return (
    <Skeleton isLoaded={!loading}>
      <HStack align={'center'} direction={'row'}>
        <ImageWithPlaceholder
          src={`https://openweathermap.org/img/w/${icon}.png`}
          alt={weather?.name}
          boxSize={42}
        />

        <HStack
          spacing={0}
          fontSize={{ base: 25, md: 30, xl: 36 }}
          color={'pbGray.500'}
          fontWeight={100}
          letterSpacing={'tighter'}
        >
          <Text>{weather?.main.temp.toFixed(0)}</Text>
          <Text color={'pbBlue.100'}>°F</Text>
        </HStack>

        <VStack
          align={'baseline'}
          spacing={0}
          lineHeight={{ base: 1.1, md: 1.35, xl: 1.6 }}
        >
          <Text
            fontSize={{ base: 11, md: 12, xl: 14 }}
            letterSpacing={{ base: 'initial', md: 'tight' }}
          >
            {weather?.name}
          </Text>
          <Text
            color={'pbBlue.100'}
            fontSize={{ base: 10, md: 11, xl: 12 }}
            letterSpacing={{ base: 'initial', md: 'tighter' }}
          >
            {` Chance of Rain: ${
              weather?.rain ? `${weather?.rain['1h']}mm` : '0%'
            }`}
          </Text>
        </VStack>
      </HStack>
    </Skeleton>
  );
};
