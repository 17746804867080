import React, { ReactElement } from 'react';
import { SavedSearches } from '../components/Search/SavedSearches';
import { SearchForm } from '../components/Search/SearchForm';
import { Flex, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { SearchType } from '../services/apiTypes';
import { PageTitle } from '../components/Global/PageTitle';
import { PageHeading } from '../components/Global/PageHeading';
import { Main } from '../components/Global/Main';
import { useParams } from 'react-router-dom';
import { useSearchListingsAdvice } from '../services/hooks/api/search-listings/useSearchListingsAdvice';
import { useURLSearchParams } from '../services/hooks/useURLSearchParams';
import { RoundedBox } from '../components/Global/RoundedBox';

const Results = ({
  count,
  isLoading,
}: {
  count?: number;
  isLoading?: boolean;
}) => {
  return (
    <Flex d={'inline-flex'}>
      {'( Results '}
      <Skeleton minH={5} ml={2} minW={'20px'} isLoaded={!isLoading}>
        {count}
      </Skeleton>
      {' )'}
    </Flex>
  );
};

export const Search = (): ReactElement => {
  const { type } = useParams<{ type: SearchType }>();
  const { searchParams } = useURLSearchParams();

  const { data: dataAdvice, isLoading: isLoadingAdvice } =
    useSearchListingsAdvice(searchParams);

  return (
    <Main>
      <PageHeading>
        <PageTitle whiteSpace={'nowrap'}>
          Search {type === 'rental' ? 'Rentals' : 'Sales'}{' '}
          <Results count={dataAdvice?.count} isLoading={isLoadingAdvice} />
        </PageTitle>
      </PageHeading>

      <SimpleGrid
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: '380px minmax(0, 1fr)',
        }}
        gap={{ base: 3, xl: 5 }}
      >
        <RoundedBox isSide marginAlignment={'right'}>
          <SavedSearches />
        </RoundedBox>

        <RoundedBox p={0} order={{ base: -1, lg: 'initial' }}>
          <SearchForm type={type} />
        </RoundedBox>
      </SimpleGrid>
    </Main>
  );
};
