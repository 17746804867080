import { AgentProfileAction, AgentProfileActionType } from './actions';
import { AgentProfileState } from '.';

export const agentProfileReducer = (
  state: AgentProfileState,
  action: AgentProfileAction,
): AgentProfileState => {
  switch (action.type) {
    case AgentProfileActionType.SET_MODIFIED_AGENT:
      if (state.modifiedAgent) {
        return {
          ...state,
          modifiedAgent: {
            ...state.modifiedAgent,
            ...action.modifiedAgent,
          },
        };
      }

      return {
        ...state,
        modifiedAgent: action.modifiedAgent,
      };
  }
};
