import { AForm } from '../../../apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

export const useAForm = (id?: string): UseQueryResult<AForm> => {
  return useQuery<AForm>(
    ['aform', id],
    () => pbAgentFetcher(`v1/aform/${id}`),
    {
      enabled: !!id,
    },
  );
};
