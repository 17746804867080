import React, { ReactElement } from 'react';
import {
  Box,
  ButtonGroup,
  Checkbox,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import IconButton from 'components/Global/IconButton';
import { DownloadIcon, PaperPlaneIcon, TrashBlueIcon } from 'icons/icons';
import { AForm } from 'services/apiTypes';
import { applicationsGridOptions } from 'scenes/Applications';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useApplicationsContext } from 'contexts/ApplicationsContext';
import { useModal } from 'contexts/ModalContext';
import { SendApplicationModal } from 'components/Clients/Application/SendApplicationModal';
import { applicationDownloadUrl } from '../../constants';
import { useHistory } from 'react-router-dom';

dayjs.extend(advancedFormat);

type ApplicationListItemActionsProps = Pick<
  AForm,
  'application_form_id' | 'client' | 'report'
>;

const ApplicationListItemActions = ({
  application_form_id: id,
  client,
  report,
}: ApplicationListItemActionsProps) => {
  const {
    data: { isDeletingApplications },
    actions: { deleteSingleApplication },
  } = useApplicationsContext();
  const { openModal, defaultModalProps } = useModal();

  return (
    <HStack spacing={2} alignItems={'center'}>
      <ButtonGroup>
        <IconButton
          aria-label={'Download application form'}
          icon={<DownloadIcon fontSize={16} />}
          onClick={() => window.open(applicationDownloadUrl(id), '_self')}
        />
        <IconButton
          aria-label={'Send application'}
          icon={<PaperPlaneIcon fontSize={16} />}
          onClick={() =>
            openModal(
              <SendApplicationModal
                {...defaultModalProps}
                application_form_id={id}
                client={client}
                files={report}
              />,
            )
          }
        />
        <IconButton
          aria-label={'Delete application'}
          isLoading={isDeletingApplications}
          icon={<TrashBlueIcon fontSize={16} />}
          onClick={() => deleteSingleApplication(id)}
        />
      </ButtonGroup>
    </HStack>
  );
};

export const ApplicationListItem = ({
  applicant_type,
  building_address,
  unit,
  paid,
  time_created,
  application_form_id,
  client,
  report,
}: AForm): ReactElement => {
  const {
    data: { selectedApplicationsIds },
    actions: { handleSelectApplication },
  } = useApplicationsContext();
  const { name, email } = client;
  const history = useHistory();

  const redirectToClient = () =>
    history.push(`/clients/rental/${client.user_id}/application`, {
      clientId: client.user_id,
    });

  return (
    <SimpleGrid
      borderWidth={'1px'}
      borderColor={'pbGray.100'}
      borderRadius={'lg'}
      p={5}
      alignItems={'center'}
      role={'group'}
      {...applicationsGridOptions}
      color={'pbBlack.100'}
      fontSize={'sm'}
    >
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        onClick={redirectToClient}
        _hover={{ cursor: 'pointer' }}
      >
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          color={'pbBlack.100'}
          fontWeight={'bold'}
        >
          {name}
        </Text>
        <Text color={'pbBlue.100'} fontSize={{ base: 'sm', md: 'md' }}>
          {email}
        </Text>
      </VStack>

      <Text>{applicant_type}</Text>
      <Text>{building_address}</Text>
      <Text display={{ base: 'none', md: 'block' }}>{unit}</Text>
      <Text display={{ base: 'none', lg: 'block' }}>{paid ? 'Yes' : 'No'}</Text>
      <Text display={{ base: 'none', xl: 'block' }}>
        {dayjs(time_created * 1000).format('MMM Do, h:mma')}
      </Text>
      <Flex justifySelf={'flex-end'} alignItems={'center'}>
        <Box
          visibility={'hidden'}
          _groupHover={{ visibility: 'visible' }}
          backgroundColor={'white'}
        >
          <ApplicationListItemActions
            application_form_id={application_form_id}
            client={client}
            report={report}
          />
        </Box>
        <Checkbox
          ml={4}
          isChecked={selectedApplicationsIds.includes(application_form_id)}
          onChange={() => handleSelectApplication(application_form_id)}
        />
      </Flex>
    </SimpleGrid>
  );
};
