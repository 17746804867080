export interface HTTPError extends Error {
  response: Response;
}

export class HTTPError extends Error {
  constructor(response: Response, ...params: string[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }

    this.name = 'HTTPError';
    this.response = response;
  }
}
