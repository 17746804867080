import AttachmentsList from 'components/Mailbox/AttachmentsLibrary/AttachmentsList';
import { attachmentDownloadUrl } from '../constants';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Option } from 'services/apiTypes';
import { useGmailAttachments } from 'services/hooks/api/gmail/useGmailAttachments';
import {
  AttachmentInLibrary,
  AttachmentOriginType,
  AttachmentType,
} from 'types/AttachmentInLibrary';
import { ChildrenProps } from 'types/ChildrenProps';
import { TabsType } from 'components/Global/OwnTabs';
import { useBoolean } from '@chakra-ui/react';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import { fetchFile } from 'services/fetchFile';

interface AttachmentLibraryContextType {
  data: {
    tabs: TabsType[];
    attachmentsOriginOptions: Option[];
    attachments: AttachmentInLibrary[];
    attachmentsToCurrentPage: AttachmentInLibrary[];
    isLoadingAttachments: boolean;
    page: number;
    defaultPageSize: number;
    attachmentsFileType: AttachmentType;
    attachmentsOriginType: AttachmentOriginType;
    selectedAttachments: string[];
    areAllAttachmentsChecked: boolean;
    isGeneratingZip: boolean;
  };
  actions: {
    goToNextPage: () => void;
    goToPreviousPage: () => void;
    setAttachmentsFileType: (type: AttachmentType) => void;
    setAttachmentsOriginType: (type: AttachmentOriginType) => void;
    setSearchValue: (value: string) => void;
    toggleAttachmentSelected: (attachmentId: string) => void;
    toggleCheckAllAttachments: () => void;
    downloadMultipleAttachments: () => void;
  };
}

const defaultPageSize = 14;

const attachmentsLibraryContextDefaultValues: AttachmentLibraryContextType = {
  data: {
    tabs: [],
    attachmentsOriginOptions: [],
    attachments: [],
    attachmentsToCurrentPage: [],
    isLoadingAttachments: false,
    page: 1,
    defaultPageSize,
    attachmentsFileType: AttachmentType.ALL,
    attachmentsOriginType: AttachmentOriginType.ALL,
    selectedAttachments: [],
    areAllAttachmentsChecked: false,
    isGeneratingZip: false,
  },
  actions: {
    goToNextPage: () => null,
    goToPreviousPage: () => null,
    setAttachmentsFileType: () => null,
    setAttachmentsOriginType: () => null,
    setSearchValue: () => null,
    toggleAttachmentSelected: () => null,
    toggleCheckAllAttachments: () => null,
    downloadMultipleAttachments: () => null,
  },
};

const AttachmentLibrary = createContext<AttachmentLibraryContextType>(
  attachmentsLibraryContextDefaultValues,
);

export function useAttachmentsLibrary(): AttachmentLibraryContextType {
  return useContext(AttachmentLibrary);
}

export function AttachmentLibraryContextProvider({
  children,
}: ChildrenProps): React.ReactElement {
  const [page, setPage] = useState(1);
  const [attachmentsFileType, setAttachmentsFileType] =
    useState<AttachmentType>(AttachmentType.ALL);
  const [attachmentsOriginType, setAttachmentsOriginType] =
    useState<AttachmentOriginType>(AttachmentOriginType.ALL);
  const { data, isLoading: isLoadingAttachments } = useGmailAttachments();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedAttachments, setSelectedAttachments] = useState<string[]>([]);
  const [isGeneratingZip, setIsGeneratingZip] = useBoolean(false);

  useEffect(() => {
    setPage(1);
  }, [attachmentsFileType]);

  const attachments = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.messages.reduce((acc, message) => {
      const messageAttachments = message.attachments.map((attachment) => ({
        extension: attachment.filename.split('.').pop() || '',
        id: attachment.content.attachmentId,
        threadId: message.thread_id,
        filename: attachment.filename,
        content: attachment.content,
        fileUrl: attachmentDownloadUrl(message.id, attachment.filename),
        messageId: message.id,
        message_id: message.message_id,
        type: message.label_ids.includes('SENT')
          ? AttachmentOriginType.SENT
          : AttachmentOriginType.RECEIVED,
        subject: message.subject,
        sendAt: message.time_created,
        sentTo: message.to_name || message.to_email,
        receivedFrom: message.from_name || message.from_email,
      }));
      return [...acc, ...messageAttachments];
    }, [] as AttachmentInLibrary[]);
  }, [data]);

  const documentAttachments = useMemo(() => {
    const documentExtensions = ['pdf', 'doc', 'docx', 'txt', 'eml'];
    return attachments.filter((attachment) =>
      documentExtensions.includes(attachment.extension.toLocaleLowerCase()),
    );
  }, [attachments]);
  const picturesAttachments = useMemo(() => {
    const picturesExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
    return attachments.filter((attachment) =>
      picturesExtensions.includes(attachment.extension.toLowerCase()),
    );
  }, [attachments]);

  const tabs: TabsType[] = [
    {
      abbreviation: 'all-files',
      label: 'All files',
      content: <AttachmentsList />,
      badgeCount: attachments.length,
    },
    {
      abbreviation: 'documents',
      label: 'Documents',
      content: <AttachmentsList />,
      badgeCount: documentAttachments.length,
    },
    {
      abbreviation: 'pictures',
      label: 'Pictures',
      content: <AttachmentsList />,
      badgeCount: picturesAttachments.length,
    },
  ];

  const attachmentsOriginOptions = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Received',
      value: 'INBOX',
    },
    {
      label: 'Sent',
      value: 'SENT',
    },
  ];

  const goToNextPage = () => setPage(page + 1);
  const goToPreviousPage = () => setPage(page - 1);

  const filtered = useMemo(() => {
    let filteredAttachments = attachments;
    if (attachmentsFileType !== AttachmentType.ALL) {
      filteredAttachments =
        attachmentsFileType === AttachmentType.DOCUMENTS
          ? documentAttachments
          : picturesAttachments;
    }

    if (attachmentsOriginType !== AttachmentOriginType.ALL) {
      filteredAttachments = filteredAttachments.filter(
        (attachment) => attachment.type === attachmentsOriginType.toLowerCase(),
      );
    }

    if (searchValue !== '') {
      return filteredAttachments.filter(
        (attachment) =>
          attachment.filename
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          attachment.subject
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          attachment.sentTo
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          attachment.receivedFrom
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()),
      );
    }

    return filteredAttachments;
  }, [
    attachments,
    attachmentsFileType,
    attachmentsOriginType,
    documentAttachments,
    picturesAttachments,
    searchValue,
  ]);

  const attachmentsToCurrentPage = useMemo(() => {
    const startIndex = (page - 1) * defaultPageSize;
    const endIndex = page * defaultPageSize;

    return filtered.slice(startIndex, endIndex);
  }, [page, filtered]);

  const toggleAttachmentSelected = (attachmentId: string): void => {
    const index = selectedAttachments.indexOf(attachmentId);
    if (index === -1) {
      setSelectedAttachments([...selectedAttachments, attachmentId]);
    } else {
      setSelectedAttachments(
        selectedAttachments.filter((attachment) => attachment !== attachmentId),
      );
    }
  };

  const toggleCheckAllAttachments = (): void => {
    if (selectedAttachments.length === attachmentsToCurrentPage.length) {
      setSelectedAttachments([]);
    } else {
      setSelectedAttachments(
        attachmentsToCurrentPage.map((attachment) => attachment.id),
      );
    }
  };

  const downloadMultipleAttachments = useCallback(async () => {
    setIsGeneratingZip.on();
    const urls = selectedAttachments.map((id) => {
      const attachment = filtered.find((attachment) => attachment.id === id);
      if (!attachment) {
        return;
      }

      const timestampedFilename = `${attachment.filename.split('.')[0]}_${
        attachment.sendAt
      }.${attachment.extension}`;

      return {
        url: attachmentDownloadUrl(attachment.messageId, attachment.filename),
        filename: timestampedFilename,
      };
    });

    const zipFile = new JSZip();

    const filePromises = urls.map(async (attachment) => {
      if (!attachment) {
        return;
      }
      const res = await fetchFile(attachment.url);
      const blob = await res.blob();
      zipFile.file(`${attachment.filename}`, blob);
    });

    await Promise.all(filePromises);

    const content = await zipFile.generateAsync({ type: 'blob' });
    saveAs(
      content,
      `attachments_${dayjs(new Date()).format('MM-DD-YYYY_h:mm a')}.zip`,
    );

    setIsGeneratingZip.off();
    setSelectedAttachments([]);
  }, [filtered, selectedAttachments, setIsGeneratingZip]);

  const value = {
    data: {
      tabs,
      attachmentsOriginOptions,
      attachments: filtered,
      attachmentsToCurrentPage,
      isLoadingAttachments,
      page,
      defaultPageSize,
      attachmentsFileType,
      attachmentsOriginType,
      selectedAttachments,
      areAllAttachmentsChecked: attachmentsToCurrentPage.length
        ? selectedAttachments.length === attachmentsToCurrentPage.length
        : false,
      isGeneratingZip,
    },
    actions: {
      goToNextPage,
      goToPreviousPage,
      setAttachmentsFileType,
      setAttachmentsOriginType,
      setSearchValue,
      toggleAttachmentSelected,
      toggleCheckAllAttachments,
      downloadMultipleAttachments,
    },
  };

  return (
    <AttachmentLibrary.Provider value={value}>
      {children}
    </AttachmentLibrary.Provider>
  );
}
