import React, { ReactElement } from 'react';
import {
  Flex,
  HStack,
  SimpleGrid,
  Skeleton,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react';
import { RoundedBox } from 'components/Global/RoundedBox';
import { useDealsSheetsOverview } from 'services/hooks/api/deals/useDealSheetsOverview';
import DealsSummaryBox, { DealsSummaryBoxProps } from './DealsSummaryBox';
import { ReactComponent as LandSalesSVG } from '../../../icons/land-sales.svg';
import { ReactComponent as SellPropertySVG } from '../../../icons/sell-property.svg';
import { ReactComponent as TodaySVG } from '../../../icons/today.svg';
import { ReactComponent as WalletSVG } from '../../../icons/wallet.svg';
import { Dropdown } from 'components/Global/Dropdown';
import { useState } from 'react';
import { getLastYearsInRangeOf } from 'services/getLastYearsInRangeOf';
import { useDealsContext } from 'contexts/DealsContext';
import { onSetMonth, onSetYear } from 'contexts/DealsContext/actions';
import { currencyFormat } from 'services/currencyFormat';

type DealsBox = Omit<DealsSummaryBoxProps, 'headText'> & {
  hasCurrency?: boolean;
};

const infoBoxes: DealsBox[] = [
  {
    bgColor: 'pbGreen.310',
    headColor: 'pbGreen.300',
    subTitleColor: 'pbGreen.500',
    subTitleText: 'Average Deal Size',
    icon: SellPropertySVG,
    hasCurrency: true,
  },
  {
    bgColor: 'pbPurple.710',
    headColor: 'pbPurple.700',
    subTitleColor: 'pbPurple.400',
    subTitleText: 'Average Commission',
    icon: WalletSVG,
    hasCurrency: true,
  },
  {
    bgColor: 'pbYellow.510',
    headColor: 'pbYellow.500',
    subTitleColor: 'pbYellow.500',
    subTitleText: 'Number of Deals',
    icon: LandSalesSVG,
  },
  {
    bgColor: 'pbBlue.510',
    headColor: 'pbBlue.500',
    subTitleColor: 'pbBlue.850',
    subTitleText: 'YTD Commission',
    icon: TodaySVG,
    hasCurrency: true,
  },
];

const monthOptions = [
  'All',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'October',
  'November',
  'December',
];

const UpperLeftSection = (): ReactElement => {
  const [activeTab, setActiveTab] = useState(0);
  const { data } = useDealsSheetsOverview();

  const { dealsState, dealsDispatch } = useDealsContext();

  const mappedInfoBoxes = infoBoxes.map((el, i) => {
    if (!data) return <Skeleton key={i} minW={'355px'} minH={35} />;

    const apiValueOfKey = Object.values(data)[i];
    const boxValue = el.hasCurrency
      ? currencyFormat(apiValueOfKey)
      : apiValueOfKey;

    const mutated = {
      ...el,
      headText: boxValue,
    };

    return <DealsSummaryBox {...mutated} key={i} />;
  });

  return (
    <RoundedBox>
      <Flex mb={5} justifyContent={'space-between'} wrap={'wrap'}>
        <Tabs isLazy defaultIndex={0} index={activeTab} onChange={setActiveTab}>
          <TabList>
            <Tab h={'38px'}>All</Tab>
            <Tab h={'38px'} className={'notImplemented'}>
              Rentals
            </Tab>
            <Tab h={'38px'} className={'notImplemented'}>
              Sales
            </Tab>
          </TabList>
        </Tabs>
        <HStack ml={'auto'}>
          <Dropdown
            options={getLastYearsInRangeOf(3)}
            onValueChange={(value) => dealsDispatch(onSetYear(value))}
            value={dealsState.year}
            fontSize={'14px'}
            variant={'grayDropdown'}
            menuProps={{
              placement: 'bottom-end',
            }}
          />
          <Dropdown
            options={monthOptions}
            onValueChange={(value) => dealsDispatch(onSetMonth(value))}
            value={dealsState?.month || 'Month'}
            fontSize={'14px'}
            variant={'grayDropdown'}
            menuProps={{
              placement: 'bottom-end',
            }}
          />
        </HStack>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 3, md: 5 }}>
        {mappedInfoBoxes}
      </SimpleGrid>
    </RoundedBox>
  );
};

export default UpperLeftSection;
