import React from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import { mediaServer } from 'services/mediaServer';

export interface UserAvatarProps extends AvatarProps {
  transcode?: string;
}

const UserAvatar: FC<UserAvatarProps> = ({
  size,
  variant,
  transcode = 'jango-contact',
}: UserAvatarProps) => {
  const { data } = useAgentProfile(
    localStorage.getItem('session_id') || undefined,
  );

  return (
    <Avatar
      src={data?.photo ? mediaServer(data?.photo, transcode) : undefined}
      name={data?.name}
      size={size || 'sm'}
      variant={variant || 'circle'}
    />
  );
};

export default UserAvatar;
