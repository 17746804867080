import React, { ReactElement } from 'react';
import { OwnTabs, TabsType } from 'components/Global/OwnTabs';
import { RoundedBox } from 'components/Global/RoundedBox';
import { useState } from 'react';
import { StringOrNumber } from 'services/apiTypes';
import PersonalDetails from './components/PersonalDetails/PersonalDetails';
import Integrations from './components/Integrations/Integrations';
import Payments from './components/Payments/Payments';
import Settings from './components/Settings/Settings';

const tabsList: TabsType[] = [
  {
    label: 'Personal Details',
    abbreviation: 'personalDetails',
    content: <PersonalDetails />,
  },
  {
    label: 'Settings',
    abbreviation: 'settings',
    content: <Settings />,
  },
  {
    label: 'Payments',
    abbreviation: 'payments',
    content: <Payments />,
  },
  {
    label: 'Outside Accounts/Integrations',
    abbreviation: 'outsideIntegrations',
    content: <Integrations />,
  },
];

const TabsWrapper = (): ReactElement => {
  const [selectedTab, setSelectedTab] =
    useState<StringOrNumber>('personalDetails');

  return (
    <RoundedBox p={0}>
      <OwnTabs
        tabs={tabsList}
        value={selectedTab}
        onChange={setSelectedTab}
        tabListStyles={{ p: 5 }}
        spacingBetween={'10px'}
      />
    </RoundedBox>
  );
};

export default TabsWrapper;
