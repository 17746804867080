import React, { ReactElement, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  HStack,
  Input,
  VStack,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { usePreviewListingsPost } from '../../../services/hooks/api/preview-listings/usePreviewListings';
import {
  PreviewListingsPostBody,
  SuggestBuildingsResponse,
} from '../../../services/apiTypes';
import { ActionModal } from 'components/Global/ActionModal';
import { RadioRow } from '../../Form/RadioRow';
import { useSearchFormOptions } from '../../../services/hooks/api/search-form/useSearchFormOptions';
import { FormSectionRow } from '../../Search/SearchForm';
import { useSuggestBuildings } from '../../../services/hooks/api/suggest/useSuggestBuildings';
import { AutoCompleteInput } from '../../Form/AutoCompleteInput';
import {
  useBuildingsApartment,
  useBuildingsApartmentPost,
} from '../../../services/hooks/api/buildings/useBuildingsApartment';
import { BuildingsApartmentPostBody } from '../../../services/hooks/api/buildings/apiTypes';
import dayjs from 'dayjs';
import { AddressPopover } from './components/AddressPopover';
import { useMyListingsContext } from '../../../contexts/MyListingsContext';
import { setListingAction } from '../../../contexts/MyListingsContext/actions';
import { DayPicker } from '../../Global/DayPicker';
import { machineDateFormat } from 'services/dateFormat';
import { redirectToOverview } from 'services/redirectToOverview';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';

const adTypeLabels = [
  {
    label: 'Rental',
    value: 'rental',
  },
  {
    label: 'For Sale',
    value: 'sale',
  },
];

const ListingFormControl = ({ children }: FormControlProps) => (
  <FormControl display={'flex'} w={'100%'} alignItems={'center'}>
    {children}
  </FormControl>
);

export interface AddListingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddListingModal = ({
  onClose,
  isOpen,
}: AddListingModalProps): ReactElement => {
  const toast = useToast();
  const methods = useForm();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const [addressField, setAddressField] = useState('');
  const [isPopoverBuildingVisible, setPopoverBuildingVisibility] = useBoolean();
  const [isPopoverUnitVisible, setPopoverUnitVisibility] = useBoolean();

  const [unitState, setUnitState] = useState<BuildingsApartmentPostBody>({
    unit: '',
  });

  const { isLoading: isLoadingFormOptions, data: formOptions } =
    useSearchFormOptions();

  const [selectedAddress, setSelectedAddress] =
    useState<SuggestBuildingsResponse>();

  const [newListing, setListing] = useState<PreviewListingsPostBody>({
    price: '',
    apartment_id: '',
    ad_type: 'rental',
    furnished_type: 'Unfurnished',
    access_type: 'OPEN',
    available_from: dayjs().format('YYYY-MM-DD'),
  });

  const { data: suggestedBuildings } = useSuggestBuildings({
    search: addressField,
  });

  const { furnished, bedrooms, bathrooms } = formOptions || {};
  const { mutate: onAddListing, isLoading: isUploading } =
    usePreviewListingsPost();

  const { data: buildingsApartment, isLoading: isLoadingBuildingsApartment } =
    useBuildingsApartment(selectedAddress?.building_id, unitState.unit, {
      onSuccess: (data) => {
        const { bedrooms, bathrooms, size, unit } = data;

        setUnitState((prev) => ({
          ...prev,
          bedrooms,
          bathrooms,
          unit,
          size,
        }));
        setPopoverUnitVisibility.on();
      },
      onError: () => {
        setPopoverUnitVisibility.on();
        setUnitState((prev) => ({
          unit: prev.unit,
        }));
      },
    });

  const { mutateAsync: onAddUnit, isLoading: isAddingUnit } =
    useBuildingsApartmentPost();

  const isFormValidated = useMemo(
    () =>
      newListing.price &&
      unitState.unit &&
      unitState.bathrooms &&
      unitState.bedrooms &&
      selectedAddress,
    [newListing, unitState, selectedAddress],
  );
  const { dispatch } = useMyListingsContext();

  const onSubmitListing = (apartment_id: string) =>
    onAddListing(
      {
        ...newListing,
        apartment_id,
      },
      {
        onSuccess: (response) => {
          dispatch && dispatch(setListingAction(response.advertisement_id));
          toast({
            title: 'Success',
            description: 'Successfully added new listing',
            status: 'success',
            position: 'bottom-right',
            duration: 5000,
            isClosable: true,
          });
          onClose();
          redirectToOverview('/my-listings', push, pathname);
        },
      },
    );

  const onSubmit = () => {
    if (!isFormValidated) {
      return;
    }

    buildingsApartment
      ? onSubmitListing(buildingsApartment.apartment_id)
      : onAddUnit({
          body: unitState,
          buildingId: selectedAddress?.building_id,
        }).then(({ apartment_id }) => onSubmitListing(apartment_id));
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={'Add New Listing'}
      isDisabled={!isFormValidated}
      isLoading={
        isUploading ||
        isLoadingFormOptions ||
        isAddingUnit ||
        isLoadingBuildingsApartment
      }
      onSubmit={onSubmit}
      size={'6xl'}
      status={
        isAddingUnit
          ? 'Saving new unit into building...'
          : isUploading
          ? 'Uploading listing...'
          : undefined
      }
    >
      <FormProvider {...methods}>
        <VStack>
          <RadioRow
            label={'Type'}
            onChange={(e) =>
              setListing((prev) => ({
                ...prev,
                ad_type:
                  adTypeLabels.find((o) => o.label === e)?.value || 'rental',
              }))
            }
            value={
              adTypeLabels.find((o) => o.value === newListing.ad_type)?.label
            }
            options={adTypeLabels.map((o) => o.label)}
          />

          <ListingFormControl>
            <FormLabel>Address</FormLabel>

            <HStack w={'100%'} mb={3}>
              <AddressPopover
                isOpen={isPopoverBuildingVisible && !!selectedAddress}
                onClose={() => setPopoverBuildingVisibility.off()}
                triggerItem={
                  <Box flex={1}>
                    <AutoCompleteInput
                      onChange={(itemsList) => {
                        setSelectedAddress(
                          suggestedBuildings?.find(
                            (b) => b.address === itemsList[0],
                          ),
                        );
                        setPopoverBuildingVisibility.on();
                      }}
                      onSearch={setAddressField}
                      value={
                        selectedAddress?.address
                          ? [selectedAddress?.address]
                          : []
                      }
                      placeholder={'Address'}
                      required
                      items={suggestedBuildings?.map((building) => ({
                        name: building.address,
                      }))}
                      isSingleInput
                    />
                  </Box>
                }
                items={
                  selectedAddress && [
                    {
                      label: 'City',
                      value: selectedAddress?.city,
                    },
                    {
                      label: 'State',
                      value: selectedAddress?.state,
                    },
                    {
                      label: 'Pets',
                      value: selectedAddress?.pet_policy,
                    },
                    {
                      label: 'Ownership',
                      value: selectedAddress?.ownership,
                    },
                    {
                      label: 'Building type',
                      value: selectedAddress?.building_type,
                    },
                  ]
                }
                heading={'Building info'}
              />

              <AddressPopover
                isOpen={isPopoverUnitVisible}
                onClose={() => setPopoverUnitVisibility.off()}
                isLoading={isLoadingBuildingsApartment}
                triggerItem={
                  <Input
                    placeholder={'Apt #'}
                    w={100}
                    onChange={(e) =>
                      setUnitState((prev) => ({
                        ...prev,
                        unit: e.target.value,
                      }))
                    }
                    value={buildingsApartment?.unit}
                    required
                    isDisabled={!selectedAddress}
                  />
                }
                items={
                  buildingsApartment && [
                    {
                      label: 'Unit',
                      value: buildingsApartment?.unit,
                    },
                    {
                      label: 'Size',
                      value: buildingsApartment?.size.toString(),
                    },
                    {
                      label: 'Baths',
                      value: buildingsApartment?.bathrooms.toString(),
                    },
                    {
                      label: 'Beds',
                      value: buildingsApartment?.bedrooms,
                    },
                  ]
                }
                heading={'Unit info'}
                notFoundText={
                  'Not found in selected building. Provided unit will be created on submit'
                }
              />
            </HStack>
          </ListingFormControl>

          <ListingFormControl>
            <FormLabel>Details</FormLabel>

            <HStack w={'100%'} mb={3}>
              <Input
                placeholder={'Price*'}
                onChange={(e) =>
                  setListing((prev) => ({
                    ...prev,
                    price: parseFloat(e.target.value) || 0,
                  }))
                }
                value={newListing?.price || ''}
                required
                type={'number'}
              />
              <DayPicker
                placeholder={'Available from'}
                style={{
                  width: '100%',
                  height: 38,
                }}
                inputProps={{
                  fontWeight: 'normal',
                  py: 2,
                }}
                onDayChange={(a) =>
                  setListing((prev) => ({
                    ...prev,
                    available_from: dayjs(a).format('YYYY-MM-DD'),
                  }))
                }
                value={dayjs(newListing?.available_from).format(
                  machineDateFormat,
                )}
                formatDate={(date) => dayjs(date).format(machineDateFormat)}
              />

              <Input
                placeholder={'Size'}
                onChange={(e) =>
                  setUnitState((prev) => ({
                    ...prev,
                    size: parseFloat(e.target.value),
                  }))
                }
                type={'number'}
                value={unitState?.size || ''}
                isDisabled={!!buildingsApartment}
              />
            </HStack>
          </ListingFormControl>

          <FormSectionRow>
            <RadioRow
              isDisabled={!!buildingsApartment}
              label={'Beds'}
              onChange={(bedrooms) =>
                setUnitState((prev) => ({
                  ...prev,
                  bedrooms,
                }))
              }
              value={unitState.bedrooms}
              options={bedrooms}
            />
          </FormSectionRow>

          <FormSectionRow>
            <RadioRow
              isDisabled={!!buildingsApartment}
              label={'Baths'}
              onChange={(bathrooms) =>
                setUnitState((prev) => ({
                  ...prev,
                  bathrooms,
                }))
              }
              value={unitState.bathrooms?.toString()}
              options={bathrooms}
            />
          </FormSectionRow>

          <FormSectionRow>
            <RadioRow
              label={'Furnished'}
              onChange={(e) =>
                setListing((prev) => ({
                  ...prev,
                  furnished_type: e.toString(),
                }))
              }
              value={newListing.furnished_type}
              options={furnished}
              isLoading={isLoadingFormOptions}
            />
          </FormSectionRow>

          <FormSectionRow>
            <RadioRow
              label={'Access type'}
              onChange={(e) =>
                setListing((prev) => ({
                  ...prev,
                  access_type: e.toString(),
                }))
              }
              value={newListing.access_type}
              options={['OPEN', 'EXCLUSIVE']}
            />
          </FormSectionRow>
        </VStack>
      </FormProvider>
    </ActionModal>
  );
};
