import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailLabel } from './apiTypes';

export const useGmailLabels = (): UseQueryResult<GmailLabel[]> =>
  useQuery<GmailLabel[]>(
    'gmail-labels',
    () => pbAgentFetcher('v1/gmail-labels'),
    {
      refetchOnWindowFocus: false,
    },
  );
