import React, { ReactElement, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { SearchListingResponse } from '../../../services/apiTypes';
import { DownloadIcon, PaperPlaneIcon } from '../../../icons/icons';
import { ListingDetailsTile } from '../../Global/ListingDetailsTile';
import { SendListingsModal } from '../../Global/SendListingsModal';
import { useClient } from 'services/hooks/api/clients/useClient';
import { useModal } from 'contexts/ModalContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ListingDetailsPDF } from 'components/pdf/ListingDetails';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';

type Tabs = 'Saved Listings' | 'Client Saved Listings';

const tabs: Tabs[] = ['Saved Listings', 'Client Saved Listings'];

export const SavedListings = (): ReactElement => {
  const { clientId } = useParams<ClientRouteParams>();
  const { data: clientData } = useClient(clientId);
  const { openModal, defaultModalProps } = useModal();

  const client_saved = clientData?.client_saved || [];
  const agent_saved = clientData?.agent_saved || [];

  const [checkedAds, setCheckedAds] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<Tabs>(tabs[0]);
  const data: SearchListingResponse[] =
    activeTab === 'Client Saved Listings' ? client_saved : agent_saved;

  const isCheckedAll = data.length ? checkedAds.length === data.length : false;

  const checkedListings = useMemo(
    () =>
      data.filter((listing) => checkedAds.includes(listing.advertisement_id)),
    [checkedAds, data],
  );

  const areAllSelectedAvailableToSend = useMemo(() => {
    if (!data.length || !checkedAds.length) return false;
    return checkedListings.every((listing) => !listing.sent);
  }, [checkedAds.length, checkedListings, data.length]);

  const onCheckAll = () => {
    if (!data) return;
    setCheckedAds(isCheckedAll ? [] : data.map((d) => d.advertisement_id));
  };

  const onCheckAdvertisement = (advertisementId: string) => {
    setCheckedAds((prev) =>
      prev.includes(advertisementId)
        ? prev.filter((v) => v !== advertisementId)
        : [...prev, advertisementId],
    );
  };
  if (!clientData) return <Skeleton />;

  const onClickSend = () => {
    openModal(
      <SendListingsModal
        clients={[clientData]}
        listings={data.filter((advertisement) =>
          checkedAds.includes(advertisement.advertisement_id),
        )}
        {...defaultModalProps}
      />,
    );
    setCheckedAds([]);
  };

  return (
    <Box px={{ base: 3, lg: 5 }}>
      <Flex
        justifyContent={'space-between'}
        alignItems={{ base: 'start', sm: 'center' }}
        mb={{ base: 2, md: 5 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <ButtonGroup spacing={{ base: 2, lg: 5 }} mb={{ base: 2, md: 0 }}>
          {tabs.map((tab) => (
            <Button
              size={'sm'}
              key={tab}
              variant={tab === activeTab ? 'blue' : 'gray'}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </Button>
          ))}
        </ButtonGroup>

        {data.length ? (
          <HStack alignItems={'center'} ml={5} mt={{ base: 1, md: 0 }}>
            <PDFDownloadLink
              document={<ListingDetailsPDF listings={checkedListings} />}
              fileName={`${dayjs(new Date()).format('MM-DD-YYYY')}.pdf`}
            >
              {({ loading }) => (
                <Button
                  leftIcon={<DownloadIcon fontSize={16} />}
                  variant={'blueLight'}
                  size={'sm'}
                  isDisabled={!data?.length || !checkedAds?.length}
                  isLoading={loading}
                  loadingText={'Generating...'}
                >
                  Download PDF
                </Button>
              )}
            </PDFDownloadLink>

            {data && activeTab === 'Saved Listings' && (
              <Button
                leftIcon={<PaperPlaneIcon fontSize={16} />}
                variant={'blueLight'}
                size={'sm'}
                isDisabled={
                  !data?.length ||
                  !checkedAds?.length ||
                  !areAllSelectedAvailableToSend
                }
                onClick={onClickSend}
              >
                Send
              </Button>
            )}
            <Box display={'flex'}>
              <FormLabel
                htmlFor={'check-all-listings'}
                fontSize={{ base: 'xs', xl: 'sm' }}
                display={{ base: 'block', md: 'none', xl: 'block' }}
                minW={30}
                color={'pbBlue.100'}
                cursor={'pointer'}
                ml={5}
              >
                Check all
              </FormLabel>
              <Tooltip label={'Check all'} placement={'left'}>
                <Box>
                  <Checkbox
                    id={'check-all-listings'}
                    isChecked={isCheckedAll}
                    onChange={onCheckAll}
                    ml={3}
                  />
                </Box>
              </Tooltip>
            </Box>
          </HStack>
        ) : null}
      </Flex>

      <VStack divider={<Divider />} spacing={5}>
        {data.length ? (
          data?.map((listing) => (
            <ListingDetailsTile
              isClientSaved={activeTab === 'Client Saved Listings'}
              key={listing.advertisement_id}
              isChecked={checkedAds.includes(listing.advertisement_id)}
              onCheck={() => onCheckAdvertisement(listing.advertisement_id)}
              listing={listing}
            />
          ))
        ) : (
          <Text alignSelf={'start'}>No listings found.</Text>
        )}
      </VStack>
    </Box>
  );
};
