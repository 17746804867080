import React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { AutoCompleteInput } from 'components/Form/AutoCompleteInput';
import { ChevronIcon } from 'icons/icons';
import _ from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useSuggestAgents } from 'services/hooks/api/suggest/useSuggestAgents';

interface AgentsDropdownProps extends Omit<ButtonProps, 'onSelect'> {
  search?: string;
  label?: string;
  onSelect: (agentId: string) => void;
  onSetSearch: (search: string) => void;
}

const AgentsDropdown: FC<AgentsDropdownProps> = ({
  search,
  label = 'Agent',
  onSelect,
  onSetSearch,
  ...triggerProps
}: AgentsDropdownProps) => {
  const [selectedAgent, setSelectedAgent] = useState<string[]>([]);

  const { isOpen, onToggle } = useDisclosure();

  const { data: agentsData } = useSuggestAgents({
    search,
  });

  const agentNames = useMemo(() => {
    if (!agentsData) return [];

    return _.chain(agentsData)
      .map((el) => ({
        name: el.name,
      }))
      .uniq()
      .value();
  }, [agentsData]);

  useEffect(
    () =>
      onSelect(
        agentsData?.find((el) => el.name === selectedAgent[0])?.user_id || '',
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAgent],
  );

  return (
    <Menu placement={'top'}>
      <MenuButton
        as={Button}
        variant={'grayDropdown'}
        {...triggerProps}
        rightIcon={
          <ChevronIcon
            width={'12px'}
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition={'transform 0.3s'}
          />
        }
        onClick={onToggle}
        py={1}
        justifyContent={'space-between'}
      >
        {selectedAgent[0] || label}
      </MenuButton>
      <MenuList p={0}>
        <AutoCompleteInput
          onChange={(value) => setSelectedAgent(value)}
          value={selectedAgent}
          placeholder={'find agent'}
          items={agentNames}
          onSearch={onSetSearch}
          isSingleInput
        />
      </MenuList>
    </Menu>
  );
};

export default AgentsDropdown;
