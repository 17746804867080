import { useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { OpInvoicesPostBody } from './apiTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOpInvoicesPost = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ body, type }: { body: OpInvoicesPostBody; type: string }) => {
      return pbAgentFetcher(`v1/op-invoices${type ? `?type=${type}` : ''}`, {
        body: JSON.stringify(body),
        method: 'POST',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('op-invoices');
      },
    },
  );
};
