import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/layout';
import { ListingHistoryRow } from './ListingHistoryRow';

const data = [
  {
    year: '2019',
    items: [
      {
        date: '9/10/2019',
        title: 'Listed for Rent, $3,500',
        agent: {
          last_name: 'Snaider',
          first_name: 'Drake',
          company: 'Compass',
        },
      },
      {
        date: '9/10/2019',
        title: 'Rented, DOM 30',
        agent: {
          last_name: 'Journey',
          first_name: 'Elizabeth',
          company: 'Compass',
        },
      },
    ],
  },
  {
    year: '2018',
    items: [
      {
        date: '9/10/2018',
        title: 'Listed for Rent, $3,500',
        agent: {
          last_name: 'Snaider',
          first_name: 'Drake',
          company: 'Compass',
        },
      },
    ],
  },
  {
    year: '2017',
    items: [],
  },
  {
    year: '2016',
    items: [],
  },
  {
    year: '2015',
    items: [],
  },
];
export const ListingHistory = (): ReactElement => {
  return (
    <Box>
      {data.map((row, index) => {
        return <ListingHistoryRow data={row} key={index} />;
      })}
    </Box>
  );
};
