import { GmailThreadsInput, GmailThreadsResponse } from './apiTypes';
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationResult,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';
import { isNil } from 'lodash';

export const defaultParams: GmailThreadsInput = {
  count: 10,
  label: 'INBOX',
};

export const useGmailThreads = (
  params?: GmailThreadsInput,
  options?: UseInfiniteQueryOptions<GmailThreadsResponse>,
): UseInfiniteQueryResult<GmailThreadsResponse> => {
  const threadsParams = new URLSearchParams(
    objectToUrlParams({ ...defaultParams, ...params }),
  );

  return useInfiniteQuery<GmailThreadsResponse>(
    ['gmail-threads', { label: params?.label, query: params?.q }],
    ({ pageParam }) => {
      if (!isNil(pageParam)) {
        threadsParams.set('page_token', pageParam || '');
      }
      return pbAgentFetcher(
        `v1/gmail-threads?${threadsParams.toString()}`,
        {},
        true,
      );
    },
    {
      refetchOnWindowFocus: false,
      getPreviousPageParam: ({ collection: { next_page_token } }) =>
        next_page_token ?? false,
      getNextPageParam: ({ collection: { next_page_token } }) =>
        next_page_token ?? false,
      ...options,
    },
  );
};

interface DeleteParams {
  threadsIds: string[];
}

export const useGmailThreadsDelete = (): UseMutationResult<
  Response,
  unknown,
  DeleteParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['gmail/threads/delete'],
    ({ threadsIds }: DeleteParams) =>
      pbAgentFetcher(`v1/gmail-threads?thread_ids=${threadsIds.join(',')}`, {
        method: 'delete',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['gmail-labels']);
      },
    },
  );
};
