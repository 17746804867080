import React, { memo, useState } from 'react';
import { SectionTitle } from '../../Global/SectionTitle';
import { SearchInput } from '../../Form/SearchInput';
import { useClients } from '../../../services/hooks/api/clients/useClients';
import { ClientSavedSearches } from './components/ClientSavedSearches';
import { Skeleton } from '@chakra-ui/react';

export const SavedSearches = memo(() => {
  const [searchValue, setSearchValue] = useState('');

  const { data: dataClients } = useClients({
    search: searchValue,
  });

  return (
    <>
      <SectionTitle mb={1}>Saved Searches</SectionTitle>

      <SearchInput
        onChange={(v) => setSearchValue(v.target.value)}
        mb={5}
        maxWidth={'none'}
      />

      {!dataClients && <Skeleton w={'100%'} h={5} borderRadius={'md'} mt={5} />}

      {dataClients
        ?.filter((c) => !!c.search_info)
        .map((client) => (
          <ClientSavedSearches client={client} key={client.user_id} />
        ))}
    </>
  );
});
