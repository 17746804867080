import { VStack } from '@chakra-ui/react';
import { SectionDivider } from 'components/Global/SectionDivider';
import React, { ReactElement } from 'react';
import InputEditor from '../PersonalDetails/shared/InputEditor';
import SectionHeading from '../PersonalDetails/shared/SectionHeading';
import { TabWrapper } from '../TabWrapper';

const Integrations = (): ReactElement => {
  return (
    <TabWrapper className={'notImplemented'}>
      <VStack align={'stretch'} maxW={'500px'}>
        <SectionHeading>RENTHOP</SectionHeading>
        <InputEditor placeholder={'Username'} onSubmit={undefined} />
        <InputEditor placeholder={'Password'} onSubmit={undefined} />
      </VStack>
      <SectionDivider />
      <VStack align={'stretch'} maxW={'500px'}>
        <SectionHeading>DocuSign</SectionHeading>
        <InputEditor placeholder={'Username'} onSubmit={undefined} />
        <InputEditor placeholder={'Password'} onSubmit={undefined} />
      </VStack>
    </TabWrapper>
  );
};

export default Integrations;
