import React, { ReactElement, useMemo } from 'react';
import { Box, Button, Flex, Skeleton, VStack } from '@chakra-ui/react';
import { getCreationTimeFormatted } from 'services/getCreationTimeFormatted';
import {
  useGetNotifications,
  useMarkAllNotificationsAsRead,
} from 'services/hooks/api/notifications/useNotifications';
import { Notification } from './Notification';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHeight } from 'contexts/HeightContext';
import { CheckSmallIcon } from 'icons/icons';

interface NotificationsProps {
  isInDropdown?: boolean;
  scrollWrapperId?: string;
}

export const Notifications: React.FC<NotificationsProps> = ({
  isInDropdown,
  scrollWrapperId,
}: NotificationsProps): ReactElement => {
  const { availableHeight } = useHeight();
  const { data, hasNextPage, fetchNextPage } = useGetNotifications();
  const { mutate: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMarkAllNotificationsAsRead();
  const handleMarkAllAsRead = (): void => markAllAsRead();

  const notifications = useMemo(
    () => data?.pages.flatMap((page) => page.collection) ?? [],
    [data],
  );

  return (
    <Box pos={'relative'}>
      <Box
        id={scrollWrapperId}
        maxH={isInDropdown ? '400px' : `${availableHeight}px`}
        overflowY={'auto'}
      >
        <InfiniteScroll
          scrollableTarget={scrollWrapperId}
          dataLength={notifications.length}
          next={fetchNextPage}
          hasMore={hasNextPage || false}
          scrollThreshold={0.6}
          loader={<Skeleton borderRadius={'lg'} w={'100%'} h={'76px'} my={5} />}
          endMessage={<Box h={100} />}
        >
          <VStack spacing={2} mt={3}>
            {notifications.map(
              ({ alert_id, time_created, message, payload, read }) => (
                <Notification
                  key={alert_id}
                  text={message}
                  time={getCreationTimeFormatted(time_created)}
                  name={payload.user.name}
                  payload={payload}
                  read={!!+read}
                  alertId={alert_id}
                />
              ),
            )}
          </VStack>
        </InfiniteScroll>
      </Box>
      <Flex
        hidden={!notifications.length}
        pos={'absolute'}
        bottom={0}
        width={'100%'}
        justifyContent={'center'}
        bgGradient={'linear(to-t, white 80%, rgba(255, 255, 255, 0.1) 100% )'}
        p={4}
        pt={12}
      >
        <Button
          variant={'blueLight'}
          leftIcon={<CheckSmallIcon />}
          aria-label={'Mark all as read'}
          onClick={handleMarkAllAsRead}
          isLoading={isMarkingAllAsRead}
        >
          Mark all as read
        </Button>
      </Flex>
    </Box>
  );
};
