import { UseQueryResult, useQuery } from 'react-query';
import { Client } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

export const useMatchingClients = (
  listingId: string,
): UseQueryResult<Client[]> =>
  useQuery<Client[]>(
    ['matching-clients', listingId],
    () => pbAgentFetcher(`v1/search-listings/${listingId}/matching-clients`),
    {
      staleTime: 1 * 60 * 1000, // 1 minute
    },
  );
