import { FormControlProps } from '@chakra-ui/form-control/dist/types/form-control';
import { CheckboxProps } from '@chakra-ui/checkbox/dist/types/checkbox';
import { RadioProps } from '@chakra-ui/radio/dist/types/radio';
import { BoxProps, InputProps } from '@chakra-ui/react';

export const radioCheckboxRow: FormControlProps = {
  pos: 'relative',
  display: 'flex',
  alignItems: 'baseline',
  flexWrap: { base: 'wrap', md: 'nowrap' },
};

export const triggerBox: BoxProps = {
  whiteSpace: 'nowrap',
  mb: { base: 1, md: 3 },
  sx: {
    ':not(:last-child)': {
      marginRight: { base: 1, md: 3 },
    },
  },
};

export const trigger: CheckboxProps | RadioProps = {
  bg: 'pbGray.80',
  color: 'pbGray.150',
  cursor: 'pointer',
  fontSize: 12,
  letterSpacing: 0,
  minH: '38px',
  minW: '38px',
  fontWeight: 'bold',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'md',
  _checked: {
    bg: 'pbBlue.500',
    color: 'white',
  },
  _groupChecked: {
    bg: 'pbBlue.500',
  },
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  px: 3,
  py: 1,
};

export const inputStyles: InputProps = {
  fontSize: 14,
  _placeholder: {
    color: 'pbBlue.205',
  },
  borderColor: 'pbGray.90',
  borderRadius: 'md',
  h: '38px',
};
