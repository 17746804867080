/**
 * Use this function in a default clause of a switch statement to
 * make typescript check if you're handling all possible cases.
 */
export function exhaustive(x: never): never {
  throw new Error(`
    Unreachable default case.
    ${x} is not assignable to type never.
  `);
}
