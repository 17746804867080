import React, { ReactElement, useState } from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { ApartmentFeatures } from './ApartamentFeatures';
import { ListingSidebarMap } from './ListingSidebarMap';
import { Divider, Flex, Skeleton } from '@chakra-ui/react';
import { SectionTitle } from '../Global/SectionTitle';
import { Link } from 'react-router-dom';
import { OwnTabs, TabsType } from '../Global/OwnTabs';
import { currencyFormat } from '../../services/currencyFormat';
import { ListingSidebarBox } from './ListingSidebarBox';
import { useBuilding } from '../../services/hooks/api/buildings/useBuilding';

export const ListingSidebar = ({
  buildingID,
}: {
  buildingID: string;
}): ReactElement => {
  const [activeTab, setActiveTab] = useState<'map' | 'photos'>('map');

  const { data: buildingData } = useBuilding(buildingID);

  if (!buildingData) {
    return <Skeleton w={'100%'} h={280} />;
  }

  const tabs: TabsType[] = [
    {
      label: 'Photos',
      abbreviation: 'photos',
      content: <Text>PHOTOS</Text>,
    },
    {
      label: 'Map',
      abbreviation: 'map',
      content: buildingData && (
        <ListingSidebarMap
          lat={buildingData.latitude}
          lng={buildingData.longitude}
        />
      ),
    },
  ];

  return (
    <>
      {buildingData?.featured_photo ? (
        <OwnTabs
          tabs={tabs}
          onChange={(e) => setActiveTab(e as typeof activeTab)}
          value={activeTab}
        />
      ) : (
        <ListingSidebarMap
          lat={buildingData.latitude}
          lng={buildingData.longitude}
        />
      )}
      {buildingData.address && buildingData.ownership && (
        <SectionTitle pl={0} as={'h3'} fontSize={18} mb={5}>
          {buildingData.address}, {buildingData.ownership}
        </SectionTitle>
      )}
      <Flex fontSize={14} wrap={'wrap'}>
        <ListingSidebarBox
          w={85}
          label={'Units'}
          value={buildingData.units_total}
        />
        <ListingSidebarBox
          w={85}
          label={'Floors'}
          value={buildingData.floors}
        />

        <ListingSidebarBox
          w={85}
          label={'Built'}
          value={buildingData.year_built}
        />
      </Flex>
      <ListingSidebarBox label={'Pets'} value={buildingData.pet_policy} />
      <ListingSidebarBox
        label={'Neighborhood'}
        value={buildingData.neighborhood}
      />
      <HStack mb={5} fontSize={14}>
        {buildingData.sales_avg && (
          <ListingSidebarBox
            label={'Sale (avg $/sqft)'}
            value={currencyFormat(buildingData.sales_avg)}
            w={'50%'}
          />
        )}
        {buildingData.rentals_avg && (
          <ListingSidebarBox
            label={'Rent (avg $/sqft)'}
            value={currencyFormat(buildingData.rentals_avg)}
            w={'50%'}
          />
        )}
      </HStack>

      <Divider
        orientation={'horizontal'}
        mx={{ base: -3, xl: -5 }}
        width={'auto'}
      />

      <ApartmentFeatures
        type={'sidebar'}
        data={buildingData?.amenities}
        title={'Building Features'}
      />

      <Flex justifyContent={'flex-end'}>
        <Button as={Link} to={`/building/${buildingID}`} variant={'blue'}>
          Building Details
        </Button>
      </Flex>
    </>
  );
};
