import {
  SavedSearchesMutationProps,
  useSavedSearchesMutation,
  useSavedSearchesMutationPut,
} from '../useSavedSearches';
import { parseUrlParamsToObject } from '../../../parseUrlParamsToObject';
import { useClientContext } from '../../../../contexts/ClientContext';
import { useURLSearchParams } from '../../useURLSearchParams';
import { SearchType } from '../../../apiTypes';
import { useParams } from 'react-router-dom';
import { useClient } from '../clients/useClient';

interface UseSearchForm {
  onSaveSearch: () => void;
  isLoading: boolean;
}

export const useSearchForm = (): UseSearchForm => {
  const { type } = useParams<{ type: SearchType }>();
  const saveClientSearch = useSavedSearchesMutation();
  const updateClientSearch = useSavedSearchesMutationPut();

  const {
    clientsState: { clientId },
  } = useClientContext();

  const { searchParams } = useURLSearchParams();
  const { data: clientData } = useClient(clientId);

  const onSaveSearch = () => {
    if (!clientId) {
      return;
    }

    const variables: SavedSearchesMutationProps = {
      clientId,
      searchParams: parseUrlParamsToObject(searchParams),
    };

    const search_id = clientData?.searches?.find(
      (s) => s.search?.ad_type === type && s.created_by !== '0',
    )?.search_id;

    if (search_id) {
      variables.searchId = search_id;
      updateClientSearch.mutate(variables);
    } else {
      saveClientSearch.mutate(variables);
    }
  };

  return {
    onSaveSearch,
    isLoading: saveClientSearch.isLoading || updateClientSearch.isLoading,
  };
};
