interface Params {
  hasBeenSaved: boolean;
  hasBeenSent: boolean;
}

export const saveListingTooltipLabel = ({
  hasBeenSaved,
  hasBeenSent,
}: Params): string => {
  if (hasBeenSent) {
    return 'Listing saved after sending';
  } else {
    return hasBeenSaved
      ? 'Remove listing from this client'
      : 'Assign listing to this client';
  }
};
