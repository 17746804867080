interface PushState {
  [key: string]: unknown;
}
export const redirectToOverview = (
  pathToRedirect: string,
  push: (pathToRedirect: string, state: PushState) => void,
  pathname: string,
  state: PushState = {},
): void | '' =>
  !pathname.includes(pathToRedirect) ? push(pathToRedirect, { ...state }) : '';
