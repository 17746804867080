import { Button } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface TabButtonProps {
  label: string;
  onClick: () => void;
  isActive: boolean;
}

export const TabButton = ({
  label,
  onClick,
  isActive,
}: TabButtonProps): ReactElement => {
  return (
    <Button
      mr={0}
      onClick={onClick}
      variant={'tab'}
      size={'tab'}
      isActive={isActive}
    >
      {label}
    </Button>
  );
};
