import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { Message } from 'services/apiTypes';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailMessagesPostBody } from './apiTypes';

interface Params {
  body: GmailMessagesPostBody;
  draftId: string;
}

interface UpdateDraftResponse {
  id: string;
  message: Message;
}

export const useDraftsUpdate = (): UseMutationResult<
  UpdateDraftResponse,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['gmail-drafts', 'put'],
    ({ body, draftId }: Params) =>
      pbAgentFetcher(`v1/gmail-drafts/${draftId}`, {
        method: 'put',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['gmail-threads']);
        queryClient.invalidateQueries(['gmail-labels']);
      },
    },
  );
};
