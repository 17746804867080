import { Box, ColorProps, Flex, FlexProps } from '@chakra-ui/react';
import { Dot } from './components/Dot';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { ResponsiveValue } from '@chakra-ui/system';

export interface DottedRowProps extends FlexProps {
  dotMx?: ResponsiveValue<number>;
  items?: ReactNode[];
  dotColor?: ColorProps['color'];
}

export const DottedRow = ({
  dotMx,
  dotColor,
  items,
  ...props
}: DottedRowProps): ReactElement => {
  return (
    <Flex align={'center'} wrap={'wrap'} {...props}>
      {Array.isArray(items) &&
        items.map((item, i) => {
          return (
            <Fragment key={i}>
              <Box whiteSpace={{ xl: 'nowrap' }}>{item}</Box>
              {i < items.length - 1 && <Dot color={dotColor} mx={dotMx} />}
            </Fragment>
          );
        })}
    </Flex>
  );
};
