import {
  Box,
  Button,
  HStack,
  Skeleton,
  SkeletonCircle,
  VStack,
} from '@chakra-ui/react';
import React, { ReactElement, useContext, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { consoleAssert } from '../../../../services/consoleAssert';
import { ChevronIcon } from '../../../../icons/icons';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import UserAvatar from 'components/Global/User/UserAvatar';
import { OwnPopover } from '../../OwnPopover';

// What should it drop? Look at the old app.
export const UserDropdown = (): ReactElement => {
  const authContext = useContext(AuthContext);

  const fullName = authContext?.user?.name;

  consoleAssert(authContext, 'UserDropdown needs AuthContext');

  const [loggingOut, setLoggingOut] = useState(false);

  return (
    <OwnPopover
      bodyContent={
        <VStack alignItems={'stretch'}>
          <Button as={ReactRouterLink} to={'/my-profile'}>
            My Profile
          </Button>
          {/* TODO: Should "My calendar" be here if it's on the dashboard? */}
          <Button as={ReactRouterLink} to={'/my-calendar'}>
            My Calendar
          </Button>
          <Button as={ReactRouterLink} to={'/account-settings'}>
            Account Settings
          </Button>
          <Button
            onClick={() => {
              authContext.onLogout();
              setLoggingOut(true);
            }}
            isLoading={loggingOut}
          >
            Log out
          </Button>
        </VStack>
      }
      triggerContent={
        <HStack
          as={Button}
          cursor={'pointer'}
          spacing={'10px'}
          alignItems={'center'}
          variant={'ghost'}
          pt={'3px'}
          pb={'3px'}
          height={'auto'}
          fontSize={'xs'}
          fontWeight={'bold'}
          px={'2'}
          color={'pbGray.500'}
          letterSpacing={'tighter'}
        >
          <SkeletonCircle isLoaded={Boolean(authContext?.user?.first_name)}>
            <UserAvatar />
          </SkeletonCircle>

          <Box
            display={{
              base: 'none',
              sm: 'block',
              lg: 'none',
              xl: 'block',
            }}
          >
            {authContext?.user?.first_name ? (
              <Box>{fullName}</Box>
            ) : (
              <Skeleton>
                <Box>Placeholder name</Box>
              </Skeleton>
            )}
          </Box>

          <ChevronIcon boxSize={'12px'} />
        </HStack>
      }
    />
  );
};
