import { VStack } from '@chakra-ui/react';
import InputEditor from '../shared/InputEditor';
import SectionContainer from '../shared/SectionContainer';
import SectionHeading from '../shared/SectionHeading';
import SectionSubHeading from '../shared/SectionSubHeading';
import React, { ReactElement } from 'react';
import { TwoInputsRow } from '../shared/TwoInputsRow';

const Details = (): ReactElement => {
  //TODO
  const onSubmit = () => undefined;

  return (
    <>
      <SectionHeading>Personal Details</SectionHeading>

      <SectionContainer className={'notImplemented'}>
        <SectionSubHeading>Address</SectionSubHeading>

        <VStack spacing={'10px'} w={'100%'} align={'stretch'}>
          <InputEditor placeholder={'Address Line 1'} onSubmit={onSubmit} />

          <InputEditor placeholder={'Address Line 2'} onSubmit={onSubmit} />
          <TwoInputsRow>
            <InputEditor placeholder={'City'} onSubmit={onSubmit} />
            <InputEditor placeholder={'State'} onSubmit={onSubmit} />
          </TwoInputsRow>
          <TwoInputsRow>
            <InputEditor placeholder={'Postal Code'} onSubmit={onSubmit} />
            <InputEditor placeholder={'Country'} onSubmit={onSubmit} />
          </TwoInputsRow>
        </VStack>
      </SectionContainer>

      <SectionContainer mt={'40px'} className={'notImplemented'}>
        <SectionSubHeading>Emergency Contact</SectionSubHeading>

        <VStack spacing={'10px'} w={'100%'} align={'stretch'}>
          <TwoInputsRow>
            <InputEditor placeholder={'First Name'} onSubmit={onSubmit} />
            <InputEditor placeholder={'Last Name'} onSubmit={onSubmit} />
          </TwoInputsRow>
          <TwoInputsRow>
            <InputEditor placeholder={'Phone Number'} onSubmit={onSubmit} />
            <InputEditor placeholder={'Email Address'} onSubmit={onSubmit} />
          </TwoInputsRow>
          <InputEditor placeholder={'Address Line 1'} onSubmit={onSubmit} />
          <InputEditor placeholder={'Address Line 2'} onSubmit={onSubmit} />
          <TwoInputsRow>
            <InputEditor placeholder={'City'} onSubmit={onSubmit} />
            <InputEditor placeholder={'State'} onSubmit={onSubmit} />
          </TwoInputsRow>
          <TwoInputsRow>
            <InputEditor placeholder={'Postal Code'} onSubmit={onSubmit} />
            <InputEditor placeholder={'Country'} onSubmit={onSubmit} />
          </TwoInputsRow>
        </VStack>
      </SectionContainer>
    </>
  );
};

export default Details;
