import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { Message } from 'services/apiTypes';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailMessagesPostBody } from './apiTypes';

interface Params {
  body: GmailMessagesPostBody;
}

interface CreateDraftResponse {
  id: string;
  message: Message;
}

export const useDraftsCreate = (): UseMutationResult<
  CreateDraftResponse,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['gmail-drafts', 'post'],
    ({ body }: Params) =>
      pbAgentFetcher('v1/gmail-drafts', {
        method: 'post',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['gmail-threads']);
        queryClient.invalidateQueries(['gmail-labels']);
      },
    },
  );
};
