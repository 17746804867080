import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

interface Params {
  draftId: string;
}

export const useDraftsDelete = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['gmail-drafts', 'delete'],
    ({ draftId }: Params) =>
      pbAgentFetcher(`v1/gmail-drafts/${draftId}`, {
        method: 'delete',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['gmail-threads']);
        queryClient.invalidateQueries(['gmail-labels']);
      },
    },
  );
};
