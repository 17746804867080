import { StringOrNumber } from './apiTypes';

export const objectToURLSearchParams = <T>(search: T): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();

  Object.keys(search).forEach((key) => {
    const val = search[key as keyof T] as unknown as StringOrNumber;

    if (Array.isArray(val)) {
      val.forEach((v) => urlSearchParams.append(key, v.toString()));
    } else {
      urlSearchParams.append(key, val?.toString());
    }
  });

  return urlSearchParams;
};
