import React, { ReactElement, useEffect } from 'react';
import { Flex, SimpleGridProps } from '@chakra-ui/react';
import { Main } from 'components/Global/Main';
import { PageHeading } from 'components/Global/PageHeading';
import { PageTitle } from 'components/Global/PageTitle';
import { RoundedBox } from 'components/Global/RoundedBox';
import { useApplicationsContext } from 'contexts/ApplicationsContext';
import { useApplications } from 'services/hooks/api/aform/useApplications';
import { ListActions } from 'components/Applications/ListActions';
import { ApplicationsPagination } from 'components/Applications/Pagination';
import { List } from 'components/Applications/List';
import { ListLabels } from 'components/Applications/ListLabels';

export const applicationsGridOptions: SimpleGridProps = {
  gridTemplateColumns: {
    base: 'repeat(1, 1fr)',
    sm: 'minmax(200px, 1fr) repeat(3, minmax(50px, 1fr))',
    md: 'minmax(300px, 1fr) minmax(60px, 1fr) minmax(200px, 1fr) minmax(50px, 1fr) minmax(200px, 1fr)',
    lg: 'minmax(300px, 1fr) minmax(50px, 1fr) minmax(200px, 1fr) repeat(2, minmax(50px, 1fr)) minmax(150px, 1fr)',
    xl: 'minmax(300px, 1fr) minmax(50px, 1fr) minmax(200px, 1fr) repeat(3, minmax(50px, 1fr)) minmax(150px, 1fr)',
  },
  columnGap: 5,
  columns: [4, 5, 5, 6, 7],
};

export const Applications = (): ReactElement => {
  const {
    data: { page, defaultPageSize, searchValue },
    actions: { setApplications },
  } = useApplicationsContext();

  const { data: fetchedApplications, isLoading } = useApplications({
    name: searchValue.name || '',
    address: searchValue.address || '',
    limit: defaultPageSize,
    offset: page > 1 ? (page - 1) * defaultPageSize : 0,
  });

  useEffect(() => {
    if (!fetchedApplications) {
      return;
    }
    setApplications(fetchedApplications);
  }, [fetchedApplications, setApplications]);

  return (
    <Main display={'flex'} flexDir={'column'} flexGrow={1}>
      <PageHeading>
        <PageTitle>Applications</PageTitle>
      </PageHeading>
      <RoundedBox flexGrow={1} display={'flex'}>
        <Flex flexDir={'column'} width={'100%'}>
          <ListActions />
          <ListLabels />
          <List loading={isLoading} />
          <ApplicationsPagination />
        </Flex>
      </RoundedBox>
    </Main>
  );
};
