import React, { ReactElement } from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { SearchListingResponse } from 'services/apiTypes';
import { mediaServer } from 'services/mediaServer';
import logo from './logo.png';
import { currencyFormat } from 'services/currencyFormat';
import hasBedroomsCount from 'services/hasBedroomsCount';
import { pluralize, pluralizeWithCount } from 'services/pluralize';
import { badgeBackgroundColors } from '../../constants';
import { isNil } from 'lodash';

Font.register({
  family: 'default',
  src: './fonts/BwNistaInternationalDEMO-Regular.otf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'default',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 20px',
    fontSize: '14px',
  },
  header: {
    padding: '10px 0',
  },
  logo: {
    width: 100,
    margin: '10px 0',
  },
  photo: {
    width: '100%',
    maxHeight: '100%',
    margin: '5px 0',
    borderRadius: '20px',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  infoItemWithLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: '20%',
    fontSize: '14px',
    marginBottom: '20px',
  },
  label: {
    fontSize: '12px',
    color: '#979797',
  },
  value: {
    fontSize: '14px',
    color: '#5f5e67',
  },
  section: {
    width: '100%',
    margin: '10px',
    padding: '10px',
  },
  sectionHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  listingTypeBadge: {
    textTransform: 'uppercase',
    padding: '5px 10px',
    borderRadius: '8px',
  },
  halfWidthCell: { width: '50%', padding: '10px 2.5%' },
  listingDescription: {
    fontSize: '14px',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#979797',
  },
});

const Label = ({ label }: { label: string }) => (
  <Text style={styles.label}>{label}</Text>
);
const Value = ({ value }: { value: string }) => (
  <Text style={styles.value}>{value}</Text>
);

const ApartmentFeatures = ({ features }: { features: string[] }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {features.map((feature, index) => (
        <View key={index} style={{ marginRight: '5px' }}>
          <Text>{feature}</Text>
        </View>
      ))}
    </View>
  );
};
interface Props {
  listings: SearchListingResponse[];
}

const ListingPreview = ({
  advertisement_id,
  city,
  price,
  bedrooms,
  bathrooms,
  size,
  featured_photo,
  address,
  ad_type,
  furnished,
  max_lease_period,
  min_lease_period,
  available_from,
  pet_policy,
  commission_structure,
  zip,
  access_type_info,
  description,
  amenities_info,
  contact_address,
  open_house,
}: SearchListingResponse) => (
  <Page key={advertisement_id} style={styles.page}>
    <View style={styles.header}>
      <Image src={logo} style={styles.logo} />
    </View>
    <View style={styles.section}>
      <View style={styles.infoRow}>
        <Text>{[address, zip, city].filter(Boolean).join(', ')}</Text>
        <View
          style={[
            styles.listingTypeBadge,
            { backgroundColor: badgeBackgroundColors[ad_type] },
          ]}
        >
          <Text>{ad_type}</Text>
        </View>
      </View>
      <View style={[styles.infoRow, { justifyContent: 'flex-start' }]}>
        <Text style={{ marginRight: '125px' }}>
          {`${currencyFormat(price)}${ad_type === 'rental' ? '/month' : ''}`}
        </Text>
        <Text style={{ marginRight: '25px' }}>
          {hasBedroomsCount(bedrooms)
            ? pluralizeWithCount(parseInt(bedrooms), 'Bed')
            : bedrooms}
        </Text>
        <Text style={{ marginRight: '25px' }}>
          {pluralizeWithCount(parseInt(bathrooms), 'Bath')}
        </Text>
        {!isNil(size) && (
          <Text style={{ marginRight: '5px' }}>{size} sqft</Text>
        )}
      </View>
    </View>
    <View style={styles.section}>
      <View
        style={[
          {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        ]}
      >
        <View style={styles.halfWidthCell}>
          {!isNil(featured_photo) && (
            <Image
              src={mediaServer(featured_photo, 'wmap')}
              style={styles.photo}
            />
          )}
        </View>
        <View style={styles.halfWidthCell}>
          <Text style={styles.sectionHeading}>General Information</Text>
          <View style={[styles.infoRow, { flexWrap: 'wrap' }]}>
            {!isNil(pet_policy) && (
              <View style={styles.infoItemWithLabel}>
                <Label label={'Pets'} />
                <Value value={pet_policy} />
              </View>
            )}

            <View style={styles.infoItemWithLabel}>
              <Label label={'Furnished'} />
              <Value value={furnished ? 'Yes' : 'No'} />
            </View>

            {!isNil(available_from) && (
              <View style={styles.infoItemWithLabel}>
                <Label label={'Availability'} />
                <Value value={`${available_from}`} />
              </View>
            )}

            {!isNil(max_lease_period) && !isNil(min_lease_period) && (
              <View style={styles.infoItemWithLabel}>
                <Label label={'Lease'} />
                <Value
                  value={`${min_lease_period} - ${max_lease_period} ${pluralize(
                    max_lease_period - min_lease_period,
                    'Month',
                  )}`}
                />
              </View>
            )}
            {!isNil(access_type_info) && (
              <View style={styles.infoItemWithLabel}>
                <Label label={'Access Type'} />
                <Value value={access_type_info} />
              </View>
            )}

            {!isNil(open_house) && (
              <View style={styles.infoItemWithLabel}>
                <Label label={'Open House'} />
                <Value value={open_house.join(',')} />
              </View>
            )}

            {!isNil(commission_structure) && (
              <View style={styles.infoItemWithLabel}>
                <Label label={'Commission Structure'} />
                <Text>{commission_structure}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>

    {!isNil(description) && (
      <View style={styles.section}>
        <Text style={styles.listingDescription}>{description}</Text>
      </View>
    )}

    <View style={styles.divider} />

    {!isNil(amenities_info) && (
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Apartment Features</Text>
        <ApartmentFeatures features={amenities_info} />
      </View>
    )}

    <View style={styles.divider} />

    {!isNil(contact_address) && (
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Listing Contacts</Text>
        <Text>{contact_address}</Text>
      </View>
    )}
  </Page>
);

// Create Document Component
export const ListingDetailsPDF = ({ listings }: Props): ReactElement => (
  <Document>{listings.map(ListingPreview)}</Document>
);
