import { SimpleGrid } from '@chakra-ui/react';
import { AttachmentInLibraryTile } from 'components/Global/UploadedFileTile';
import { useAttachmentsLibrary } from 'contexts/AttachmentLibraryContext';
import React, { ReactElement } from 'react';

const AttachmentsList = (): ReactElement => {
  const {
    data: { attachmentsToCurrentPage },
  } = useAttachmentsLibrary();

  return (
    <SimpleGrid
      mr={{ base: 0, md: 3 }}
      columns={{ base: 1, md: 2 }}
      gap={3}
      gridTemplateColumns={{
        base: '100%',
        md: 'repeat(2, 50%)',
      }}
    >
      {attachmentsToCurrentPage.map((attachment) => (
        <AttachmentInLibraryTile key={attachment.id} {...attachment} />
      ))}
    </SimpleGrid>
  );
};

export default AttachmentsList;
