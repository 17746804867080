import { useDealsContext } from 'contexts/DealsContext';
import { UseQueryResult, useQuery } from 'react-query';
import { DealSheetsOverview } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';
import { objectToUrlParams } from 'services/objectToUrlParams';

export const useDealsSheetsOverview =
  (): UseQueryResult<DealSheetsOverview> => {
    const { dealsState } = useDealsContext();
    const params = new URLSearchParams(
      objectToUrlParams({
        year: dealsState.year,
        month: dealsState.month || '',
      }),
    );

    return useQuery<DealSheetsOverview>(
      ['deal-sheets-overview', params.toString()],
      () => pbAgentFetcher(`v1/deal-sheets-overview?${params.toString()}`),
      { refetchOnWindowFocus: false },
    );
  };
