/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  CheckboxProps,
  Flex,
  FlexProps,
  RadioProps,
  Text,
  useCheckbox,
  useRadio,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { trigger, triggerBox } from './styles';
import { CheckIcon, CheckSmallIcon } from '../../icons/icons';

export const RadioCard = (props: RadioProps): ReactElement => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box
      as={'label'}
      {...triggerBox}
      opacity={input['aria-disabled'] ? 0.5 : 1}
    >
      <input {...input} />
      <Flex {...checkbox} {...trigger}>
        {props.children}
      </Flex>
    </Box>
  );
};

export const CheckboxCard = (props: CheckboxProps): ReactElement => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as={'label'} {...triggerBox} cursor={'pointer'}>
      <input {...input} />
      <Flex {...checkbox} {...trigger}>
        {props.children}
      </Flex>
    </Box>
  );
};

const RadioCircle = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <Flex
      as={'span'}
      w={'26px'}
      h={'26px'}
      align={'center'}
      justify={'center'}
      borderRadius={'full'}
      borderWidth={1}
      borderColor={isSelected ? 'pbBlue.500' : 'pbBlue.100'}
      mr={3}
    >
      <Box
        as={'span'}
        w={'12px'}
        h={'12px'}
        bg={isSelected ? 'pbBlue.500' : 'transparent'}
        borderRadius={'full'}
      />
    </Flex>
  );
};

export const RadioItem = (props: RadioProps): ReactElement => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  // Not sure why, but `checked` doesnt exists in chackra types of `getInputProps`
  // hook, but exists in returned object
  // @ts-ignore
  const isSelected = props.isChecked || input.checked;

  return (
    <Box
      as={'label'}
      {...triggerBox}
      opacity={input['aria-disabled'] ? 0.5 : 1}
    >
      <input {...input} />
      <Flex
        {...checkbox}
        color={isSelected ? 'currentColor' : 'pbBlue.100'}
        fontSize={14}
        cursor={'pointer'}
        align={'center'}
        fontWeight={500}
      >
        <RadioCircle isSelected={isSelected} />
        {props.children}
      </Flex>
    </Box>
  );
};

export const CheckboxItem = (
  props: CheckboxProps & { containerProps?: FlexProps },
): ReactElement => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);
  const { size, isChecked: isCheckedProp, isDisabled } = props;
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  // Not sure why, but `checked` doesnt exists in chackra types of `useCheckbox`
  // hook, but exists in returned object
  // @ts-ignore
  const isChecked = isCheckedProp || input?.checked;
  const isSmall = size === 'small';

  const checkBoxSize = isSmall ? '18px' : '26px';
  const borderRadius = isSmall ? 'base' : 'md';
  const fontSize = isSmall ? 12 : 14;
  const mr = isSmall ? 2 : 3;
  const Icon = isSmall ? CheckSmallIcon : CheckIcon;

  return (
    <Flex
      alignItems={'center'}
      as={'label'}
      opacity={isDisabled ? '.5' : '1'}
      {...props.containerProps}
    >
      <input {...input} />
      <Flex
        {...checkbox}
        justifyContent={'center'}
        alignItems={'center'}
        minW={checkBoxSize}
        h={checkBoxSize}
        mr={mr}
        borderRadius={borderRadius}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        borderColor={'pbBlue.100'}
        borderWidth={1}
        bg={'transparent'}
        _checked={{
          bg: 'pbBlue.500',
          borderColor: 'pbBlue.500',
        }}
      >
        <Icon color={isChecked ? 'white' : 'transparent'} zIndex={1} />
      </Flex>
      <Text
        as={'span'}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        userSelect={'none'}
        fontSize={fontSize}
        color={props.color ?? (isChecked ? 'pbBlack.100' : 'pbBlue.100')}
      >
        {props.children}
      </Text>
    </Flex>
  );
};
