import React, { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Pagination } from 'components/Global/Pagination';
import { useApplicationsContext } from 'contexts/ApplicationsContext';

export const ApplicationsPagination = (): ReactElement | null => {
  const {
    data: { page, applications, defaultPageSize },
    actions: { setPage },
  } = useApplicationsContext();

  if (!applications || applications.total < 1) {
    return null;
  }

  const total = applications.total;
  const visibleItemsCount = total < defaultPageSize ? total : defaultPageSize;

  const pages = Math.ceil(total / visibleItemsCount);

  if (!pages || pages < 2) {
    return null;
  }

  const pageFirstItem = (page - 1) * defaultPageSize + 1;
  const pageLastItem =
    page * defaultPageSize > total ? total : page * defaultPageSize;

  const paginationText = `Showing ${pageFirstItem} to ${pageLastItem} of ${total} applications`;
  return applications ? (
    <Flex alignSelf={'flex-end'} alignItems={'center'} mt={8}>
      <Text mr={4}>{paginationText}</Text>
      <Pagination
        maxNumPages={Math.ceil(total / visibleItemsCount)}
        page={page}
        onChange={setPage}
      />
    </Flex>
  ) : null;
};
