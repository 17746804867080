import { ListingInquiryResponse, SearchParams } from '../../../apiTypes';
import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';

export const defaultValues: SearchParams = {
  // TODO: change to something smaller, backend update needed
  limit: 50,
  offset: 0,
};

export const useListingInquiries = (
  params?: SearchParams,
): UseInfiniteQueryResult<ListingInquiryResponse> => {
  const listingsParams = new URLSearchParams(
    objectToUrlParams({ ...defaultValues, ...params }),
  );

  return useInfiniteQuery<ListingInquiryResponse>(
    ['listing-inquiries'],
    ({ pageParam }) => {
      if (pageParam) {
        const newOffset = (
          parseInt(listingsParams.get('offset') || '') + pageParam
        ).toString();
        listingsParams.set('offset', newOffset);
      }
      return pbAgentFetcher(
        `v1/listing-inquiries?${listingsParams.toString()}`,
        {},
        true,
      );
    },
    {
      refetchIntervalInBackground: true,
      staleTime: 300000, // don't refetch if data is older than 5 minutes
      getNextPageParam: (lastPage, allPages) => {
        const nextPageParam = allPages.flatMap(
          (page) => page.collection,
        ).length;
        if (nextPageParam >= lastPage.total) {
          return false;
        }

        return nextPageParam;
      },
    },
  );
};
