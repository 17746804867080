import React, { ReactElement, useState } from 'react';
import {
  Box,
  Divider,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchListing } from '../../services/apiTypes';
import { OwnBadge } from '../Global/OwnBadge';
import { PhotoSlider } from './PhotoSlider';
import { SectionSubTitle } from '../Global/SectionSubTitle';
import { ApartmentFeatures } from './ApartamentFeatures';
import { currencyFormat } from '../../services/currencyFormat';
import { PhotoSliderPopup } from '../Global/PhotoSliderPopup';
import { ContactBox } from '../Global/ContactBox';
import { ShortListingSummary } from 'components/Global/ShortListingSummary';
import { ListingTitle } from 'components/Global/ListingTitle';
import {
  LabeledValue,
  LabeledValuesGrid,
} from 'components/Global/LabeledValue';

interface ListingDetailsProps {
  listingData?: SearchListing;
  isLoading: boolean;
}

export const ListingDetails = ({
  listingData,
  isLoading,
}: ListingDetailsProps): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedPhoto, setClickedPhoto] = useState<number>(0);

  const photoClickHandler = (photoIndex: number) => {
    setClickedPhoto(photoIndex);
    onOpen();
  };

  return (
    <Box>
      {!listingData ? (
        <>
          <SkeletonText mb={5} />
          <SkeletonText mb={5} />
          <SkeletonText />
        </>
      ) : (
        <>
          <Stack
            mb={1}
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            <ListingTitle
              address={listingData.address}
              city={listingData.city}
              unit={listingData.unit}
              zip={listingData.zip}
              singleLine
              py={{ base: 0, md: 3 }}
            />
            {listingData.ribbon && (
              <OwnBadge
                color={listingData.ribbon.color}
                text={listingData.ribbon.text}
              />
            )}
          </Stack>
          <ShortListingSummary
            isRental={listingData.ad_type === 'rental'}
            price={listingData.price}
            bedrooms={listingData.bedrooms}
            bathrooms={listingData.bathrooms}
            size={listingData.size}
            items={[
              listingData.commission_structure && (
                <>
                  <Text fontWeight={'bold'} as={'span'}>
                    {listingData?.commission_structure}
                  </Text>{' '}
                  CO Broke
                </>
              ),
            ]}
          />
        </>
      )}
      <PhotoSlider
        photos={listingData?.photos?.map((photo) => photo.path)}
        isLoading={isLoading}
        onPhotoClick={photoClickHandler}
        containerProps={{
          mr: { base: -3, xl: -5 },
        }}
      />
      <PhotoSliderPopup
        onClose={onClose}
        isOpen={isOpen}
        clickedPhoto={clickedPhoto}
        photos={listingData?.photos?.map((photo) => photo.path)}
      />
      <SectionSubTitle mb={5} pl={0}>
        General Information
      </SectionSubTitle>
      {!listingData ? (
        <>
          <Skeleton minH={63} mb={30} />
          <Skeleton minH={63} mb={30} />
        </>
      ) : (
        <LabeledValuesGrid
          mb={5}
          columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 8 }}
        >
          <LabeledValue
            label={'Price'}
            value={currencyFormat(listingData.price)}
          />
          <LabeledValue label={'Bedrooms'} value={listingData.bedrooms} />
          <LabeledValue label={'Bathrooms'} value={listingData.bathrooms} />
          <LabeledValue label={'Size'} value={`${listingData.size} sqft`} />
          <LabeledValue label={'Ownership'} value={listingData.ownership} />
          <LabeledValue
            label={'Building Type'}
            value={listingData.access_type_info}
          />
          <LabeledValue label={'Pets'} value={listingData.pet_policy} />
          <LabeledValue label={'Furnished'} value={listingData.furnished} />
          <LabeledValue
            label={'Availability'}
            value={listingData.available_from}
          />
          <LabeledValue label={'Lease'} value={listingData.lease_term_info} />
          <LabeledValue label={'DOM'} value={listingData.dom} />
          <LabeledValue
            label={'Type'}
            value={listingData.access_type}
            gridColumn={{ base: 'initial', xl: 'span 2' }}
          />
          {listingData.updated_on && listingData.source_info && (
            <LabeledValue
              label={'Updated'}
              value={`${
                listingData.updated_on &&
                new Date(listingData.updated_on).toLocaleDateString()
              } ${listingData.source_info}`}
            />
          )}
          <LabeledValue
            label={'Open House'}
            value={listingData.open_house}
            gridColumn={'span 2'}
          />
        </LabeledValuesGrid>
      )}
      <Divider
        orientation={'horizontal'}
        mx={{ base: -3, xl: -5 }}
        width={'auto'}
      />

      {listingData?.description && (
        <>
          <Box py={15}>
            <Text fontSize={'14px'} color={'pbGray.500'}>
              {listingData.description}
            </Text>
          </Box>
          <Divider
            orientation={'horizontal'}
            mx={{ base: -3, xl: -5 }}
            width={'auto'}
          />
        </>
      )}

      <ApartmentFeatures
        type={'normal'}
        data={listingData?.apartment_amenities}
        title={'Apartment Features'}
      />
      <Divider
        orientation={'horizontal'}
        mx={{ base: -3, xl: -5 }}
        width={'auto'}
      />
      <Box py={15}>
        <SectionSubTitle pl={0} mb={5}>
          Listing Contacts
        </SectionSubTitle>
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 3 }}
          gap={{ base: 3, xl: 5 }}
        >
          <ContactBox
            name={listingData?.leasing_contact?.name}
            email={listingData?.leasing_contact?.email}
            phone={listingData?.leasing_contact?.phone}
            photo={listingData?.leasing_contact?.photo}
          />
          {listingData?.viewing_contacts.map((contact, index) => (
            <ContactBox
              key={index}
              name={contact.name}
              email={contact.email}
              phone={contact.phone_number}
              photo={contact.photo}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
