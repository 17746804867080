interface Params {
  checkmarkVisible: boolean;
  hasBeenSent: boolean;
}

export const sendListingTooltipLabel = ({
  checkmarkVisible,
  hasBeenSent,
}: Params): string => {
  if (checkmarkVisible) {
    return 'Listing sent';
  } else {
    return hasBeenSent ? 'Send listing again' : 'Send listing';
  }
};
