import React, { ReactElement } from 'react';
import { OwnChakraProvider } from 'components/OwnChakraProvider';
import { OwnQueryClientProvider } from 'components/OwnQueryClientProvider';
import { AuthContextProvider } from './AuthContext/AuthContext';
import { ClientProvider } from './ClientContext';
import { ModalContextProvider } from './ModalContext';
import { MyListingsContextProvider } from './MyListingsContext';
import { HeightContextProvider } from './HeightContext';
import { ResultsContextProvider } from './ResultsContext';

type Props = {
  children: ReactElement;
};

export const CombinedContextProvider = ({ children }: Props): ReactElement => (
  <OwnQueryClientProvider>
    <OwnChakraProvider>
      <ClientProvider>
        <MyListingsContextProvider>
          <ModalContextProvider>
            <AuthContextProvider>
              <HeightContextProvider>
                <ResultsContextProvider>{children}</ResultsContextProvider>
              </HeightContextProvider>
            </AuthContextProvider>
          </ModalContextProvider>
        </MyListingsContextProvider>
      </ClientProvider>
    </OwnChakraProvider>
  </OwnQueryClientProvider>
);
