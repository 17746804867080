import { AgentProfile } from 'services/hooks/api/agent-profile/apiTypes';

export enum AgentProfileActionType {
  SET_MODIFIED_AGENT,
}

export type AgentProfileAction = {
  type: AgentProfileActionType.SET_MODIFIED_AGENT;
  modifiedAgent?: Partial<AgentProfile>;
};

export const onSetModifiedAgent = (
  modifiedAgent?: Partial<AgentProfile>,
): AgentProfileAction => ({
  type: AgentProfileActionType.SET_MODIFIED_AGENT,
  modifiedAgent,
});
