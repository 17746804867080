import {
  Box,
  Flex,
  HStack,
  SimpleGrid,
  SimpleGridProps,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { SearchInput } from '../../Form/SearchInput';
import React, { ReactElement, useState } from 'react';
import { ClientTile } from './components/ClientTile';
import { useClients } from '../../../services/hooks/api/clients/useClients';
import { Dropdown } from '../../Global/Dropdown';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TabButton } from '../../Global/TabButton';
import { getCreationTimeFormatted } from 'services/getCreationTimeFormatted';
import { useHistory, useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';

dayjs.extend(relativeTime);

type SortOptionLabel =
  | 'A-Z'
  | 'Z-A'
  | 'Newest'
  | 'Oldest'
  | 'Activity'
  | 'Assigned';
type SortBy =
  | 'time_created'
  | 'time_related'
  | 'last_active'
  | 'first_name, email';
type SortDir = 'asc' | 'desc';

interface SortOptions {
  label: SortOptionLabel;
  value: {
    sort_by: SortBy;
    sort_dir: SortDir;
  };
}

const sortOptions: SortOptions[] = [
  { label: 'A-Z', value: { sort_by: 'first_name, email', sort_dir: 'asc' } },
  { label: 'Z-A', value: { sort_by: 'first_name, email', sort_dir: 'desc' } },
  {
    label: 'Newest',
    value: { sort_by: 'time_created', sort_dir: 'desc' },
  },
  { label: 'Oldest', value: { sort_by: 'time_created', sort_dir: 'asc' } },
  {
    label: 'Activity',
    value: { sort_by: 'last_active', sort_dir: 'desc' },
  },
  {
    label: 'Assigned',
    value: { sort_by: 'time_related', sort_dir: 'desc' },
  },
];

type ClientsListProps = SimpleGridProps;

export const ClientsList = ({
  ...gridProps
}: ClientsListProps): ReactElement => {
  const { clientId, clientType } = useParams<ClientRouteParams>();
  const history = useHistory();
  const defaultSortOption = 2;
  const [searchValue, setSearchValue] = useState('');
  const [sortOption, setSortOption] = useState<SortOptionLabel>(
    sortOptions[defaultSortOption].label,
  );
  const [activeTab, setActiveTab] = useState('rental');

  const {
    value: { sort_by, sort_dir },
  } =
    sortOptions.find((s) => s.label === sortOption) ||
    sortOptions[defaultSortOption];

  const { data: clientsData } = useClients({
    search: searchValue,
    sort_by,
    sort_dir,
    search_type: clientType || activeTab,
  });

  const onTabSwitch = (tab: string) => {
    history.replace(`/clients/${tab}`);
    setActiveTab(tab);
  };

  return (
    <Box>
      <Flex justifyContent={'space-between'} mb={4} padding={'5px'}>
        <HStack spacing={1}>
          <TabButton
            onClick={() => onTabSwitch('rental')}
            isActive={clientType === 'rental' || activeTab === 'rental'}
            label={'Rental Clients'}
          />
          <TabButton
            onClick={() => onTabSwitch('sale')}
            isActive={clientType === 'sale' || activeTab === 'sale'}
            label={'Sales Clients'}
          />
        </HStack>

        <Dropdown
          options={sortOptions.map((option) => option.label)}
          onValueChange={setSortOption}
          value={sortOption}
          ml={'auto'}
        />
      </Flex>

      <SearchInput
        onChange={(v) => setSearchValue(v.target.value)}
        maxWidth={'none'}
      />

      <Skeleton
        isLoaded={!!clientsData}
        w={'100%'}
        minH={90}
        borderRadius={'lg'}
        my={4}
      >
        {clientsData?.length === 0 && (
          <Text m={2} fontSize={14}>
            No clients found matching the given criteria
          </Text>
        )}
        <SimpleGrid gap={{ base: 2, xl: 3 }} {...gridProps}>
          {clientsData?.map(
            ({
              name,
              user_id,
              photo,
              searches_info,
              time_created,
              is_new,
              last_activity,
            }) => {
              const sortValue = () => {
                if (sort_by === 'time_related' || sort_by === 'last_active') {
                  return last_activity;
                }

                return time_created;
              };
              return (
                <ClientTile
                  isNewClient={!!+is_new}
                  key={user_id}
                  name={name}
                  onClick={() =>
                    history.replace(
                      `/clients/${clientType || activeTab}/${user_id}/details`,
                    )
                  }
                  avatar={photo}
                  time={getCreationTimeFormatted(sortValue())}
                  isActive={user_id === clientId}
                  searchInfoParts={searches_info[clientType]?.search_info_parts}
                />
              );
            },
          )}
        </SimpleGrid>
      </Skeleton>
    </Box>
  );
};
