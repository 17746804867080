import React, { ReactElement } from 'react';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import { ComponentProps, useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { consoleAssert } from 'services/consoleAssert';

export const PrivateRoute = ({
  children,
  ...rest
}: ComponentProps<typeof Route>): ReactElement | null => {
  const authContext = useContext(AuthContext);

  consoleAssert(
    authContext,
    'No AuthContext provider detected before usage of <PrivateRoute>',
  );

  const history = useHistory();

  if (authContext.sessionId === undefined) {
    return null;
  }

  return (
    <Route {...rest}>
      {authContext.sessionId ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: history.location },
          }}
        />
      )}
    </Route>
  );
};
