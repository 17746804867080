import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { SearchInput } from '../../../apiTypes';
import { pbAgentFetcher } from '../../../fetcher';

interface Params {
  clientId: string | number;
  savedSearchId: string | number;
  searchInput: SearchInput;
}

export const useClientsSavedSearchesMutation = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['clients-saved-searches'],
    ({ clientId, savedSearchId, searchInput }: Params) =>
      pbAgentFetcher(`v1/clients-saved-searches/${clientId}/${savedSearchId}`, {
        method: 'put',
        body: JSON.stringify(searchInput),
      }),
    {
      onSuccess: (e, { clientId }) => {
        queryClient.invalidateQueries(['clients-saved-search', clientId]);
        queryClient.invalidateQueries(['clients', clientId]);
      },
    },
  );
};
