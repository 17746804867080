import React, { ReactElement, useEffect } from 'react';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useMessage } from 'contexts/MessageContext';
import { MessageActions } from './Actions/MainActions';
import { MessageForm } from './MessageForm';
import { MessageAttachments } from './MessageAttachments';
import { PreviousMessages } from './PreviousMessages';
import { QuickActions } from './Actions/QuickActions';
import { AttachIcon } from 'icons/icons';
import { MailboxLocationState } from 'types/MailboxRouteParams';
import { useLocation } from 'react-router-dom';

export const Message = (): ReactElement => {
  const location = useLocation<MailboxLocationState>();
  const {
    data: {
      message: {
        excerpt,
        from_name,
        to_name,
        to_email,
        email_chat,
        time_created,
        has_attachments,
        message_id,
      },
      formState: { isOpen: isFormOpen, type },
      previousMessages,
      currentMessage,
    },
    actions: { setMessageForm },
  } = useMessage();

  const isTargetMessage = location.state?.messageToOpen === message_id;
  const shouldOpenForm =
    isTargetMessage && location.state.shouldOpenForm !== false
      ? true
      : isFormOpen;

  useEffect(() => {
    shouldOpenForm && setMessageForm({ isOpen: true, type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccordionItem _first={{ borderTop: 0 }} pt={4} position={'relative'}>
      {({ isExpanded }) => (
        <>
          <Flex alignItems={'flex-start'} position={'relative'}>
            <AccordionButton
              _focus={{ boxShadow: 'none' }}
              borderRadius={'lg'}
              minH={'65px'}
              pr={10}
              onClick={() =>
                isFormOpen && setMessageForm({ type, isOpen: false })
              }
            >
              <SimpleGrid
                alignItems={'center'}
                columns={3}
                gridTemplateColumns={'50px minmax(200px, auto) 150px'}
                width={'100%'}
              >
                <Avatar name={from_name} />
                <VStack
                  spacing={1}
                  alignItems={'flex-start'}
                  lineHeight={'18px'}
                >
                  <Text fontWeight={'bold'}>{from_name}</Text>
                  {isExpanded ? (
                    <Text fontSize={'xs'}>{`To ${to_name || to_email}`}</Text>
                  ) : (
                    <Text
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      textOverflow={'ellipsis'}
                      width={'100%'}
                      fontSize={'sm'}
                      textAlign={'left'}
                    >
                      {excerpt}
                    </Text>
                  )}
                </VStack>
                <HStack
                  spacing={3}
                  alignItems={'center'}
                  alignSelf={'flex-start'}
                  ml={'auto'}
                >
                  {has_attachments && (
                    <AttachIcon fontSize={'16px'} color={'pbBlue.100'} />
                  )}
                  <Text fontSize={'xs'} color={'pbBlue.100'}>
                    {dayjs(time_created).format('DD MMM YYYY, h:mma')}
                  </Text>
                </HStack>
              </SimpleGrid>
            </AccordionButton>
            <Box mt={1} position={'absolute'} right={0}>
              <QuickActions />
            </Box>
          </Flex>

          <AccordionPanel>
            <VStack spacing={4} alignItems={'stretch'}>
              <Box
                dangerouslySetInnerHTML={{
                  __html: email_chat || currentMessage,
                }}
                fontSize={'sm'}
              />
              <PreviousMessages previousMessages={previousMessages} />
              {has_attachments ? <MessageAttachments /> : ''}
              <MessageActions />
              {shouldOpenForm && <MessageForm />}
            </VStack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
