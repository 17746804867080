import { Button, Checkbox, HStack, Text, VStack } from '@chakra-ui/react';
import { SearchInput } from 'components/Form/SearchInput';
import { Dropdown } from 'components/Global/Dropdown';
import { useAttachmentsLibrary } from 'contexts/AttachmentLibraryContext';
import { DownloadIcon } from 'icons/icons';
import React, { ReactElement } from 'react';
import { capitalizeFirstLetter } from 'services/capitalizeFirstLetter';
import { AttachmentOriginType } from 'types/AttachmentInLibrary';

export const AttachmentsHeading = (): ReactElement => {
  const {
    data: {
      attachmentsOriginOptions,
      attachmentsOriginType,
      areAllAttachmentsChecked,
      attachmentsToCurrentPage,
      isGeneratingZip,
      selectedAttachments,
    },
    actions: {
      setAttachmentsOriginType,
      setSearchValue,
      toggleCheckAllAttachments,
      downloadMultipleAttachments,
    },
  } = useAttachmentsLibrary();

  return (
    <VStack spacing={3} my={3}>
      <HStack spacing={3} alignItems={'center'} w={'100%'}>
        <SearchInput
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          maxWidth={{ base: '30%', xl: '50%' }}
          fontSize={5}
          flexShrink={1}
        />

        <Dropdown
          flexGrow={1}
          options={attachmentsOriginOptions.map(({ label }) => label)}
          onValueChange={(v) =>
            setAttachmentsOriginType(v.toLowerCase() as AttachmentOriginType)
          }
          defaultValue={'All'}
          value={capitalizeFirstLetter(attachmentsOriginType)}
          fontSize={'14px'}
          variant={'grayDropdown'}
          menuProps={{
            placement: 'bottom-end',
          }}
        />

        <Checkbox
          label={'Check all'}
          role={'checkbox'}
          mx={5}
          onChange={toggleCheckAllAttachments}
          isChecked={areAllAttachmentsChecked}
          isDisabled={!attachmentsToCurrentPage.length}
        >
          <Text fontSize={'sm'}>Check all</Text>
        </Checkbox>
        <Button
          size={'sm'}
          variant={'blueLight'}
          leftIcon={<DownloadIcon fontSize={'16px'} />}
          loadingText={'Downloading...'}
          isLoading={isGeneratingZip}
          isDisabled={
            !attachmentsToCurrentPage.length || !selectedAttachments.length
          }
          onClick={downloadMultipleAttachments}
        >
          Download
        </Button>
      </HStack>
    </VStack>
  );
};
