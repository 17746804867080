import {
  Avatar,
  Checkbox,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { StarIcon as ActiveStarIcon } from '@chakra-ui/icons';
import { mediaServer } from '../../services/mediaServer';
import { AttachIcon, PencilIcon, StarIcon } from '../../icons/icons';
import { useClient } from 'services/hooks/api/clients/useClient';
import { useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';
import { useMailbox } from 'contexts/MailboxContext';
import { useThread } from 'contexts/ThreadContext';
import dayjs from 'dayjs';
import { ThreadCardActions } from './ThreadCardActions';

interface ThreadCardProps {
  avatar?: string;
  name: string;
  subject: string;
  snippet: string;
  isUnread?: boolean;
  hasAttachment?: boolean;
  onClick: () => void;
}

export const ThreadCard = ({
  avatar,
  hasAttachment,
  isUnread,
  snippet,
  subject,
  name,
  onClick,
}: ThreadCardProps): ReactElement | null => {
  const { clientId, tab } = useParams<ClientRouteParams>();
  const { data: clientData } = useClient(clientId);

  const {
    data: { selectedThreads, currentFolder },
  } = useMailbox();

  const {
    data: {
      isTogglingFlag,
      thread: { time_created, messages, id: threadId },
    },
    actions: { onThreadCheck, toggleStarred },
  } = useThread();

  const timeFormatted = dayjs(time_created).format(
    dayjs(time_created).isToday() ? 'HH:mm' : 'MMM D',
  );

  const isStarred =
    !!messages.some((message) => message.label_ids.includes('STARRED')) ||
    false;
  const isClientView = tab === 'messages';

  if (isClientView && !clientData) {
    return null;
  }

  const isDraft = messages.some((message) =>
    message.label_ids.includes('DRAFT'),
  );

  const StarIconWrapper = (): ReactElement => {
    if (isTogglingFlag) return <Spinner size={'sm'} />;
    return isStarred ? (
      <ActiveStarIcon color={'pbYellow.500'} />
    ) : (
      <StarIcon color={'pbGray.150'} />
    );
  };

  return (
    <Flex
      flexGrow={1}
      role={'group'}
      borderRadius={'lg'}
      borderWidth={1}
      borderColor={isUnread ? 'transparent' : 'pbGray.100'}
      px={{ base: 2, xl: 5 }}
      py={{ base: 3 }}
      pr={{ xl: 2 }}
      backgroundColor={isUnread ? 'pbGray.80' : 'transparent'}
      alignItems={'center'}
      letterSpacing={'tighter'}
    >
      <SimpleGrid
        width={'100%'}
        columns={3}
        columnGap={5}
        gridTemplateColumns={{
          base: '250px minmax(200px, 100%) 1fr',
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <HStack spacing={3}>
          {!isClientView && (
            <Checkbox
              isChecked={selectedThreads.includes(threadId)}
              onChange={(e) => onThreadCheck(e, threadId)}
              gridArea={'checkbox'}
            />
          )}
          {!tab && currentFolder !== 'trash' && !isDraft && (
            <Tooltip
              label={isStarred ? 'Remove from starred' : 'Star thread'}
              placement={'top'}
            >
              <Flex onClick={(e) => toggleStarred(e, threadId, isStarred)}>
                <StarIconWrapper />
              </Flex>
            </Tooltip>
          )}

          <Avatar
            src={avatar && mediaServer(avatar)}
            name={avatar}
            borderRadius={'md'}
            w={{ base: '28px', md: '38px' }}
            h={{ base: '28px', md: '38px' }}
            sx={{
              '.chakra-avatar__initials': {
                fontSize: { base: 10, sm: 12, md: 14 },
              },
            }}
          />

          <Heading
            fontSize={{ base: 13, sm: 14, xl: 16 }}
            as={'h4'}
            wordBreak={'break-all'}
            minW={'150px'}
            maxWidth={'150px'}
            overflowX={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            onClick={onClick}
          >
            {name}
          </Heading>
        </HStack>

        <Flex alignItems={'center'} minW={0} onClick={onClick}>
          {isDraft && (
            <HStack alignItems={'center'} mr={2} color={'pbBlue.100'}>
              <PencilIcon fontSize={'16px'} />
              <Text>Draft</Text>
            </HStack>
          )}
          <Text
            fontSize={14}
            fontWeight={isUnread ? 'bold' : 'initial'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            {subject}
            <Text as={'span'} ml={3} color={'pbBlue.100'}>
              {snippet}
            </Text>
          </Text>
        </Flex>

        <Flex
          justifyContent={'flex-end'}
          alignItems={'center'}
          pl={3}
          ml={'auto'}
          mr={{ base: 2, xl: 4 }}
        >
          <ThreadCardActions />

          {hasAttachment ? <AttachIcon color={'pbGray.150'} /> : ''}

          <Text
            fontSize={{ base: 10, md: 12 }}
            color={'pbgray.200'}
            fontWeight={'bold'}
            textAlign={'center'}
            letterSpacing={'tight'}
            whiteSpace={'nowrap'}
            ml={2}
            minW={{ base: '0', xl: 50 }}
          >
            {timeFormatted}
          </Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};
