import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import { DashboardNewListing } from '../../services/apiTypes/dashboard';
import { ListingTitle } from '../Global/ListingTitle';
import { OwnBadge } from '../Global/OwnBadge';
import { Text } from '@chakra-ui/layout';
import { PhotoSlider } from '../ListingDetails/PhotoSlider';
import { PhotoSliderPopup } from 'components/Global/PhotoSliderPopup';
import { currencyFormat } from '../../services/currencyFormat';
import { DottedRow } from '../DottedRow';
import { Link, useHistory } from 'react-router-dom';
import { pluralize } from '../../services/pluralize';
import { InfoRowTextElement } from '../Global/InfoRow';
import { ChatIcon } from '@chakra-ui/icons';
import { ForwardIcon } from '../../icons/icons';
import dayjs from 'dayjs';
import { StringOrNumber } from '../../services/apiTypes';

interface ListingItemWithGalleryProps {
  listing: DashboardNewListing;
  containerProps?: BoxProps;
  onMessageSend?: () => void;
  onListingSend?: () => void;
}

export const ListingItemWithGallery = ({
  listing,
  containerProps,
  onListingSend,
  onMessageSend,
}: ListingItemWithGalleryProps): ReactElement => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedPhoto, setClickedPhoto] = useState<number>(0);

  const photoClickHandler = (photoIndex: number) => {
    setClickedPhoto(photoIndex);
    onOpen();
  };

  const redirect = (clientId: StringOrNumber) => {
    history.push(`/clients/${listing.ad_type}/${clientId}/details`);
  };

  return (
    <Box {...containerProps}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={{ base: 2, md: 0 }}
      >
        <HStack
          spacing={{ base: 0, md: 3 }}
          as={Link}
          to={`listing/${listing.advertisement_id}`}
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
          alignItems={'flex-start'}
        >
          <ListingTitle address={listing.address} unit={listing.unit} p={0} />

          <HStack spacing={3}>
            {listing.ribbon && (
              <OwnBadge
                color={listing.ribbon.color}
                text={listing.ribbon.text}
              />
            )}

            {listing.commission_structure && (
              <Text
                as={'span'}
                color={'pbRed.300'}
                fontSize={10}
                textTransform={'uppercase'}
                fontWeight={'bold'}
              >
                {`${listing.commission_structure}`}
              </Text>
            )}
          </HStack>
        </HStack>

        <HStack spacing={{ base: 1, md: 3 }}>
          <Text
            color={'pbBlue.100'}
            fontSize={{ base: 10, md: 12 }}
            letterSpacing={'tight'}
          >
            {dayjs(listing.time_created * 1000).format('MMM DD')}
          </Text>

          {onMessageSend && (
            <IconButton
              minW={0}
              h={30}
              w={30}
              icon={<ChatIcon />}
              aria-label={'Trash'}
              type={'button'}
              onClick={onMessageSend}
              disabled={false}
              color={'pbBlue.100'}
            />
          )}

          <IconButton
            minW={0}
            h={30}
            w={30}
            icon={<ForwardIcon />}
            type={'button'}
            aria-label={'Forward'}
            onClick={onListingSend}
            disabled={false}
            color={'pbBlue.100'}
          />
        </HStack>
      </Flex>

      <Box fontSize={{ base: 12, md: 14 }} lineHeight={{ base: 1.5, md: 1.75 }}>
        <Text as={'span'} fontWeight={'bold'} letterSpacing={'tight'} mr={2}>
          {currencyFormat(listing.price)}
          {listing.ad_type === 'rental' && '/month'}
        </Text>

        {listing.amenities_info && (
          <DottedRow
            display={{ base: 'inline-flex', md: 'flex' }}
            color={'pbBlue.100'}
            items={[
              <InfoRowTextElement
                key={'bathrooms'}
                label={pluralize(listing.bathrooms, 'Bath')}
                number={listing.bathrooms}
              />,
              <InfoRowTextElement
                key={'bedrooms'}
                label={pluralize(listing.bedrooms, 'Bed')}
                number={listing.bedrooms}
              />,
              <InfoRowTextElement
                key={'size'}
                label={'sqft'}
                number={listing.size}
              />,
            ]}
          />
        )}

        {listing.amenities_info && (
          <DottedRow
            wrap={'wrap'}
            color={'pbBlue.100'}
            items={listing.amenities_info}
          />
        )}

        <Flex mt={2} mb={3}>
          {listing.clients.map((client, i) => (
            <Button
              key={client.user_id}
              fontWeight={'normal'}
              variant={'reset'}
              onClick={() => redirect(client.user_id)}
              whiteSpace={'pre'}
              fontSize={{ base: 12, md: 14 }}
            >
              {client.name}
              {i < listing.clients.length - 1 && ', '}
            </Button>
          ))}
        </Flex>
      </Box>

      <PhotoSlider
        photos={listing?.photos}
        onPhotoClick={photoClickHandler}
        settingsOverride={{
          infinite: true,
        }}
        containerProps={{
          mt: 3,
          mb: 0,
          mr: { base: -3, xl: -5 },
        }}
        imagesHeight={[100, 120, 150, 180, 200, 216]}
      />

      <PhotoSliderPopup
        onClose={onClose}
        isOpen={isOpen}
        clickedPhoto={clickedPhoto}
        photos={listing?.photos}
      />
    </Box>
  );
};
