import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { DashboardNewListing } from '../../apiTypes/dashboard';

export const useDashboardNewListings = (): UseQueryResult<
  DashboardNewListing[]
> => {
  const params = new URLSearchParams({
    limit: '0',
    offset: '0',
  });

  return useQuery<DashboardNewListing[]>(['dashboard-new-listings'], () =>
    pbAgentFetcher(`v1/dashboard-new-listings?${params.toString()}`),
  );
};
