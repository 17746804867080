import { ChangeEventHandler, Dispatch, SetStateAction, useState } from 'react';
import { GmailMessagesFile, GmailMessagesPostBody } from './apiTypes';
import { formatBytes } from '../../../formatBytes';
import { Option } from 'services/apiTypes';
import { uniqBy } from 'lodash';

export interface FormData extends GmailMessagesPostBody {
  cc_name?: string[];
}

interface UseGmailBody {
  state: [FormData, Dispatch<SetStateAction<FormData>>];
  onAttachmentRemove: (fileName: string) => void;
  onResetState: () => void;
  onFileInputChange: ChangeEventHandler<HTMLInputElement>;
  onRecipientChange: (options: Option[]) => void;
  onCcChange: (options: Option[]) => void;
}

export const useGmailBody = (
  init: Omit<GmailMessagesPostBody, 'to'> = {},
): UseGmailBody => {
  const initialState = {
    attachments: [],
    to: [],
    cc: [],
    bcc: [],
    to_name: [],
    body: '',
    subject: '',
    ...init,
  };

  const [body, setBody] = useState<FormData>(initialState);

  const onResetState = () => setBody(initialState);

  const onAttachmentRemove = (fileName: string) => {
    setBody((prev) => ({
      ...prev,
      attachments: prev.attachments?.filter((a) => a.name !== fileName),
    }));
  };

  const onFileInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { files },
  }) => {
    if (!files?.length) {
      return;
    }

    const fileArray: File[] = Array.from(files);

    fileArray.forEach((f) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => {
        const data =
          reader?.result &&
          typeof reader?.result === 'string' &&
          reader.result.split(',')[1];

        if (!data) {
          return;
        }

        const newAttachment: GmailMessagesFile = {
          name: f.name,
          type: f.type,
          size_estimate: formatBytes(f.size),
          data,
        };

        setBody((prevState) => ({
          ...prevState,
          attachments: prevState.attachments?.length
            ? [...prevState.attachments, newAttachment]
            : [newAttachment],
        }));
      };
    });
  };

  const onRecipientChange = (options: Option[]) => {
    if (!options) {
      return;
    }

    options = uniqBy(options, 'value');

    setBody({
      ...body,
      to: options.map((o) => o.value),
      to_name: options.map((o) => o.label || o.value),
    });
  };

  const onCcChange = (options: Option[]) =>
    setBody((prev) => ({
      ...prev,
      cc: options.map((o) => o.value),
      cc_name: options.map((o) => o.label),
    }));

  return {
    state: [body, setBody],
    onAttachmentRemove,
    onFileInputChange,
    onResetState,
    onRecipientChange,
    onCcChange,
  };
};
