import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { AmexIcon, MastercardIcon, VisaIcon } from 'icons/icons';
import React, { FC } from 'react';

export type SavedCardProps = {
  cardType: typeof VisaIcon | typeof AmexIcon | typeof MastercardIcon;
  cardLastDigits: number;
  cardExpiration: string;
  isActive?: boolean;
};

const SavedCard: FC<SavedCardProps> = ({
  cardType,
  isActive,
  cardLastDigits,
  cardExpiration,
}: SavedCardProps) => {
  return (
    <Box
      p={4}
      minW={'210px'}
      h={'110px'}
      bgColor={'pbGray.60'}
      borderRadius={'lg'}
    >
      <HStack justify={'space-between'}>
        <Icon as={cardType} boxSize={'28px'} />
        <Box
          px={'10px'}
          borderWidth={'1px'}
          borderColor={isActive ? undefined : 'pbGray.40'}
          color={isActive ? 'white' : 'pbGray.40'}
          bgColor={isActive ? 'pbGreen.300' : undefined}
          borderRadius={'base'}
        >
          <Text fontWeight={'bold'} fontSize={'10px'}>
            PRIMARY
          </Text>
        </Box>
      </HStack>
      <HStack
        fontWeight={'bold'}
        fontSize={'12px'}
        pt={5}
        letterSpacing={'tight'}
        lineHeight={3}
      >
        <Text color={'pbGray.30'}>XXXX</Text>
        <Text color={'pbGray.30'}>XXXX</Text>
        <Text color={'pbGray.30'}>XXXX</Text>
        <Text color={'pbGray.500'}>{cardLastDigits}</Text>
      </HStack>
      <Text
        fontWeight={'bold'}
        fontSize={'12px'}
        letterSpacing={'tight'}
        pt={2}
        color={'pbGray.30'}
      >
        {cardExpiration}
      </Text>
    </Box>
  );
};

export default SavedCard;
