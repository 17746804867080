import { Input, VStack } from '@chakra-ui/react';
import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { RadioRow } from '../../Form/RadioRow';
import { ParticipantInput } from '../../../services/apiTypes';
import { useParticipantMutationPost } from '../../../services/hooks/api/clients/useParticipantMutation';
import { useClientContext } from '../../../contexts/ClientContext';
import { ActionModal } from '../../Global/ActionModal';
import { ModalProps } from 'types/ModalProps';

const defaultValues = {
  type: 'TENANT',
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
};

export const AddParticipantModal = ({
  onClose,
  isOpen,
}: ModalProps): ReactElement => {
  const {
    clientsState: { clientId },
  } = useClientContext();
  const [newParticipant, setNewParticipant] =
    useState<ParticipantInput>(defaultValues);

  const {
    status: mutationStatus,
    isLoading,
    mutate,
  } = useParticipantMutationPost();

  useEffect(() => {
    if (mutationStatus === 'success') {
      setNewParticipant(defaultValues);
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationStatus]);

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setNewParticipant({
      ...newParticipant,
      [name]: value,
    });
  };

  const onSubmit = () => {
    clientId &&
      mutate({
        clientId,
        participantData: newParticipant,
      });
  };
  return (
    <ActionModal
      isOpen={isOpen}
      isDisabled={!newParticipant?.first_name || !newParticipant?.email}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
      title={'Add New Participant'}
    >
      <VStack>
        <RadioRow
          label={'Type'}
          onChange={(e) =>
            setNewParticipant((prev) => ({
              ...prev,
              type: e as string,
            }))
          }
          value={newParticipant?.type}
          options={['TENANT', 'GUARANTOR']}
        />
        <Input
          placeholder={'First name*'}
          onChange={onChange}
          value={newParticipant?.first_name}
          name={'first_name'}
          required
        />
        <Input
          placeholder={'Last name*'}
          required
          onChange={onChange}
          name={'last_name'}
          value={newParticipant?.last_name}
        />
        <Input
          placeholder={'Phone*'}
          required
          onChange={onChange}
          name={'phone'}
          value={newParticipant?.phone}
          type={'tel'}
        />
        <Input
          placeholder={'Email*'}
          required
          onChange={onChange}
          name={'email'}
          value={newParticipant?.email}
          type={'email'}
        />
      </VStack>
    </ActionModal>
  );
};
