import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { Ownership } from '../../../services/apiTypes';
import { CheckboxesColumn } from '../../Form/CheckboxesColumn';
import { useURLSearchParams } from '../../../services/hooks/useURLSearchParams';
import { useParams } from 'react-router-dom';
import { OwnSelect } from '../../Form/OwnSelect';
import { RadioRow } from '../../Form/RadioRow';
import { PriceRange } from '../../Form/PriceRange';
import { SectionSubTitle } from '../../Global/SectionSubTitle';
import { useClientContext } from '../../../contexts/ClientContext';
import { CheckboxesRow } from '../../Form/CheckboxesRow';
import { onSetClient } from '../../../contexts/ClientContext/actions';
import { ChevronIcon } from '../../../icons/icons';
import { useClients } from 'services/hooks/api/clients/useClients';
import { useSearchForm } from 'services/hooks/api/search-form/useSearchForm';
import { useSearchFormOptions } from 'services/hooks/api/search-form/useSearchFormOptions';
import { useResults } from 'contexts/ResultsContext';

const AccordionHeading = ({
  label,
  isExpanded,
  selections = 0,
}: {
  label: string;
  isExpanded?: boolean;
  selections?: number | string;
}) => (
  <AccordionButton
    px={5}
    py={4}
    fontSize={'12px'}
    color={'pbBlue.100'}
    sx={{
      svg: {
        ml: 4,
        transform: `rotate(${isExpanded ? '180' : '0'}deg)`,
      },
    }}
  >
    <Heading as={'h4'} fontSize={14} mr={'auto'} color={'pbGray.500'}>
      {label}
    </Heading>

    {!!selections && selections !== 0 && <Text as={'span'}>{selections}</Text>}

    <ChevronIcon width={'17px'} />
  </AccordionButton>
);

const AccordionElement = ({
  children,
  selections = 0,
  label,
}: {
  children: ReactNode;
  label: string;
  selections?: number | string;
}) => (
  <AccordionItem>
    {({ isExpanded }) => {
      return (
        <>
          <AccordionHeading
            label={label}
            isExpanded={isExpanded}
            selections={selections}
          />
          <AccordionPanel pt={1} pb={4}>
            {children}
          </AccordionPanel>
        </>
      );
    }}
  </AccordionItem>
);

export const SearchResultsFilters = (): ReactElement => {
  const { type } = useParams<{ type: string }>();
  const { data: dataSearchForm } = useSearchFormOptions();
  const { searchParams, addSearchParam, setSearchParams } =
    useURLSearchParams();
  const { onSaveSearch, isLoading: isSearchLoading } = useSearchForm();
  const {
    clientsState: { clientId, isClientSearchActive },
    clientsDispatch,
  } = useClientContext();
  const {
    actions: { setClientId },
  } = useResults();

  const { data: clientsData } = useClients();

  const {
    building_type,
    ownership,
    bedrooms,
    bathrooms,
    furnished,
    pets,
    building_features,
    price_defaults,
  } = dataSearchForm || {};

  const typedOwnership = !!ownership && ownership[type as keyof Ownership];
  const typedPriceDefaults =
    !!price_defaults && price_defaults[type as keyof Ownership];

  const handleClientSelect = useCallback(
    (e) => {
      clientsDispatch(onSetClient(e.target.value));
      setClientId(e.target.value);
    },
    [clientsDispatch, setClientId],
  );

  return (
    <Box
      ml={{ base: 0, lg: 5 }}
      borderRadius={'lg'}
      bg={'white'}
      w={{ base: '100%', lg: 380 }}
      position={{ base: 'absolute', lg: 'relative' }}
    >
      <Flex
        px={6}
        py={5}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <SectionSubTitle>Filters</SectionSubTitle>
        <HStack>
          <Button
            onClick={() => {
              setSearchParams();
              clientsDispatch(onSetClient());
            }}
            variant={'link'}
            mr={4}
          >
            Reset
          </Button>
          <Button
            variant={'blueLight'}
            onClick={onSaveSearch}
            disabled={!clientId || isClientSearchActive || isSearchLoading}
          >
            Save Search
          </Button>
        </HStack>
      </Flex>

      <Box px={5} mb={3}>
        <OwnSelect
          options={clientsData?.map(({ user_id: value, name: label }) => ({
            value,
            label,
          }))}
          placeholder={'Load Client'}
          value={clientId}
          onChange={handleClientSelect}
        />
      </Box>

      <Skeleton isLoaded={!!dataSearchForm} pb={5}>
        <Accordion allowMultiple allowToggle>
          {building_type && (
            <AccordionElement
              label={'Building Type'}
              selections={searchParams.getAll('building_type').length}
            >
              <CheckboxesColumn
                onChange={(e) => addSearchParam('building_type', e)}
                options={building_type}
                value={searchParams.getAll('building_type')}
                columns={3}
                size={'small'}
              />
            </AccordionElement>
          )}
          <AccordionElement
            label={'Price Range'}
            selections={
              searchParams.get('min_price') || searchParams.get('max_price')
                ? 1
                : 0
            }
          >
            <PriceRange
              priceDefaults={typedPriceDefaults}
              from={parseInt(searchParams.get('min_price') as string)}
              to={parseInt(searchParams.get('max_price') as string)}
              onFromChange={(e) => addSearchParam('min_price', e.target.value)}
              onToChange={(e) => addSearchParam('max_price', e.target.value)}
            />
          </AccordionElement>
          {typedOwnership && (
            <AccordionElement
              label={'Ownership'}
              selections={searchParams.getAll('ownership').length}
            >
              <CheckboxesColumn
                onChange={(e) => addSearchParam('ownership', e)}
                options={typedOwnership}
                value={searchParams.getAll('ownership')}
                columns={3}
                size={'small'}
              />
            </AccordionElement>
          )}
          {bedrooms && (
            <AccordionElement
              label={'Beds'}
              selections={searchParams.getAll('bedrooms').join(', ')}
            >
              <CheckboxesRow
                onChange={(e) => addSearchParam('bedrooms', e)}
                value={searchParams.getAll('bedrooms')}
                options={bedrooms}
              />
            </AccordionElement>
          )}
          {bathrooms && (
            <AccordionElement
              label={'Baths'}
              selections={searchParams.getAll('bathrooms').join(', ')}
            >
              <CheckboxesRow
                onChange={(e) => addSearchParam('bathrooms', e)}
                value={searchParams.getAll('bathrooms')}
                options={bathrooms}
              />
            </AccordionElement>
          )}
          {pets && (
            <AccordionElement
              label={'Pets'}
              selections={searchParams.getAll('pets').length}
            >
              <RadioRow
                onChange={(e) => addSearchParam('pets', e)}
                options={pets}
                value={searchParams.get('pets') as string}
              />
            </AccordionElement>
          )}
          {furnished && (
            <AccordionElement
              label={'Furnished'}
              selections={searchParams.get('furnished') as string}
            >
              <CheckboxesRow
                onChange={(e) => addSearchParam('furnished', e)}
                value={searchParams.getAll('furnished')}
                options={furnished}
              />
            </AccordionElement>
          )}
          {building_features && (
            <AccordionElement
              label={'Building Features'}
              selections={searchParams.getAll('building_features').length}
            >
              <CheckboxesColumn
                onChange={(e) => addSearchParam('building_features', e)}
                options={building_features}
                value={searchParams.getAll('building_features')}
                columns={3}
                size={'small'}
              />
            </AccordionElement>
          )}
        </Accordion>
      </Skeleton>
    </Box>
  );
};
