import React, { ReactElement, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import {
  Client,
  ClientsMutationInput,
  SearchType,
} from '../../../services/apiTypes';
import { useClientsMutationPost } from '../../../services/hooks/api/clients/useClient';
import { ActionModal } from '../../Global/ActionModal';
import { redirectToOverview } from 'services/redirectToOverview';
import { useHistory } from 'react-router';
import { onSetClient } from 'contexts/ClientContext/actions';
import { useClientContext } from 'contexts/ClientContext';
import ClientDetailsForm from '../ClientDetailsForm';
import { ModalProps } from 'types/ModalProps';
import { isString, mapValues } from 'lodash';
import generateSuccessToast from 'services/generateToast';
import { useQueryClient } from 'react-query';
import { EmailExists } from 'services/hooks/api/validation/apiTypes';
import { useRestoreClient } from 'services/hooks/api/clients/useRestoreClient';

export const AddClientModal = ({
  onClose,
  isOpen,
}: ModalProps): ReactElement => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [adType, setAdType] = useState<string>();
  const methods = useForm({ mode: 'onChange' });
  const { mutate, isLoading } = useClientsMutationPost();
  const { mutate: restoreClient, isLoading: isRestoringClient } =
    useRestoreClient();
  const { clientsDispatch } = useClientContext();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const userExists = queryClient.getQueryData<EmailExists>([
    'email-exists',
    methods.getValues('email'),
  ])?.exists;

  const handleSuccess = (response: Client) => {
    clientsDispatch(onSetClient(response.user_id));
    toast(
      generateSuccessToast(
        `New client, ${response.name || response.email}, has been added`,
      ),
    );
    onClose();
    redirectToOverview(
      `clients/${adType || 'rental'}/${response.user_id}/details`,
      push,
      pathname,
    );
  };

  const onSubmit = () => {
    if (userExists) {
      return restoreClient(methods.getValues('email'), {
        onSuccess: handleSuccess,
      });
    }

    const newClient = {
      ...methods.getValues(),
      search: {
        ad_type: adType as SearchType,
      },
    };

    const sanitizedClient = mapValues(newClient, (value) =>
      isString(value) ? value.replace('\n', '') : value,
    ) as ClientsMutationInput;

    mutate(sanitizedClient, {
      onSuccess: handleSuccess,
    });
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={'Add New Client'}
      isDisabled={!methods.formState.isValid}
      isLoading={isLoading || isRestoringClient}
      onSubmit={methods.handleSubmit(onSubmit)}
      submitLabel={'Save'}
      size={'2xl'}
    >
      <FormProvider {...methods}>
        <ClientDetailsForm
          adType={adType}
          onRadioChange={(newAddType) =>
            setAdType((newAddType as string).toLowerCase())
          }
        />
      </FormProvider>
    </ActionModal>
  );
};
