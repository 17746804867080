import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  SimpleGrid,
  Skeleton,
} from '@chakra-ui/react';
import React, { ReactElement, memo, useMemo } from 'react';
import { useURLSearchParams } from '../../services/hooks/useURLSearchParams';
import { RadioRow } from '../Form/RadioRow';
import { CheckboxesRow } from '../Form/CheckboxesRow';
import { CheckboxesColumn } from '../Form/CheckboxesColumn';
import { Ownership, SearchType } from '../../services/apiTypes';
import { OwnTabs, TabsType } from '../Global/OwnTabs';
import { RangesSections } from '../Form/RangesSections';
import { SectionTitle } from '../Global/SectionTitle';
import { SectionSubTitle } from '../Global/SectionSubTitle';
import { OwnSelect } from '../Form/OwnSelect';
import { useClients } from '../../services/hooks/api/clients/useClients';
import { useClientContext } from '../../contexts/ClientContext';
import { PriceRange } from '../Form/PriceRange';
import { CompanyInput } from '../Form/CompanyInput';
import { SearchIcon } from '@chakra-ui/icons';
import { onSetClient } from '../../contexts/ClientContext/actions';
import { useSearchForm } from '../../services/hooks/api/search-form/useSearchForm';
import { RoundedBoxSection } from '../Global/RoundedBoxSection';
import { useClientSearchParams } from '../../services/hooks/useClientSearchParams';
import { useSearchFormOptions } from '../../services/hooks/api/search-form/useSearchFormOptions';

interface FormSectionRowProps extends BoxProps {
  isLoading?: boolean;
}

export const FormSectionRow = ({
  children,
  isLoading,
  ...props
}: FormSectionRowProps): ReactElement => {
  return (
    <Box mt={{ base: 2, xl: 5 }} w={'100%'} {...props}>
      {isLoading ? (
        <Skeleton w={'100%'} h={38} borderRadius={'md'} />
      ) : (
        children
      )}
    </Box>
  );
};

export const SearchForm = memo(({ type }: { type: SearchType }) => {
  const { data: dataSearchForm, isLoading: isLoadingSearchForm } =
    useSearchFormOptions();

  const {
    building_type,
    furnished,
    ownership,
    pets,
    bathrooms,
    bedrooms,
    building_features,
    apartment_features,
    boroughs,
    price_defaults,
  } = dataSearchForm || {};

  const { data: clientsData } = useClients();
  const {
    clientsState: { clientId, isClientSearchActive },
    clientsDispatch,
  } = useClientContext();

  const { onSaveSearch, isLoading: isSearchLoading } = useSearchForm();

  const { searchParams, addSearchParam, history } = useURLSearchParams();

  const boroughsTabs: TabsType[] = useMemo(
    () =>
      boroughs
        ?.filter(
          ({ sections, neighborhoods }) =>
            sections?.length > 0 || neighborhoods?.length > 0,
        )
        .map(({ name: label, abbreviation, sections, neighborhoods }) => ({
          label,
          abbreviation,
          content: (
            <RangesSections
              sections={sections}
              neighborhoods={neighborhoods}
              onChange={(e) => addSearchParam('neighborhood', e)}
              value={searchParams.getAll('neighborhood')}
            />
          ),
        })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [boroughs, searchParams],
  );

  const onRunSearch = () =>
    history.push(`/results/${type}?${searchParams}`, { clientId });

  const typedPriceDefaults =
    !!price_defaults && price_defaults[type as keyof Ownership];

  useClientSearchParams({
    clientId,
    type,
  });

  return (
    <>
      {isLoadingSearchForm && <Skeleton w={'100%'} borderRadius={'lg'} />}

      <RoundedBoxSection hasBorder pb={5}>
        <Flex justify={'space-between'} wrap={'wrap'}>
          <SectionTitle>New Search</SectionTitle>
          <OwnSelect
            options={clientsData?.map(({ user_id: value, name: label }) => ({
              value,
              label,
            }))}
            placeholder={'Load Client'}
            onChange={(e) => clientsDispatch(onSetClient(e.target.value))}
            value={clientId}
            mb={{ base: 2, lg: 0 }}
          />
        </Flex>

        <SectionSubTitle my={{ base: 1, xl: 3 }}>
          General Information
        </SectionSubTitle>

        <SimpleGrid columns={{ xl: 2 }}>
          <Box pl={{ xl: 3 }} pr={{ base: 3, md: 5, '2xl': 105 }}>
            <FormSectionRow>
              <CheckboxesRow
                label={'Beds'}
                onChange={(e) => addSearchParam('bedrooms', e)}
                value={searchParams.getAll('bedrooms')}
                options={bedrooms}
              />
            </FormSectionRow>

            <FormSectionRow>
              <CheckboxesRow
                label={'Baths'}
                onChange={(e) => addSearchParam('bathrooms', e)}
                value={searchParams.getAll('bathrooms')}
                options={bathrooms}
              />
            </FormSectionRow>

            <FormSectionRow>
              <PriceRange
                priceDefaults={typedPriceDefaults}
                from={parseInt(searchParams.get('min_price') as string)}
                to={parseInt(searchParams.get('max_price') as string)}
                onFromChange={(e) =>
                  addSearchParam('min_price', e.target.value)
                }
                onToChange={(e) => addSearchParam('max_price', e.target.value)}
                label={'Price Range'}
              />
            </FormSectionRow>

            <FormSectionRow>
              <CompanyInput
                value={searchParams.getAll('company_name')}
                onChange={(e) => addSearchParam('company_name', e)}
                label={'Company'}
                placeholder={'Type Company Name'}
              />
            </FormSectionRow>
          </Box>
          <Box pt={{ base: 3, xl: 0 }}>
            <FormSectionRow>
              <CheckboxesRow
                label={'Building Type'}
                onChange={(e) => addSearchParam('building_type', e)}
                value={searchParams.getAll('building_type')}
                options={building_type}
              />
            </FormSectionRow>
            <FormSectionRow>
              <CheckboxesRow
                label={'Ownership'}
                onChange={(e) => addSearchParam('ownership', e)}
                value={searchParams.getAll('ownership')}
                options={ownership?.[type]}
              />
            </FormSectionRow>

            <FormSectionRow>
              <CheckboxesRow
                label={'Furnished'}
                onChange={(e) => addSearchParam('furnished', e)}
                value={searchParams.getAll('furnished')}
                options={furnished}
              />
            </FormSectionRow>

            <FormSectionRow>
              <RadioRow
                label={'Pets'}
                onChange={(e) => addSearchParam('pets', e)}
                value={searchParams.get('pets') as string}
                options={pets}
              />
            </FormSectionRow>
          </Box>
        </SimpleGrid>
      </RoundedBoxSection>

      <RoundedBoxSection hasBorder pb={3}>
        <SectionSubTitle mt={2} mb={{ base: 3, md: 5, xl: 7 }}>
          Neighborhoods
        </SectionSubTitle>
        <Box pl={{ xl: 3 }}>
          <OwnTabs
            spacingBetween={{ xl: 6 }}
            tabs={boroughsTabs}
            onChange={(e) => {
              addSearchParam('borough', e);
              addSearchParam('neighborhood', []);
            }}
            value={searchParams.get('borough') as string}
          />
        </Box>
      </RoundedBoxSection>

      <RoundedBoxSection>
        <SimpleGrid
          columns={{ md: 2, '2xl': 3 }}
          gap={{ base: 5, xl: 2 }}
          pl={{ xl: 3 }}
          py={2}
        >
          <CheckboxesColumn
            label={'Building Features'}
            onChange={(e) => addSearchParam('building_features', e)}
            options={building_features}
            value={searchParams.getAll('building_features')}
          />
          <CheckboxesColumn
            label={'Apartment Features'}
            onChange={(e) => addSearchParam('apartment_features', e)}
            options={apartment_features}
            value={searchParams.getAll('apartment_features')}
          />
        </SimpleGrid>
      </RoundedBoxSection>

      <RoundedBoxSection>
        <HStack spacing={8} pr={3} align={'center'} justify={'flex-end'}>
          <Button
            size={'md'}
            variant={'link'}
            onClick={onSaveSearch}
            disabled={!clientId || isClientSearchActive || isSearchLoading}
          >
            Save Search
          </Button>
          <Button
            size={'md'}
            variant={'blue'}
            onClick={onRunSearch}
            leftIcon={<SearchIcon color={'inherit'} boxSize={'4'} mr={0} />}
            disabled={isSearchLoading}
          >
            Run Search
          </Button>
        </HStack>
      </RoundedBoxSection>
    </>
  );
});
