import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailContextTemplate } from './apiTypes';

export const useGmailContextTemplates = (
  email_id?: string,
  template = 'inquiry',
): UseQueryResult<GmailContextTemplate[]> => {
  const url = !!email_id
    ? `v1/gmail-context-templates/${template}?email_id=${email_id}`
    : `v1/gmail-context-templates/${template}`;

  return useQuery<GmailContextTemplate[]>(
    ['gmail-context-templates', email_id],
    () => pbAgentFetcher(url),
    {
      refetchOnWindowFocus: false,
    },
  );
};
