import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';
import { ChildrenProps } from 'types/ChildrenProps';

export const OwnQueryClientProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {},
  });
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
