import React, { ReactElement, useCallback, useState } from 'react';
import { mediaServer } from '../../services/mediaServer';
import { ImageWithPlaceholder } from '../Global/ImageWithPlaceholder';
import { Box, BoxProps, ResponsiveValue, Skeleton } from '@chakra-ui/react';
import Slider, { Settings } from 'react-slick';
import { StringOrNumber } from '../../services/apiTypes';

import 'slick-carousel/slick/slick.css';

interface PhotoSliderProps {
  photos?: string[];
  isLoading?: boolean;
  onPhotoClick?: (photoIndex: number) => void;
  settingsOverride?: Settings;
  containerProps?: BoxProps;
  imagesHeight?: ResponsiveValue<StringOrNumber>;
}

export const PhotoSlider = ({
  photos,
  isLoading = false,
  onPhotoClick,
  settingsOverride,
  containerProps,
  imagesHeight = { base: '150px', md: '200px', xl: '333px' },
}: PhotoSliderProps): ReactElement => {
  // Solution from https://github.com/akiran/react-slick/issues/848#issuecomment-635672543
  // while react slick doesn't support not firing click event on swiping by default.
  const [swiped, setSwiped] = useState(false);

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const onClickHandler = useCallback(
    (e, i) => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
      } else {
        if (onPhotoClick) onPhotoClick(i);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swiped],
  );

  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    variableWidth: true,
    touchThreshold: 10,
    onSwipe: handleSwiped,
    ...settingsOverride,
  };

  return (
    // Line height set to 0 to make it equal height with images inside
    <Box my={5} lineHeight={0} {...containerProps}>
      <Slider {...settings}>
        {photos?.map((photo, index) => (
          <Skeleton isLoaded={!isLoading} h={imagesHeight} key={photo}>
            <ImageWithPlaceholder
              maxWidth={'540px'}
              w={'auto !important'}
              h={'auto'}
              minHeight={imagesHeight}
              maxH={'100%'}
              marginRight={'10px'}
              objectFit={'contain'}
              src={mediaServer(photo, 'wadmin')}
              borderRadius={'10px'}
              onClick={(e) => onClickHandler(e, index)}
              cursor={'pointer'}
            />
          </Skeleton>
        ))}
      </Slider>
    </Box>
  );
};
