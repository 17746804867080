import React, { ReactElement } from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { mediaServer } from '../../services/mediaServer';
import { RemoveButton } from './RemoveButton';

interface ContactBoxProps {
  name?: string;
  photo?: string;
  email?: string;
  phone?: string;
  onRemove?: () => void;
}

export const ContactBox = ({
  onRemove,
  name,
  photo,
  email,
  phone,
}: ContactBoxProps): ReactElement => {
  return (
    <Flex
      border={'1px'}
      borderColor={'pbGray.100'}
      borderRadius={9}
      w={'100%'}
      p={{ base: 3, md: 5 }}
      pb={{ base: 2, md: 4 }}
      mr={'10px'}
      pos={'relative'}
    >
      {onRemove && <RemoveButton onClick={onRemove} />}

      <Avatar
        src={photo ? mediaServer(photo, 'jango-contact') : undefined}
        name={name}
        mr={'13px'}
      />
      <Box mt={-1}>
        {name && (
          <Text color={'pbGray.500'} fontSize={16} fontWeight={'bold'}>
            {name}
          </Text>
        )}
        {phone && (
          <Text color={'pbGray.400'} fontSize={14}>
            {phone}
          </Text>
        )}
        {email && (
          <Text color={'pbGray.400'} fontSize={14} wordBreak={'break-all'}>
            {email}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
