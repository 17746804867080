import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { useURLSearchParams } from '../services/hooks/useURLSearchParams';
import { useParams } from 'react-router-dom';
import { SearchTypeParam } from '../services/apiTypes';
import { SearchResultsList } from '../components/SearchResults/SearchResultsList';
import { SearchResultGrid } from '../components/SearchResults/SearchResultsGrid';
import { Pagination } from '../components/Global/Pagination';
import { SearchResultsFilters } from '../components/SearchResults/SearchResultsFilters';
import { useSearchListingsAdvice } from '../services/hooks/api/search-listings/useSearchListingsAdvice';
import {
  listingsFetcher,
  useSearchListings,
} from '../services/hooks/api/search-listings/useSearchListings';
import { stringifyParamsAccordingToAPI } from '../services/stringifyParamsAccordingToAPI';
import { mergeListingsData } from 'services/mergeListingData';
import { useResults } from 'contexts/ResultsContext';

export const SearchResults = (): ReactElement => {
  const {
    data: { layoutType, filtersVisible },
    actions: { setListingIds },
  } = useResults();
  const { type } = useParams<SearchTypeParam>();
  const { searchParams, addSearchParam } = useURLSearchParams();

  const queryClient = useQueryClient();
  const listingsPerPage: number = parseInt(
    searchParams.get('limit') || '3',
    10,
  );
  const offset: number = parseInt(searchParams.get('offset') || '0', 10);

  const initialPage = Math.ceil(offset / listingsPerPage) + 1;
  const [page, setPage] = useState(initialPage || 1);
  const [maxNumPages, setMaxNumPages] = useState(0);

  useEffect(() => {
    addSearchParam('ad_type', type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: listingData,
    isPreviousData,
    isLoading,
  } = useSearchListings(searchParams, page);

  useEffect(() => {
    if (page + 1 < maxNumPages) {
      queryClient.prefetchQuery(
        [
          'search-listings',
          stringifyParamsAccordingToAPI(searchParams),
          page + 1,
        ],
        () => listingsFetcher(stringifyParamsAccordingToAPI(searchParams)),
      );
    }
    // Determine prefetch only on page change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const { data: dataAdvice } = useSearchListingsAdvice(searchParams);

  useEffect(() => {
    window?.scrollTo({ top: 0 });
    addSearchParam(
      'offset',
      Math.max(page * listingsPerPage - listingsPerPage, 0),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingsPerPage, page]);

  useEffect(() => {
    if (!dataAdvice || dataAdvice?.count === 0) {
      return;
    }

    setMaxNumPages(Math.ceil(dataAdvice.count / listingsPerPage));
  }, [listingsPerPage, dataAdvice]);

  useEffect(
    () =>
      setListingIds(
        listingData?.map((listing) => listing.advertisement_id) || [],
      ),
    [listingData, setListingIds],
  );

  const mergedListingData = mergeListingsData([], listingData);
  const ListComponent =
    layoutType === 'grid' ? SearchResultGrid : SearchResultsList;
  return (
    <>
      <Flex position={'relative'}>
        <Box p={5} borderRadius={'lg'} bg={'white'} flex={'1'}>
          <ListComponent
            data={mergedListingData}
            isLoading={isLoading}
            isShrinked={filtersVisible}
          />
        </Box>

        {filtersVisible && <SearchResultsFilters />}
      </Flex>

      {maxNumPages > 1 && (
        <Box p={5} borderRadius={'lg'}>
          <Pagination
            page={page}
            maxNumPages={maxNumPages}
            onChange={setPage}
            disabled={isPreviousData}
          />
        </Box>
      )}
    </>
  );
};
