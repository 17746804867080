import {
  UseInfiniteQueryResult,
  UseMutationResult,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { NotificationResponse } from './apiTypes';
import { pbAgentFetcher } from 'services/fetcher';
import { SearchParams } from 'services/apiTypes';
import { objectToUrlParams } from 'services/objectToUrlParams';
import { PageParams } from 'types/ApiResponse';

export const defaultValues: SearchParams = {
  limit: 10,
  offset: 0,
};

// intended naming change [backend] alerts -> [frontend] notifications

export const useGetNotifications = (
  params?: SearchParams,
): UseInfiniteQueryResult<NotificationResponse> => {
  const alertsParams = new URLSearchParams(
    objectToUrlParams({ ...defaultValues, ...params }),
  );

  return useInfiniteQuery<NotificationResponse>(
    ['dashboard-notifications'],
    ({ pageParam }) => {
      if (pageParam) {
        const newOffset = (
          parseInt(alertsParams.get('offset') || '') + pageParam
        ).toString();
        alertsParams.set('offset', newOffset);
      }
      return pbAgentFetcher(
        `v1/dashboard-alerts?${alertsParams.toString()}`,
        {},
        true,
      );
    },
    {
      notifyOnChangeProps: ['data'],
      getNextPageParam: (lastPage, allPages) => {
        const nextPageParam = allPages.flatMap(
          (page) => page.collection,
        ).length;

        if (nextPageParam >= lastPage.total) {
          return false;
        }

        return nextPageParam;
      },
      staleTime: 5 * 60 * 1000, // 5 minutes,
      refetchOnWindowFocus: false,
    },
  );
};

interface PostParams {
  alert_id: string;
}

export const useMarkNotificationAsRead = (): UseMutationResult<
  Response,
  unknown,
  PostParams,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ alert_id }: PostParams) =>
      pbAgentFetcher(`v1/dashboard-alerts-read/${alert_id}`, {
        method: 'POST',
      }),
    {
      onSuccess: (data, variables) => {
        const queryData = queryClient.getQueryData<{
          pages: NotificationResponse[];
          pageParams: PageParams;
        }>(['dashboard-notifications']);

        if (!queryData) {
          return;
        }

        const notifications = {
          ...queryData,
          pages: queryData.pages.map((page) => ({
            ...page,
            collection: page.collection.map((notification) => {
              if (notification.alert_id === variables.alert_id) {
                notification.read = 1;
              }
              return notification;
            }),
            unreadCount: (parseInt(page.unreadCount) - 1).toString(),
          })),
        };

        queryClient.setQueryData(['dashboard-notifications'], notifications);
      },
    },
  );
};

export const useMarkAllNotificationsAsRead = (): UseMutationResult<
  Response,
  unknown,
  void,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    () => pbAgentFetcher('v1/dashboard-alerts-all-read', { method: 'POST' }),
    {
      onSuccess: () => {
        const queryData = queryClient.getQueryData<{
          pages: NotificationResponse[];
          pageParams: PageParams;
        }>(['dashboard-notifications']);

        if (!queryData) {
          return;
        }

        const notifications = {
          ...queryData,
          pages: queryData.pages.map((page) => ({
            ...page,
            collection: page.collection.map((notification) => ({
              ...notification,
              read: 1,
            })),
            unreadCount: 0,
          })),
        };

        queryClient.setQueryData(['dashboard-notifications'], notifications);
      },
    },
  );
};
