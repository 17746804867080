import React, { ChangeEventHandler, ReactElement } from 'react';
import { Button, Flex, FormLabel, Input, VStack } from '@chakra-ui/react';
import { AttachIcon } from 'icons/icons';
import { GmailMessagesFile } from 'services/apiTypes';
import { AttachmentTile } from 'components/Mailbox/Message/AttachmentTile';

interface NewMessageAttachmentsProps {
  attachments: GmailMessagesFile[] | [] | undefined;
  onAttachmentRemove: (filename: string) => void;
  onFileInputChange: ChangeEventHandler<HTMLInputElement>;
}

export const NewMessageAttachments = ({
  attachments,
  onAttachmentRemove,
  onFileInputChange,
}: NewMessageAttachmentsProps): ReactElement => {
  return (
    <>
      <Flex mt={8} alignItems={'flex-start'}>
        <FormLabel minW={140}>Additional files</FormLabel>

        {attachments && attachments.length > 0 && (
          <VStack
            alignItems={'flex-start'}
            spacing={3}
            flexGrow={1}
            mr={3}
            overflow={'hidden'}
          >
            {attachments.map(({ name }) => {
              const fileExtension = name.split('.').pop() || '';
              return (
                <AttachmentTile
                  key={name}
                  extension={fileExtension}
                  filename={name}
                  tileText={name}
                  fileUrl={''}
                  onAttachmentRemove={() => onAttachmentRemove(name)}
                />
              );
            })}
          </VStack>
        )}

        <FormLabel htmlFor={'attachments-upload'} ml={'auto'}>
          <Button
            as={'span'}
            _hover={{ cursor: 'pointer' }}
            leftIcon={<AttachIcon boxSize={'20px'} />}
            variant={'blueLight'}
            aria-label={'Attach Files'}
          >
            Attach Files
          </Button>
        </FormLabel>
      </Flex>

      <Input
        type={'file'}
        id={'attachments-upload'}
        onChange={onFileInputChange}
        multiple
        display={'none'}
      />
    </>
  );
};
