import { Button, Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface QuickResponsesProps extends Omit<FlexProps, 'onClick'> {
  options: string[];
  onClick: (option: string) => void;
}

export const QuickResponses = ({
  options,
  onClick,
  ...containerProps
}: QuickResponsesProps): ReactElement => {
  return (
    <Flex overflowX={'auto'} {...containerProps}>
      {options.map((option) => (
        <Button
          key={option}
          variant={'blueLight'}
          size={'sm'}
          onClick={() => onClick(option)}
          flex={'1 0 auto'}
          mx={0.5}
          mb={2}
        >
          {option}
        </Button>
      ))}
    </Flex>
  );
};
