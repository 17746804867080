import { SuggestBuildingsPostBody, SuggestBuildingsResponse } from './apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';

export const defaultValues: SuggestBuildingsPostBody = {
  limit: 10,
  offset: 0,
  search: '',
};

export const useSuggestBuildings = (
  searchParams?: SuggestBuildingsPostBody,
): UseQueryResult<SuggestBuildingsResponse[]> => {
  const queryParams = new URLSearchParams(
    objectToUrlParams({ ...defaultValues, ...searchParams }),
  );

  return useQuery<SuggestBuildingsResponse[]>(
    ['suggest-buildings', queryParams.toString()],
    () => pbAgentFetcher(`v1/suggest-buildings?${queryParams.toString()}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};
