import React, { ReactElement, useCallback } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Text,
  VStack,
  useBoolean,
} from '@chakra-ui/react';
import { MergedListingInfo } from 'services/apiTypes';
import { mediaServer } from 'services/mediaServer';
import { ImageWithPlaceholder } from '../../Global/ImageWithPlaceholder';
import { currencyFormat } from 'services/currencyFormat';
import { SalePriceText } from './SalePriceText';
import { InfoRow } from 'components/Global/InfoRow';
import { Link } from 'react-router-dom';
import {
  useClientSavedListingPost,
  useClientsSavedListingsDelete,
} from 'services/hooks/api/clients/useClientsSavedListings';
import { D360Icon, FloorPlanIcon } from '../../../icons/icons';
import { useModal } from 'contexts/ModalContext';
import { PhotoSliderPopup } from 'components/Global/PhotoSliderPopup';
import { pluralize } from 'services/pluralize';
import { ListingTypeBadge } from 'components/Global/ListingTypeBadge';
import { ClientListingActions } from 'components/Global/ClientListingActions';
import { useSendListing } from 'services/hooks/useSendListing';
import { useResults } from 'contexts/ResultsContext';
import TileBadgeContainer from 'components/Global/TileBadgeContainer';

interface SearchListingTileProps {
  element: MergedListingInfo;
}

export const SearchListingTile = ({
  element,
}: SearchListingTileProps): ReactElement => {
  const {
    data: { selectedListingIds, clientId },
    actions: { handleSelectListing },
  } = useResults();
  const {
    bedrooms,
    bathrooms,
    ad_type,
    size,
    dom,
    advertisement_id,
    address,
    unit,
    ownership,
    ownership_info,
    price,
    price_details_formatted,
    commission_structure,
    has_floorplan,
    has_360,
    featured_photo,
    amenities_info,
    photo_floorplan,
    ribbon,
    saved: hasBeenSaved,
    sent: hasBeenSent,
  } = element;

  const isRental = ad_type === 'rental';
  const parsedBedrooms = parseFloat(bedrooms);
  const parsedBathrooms = parseFloat(bathrooms);
  const [checkmarkVisible, setCheckmarkVisible] = useBoolean(false);

  const { mutate: saveForClient, isLoading: isSavingForClient } =
    useClientSavedListingPost();
  const { mutate: removeFromSaved, isLoading: isRemovingFromSaved } =
    useClientsSavedListingsDelete();

  const { send, isSendingListing } = useSendListing();

  const showCheckmark = useCallback((): void => {
    setCheckmarkVisible.on();
    setTimeout(() => setCheckmarkVisible.off(), 3000);
  }, [setCheckmarkVisible]);

  const { openModal, defaultModalProps } = useModal();
  const firstInfoRowElements = [
    {
      number: bedrooms,
      label: !isNaN(parsedBedrooms)
        ? pluralize(parseFloat(bedrooms), 'bed')
        : undefined,
    },
    {
      number: bathrooms,
      label: !isNaN(parsedBathrooms)
        ? pluralize(parseFloat(bathrooms), 'bath')
        : undefined,
    },
    {
      number: size?.toString(),
      label: 'sqft',
    },
    {
      number: dom?.toString(),
      label: 'DOM',
    },
  ];

  const handleOpenFloorplanModal = () =>
    openModal(
      <PhotoSliderPopup photos={[photo_floorplan]} {...defaultModalProps} />,
    );

  const toggleSavedForClient = useCallback(() => {
    if (hasBeenSaved) {
      removeFromSaved({ clientId, listingId: advertisement_id });
    } else {
      saveForClient({ clientId, listingId: advertisement_id });
    }
  }, [
    advertisement_id,
    clientId,
    hasBeenSaved,
    removeFromSaved,
    saveForClient,
  ]);

  const handleSend = async () => {
    if (isSendingListing) {
      return;
    }

    await send({
      listingIds: [advertisement_id],
      clientIds: [clientId],
    });
    showCheckmark();
  };

  const isListingSelected = selectedListingIds.includes(advertisement_id);

  return (
    <Flex
      as={'article'}
      flexDirection={'column'}
      border={'1px'}
      borderColor={'pbGray.100'}
      borderRadius={9}
    >
      <Box p={4} mb={1}>
        <Link to={`/listing/${advertisement_id}/details`}>
          <Heading as={'h3'} fontSize={'18px'} fontWeight={'bold'} mb={1}>
            {`${address}, ${unit}, ${ownership}`}
          </Heading>
        </Link>

        <Flex justifyContent={'space-between'} alignItems={'baseline'}>
          <Text
            fontSize={14}
            color={'pbBlue.100'}
            fontWeight={'bold'}
            _after={{
              content: isRental ? '" /month"' : undefined,
              fontWeight: 'normal',
            }}
          >
            {isRental ? (
              currencyFormat(price)
            ) : (
              <SalePriceText priceDetailsFormatted={price_details_formatted} />
            )}
          </Text>
          <Text
            fontSize={10}
            color={'pbRed.300'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
          >
            {commission_structure}
          </Text>
        </Flex>
      </Box>

      <Box h={220} pos={'relative'}>
        <TileBadgeContainer>
          <ListingTypeBadge
            type={ad_type}
            text={ribbon?.text}
            color={ribbon?.color}
          />
        </TileBadgeContainer>
        <VStack position={'absolute'} right={3} top={3}>
          {Boolean(has_floorplan) && !!photo_floorplan && (
            <FloorPlanIcon
              onClick={handleOpenFloorplanModal}
              cursor={'pointer'}
              boxSize={10}
            />
          )}
          {Boolean(has_360) && (
            <Box
              width={10}
              height={10}
              bgColor={'white'}
              borderRadius={'50%'}
              p={1.5}
              opacity={0.8}
            >
              <D360Icon boxSize={10} />
            </Box>
          )}
        </VStack>

        <ImageWithPlaceholder
          src={
            featured_photo
              ? mediaServer(featured_photo, 'wcontact-l')
              : undefined
          }
          objectFit={'cover'}
          fit={'cover'}
          boxSize={'100%'}
        />
      </Box>

      <Box px={4} py={3}>
        <InfoRow elements={firstInfoRowElements} />

        <InfoRow
          elements={amenities_info
            .map((amenity) => ({
              label: amenity,
            }))
            .slice(0, 4)}
        />

        <InfoRow
          elements={ownership_info.map((ownership) => ({
            label: ownership,
          }))}
        />
      </Box>

      {clientId && (
        <Flex
          px={4}
          py={3}
          mt={'auto'}
          justifyContent={'space-between'}
          borderTop={'1px'}
          borderColor={'pbGray.100'}
        >
          <Checkbox
            label={'Check'}
            isChecked={isListingSelected}
            onChange={() => handleSelectListing(advertisement_id)}
            mr={4}
          />

          <ClientListingActions
            hasBeenSaved={!!+hasBeenSaved}
            hasBeenSent={!!+hasBeenSent}
            isSaving={isSavingForClient || isRemovingFromSaved}
            isSending={isSendingListing}
            onSave={toggleSavedForClient}
            onSend={handleSend}
            checkmarkVisible={checkmarkVisible}
          />
        </Flex>
      )}
    </Flex>
  );
};
