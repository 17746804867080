import { Button, ButtonGroup } from '@chakra-ui/react';
import { useMailbox } from 'contexts/MailboxContext';
import { ForwardIcon, MarkAsUnreadIcon, TrashBlueIcon } from 'icons/icons';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGmailThreadsReadState } from 'services/hooks/api/gmail/useGmailMessagesThread';
import { useGmailThreadsDelete } from 'services/hooks/api/gmail/useGmailThreads';
import { MailboxRouteParams } from 'types/MailboxRouteParams';

export const ThreadsActions = (): ReactElement => {
  const history = useHistory();
  const { threadId } = useParams<MailboxRouteParams>();
  const {
    data: {
      selectedThreads,
      isTogglingReadState,
      areSomeThreadsUnread,
      currentFolder,
      threads,
      isDeletingMultipleThreads,
      isDeletingSelectedThreadsPermanently,
    },
    actions: {
      refetchThreads,
      markAsReadSelectedThreads,
      markAsUnreadSelectedThreads,
      forwardThread,
      deleteSelectedThreads,
      deleteSelectedThreadsPermanently,
    },
  } = useMailbox();

  const { mutate: deleteThreads, isLoading: isDeletingThread } =
    useGmailThreadsDelete();

  const onSuccessfulDelete = useCallback(() => {
    refetchThreads();
    history.push(`/mailbox/${currentFolder}`);
  }, [currentFolder, history, refetchThreads]);

  const deleteSingleThread = useCallback(() => {
    deleteThreads(
      { threadsIds: [threadId] },
      { onSuccess: onSuccessfulDelete },
    );
  }, [deleteThreads, onSuccessfulDelete, threadId]);

  const {
    markAsRead: { mutate: markAsRead, isLoading: isMarkingAsRead },
    markAsUnread: { mutate: markAsUnread, isLoading: isMarkingAsUnread },
  } = useGmailThreadsReadState({ currentFolder });

  const toggleReadState = useCallback(
    (isUnread?: boolean) => {
      const threadMessagesIds =
        threads
          .find(({ id }) => id === threadId)
          ?.messages.map(({ id }) => id)
          .join(',') || '';
      if (isUnread) {
        markAsRead({ message_ids: threadMessagesIds });
      } else {
        markAsUnread({ message_ids: threadMessagesIds });
      }
    },
    [markAsRead, markAsUnread, threadId, threads],
  );

  const setThreadsReadState = useCallback(() => {
    if (threadId) {
      toggleReadState(areSomeThreadsUnread);
      return history.goBack();
    }

    if (areSomeThreadsUnread) {
      markAsReadSelectedThreads();
    } else {
      markAsUnreadSelectedThreads();
    }
  }, [
    areSomeThreadsUnread,
    history,
    markAsReadSelectedThreads,
    markAsUnreadSelectedThreads,
    threadId,
    toggleReadState,
  ]);

  const handleDeleteThreads = useCallback(() => {
    if (currentFolder === 'trash') {
      return deleteSelectedThreadsPermanently();
    }
    return threadId ? deleteSingleThread() : deleteSelectedThreads();
  }, [
    currentFolder,
    deleteSelectedThreadsPermanently,
    deleteSelectedThreads,
    deleteSingleThread,
    threadId,
  ]);

  const displayActions = useMemo(() => {
    if (threadId) {
      return 'flex';
    }
    return selectedThreads.length ? 'flex' : 'none';
  }, [selectedThreads.length, threadId]);

  const isActionDisabled = useMemo(() => {
    if (threadId) {
      return false;
    }
    return !selectedThreads.length;
  }, [selectedThreads.length, threadId]);

  return (
    <ButtonGroup spacing={2} display={displayActions}>
      {threadId && currentFolder !== 'trash' && (
        <Button
          leftIcon={<ForwardIcon fontSize={16} />}
          fontSize={{ base: 12, lg: 14 }}
          variant={'blueLight'}
          size={'sm'}
          onClick={(e) => forwardThread(e, threadId)}
        >
          Forward thread
        </Button>
      )}
      <Button
        display={currentFolder === 'sent' ? 'none' : 'flex'}
        fontSize={{ base: 12, lg: 14 }}
        variant={'blueLight'}
        leftIcon={<MarkAsUnreadIcon fontSize={16} />}
        onClick={setThreadsReadState}
        isLoading={isTogglingReadState || isMarkingAsUnread || isMarkingAsRead}
        loadingText={
          areSomeThreadsUnread ? 'Marking as read...' : 'Marking as unread...'
        }
        isDisabled={isActionDisabled}
        minW={85}
        size={'sm'}
      >
        {areSomeThreadsUnread ? 'Mark as read' : 'Mark as unread'}
      </Button>

      {currentFolder !== 'draft' && (
        <Button
          fontSize={{ base: 12, lg: 14 }}
          variant={'blueLight'}
          leftIcon={<TrashBlueIcon fontSize={16} />}
          onClick={handleDeleteThreads}
          isLoading={
            isDeletingThread ||
            isDeletingMultipleThreads ||
            isDeletingSelectedThreadsPermanently
          }
          isDisabled={isActionDisabled}
          minW={85}
          size={'sm'}
        >
          {currentFolder === 'trash' ? 'Delete permanently' : 'Delete'}
        </Button>
      )}
    </ButtonGroup>
  );
};
