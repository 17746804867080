/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Flex } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useDealsContext } from 'contexts/DealsContext';
import { currencyFormat } from 'services/currencyFormat';
import { useDealSheets } from 'services/hooks/api/deals/useDealSheets';
import TableHeading from '../../Global/Table/TableHead';
import TableRow, { Header } from '../../Global/Table/TableRow';

export type TableColumn = {
  width: string;
  headerLabel: string;
};

export type LowerSectionTableProps = {
  columns: TableColumn[];
};

const LowerSectionTable: FC<LowerSectionTableProps> = ({
  columns,
}: LowerSectionTableProps) => {
  const mappedHeaders = useMemo(
    () =>
      columns.map((el) => (
        <TableHeading
          key={`${el.headerLabel}_${el.width}`}
          flexWidth={el.width}
          label={el.headerLabel}
        />
      )),
    [columns],
  );

  const { dealsState } = useDealsContext();
  const { data } = useDealSheets({
    agent_id: dealsState.agentId,
    status: dealsState.dealStatus?.toLowerCase().replaceAll(' ', '_') || '',
    search: dealsState.search,
  });

  const mappedDataRows = useMemo(() => {
    return data?.map((el) => {
      const clientStatusColumn = [el.client_name, el.status];
      const agentColumn = el.agent_name;

      //#region feeAmountColumn
      const fee =
        el.fee_amount && el.fee_amount > 0
          ? `${currencyFormat(el.fee_amount)} Fee`
          : '';

      const op =
        el.op_amount && el.op_amount > 0
          ? `${currencyFormat(el.op_amount)} OP`
          : '';

      let feeAmountColumn;
      if (fee && op) feeAmountColumn = [fee, op];
      else if (fee) feeAmountColumn = fee;
      else if (op) feeAmountColumn = op;
      else feeAmountColumn = '';
      //#endregion

      const addressManagementColumn = `${el.building_address} ${el.unit} ${el.management}`;
      const leaseStartColumn = el.lease_start;
      const leaseEndColumn = '-';
      const moneyReceivedColumn = '-';
      const paidToAgentColumn = '-';
      const daysOutstandingColumn = '-';

      const content: Header[] = [
        //@ts-ignore It's array with 2 elements
        clientStatusColumn,
        agentColumn,
        //@ts-ignore If it's array - it always has 2 elements
        feeAmountColumn,
        addressManagementColumn,
        leaseStartColumn,
        leaseEndColumn,
        moneyReceivedColumn,
        paidToAgentColumn,
        daysOutstandingColumn,
      ];
      return (
        <TableRow
          content={content}
          columnsWidth={columns.map((el) => el.width)}
          key={el.deal_sheet_id}
        />
      );
    });
  }, [data, columns]);

  return (
    <Box h={'100%'} overflowX={'auto'}>
      <Box minW={'fit-content'}>
        <Flex>{mappedHeaders}</Flex>
        {mappedDataRows}
      </Box>
    </Box>
  );
};

export default LowerSectionTable;
