import React, { ReactElement } from 'react';
import {
  Divider,
  Flex,
  Link,
  Skeleton,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';
import { useCompsOverview } from '../../services/hooks/api/comps/useCompsOverwiev';
import { ListingDetailsTile } from '../Global/ListingDetailsTile';
import { Link as RouterLink } from 'react-router-dom';
import { objectToURLSearchParams } from '../../services/objectToURLSearchParams';
import { SearchInput } from 'services/apiTypes';
import { responsiveImageHeight, responsiveImageWidth } from '../../constants';

export const DashboardComps = (): ReactElement => {
  const { data, isLoading } = useCompsOverview();

  return (
    <VStack spacing={5}>
      {isLoading && (
        <Flex
          w={'100%'}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Skeleton
            h={responsiveImageHeight}
            w={responsiveImageWidth}
            maxH={180}
            borderRadius={'md'}
            mb={{
              base: 5,
              md: 0,
            }}
            mr={{
              base: 0,
              md: 5,
            }}
          />
          <VStack flex={1} align={'stretch'}>
            <Skeleton h={8} />
            <SkeletonText noOfLines={5} />
          </VStack>
        </Flex>
      )}
      {data?.map(({ listing, search }, i) => (
        <>
          <Link
            as={RouterLink}
            to={{
              pathname: `/results/${search.ad_type}`,
              search: objectToURLSearchParams<SearchInput>(search).toString(),
            }}
            w={'100%'}
          >
            <ListingDetailsTile
              listing={listing}
              key={listing.advertisement_id}
              gridColumns={{ base: 3, '2xl': 4 }}
            />
          </Link>
          {i < (data?.length || 0) - 1 && <Divider mx={-5} />}
        </>
      ))}
    </VStack>
  );
};
