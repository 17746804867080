import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { DotsIcon } from 'icons/icons';
import React, { ReactElement, useMemo } from 'react';
import { OpInvoice } from '../../services/hooks/api/op-invoices/apiTypes';
import { capitalizeFirstLetter } from '../../services/capitalizeFirstLetter';
import TableHeading, { TableHeadingProps } from '../Global/Table/TableHead';
import TableRow from '../Global/Table/TableRow';

const InvoiceActions = ({
  invoiceId,
  onView,
}: {
  invoiceId: string;
  onView: () => void;
}) => {
  return (
    <Menu placement={'bottom-end'}>
      <MenuButton
        as={IconButton}
        maxW={'30px'}
        w={'100%'}
        px={0}
        minW={'0'}
        icon={<DotsIcon />}
        aria-label={'invoice-actions'}
      />
      <MenuList>
        <MenuItem onClick={onView}>View</MenuItem>
        <MenuItem
          as={'a'}
          href={`${process.env.REACT_APP_PB_AGENT_API}/v1/op-invoices/${invoiceId}/download`}
          download
        >
          Download
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
const columns: TableHeadingProps[] = [
  {
    flexWidth: '164px',
    label: 'Client (Status)',
  },
  {
    flexWidth: '126px',
    label: 'Agent',
  },
  {
    flexWidth: '171px',
    label: 'Commission Amount',
  },
  {
    flexWidth: '276px',
    label: 'Address Management',
  },
  {
    flexWidth: '138px',
    label: 'Move In Date',
  },
  {
    flexWidth: '70px',
    label: 'Actions',
    isSortable: false,
  },
];

interface OpTableProps {
  invoices?: OpInvoice[];
  onView: (invoice_id: string) => void;
}

const OpTable = ({ invoices, onView }: OpTableProps): ReactElement => {
  const mappedHeaders = useMemo(
    () =>
      columns.map((el) => {
        return (
          <TableHeading
            flexWidth={el.flexWidth}
            label={el.label}
            key={`${el.label}_${el.flexWidth}`}
          />
        );
      }),
    [],
  );

  return (
    <Box h={'100%'} overflowX={'auto'} pt={10}>
      <Box minW={'fit-content'}>
        <Flex>{mappedHeaders}</Flex>
        {invoices?.map(
          ({
            invoice_id,
            agent_name,
            status,
            contact_name,
            lease_start_date,
            property_address,
            property_unit,
            commission_amount,
          }) => (
            <TableRow
              key={invoice_id}
              content={[
                [
                  contact_name,
                  capitalizeFirstLetter(status.replaceAll('_', ' ')),
                ],
                agent_name,
                commission_amount,
                property_address &&
                  property_unit &&
                  `${property_address}, ${property_unit}`,
                lease_start_date,
                <InvoiceActions
                  key={'invoice-actions'}
                  invoiceId={invoice_id}
                  onView={() => onView(invoice_id)}
                />,
              ]}
              columnsWidth={columns.map((el) => el.flexWidth)}
            />
          ),
        )}
      </Box>
    </Box>
  );
};

export default OpTable;
