import { Applications } from '../../../apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToURLSearchParams } from 'services/objectToURLSearchParams';

interface QueryParams {
  name?: string;
  address?: string;
  limit?: number;
  offset?: number;
}

export const useApplications = (
  queryParams?: QueryParams,
): UseQueryResult<Applications> => {
  const query = queryParams ? `?${objectToURLSearchParams(queryParams)}` : '';
  return useQuery<Applications>(['applications', queryParams], () =>
    pbAgentFetcher(`v1/aform${query}`, {}, true),
  );
};
