import { Box, Text } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
}: FallbackProps): ReactElement => {
  console.error(error.message);

  return (
    <Box p={4} role={'alert'}>
      <Text fontSize={'16px'} fontWeight={'bold'} color={'pbGray.500'}>
        Something went wrong, this feature is not available right now
      </Text>
    </Box>
  );
};

export default ErrorFallback;
