import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailThread } from './apiTypes';

export const useGmailThread = (id?: string): UseQueryResult<GmailThread> =>
  useQuery<GmailThread>(
    ['gmail-threads', id],
    () => pbAgentFetcher(`v1/gmail-threads/${id}`),
    {
      refetchInterval: 20 * 1000, // 20 seconds
      enabled: !!id,
    },
  );
