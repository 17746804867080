import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import dayjs from 'dayjs';
import { NavbarElementProps } from 'react-day-picker/types/Props';
import { ChevronIcon } from '../../icons/icons';
import { hexToRgb } from '../../services/hexToRgb';
import { colors } from '../../services/theme/colors';

interface EventsCalendarProps {
  selectedDays: (Date | { before: Date; after: Date })[];
  onDayClick: (day: Date) => void;
}

const NavbarElement = ({
  month,
  onNextClick,
  onPreviousClick,
}: NavbarElementProps) => {
  return (
    <Flex justifyContent={'space-between'} align={'center'} p={'12px'}>
      <IconButton
        aria-label={'prev'}
        variant={'clear'}
        icon={<ChevronIcon />}
        onClick={onPreviousClick as () => void}
        transform={'rotate(90deg)'}
      />

      <Text display={'table-caption'} textAlign={'center'}>
        {dayjs(month).format('MMMM')}
      </Text>

      <IconButton
        aria-label={'next'}
        variant={'clear'}
        icon={<ChevronIcon />}
        onClick={onNextClick as () => void}
        transform={'rotate(-90deg)'}
      />
    </Flex>
  );
};

export const EventsCalendar = ({
  selectedDays,
  onDayClick,
}: EventsCalendarProps): ReactElement => {
  const modifiers = {
    selectedDays,
  };
  const modifiersStyles = {
    selectedDays: {
      backgroundColor: 'transparent',
      color: 'currentColor',
    },
  };

  const renderDay = (day: Date, modifier: DayModifiers) => (
    <Text
      fontSize={14}
      pos={'relative'}
      w={'40px'}
      h={'40px'}
      d={'flex'}
      alignItems={'center'}
      m={'0 auto'}
      justifyContent={'center'}
      bg={modifier?.today ? 'pbBlue.500' : 'transparent'}
      color={modifier?.today ? 'white' : 'currentColor'}
      borderRadius={'md'}
      fontWeight={'bold'}
      _before={{
        content: modifier?.selectedDays ? '""' : undefined,
        display: 'block',
        width: 1,
        height: 1,
        backgroundColor: modifier?.today ? 'white' : 'pbBlue.500',
        position: 'absolute',
        left: '50%',
        borderRadius: 'full',
        transform: 'translateX(-50%)',
        bottom: 1,
      }}
    >
      {day.getDate()}
    </Text>
  );

  return (
    <Box
      bg={`rgba(${hexToRgb(colors.pbGray['80'])}, .5)`}
      mx={-5}
      my={5}
      sx={{
        '.DayPicker, .DayPicker-Month': {
          w: '100%',
          m: 0,
        },
        '.DayPicker-Caption > div': {
          fontSize: '16px',
          fontWeight: 'bold !important',
          textAlign: 'center',
        },
        '.DayPicker-NavButton': {
          top: 0,
        },
        '.DayPicker-NavButton--prev': {
          left: 0,
          right: 'auto',
          margin: 0,
        },
        '.DayPicker-Day': {
          p: '3px 0 2px',
        },
        '.DayPicker-Body': {
          borderTop: '10px solid transparent',
        },
        '.DayPicker-Day:hover': {
          bg: 'inherit',
          bgColor: 'inherit !important',
        },
      }}
    >
      <DayPicker
        selectedDays={selectedDays}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        weekdaysShort={['M', 'T', 'W', 'T', 'F', 'S', 'S']}
        renderDay={renderDay}
        onDayClick={onDayClick}
        captionElement={() => {
          //only way i found to remove caption rather than disabling by CSS
          return null;
        }}
        navbarElement={NavbarElement}
      />
    </Box>
  );
};
