import { Client, ListingClientInfo, MergedClientInfo } from './apiTypes';

export const mergeClientsData = (
  matchingClientsInfo?: ListingClientInfo[] | undefined,
  matchingClients?: Client[],
): MergedClientInfo[] =>
  matchingClientsInfo?.map((clientData: ListingClientInfo) => {
    const foundClient = matchingClients?.find(
      (client: Client) => client.user_id === clientData.client_id,
    );

    return { ...foundClient, ...clientData };
  }) as MergedClientInfo[];
