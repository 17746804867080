import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import {
  ClientsSavedSearchesSuspendProps,
  useClientsSavedSearchesSuspend,
} from '../../../services/hooks/api/clients/useClientsSavedSearchesSuspend';
import { StringOrNumber } from '../../../services/apiTypes';

interface SavedSearchNotificationTogglerProps {
  isSuspended?: number;
  isDisabled?: boolean;
  searchId?: StringOrNumber;
  clientId?: StringOrNumber;
}

export const SavedSearchNotificationToggler = ({
  isSuspended,
  isDisabled,
  searchId,
  clientId,
}: SavedSearchNotificationTogglerProps): ReactElement => {
  const { isLoading, mutate } = useClientsSavedSearchesSuspend();
  const isSuspendedBoolean =
    typeof isSuspended !== 'undefined' ? isSuspended === 1 : true;

  const onSearchSuspendChange = () => {
    if (!searchId) return;

    const mutationObject: ClientsSavedSearchesSuspendProps = {
      clientId,
      searchId,
      suspend: !isSuspendedBoolean,
    };

    if (!isSuspendedBoolean) {
      mutationObject.body = {
        days: 30,
      };
    }

    mutate(mutationObject);
  };

  return (
    <FormControl display={'flex'} w={'auto'} alignItems={'center'}>
      <FormLabel
        htmlFor={'notifications'}
        mb={'0'}
        fontSize={13}
        fontWeight={'bold'}
        color={'pbBlue.100'}
        minW={0}
        letterSpacing={-1}
        mr={2}
      >
        Notifications
      </FormLabel>
      <Switch
        id={'notifications'}
        size={'md'}
        isChecked={!isSuspendedBoolean}
        onChange={onSearchSuspendChange}
        isDisabled={isLoading || isDisabled}
      />
    </FormControl>
  );
};
