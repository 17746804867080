import {
  SearchBuildingParams,
  SearchBuildingResponse,
  StringOrNumber,
} from '../../../apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';

export const defaultValues: SearchBuildingParams = {
  inactive_too: 0,
};

export const useBuilding = (
  id: StringOrNumber,
  params?: SearchBuildingParams,
): UseQueryResult<SearchBuildingResponse> => {
  const searchParams = new URLSearchParams(
    objectToUrlParams({ ...defaultValues, ...params }),
  );

  return useQuery<SearchBuildingResponse>(
    ['search-buildings', id, searchParams.toString()],
    () =>
      pbAgentFetcher(`v1/search-buildings/${id}?${searchParams.toString()}`),
  );
};
