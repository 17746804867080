import React, {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { GmailThread } from 'services/apiTypes';
import { deleteDraftById, findDraftByDraftMessageId } from 'services/draftsDb';
import { useDraftsDelete } from 'services/hooks/api/gmail/useDraftsDelete';
import { useGmailFlags } from 'services/hooks/api/gmail/useGmailFlags';
import { useGmailThreadsReadState } from 'services/hooks/api/gmail/useGmailMessagesThread';
import { useGmailThreadsDelete } from 'services/hooks/api/gmail/useGmailThreads';
import { useGmailTrash } from 'services/hooks/api/gmail/useGmailTrash';
import { useMailbox } from './MailboxContext';

type ThreadContextType = {
  data: {
    thread: GmailThread;
    messageIds: string[];
    isTogglingFlag: boolean;
    isTogglingReadState: boolean;
    isDeletingThread: boolean;
  };
  actions: {
    toggleStarred: (
      event: SyntheticEvent,
      threadId: string,
      isStarred: boolean,
    ) => void;
    toggleReadState: (event: SyntheticEvent, isUnread?: boolean) => void;
    handleDeleteThread: (e: SyntheticEvent, threadId: string) => void;
    onThreadCheck: (e: SyntheticEvent, threadId: string) => void;
    handleRestoreThread: (e: SyntheticEvent, threadId: string) => void;
  };
};
type Props = {
  children: ReactNode;
  thread: GmailThread;
};

const threadContextDefaultValues: ThreadContextType = {
  data: {
    thread: {} as GmailThread,
    messageIds: [],
    isTogglingReadState: false,
    isTogglingFlag: false,
    isDeletingThread: false,
  },
  actions: {
    toggleStarred: (event, threadId, isStarred) => ({
      event,
      threadId,
      isStarred,
    }),
    toggleReadState: (event, isUnread) => ({ event, isUnread }),
    handleDeleteThread: (event, threadId) => ({ event, threadId }),
    onThreadCheck: (event, threadId) => ({ event, threadId }),
    handleRestoreThread: (event, threadId) => ({ event, threadId }),
  },
};

const ThreadContext = createContext<ThreadContextType>(
  threadContextDefaultValues,
);

export function useThread(): ThreadContextType {
  return useContext(ThreadContext);
}

export function ThreadContextProvider({
  thread,
  children,
}: Props): ReactElement {
  const {
    data: { currentFolder },
    actions: { refetchThreads, toggleSelectThread },
  } = useMailbox();

  const messageIds = thread.messages.map((message) => message.id);

  const { mutate: deleteThreads, isLoading: isDeletingThread } =
    useGmailThreadsDelete();

  const deleteThread = useCallback(
    (e: SyntheticEvent, threadId: string) => {
      e?.stopPropagation();
      deleteThreads({ threadsIds: [threadId] }, { onSuccess: refetchThreads });
    },
    [deleteThreads, refetchThreads],
  );

  const { mutateAsync: deleteDraft, isLoading: isDeletingDraft } =
    useDraftsDelete();

  const {
    markAsRead: { mutate: markAsRead, isLoading: isMarkingAsRead },
    markAsUnread: { mutate: markAsUnread, isLoading: isMarkingAsUnread },
  } = useGmailThreadsReadState({ currentFolder });

  const {
    addFlag: {
      mutateAsync: addFlagAsync,
      mutate: addFlag,
      isLoading: isAddingFlag,
    },
    removeFlag: {
      mutateAsync: removeFlagAsync,
      mutate: removeFlag,
      isLoading: isRemovingFlag,
    },
  } = useGmailFlags(currentFolder);

  const {
    deleteThreadPermanently: {
      mutate: deleteThreadPermanently,
      isLoading: isDeletingThreadPermanently,
    },
  } = useGmailTrash();

  const toggleStarThread = (
    e: SyntheticEvent,
    threadId: string,
    isStarred?: boolean,
  ) => {
    e.stopPropagation();
    const variables = { id: threadId, flag: 'starred' };
    if (isStarred) {
      removeFlag(variables);
    } else {
      addFlag(variables);
    }
  };

  const toggleReadState = (e: SyntheticEvent, isUnread?: boolean) => {
    e.stopPropagation();
    const threadMessagesIds = messageIds.join(',');
    if (isUnread) {
      markAsRead({ message_ids: threadMessagesIds });
    } else {
      markAsUnread({ message_ids: threadMessagesIds });
    }
  };

  const onCheck = (e: SyntheticEvent, threadId: string) => {
    e.stopPropagation();
    toggleSelectThread(threadId);
  };

  const handleDeleteThread = async (e: SyntheticEvent, threadId: string) => {
    e?.stopPropagation();

    if (currentFolder === 'trash') {
      return deleteThreadPermanently(
        { threadId },
        { onSuccess: refetchThreads },
      );
    }

    if (currentFolder === 'draft') {
      const draft = await findDraftByDraftMessageId(threadId);

      if (draft) {
        deleteDraft({ draftId: draft.draftId }, { onSuccess: refetchThreads });
        deleteDraftById(draft.draftId);
        return;
      }
    }

    deleteThread(e, threadId);
  };

  const handleRestoreThread = async (e: SyntheticEvent, threadId: string) => {
    e.stopPropagation();
    const shouldMoveToInbox = !thread.messages.some(
      (message) =>
        message.label_ids.includes('SENT') ||
        message.label_ids.includes('DRAFT'),
    );
    const variables = { id: threadId, flag: 'deleted' };

    if (shouldMoveToInbox) {
      await addFlagAsync({ id: threadId, flag: 'inbox' });
    }
    await removeFlagAsync(variables);
  };

  const value = {
    data: {
      thread,
      messageIds,
      isTogglingFlag: isAddingFlag || isRemovingFlag,
      isTogglingReadState: isMarkingAsRead || isMarkingAsUnread,
      isDeletingThread:
        isDeletingThread || isDeletingThreadPermanently || isDeletingDraft,
    },
    actions: {
      toggleStarred: toggleStarThread,
      toggleReadState,
      handleDeleteThread,
      onThreadCheck: onCheck,
      handleRestoreThread,
    },
  };
  return (
    <ThreadContext.Provider value={value}>{children}</ThreadContext.Provider>
  );
}
