import React, { ReactElement, useRef } from 'react';
import {
  Avatar,
  Box,
  Flex,
  SimpleGrid,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ToFieldWithCCField } from 'components/Form/ToFieldWithCCField';
import { useMessage } from 'contexts/MessageContext';
import { AttachmentTile } from './AttachmentTile';
import { MessageFormType } from 'types/MessageFormType';
import { MessageFormActions } from './Actions/FormActions';
import { PreviousMessages } from './PreviousMessages';
import { useIdleTimer } from 'react-idle-timer';
import useDraftUpsert from 'services/hooks/useDraftUpsert';

export const MessageForm = (): ReactElement => {
  const messageBodyRef = useRef<HTMLTextAreaElement>(null);

  const {
    data: {
      message: {
        email_html,
        email_chat,
        email_plain,
        subject,
        thread_id,
        message_id,
      },
      formState: { recipients, cc, attachments, body, type, isOpen },
    },
    actions: {
      onRecipientChange,
      onCcChange,
      onAttachmentRemove,
      setMessageBody,
      openMessageForm,
    },
  } = useMessage();

  const { upsertDraft, isSaving } = useDraftUpsert(isOpen);

  const handleFormTypeChange = (formType: MessageFormType) => {
    openMessageForm(formType);
    messageBodyRef.current?.focus();
  };

  useIdleTimer({
    timeout: 2 * 1000,
    onIdle: () =>
      !isSaving
        ? upsertDraft(
            {
              to: recipients || [],
              cc,
              body,
              subject,
            },
            thread_id,
            message_id,
          )
        : null,
    debounce: 500,
  });

  return (
    <Flex alignItems={'flex-start'} mt={6} flexDir={'column'}>
      <Flex width={'100%'}>
        <Avatar />
        <Flex
          flexGrow={1}
          p={3}
          pt={1}
          ml={5}
          border={'1px solid lightgray'}
          borderRadius={'lg'}
        >
          <VStack spacing={2} alignItems={'stretch'} w={'100%'}>
            <ToFieldWithCCField
              onFormTypeChange={handleFormTypeChange}
              formType={type}
              withLabels={{ to: false, cc: true }}
              inputProps={{ withBorders: false, withPlaceholders: true }}
              labelWidth={'70px'}
              toValue={recipients}
              ccValue={cc}
              onToChange={onRecipientChange}
              onCcChange={onCcChange}
            />

            <Textarea
              variant={'unstyled'}
              border={0}
              minH={'50px'}
              value={body}
              ref={messageBodyRef}
              onChange={(e) => setMessageBody(e.target.value)}
            />

            <PreviousMessages
              previousMessages={email_html || email_chat || email_plain}
            />

            {attachments?.length && (
              <SimpleGrid
                _last={{ mt: 10 }}
                gap={4}
                columns={3}
                gridTemplateColumns={'repeat(3, minmax(auto, 33%))'}
                flexGrow={1}
                flexWrap={'wrap'}
              >
                {attachments.map(({ name }, index) => {
                  const extension = name.split('.').pop() || '';
                  return (
                    <Box key={index}>
                      <AttachmentTile
                        extension={extension}
                        filename={name}
                        tileText={name}
                        fileUrl={''}
                        onAttachmentRemove={() => onAttachmentRemove(name)}
                      />
                    </Box>
                  );
                })}
              </SimpleGrid>
            )}
          </VStack>
        </Flex>
      </Flex>
      <Box ml={10} mt={5}>
        <MessageFormActions />
      </Box>
    </Flex>
  );
};
