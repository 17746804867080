import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

export type UpdatedFields = Array<{
  field: string;
  value: null | string | number | File;
}>;
interface PostParams {
  id: string;
  data: UpdatedFields;
}

export const useAFormPost = (): UseMutationResult<
  Response,
  unknown,
  PostParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['aform/post'],
    ({ id, data }: PostParams) => {
      const formData = new FormData();

      const fields = data.map(({ field }) => field).join(',');

      data.forEach(({ field, value }) => {
        formData.append(field, value as string);
      });

      return pbAgentFetcher(
        `v1/aform-field/${id}?field_name=${fields}`,
        {
          method: 'post',
          body: formData,
        },
        false,
        true,
      );
    },
    {
      onSuccess: (response, { id }) =>
        queryClient.invalidateQueries(['aform', id]),
    },
  );
};
interface DeleteParams {
  id: string;
}

export const useAFormDelete = (): UseMutationResult<
  Response,
  unknown,
  DeleteParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['aform/delete'],
    ({ id }: DeleteParams) =>
      pbAgentFetcher(`v1/aform/${id}`, {
        method: 'delete',
      }),
    {
      onSuccess: (response, { id }) => {
        queryClient.invalidateQueries(['aform', id]);
        queryClient.invalidateQueries(['applications']);
        queryClient.invalidateQueries(['clients', response.client.user_id]);
      },
    },
  );
};

interface DeleteFieldParams {
  application_form_id: string;
  fieldName: string;
}

export const useAFormDeleteFile = (): UseMutationResult<
  Response,
  unknown,
  DeleteFieldParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['aform/delete-file'],
    ({ application_form_id, fieldName }: DeleteFieldParams) => {
      return pbAgentFetcher(
        `v1/aform-field/${application_form_id}?field_name=${fieldName}`,
        {
          method: 'delete',
        },
      );
    },
    {
      onSuccess: (response, { application_form_id }) => {
        queryClient.invalidateQueries(['aform', application_form_id]);
        queryClient.invalidateQueries(['clients', response.client.user_id]);
      },
    },
  );
};
