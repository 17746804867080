import { hexToRgb } from '../hexToRgb';
import { colors } from './colors';

const tabSizes = {
  height: { base: 8, xl: 10 },
  fontSize: 14,
  px: { base: 2, xl: 4 },
  py: 1,
};

const tabStyles = {
  borderRadius: 'lg',
  borderWidth: 1,
  color: 'pbBlue.100',
  fontWeight: 'bold',
  borderColor: 'transparent',
  letterSpacing: 'tight',
};

const tabActiveStyles = {
  color: 'black',
  borderColor: 'pbGray.400',
};

export const components = {
  Popover: {
    variants: {
      'fit-to-content': {
        popper: {
          width: 'auto',
          maxWidth: 'unset',
        },
      },
    },
  },
  Textarea: {
    baseStyle: {
      borderWidth: 1,
      borderRadius: 'md',
      _placeholder: {
        color: 'pbBlue.205',
      },
    },
    sizes: {
      md: {
        h: '38px',
        fontSize: 14,
      },
    },
  },
  Input: {
    baseStyle: {
      field: {
        borderWidth: 1,
        borderRadius: 'md',
        _placeholder: {
          color: 'pbBlue.205',
        },
      },
    },
    sizes: {
      md: {
        field: {
          h: '38px',
          fontSize: 14,
        },
      },
    },
    variants: {
      outline: {
        field: { borderColor: 'pbGray.90' },
      },
      grayed: {
        field: {
          borderRadius: 'lg',
          bg: 'pbGray.60',
          borderWidth: '0',
          _placeholder: {
            color: 'pbBlue.200',
          },
          fontSize: 'sm',
          color: 'pbBlue.200',
        },
      },
      reset: {
        field: {
          p: 0,
          border: 0,
          m: 0,
          h: 'auto',
          w: '100%',
        },
      },
    },
  },
  Link: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  Button: {
    baseStyle: {
      fontWeight: 'bold',
      _focus: {
        boxShadow: 'none',
      },
    },
    sizes: {
      sm: {
        fontSize: '12px',
        h: 38,
        minW: 38,
      },
      md: {
        fontSize: { base: 12, xl: 14 },
        borderRadius: 'lg',
        lineHeight: 1,
        h: { base: 9, xl: 12 },
        minW: 38,
        px: { base: 4, xl: 6 },
      },
      tab: tabSizes,
    },
    variants: {
      blue: {
        bg: 'pbBlue.500',
        color: 'white',
        _hover: {
          bg: `rgba(${hexToRgb(colors.pbBlue['500'])}, 0.1)`,
          color: 'pbBlue.500',
        },
      },
      blueLight: {
        bg: `rgba(${hexToRgb(colors.pbBlue['500'])}, 0.1)`,
        color: 'pbBlue.500',
        _hover: {
          color: 'pbGray.150',
        },
      },
      blueLightTextGray: {
        bg: `rgba(${hexToRgb(colors.pbBlue['500'])}, 0.1)`,
        color: 'pbGray.150',
        _hover: {
          color: 'pbBlue.500',
        },
      },
      gray: {
        bg: 'pbGray.80',
        color: 'pbGray.150',
        _hover: {
          bg: 'pbBlue.500',
          color: 'white',
        },
      },
      tab: {
        ...tabStyles,
        _active: tabActiveStyles,
      },
      link: {
        color: 'pbBlue.500',
      },
      reset: {
        h: 'auto',
        px: 0,
        py: 0,
        m: 0,
        color: 'currentColor',
        minW: 0,
      },
      grayDropdown: {
        bgColor: 'pbGray.80',
        py: '10px',
        px: '16px !important',
        minW: { base: '0', lg: 170 },
        h: '38px',
        textAlign: 'left',
      },
      ghost: {
        px: 0,
        py: 0,
      },
    },
  },
  Tabs: {
    parts: ['tablist', 'tab'],
    baseStyle: {
      tab: {
        ...tabStyles,
        mr: { base: 1, xl: 3 },
        my: 1,
        whiteSpace: 'nowrap',
        _selected: tabActiveStyles,
        _disabled: {
          opacity: 0.3,
        },
      },
      tablist: {
        maxW: '100%',
        overflowX: 'auto',
      },
    },
    sizes: { md: { tab: tabSizes } },
    defaultProps: {
      variant: 'unstyled',
    },
  },
  Badge: {
    variants: {
      red: {
        bg: 'pbRed.500',
        color: 'white',
      },
      green: {
        bg: 'pbGreen.600',
        color: 'white',
      },
    },
  },
  Avatar: {
    baseStyle: {
      container: {
        bgColor: 'pbBlue.510',
        color: 'pbBlue.500',
      },
    },
    sizes: {
      md: {
        container: {
          width: 38,
          height: 38,
        },
        label: {
          fontSize: '14px',
        },
      },
      lg: {
        container: {
          width: 58,
          height: 58,
        },
        label: {
          fontSize: '22px',
        },
      },
      xxl: {
        container: {
          width: 200,
          height: 200,
        },
        label: {
          fontSize: '22px',
        },
      },
    },
    variants: {
      rectangle: {
        container: {
          borderRadius: 'base',
        },
      },
      circle: {
        container: {
          borderRadius: 'full',
        },
      },
    },
    defaultProps: {
      variant: 'rectangle',
      size: 'md',
    },
  },
  FormLabel: {
    baseStyle: {
      fontWeight: 'bold',
      minW: 120,
      pr: 1,
      m: 0,
    },
  },
  Switch: {
    baseStyle: {
      track: {
        alignItems: 'center',
        bg: 'pbGray.70',
        _checked: {
          bg: 'pbBlue.500',
        },
      },
      thumb: {
        boxShadow: 'base',
      },
    },
    sizes: {
      md: {
        track: {
          w: 8,
          h: 5,
        },
        thumb: {
          // h: 20,
        },
      },
    },
    defaultProps: {
      size: 'md',
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: 1,
        borderRadius: 'base',
        borderColor: 'pbBlue.100',
        _checked: {
          bg: 'pbBlue.500',
          borderColor: 'pbBlue.500',
        },
      },
    },
    sizes: {
      md: {
        control: {
          w: '19px',
          h: '19px',
        },
      },
    },
  },
  Modal: {
    sizes: {
      '10xl': {
        dialog: {
          maxW: {
            base: '100%',
            md: 'calc(100% - 20px)',
            lg: 'calc(100% - 100px)',
            xl: 'calc(100% - 190px)',
          },
        },
      },
    },
  },
  Tooltip: {
    baseStyle: {
      fontSize: '11px',
      borderRadius: 'lg',
      backgroundColor: 'pbGray.151',
    },
  },
};
