import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';

const putValues = async (data?: Record<string, number>) => {
  return pbAgentFetcher('v1/agent-profile-preferences', {
    body: JSON.stringify(data),
    method: 'PUT',
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAgentProfilePreferences = (
  preferences?: Record<string, number>,
  options?: UseMutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => putValues(preferences), {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries('agent-profile');
      options?.onSuccess?.(...args);
    },
  });
};
