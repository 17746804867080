import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { ChildrenProps } from 'types/ChildrenProps';
import { theme } from '../services/theme';

export const OwnChakraProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => (
  <ChakraProvider theme={theme} resetCSS>
    {children}
  </ChakraProvider>
);
