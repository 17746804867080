import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';

const postPhoto = async (file: File) => {
  const formData = new FormData();
  formData.append('photo', file);
  return pbAgentFetcher(
    'v1/agent-profile-photo',
    {
      body: formData,
      method: 'POST',
    },
    undefined,
    true,
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAgentProfilePhoto = (
  file: File,
  options?: UseMutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => postPhoto(file), {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries('agent-profile');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
    },
  });
};
