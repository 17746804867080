import { SearchInput } from './apiTypes';

export const parseUrlParamsToObject = (
  params: URLSearchParams,
): SearchInput => {
  // Default value to be overriten anyway by below loop, but prevents TS issue ;)
  let obj = {
    ad_type: params.get('ad_type') || 'rental',
  };

  for (const key of params.keys()) {
    obj = {
      ...obj,
      [key]:
        params.getAll(key).length > 1 ? params.getAll(key) : params.get(key),
    };
  }

  return obj;
};
