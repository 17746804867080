import { Heading, HeadingProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const PageTitle = ({
  children,
  ...props
}: HeadingProps): ReactElement => (
  <Heading
    as={'h1'}
    fontWeight={'bold'}
    fontSize={{ base: 20, md: 22, xl: 24 }}
    {...props}
  >
    {children}
  </Heading>
);
