import React, { ReactElement, useRef } from 'react';
import { Button, ButtonGroup, Input, useToast } from '@chakra-ui/react';
import IconButton from 'components/Global/IconButton';
import { useMessage } from 'contexts/MessageContext';
import { AttachIcon, TrashBlueIcon } from 'icons/icons';
import generateSuccessToast from 'services/generateToast';
import { useGmailMessages } from 'services/hooks/api/gmail/useGmailMessages';
import { useGmailMessagesThread } from 'services/hooks/api/gmail/useGmailMessagesThread';
import { deleteDraftById, findDraftByMessageId } from 'services/draftsDb';
import { useDraftsDelete } from 'services/hooks/api/gmail/useDraftsDelete';
import { useHistory } from 'react-router-dom';
import { MailboxLocationState } from 'types/MailboxRouteParams';
import dayjs from 'dayjs';

export const MessageFormActions = (): ReactElement => {
  const history = useHistory<MailboxLocationState>();
  const {
    data: {
      message: {
        subject,
        thread_id,
        message_id,
        time_created,
        from_email,
        email_html,
        email_chat,
      },
      formState: { recipients, cc, attachments, body, type },
    },
    actions: { handleUploadAttachment, setMessageForm, setMessageBody },
  } = useMessage();

  const toast = useToast();

  const { mutate: forwardMessage, isLoading: isForwardingEmail } =
    useGmailMessages();
  const { mutate: replyInThread, isLoading: isReplyingInThread } =
    useGmailMessagesThread();

  const { mutateAsync: deleteDraft, isLoading: isDeletingDraft } =
    useDraftsDelete();

  const getSubjectWithPrefix = (): string => {
    if (type.includes('reply')) {
      return `Re: ${subject}`;
    }
    if (type === 'forward') {
      return `Fwd: ${subject}`;
    }
    return subject;
  };

  const bodyWithPreviousMessages = `<div>
    ${body}

    <div>
      <p>On: ${dayjs(time_created).format(
        'MMM DD YYYY h:mm a',
      )} ${from_email} wrote:</p>
      <blockquote style="border-left: 1px solid;border-left-color: pbBlue.100;">
        <div style="margin-left: 24px;">
          ${email_html || email_chat}
        </div>
      </blockquote>
    </div>
  </div>`;

  const onSubmit = () => {
    const variables = {
      body: {
        to: recipients as string[],
        cc,
        subject: getSubjectWithPrefix(),
        attachments,
        body: bodyWithPreviousMessages,
        in_reply_to: message_id,
      },
    };

    const onSuccess = async () => {
      toast(
        generateSuccessToast(
          `Your message to ${recipients?.join(', ')} has been sent`,
        ),
      );

      const draft = await findDraftByMessageId(message_id);

      setMessageForm({
        isOpen: false,
        type,
      });

      history.replace({
        ...history.location,
        state: { ...history.location.state, messageToOpen: '' },
      });

      setMessageBody('');

      if (draft) {
        await deleteDraft({ draftId: draft.draftId });
        await deleteDraftById(draft.draftId);
      }
    };

    if (type === 'forward') {
      forwardMessage(variables, { onSuccess });
    } else {
      replyInThread(
        { threadId: thread_id, data: variables.body },
        { onSuccess },
      );
    }
  };

  const handleRemoveDraft = async () => {
    const draft = await findDraftByMessageId(message_id);

    if (draft) {
      await deleteDraft({ draftId: draft.draftId });
      await deleteDraftById(draft.draftId);
    }

    history.replace({
      pathname: history.location.pathname,
      state: {
        ...history.location.state,
        messageToOpen: '',
        shouldOpenForm: false,
      },
    });
    setMessageForm({ type, isOpen: false });
  };

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <ButtonGroup ml={4} spacing={2}>
      <Button
        variant={'blue'}
        size={'sm'}
        onClick={onSubmit}
        isDisabled={!recipients?.length}
        isLoading={isForwardingEmail || isReplyingInThread}
        loadingText={'Sending...'}
        lineHeight={'16px'}
      >
        Send
      </Button>
      <IconButton
        icon={<AttachIcon fontSize={'16px'} />}
        aria-label={'Attach file'}
        h={'38px'}
        w={'38px'}
        tooltipMessage={'Attach file'}
        onClick={() => inputRef.current?.click()}
      />

      <IconButton
        icon={<TrashBlueIcon fontSize={'16px'} />}
        aria-label={'Remove draft'}
        tooltipMessage={'Remove draft'}
        h={'38px'}
        w={'38px'}
        isLoading={isDeletingDraft}
        onClick={handleRemoveDraft}
      />

      <Input
        type={'file'}
        ref={inputRef}
        onChange={handleUploadAttachment}
        multiple
        display={'none'}
      />
    </ButtonGroup>
  );
};
