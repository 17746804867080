import { Badge } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { BadgeProps } from '@chakra-ui/layout/dist/types/badge';

interface DotBadgeType extends BadgeProps {
  color?: string;
}
export const DotBadge = ({
  color = 'red',
  ...props
}: DotBadgeType): ReactElement => (
  <Badge
    variant={color}
    p={0}
    w={'6px'}
    h={'6px'}
    borderRadius={'base'}
    {...props}
  />
);
