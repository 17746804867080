import { UseQueryResult, useQuery } from 'react-query';
import { OpInvoiceSingle } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

export const useOpInvoice = (
  invoiceId?: string,
): UseQueryResult<OpInvoiceSingle> => {
  return useQuery<OpInvoiceSingle>(
    ['op-invoices', invoiceId],
    () => pbAgentFetcher(`v1/op-invoices/${invoiceId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!invoiceId,
    },
  );
};
