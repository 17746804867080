import { CombinedContextProvider } from 'contexts/CombinedContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

import { BrowserRouter as Router } from 'react-router-dom';

const appStructure = (
  <React.StrictMode>
    <Router>
      <CombinedContextProvider>
        <App />
      </CombinedContextProvider>
    </Router>
  </React.StrictMode>
);

async function prepare() {
  if (process.env.REACT_APP_STAGE === 'test') {
    console.log('test environment');
    const { worker } = await import('./mocks/browser');
    return worker.start();
  }
  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(appStructure, document.getElementById('root'));
});
