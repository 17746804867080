/* eslint-disable @typescript-eslint/no-explicit-any */
// Generated with https://transform.tools/json-to-typescript

export enum DealStatus {
  All = 'All',
  Draft = 'Draft',
  Pending_Review = 'Pending_Review',
  Deal_Submitted = 'Deal_Submitted',
  Pending_Op = 'Pending_Op',
  Pending_Comission_Check = 'Pending_Comission_Check',
  Payment_Sent = 'Payment_Sent',
}

export interface DealSheetsOverview {
  avg_deal_size: number;
  avg_commission: number;
  count: number;
  ytd_commission: number;
}

export interface DealSheet {
  deal_sheet_id: string;
  deal_no: number;
  client_name: string;
  client_email: string;
  building_address?: string;
  unit?: string;
  agent_name: string;
  management?: string;
  lease_start?: string;
  lease_end?: number;
  draft?: number;
  status: string;
  agent_id: string;
  date_submitted?: number;
  fee_amount?: number;
  op_amount?: number;
}

export interface DealSheetResponse {
  deal_sheet_id: string;
  deal_sheet: DealSheetSingleQuery;
  draft: number;
  status: string;
}

export interface DealSheetSingleQuery {
  basic_info?: BasicInfo;
  paperwork?: Paperwork;
  commissions?: Commission[];
  funds?: Funds;
}

export interface BasicInfo {
  agent_name?: string;
  date?: string;
  deal_no?: number;
  apt_address?: string;
  apt_unit?: string;
  apt_price?: number;
  management?: string;
  lease_start?: string;
  lease_end?: string;
  client_name?: string;
  client_phone?: string;
  client_email?: string;
  roommates?: string;
}

export interface Paperwork {
  lease_pages: File;
  lease_pages_2: File;
  dos: File;
  commission_check: File;
}

export interface File {
  value: string;
  url: string;
  filename: string;
}

export interface Funds {
  paid: boolean;
  methods: Method[];
  funds_previously_deposited: boolean;
}

export interface Method {
  label: string;
  checked: boolean;
}

export interface DealSheetPostParams {
  deal_sheet: DealSheetSingleQuery;
}

export interface DealSheetsPostParams {
  client_id: string;
}

export type DealSheetsTypeheadResponse = string[];

export interface DealSheetsAdvice {
  basic_info: BasicInfo;
  paperwork: Paperwork;
  commissions: Commission[];
  funds: Funds;
}

export interface Commission {
  description: string;
  fee?: any;
  op?: any;
  deducts?: boolean;
  agent_id?: string;
  percentage?: number;
}
