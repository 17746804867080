import Dexie, { IndexableType } from 'dexie';

export interface IndexedDraft {
  id: string;
  draftId: string;
  draftMessageId: string;
  threadId: string;
  messageId: string;
  body?: string;
  to_email?: string;
  to_name?: string;
  subject?: string;
}

const db = new Dexie('gmail-drafts');
db.version(1).stores({
  drafts:
    '++id, draftId, draftMessageId, threadId, messageId, body, to_email, to_name, subject',
});

export const saveDraftIntoIndexedDB = async ({
  draftId,
  draftMessageId,
  threadId,
  messageId,
  body,
  to_email,
  to_name,
  subject,
}: Omit<IndexedDraft, 'id'>): Promise<IndexableType | undefined> => {
  try {
    return db.table('drafts').add({
      draftId,
      draftMessageId,
      threadId,
      messageId,
      body,
      to_email,
      to_name,
      subject,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteDraftById = async (id: string): Promise<number> =>
  db.table('drafts').where('draftId').equals(id).delete();

export const updateIndexedDraft = async (
  oldId: string,
  {
    draftId,
    draftMessageId,
    threadId,
    messageId,
    body,
    to_email,
    to_name,
    subject,
  }: Omit<IndexedDraft, 'id'>,
): Promise<IndexableType | undefined> => {
  try {
    await deleteDraftById(oldId);
    return db.table('drafts').put({
      draftId,
      draftMessageId,
      threadId,
      messageId,
      body,
      to_email,
      to_name,
      subject,
    });
  } catch (error) {
    console.log(error);
  }
};

export const findDraftByDraftMessageId = async (
  draftMessageId: string,
): Promise<IndexedDraft | undefined> =>
  db.table('drafts').where('draftMessageId').equals(draftMessageId).first();

export const findDraftByMessageId = async (
  messageId: string,
): Promise<IndexedDraft | undefined> =>
  db.table('drafts').where('messageId').equals(messageId).first();

export const findDraftByThreadId = async (
  threadId: string,
): Promise<IndexedDraft | undefined> =>
  db.table('drafts').where('threadId').equals(threadId).first();
