import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Heading,
  SimpleGrid,
  SimpleGridProps,
  Skeleton,
  SkeletonText,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useAForm } from '../../../services/hooks/api/aform/useAForm';
import { LabeledValue } from 'components/Global/LabeledValue';
import { useAFormDelete } from '../../../services/hooks/api/aform/useAFormMutation';
import {
  DownloadIcon,
  PaperPlaneIcon,
  TrashBlueIcon,
} from '../../../icons/icons';

import { ImageWithPlaceholder } from '../../Global/ImageWithPlaceholder';
import { ReportValue } from '../../../services/apiTypes';
import { SendApplicationModal } from './SendApplicationModal';
import { useModal } from 'contexts/ModalContext';
import { applicationDownloadUrl } from '../../../constants';
import { useHistory, useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';
import { useClient } from 'services/hooks/api/clients/useClient';
import EditRentalInfoModal from './EditRentalInfoModal';

import { isString } from 'lodash';

import UploadedFileTile from 'components/Global/UploadedFileTile';
import { useApplicationContext } from 'contexts/ApplicationContext';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import generateSuccessToast from 'services/generateToast';
import { ConfirmPopover } from 'components/Global/ConfirmPopover';

dayjs.extend(advancedFormat);

const ApplicationGrid = ({ children, ...rest }: SimpleGridProps) => (
  <SimpleGrid
    columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
    gap={{ base: 3, md: 6 }}
    gridTemplateColumns={{
      base: '1fr',
      sm: 'repeat(2, minmax(100px, 1fr))',
      md: 'repeat(3, minmax(100px, 1fr))',
      xl: 'repeat(3, 170px) minmax(200px, 1fr)',
    }}
    {...rest}
  >
    {children}
  </SimpleGrid>
);

export const Application = ({
  application_form_id,
}: {
  application_form_id: string;
}): ReactElement => {
  const toast = useToast();
  const history = useHistory();
  const {
    onOpen: onApplicationDeleteClick,
    onClose: onApplicationDeleteClose,
    isOpen: isApplicationDeleteConfirmVisible,
  } = useDisclosure({ id: 'application-delete-confirm' });
  const {
    onOpen: onMultipleFilesDeleteClick,
    onClose: onMultipleFilesDeleteClose,
    isOpen: isMultipleFilesDeleteConfirmVisible,
  } = useDisclosure({ id: 'multiple-files-delete-confirm' });

  const { clientId, clientType } = useParams<ClientRouteParams>();
  const { openModal, defaultModalProps } = useModal();
  const { mutate: deleteApplication, isLoading: isDeletingApplications } =
    useAFormDelete();

  const { data: client } = useClient(clientId);
  const {
    data: { allFilesAreSelected, isDeleting, selectedFiles },
    actions: {
      setFieldsWithFiles,
      toggleAllFilesSelected,
      setApplication_form_id,
      deleteMultipleFiles,
    },
  } = useApplicationContext();
  const { data, isLoading: isFormLoading } = useAForm(application_form_id);

  const sections = [
    ...new Set(data?.report.map((r) => r.field.section)),
  ].filter(
    (section) =>
      section !== 'Files' && section !== 'Signature' && Boolean(section),
  );

  const files = useMemo(
    () => data?.report.filter((s) => s.field.section === 'Files'),
    [data],
  );
  const signatures = useMemo(
    () => data?.report.filter((s) => s.field.section === 'Signature'),
    [data],
  );

  const fieldsWithFiles = useMemo(() => {
    if (!files) return [];
    return files.reduce<string[]>((acc, curr) => {
      if (curr.value && !isString(curr.value)) {
        acc.push(curr.field.field);
      }
      return acc;
    }, []);
  }, [files]);

  useEffect(() => {
    setFieldsWithFiles(fieldsWithFiles);
    setApplication_form_id(application_form_id);
  }, [
    application_form_id,
    fieldsWithFiles,
    files,
    setApplication_form_id,
    setFieldsWithFiles,
  ]);

  const deleteSingleApplication = useCallback(
    (id: string) => {
      deleteApplication(
        { id },
        {
          onSuccess: () => {
            toast(generateSuccessToast('Application deleted successfully'));
            history.push(`/clients/${clientType}/${clientId}/details`);
          },
        },
      );
    },
    [clientId, clientType, deleteApplication, history, toast],
  );

  if (!application_form_id || !client) {
    return <Box p={5}>No Application form for current client</Box>;
  }

  if (!!data && data.status !== 'NEW') {
    return (
      <Box p={5}>{`Application has unachievable status : ${data.status}`}</Box>
    );
  }

  return (
    <Box px={5}>
      <Flex justifyContent={'flex-end'}>
        <HStack
          alignItems={'center'}
          justifySelf={'flex-end'}
          mt={{ base: 1, md: 0 }}
        >
          <Button
            leftIcon={<DownloadIcon fontSize={16} />}
            variant={'blueLight'}
            size={'sm'}
            loadingText={'Generating...'}
            onClick={() =>
              window.open(applicationDownloadUrl(application_form_id), '_self')
            }
          >
            Download PDF
          </Button>

          <Button
            leftIcon={<PaperPlaneIcon fontSize={16} />}
            variant={'blueLight'}
            size={'sm'}
            loadingText={'Sending...'}
            onClick={() =>
              openModal(
                <SendApplicationModal
                  application_form_id={application_form_id}
                  files={files}
                  client={client}
                  {...defaultModalProps}
                />,
              )
            }
          >
            Send
          </Button>
          <ConfirmPopover
            isOpen={isApplicationDeleteConfirmVisible}
            onConfirm={() => deleteSingleApplication(application_form_id)}
            onClose={onApplicationDeleteClose}
            confirmLabel={'Yes, delete application'}
            placement={'top'}
          >
            <Button
              leftIcon={<TrashBlueIcon fontSize={16} />}
              variant={'blueLight'}
              size={'sm'}
              isLoading={isDeletingApplications}
              loadingText={'Deleting...'}
              onClick={onApplicationDeleteClick}
            >
              Delete
            </Button>
          </ConfirmPopover>
        </HStack>
      </Flex>

      {isFormLoading && (
        <Box>
          <Box py={6} borderColor={'pbGray.100'} borderBottomWidth={'1px'}>
            <Skeleton h={2} mb={3} w={'200px'} />

            <ApplicationGrid>
              <SkeletonText noOfLines={2} />
              <SkeletonText noOfLines={2} />
              <SkeletonText noOfLines={2} />
              <SkeletonText noOfLines={2} />
            </ApplicationGrid>
          </Box>
          <Box py={6} borderColor={'pbGray.100'} borderBottomWidth={'1px'}>
            <Skeleton h={2} mb={3} w={'200px'} />

            <ApplicationGrid>
              <SkeletonText noOfLines={2} />
              <SkeletonText noOfLines={2} />
              <SkeletonText noOfLines={2} />
              <SkeletonText noOfLines={2} />
            </ApplicationGrid>
          </Box>
        </Box>
      )}
      {sections?.map((section) => (
        <Box
          key={section}
          borderColor={'pbGray.100'}
          borderBottomWidth={'1px'}
          py={6}
        >
          <Heading mb={3} fontSize={16} as={'h3'} color={'pbGray.500'}>
            <Flex justifyContent={'space-between'}>
              {section}
              {section === 'Rental Info' && (
                <Button
                  leftIcon={<PaperPlaneIcon fontSize={16} />}
                  variant={'blueLight'}
                  size={'sm'}
                  loadingText={'Editing...'}
                  onClick={() =>
                    openModal(
                      <EditRentalInfoModal
                        {...defaultModalProps}
                        application_form_id={application_form_id}
                      />,
                    )
                  }
                >
                  Edit Rental Info
                </Button>
              )}
            </Flex>
          </Heading>

          <ApplicationGrid>
            {data?.report
              .filter((r) => r.field.section === section)
              .map((r) => {
                if (
                  r.field.field === 'lease_start_date' ||
                  r.field.field === 'lease_end_date'
                ) {
                  r.value = dayjs(new Date(r.value as string)).format(
                    'MMMM D, YYYY',
                  );
                }
                return (
                  <LabeledValue
                    key={r.field.title}
                    label={r.field.title}
                    value={<Text color={'pbBlack.100'}>{r.value || '-'}</Text>}
                  />
                );
              })}
          </ApplicationGrid>
        </Box>
      ))}
      {files && (
        <Box py={6} borderColor={'pbGray.100'} borderBottomWidth={'1px'}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            mb={4}
          >
            <Heading mb={3} fontSize={16} as={'h3'} color={'pbGray.500'}>
              Supporting documents
            </Heading>
            <HStack alignItems={'center'} ml={5} mt={{ base: 1, md: 0 }}>
              <ConfirmPopover
                isOpen={isMultipleFilesDeleteConfirmVisible}
                onConfirm={deleteMultipleFiles}
                onClose={onMultipleFilesDeleteClose}
                confirmLabel={'Yes, delete files'}
                placement={'top'}
              >
                <Button
                  leftIcon={<TrashBlueIcon fontSize={16} />}
                  variant={'blueLight'}
                  size={'sm'}
                  loadingText={'Deleting...'}
                  isLoading={isDeleting}
                  isDisabled={selectedFiles.length === 0}
                  onClick={onMultipleFilesDeleteClick}
                >
                  Delete
                </Button>
              </ConfirmPopover>
              <Box display={'flex'} flexWrap={'nowrap'}>
                <FormLabel
                  htmlFor={'check-all-files'}
                  fontSize={{ base: 'xs', xl: 'sm' }}
                  display={{ base: 'block', md: 'none', xl: 'block' }}
                  whiteSpace={'nowrap'}
                  minW={30}
                  color={'pbBlue.100'}
                  cursor={'pointer'}
                  ml={5}
                >
                  Check all
                </FormLabel>
                <Tooltip label={'Check all'} placement={'top-end'}>
                  <Box>
                    <Checkbox
                      id={'check-all-files'}
                      ml={3}
                      onChange={toggleAllFilesSelected}
                      isChecked={allFilesAreSelected}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </HStack>
          </Flex>

          <ApplicationGrid
            maxW={'100%'}
            columns={{ base: 1, sm: 2 }}
            gridTemplateColumns={{
              base: 'minmax(0, 1fr)',
              sm: 'repeat(2, minmax(0, 450px))',
            }}
          >
            {files.map(({ field, value }) => {
              const fileExtension =
                value && !isString(value)
                  ? value.filename.split('.').pop() || ''
                  : 'no-file';
              const fileUrl = value && !isString(value) ? value.url : '';
              const filename = value && !isString(value) ? value.filename : '';
              return (
                <Flex key={field.field} direction={'column'}>
                  <Text color={'pbGray.800'} mb={3}>
                    {field.title}
                  </Text>
                  <UploadedFileTile
                    label={field.title}
                    extension={fileExtension}
                    fileUrl={fileUrl}
                    filename={filename}
                    fieldName={field.field}
                  />
                </Flex>
              );
            })}
          </ApplicationGrid>
        </Box>
      )}
      {signatures && (
        <Box py={6}>
          <Heading mb={3} fontSize={16} as={'h3'} color={'pbGray.500'}>
            Signature
          </Heading>

          {signatures.map((signature) => (
            <ImageWithPlaceholder
              key={(signature.value as ReportValue)?.media_item_id}
              src={(signature.value as ReportValue)?.url}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
