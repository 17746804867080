import {
  Client,
  ClientPutMutation,
  ClientsMutationInput,
  StringOrNumber,
} from '../../../apiTypes';
import {
  QueryObserverOptions,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

interface ClientsDeleteMutationParams {
  reason: string;
  type: string;
}

export const useClient = (
  id?: StringOrNumber,
  opts?: QueryObserverOptions<Client>,
): UseQueryResult<Client> =>
  useQuery<Client>(
    ['clients', id],
    () => pbAgentFetcher(`v1/clients/${id}?listing_format=search-listings`),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000, // 2 minutes
      ...opts,
    },
  );

const defaultDeleteParams: ClientsDeleteMutationParams = {
  reason: 'Some optional, probably predefined reason',
  type: 'REJECTED',
};

interface DeleteParams {
  clientId: StringOrNumber;
}

export const useClientsMutationDelete = (): UseMutationResult<
  Response,
  unknown,
  DeleteParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ clientId }: DeleteParams) =>
      pbAgentFetcher(`v1/clients/${clientId}`, {
        method: 'delete',
        body: JSON.stringify(defaultDeleteParams),
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['clients']),
    },
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useClientsMutationPost = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (userParams: ClientsMutationInput) =>
      pbAgentFetcher('v1/clients', {
        method: 'post',
        body: JSON.stringify(userParams),
      }),
    {
      onSuccess: (e) => {
        queryClient.invalidateQueries(['clients']);
        queryClient.invalidateQueries(['clients', e.user_id]);
      },
    },
  );
};

export const useClientsMutationPut = (
  clientId: StringOrNumber,
): UseMutationResult<Response, unknown, ClientPutMutation, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(
    (userParams: ClientPutMutation) =>
      pbAgentFetcher(`v1/clients/${clientId}`, {
        method: 'put',
        body: JSON.stringify(userParams),
      }),
    {
      onSuccess: (e) => {
        queryClient.invalidateQueries(['clients']);
        queryClient.invalidateQueries(['clients', e.user_id]);
      },
    },
  );
};
