export enum ClientsActionType {
  SET_CLIENT,
  SEARCH_ACTIVE,
}

export const ON_SET_CLIENT = 'set-client';
export const ON_SEARCH_ACTIVATE = 'set-search-active';

export type ClientsAction =
  | { type: ClientsActionType.SET_CLIENT; clientId: string }
  | { type: ClientsActionType.SEARCH_ACTIVE; flag: boolean };

export const onSetClient = (clientId = ''): ClientsAction => ({
  type: ClientsActionType.SET_CLIENT,
  clientId,
});

export const onSearchActivate = (flag: boolean): ClientsAction => ({
  type: ClientsActionType.SEARCH_ACTIVE,
  flag,
});
