import {
  Flex,
  FormControl,
  FormLabel,
  useCheckboxGroup,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { radioCheckboxRow } from './styles';
import { CheckboxCard } from './TriggerCard';
import { StringOrNumber } from '../../services/apiTypes';

type Props = {
  label?: string;
  onChange: (value: StringOrNumber[]) => void;
  value: StringOrNumber[];
  options?: StringOrNumber[] | null;
  isDisabled?: boolean;
};

export const CheckboxesRow = ({
  label,
  isDisabled,
  onChange,
  value,
  options,
}: Props): ReactElement => {
  const { getCheckboxProps } = useCheckboxGroup({
    onChange,
    value,
  });

  return (
    <FormControl {...radioCheckboxRow}>
      {label && <FormLabel>{label}</FormLabel>}

      {options && (
        <Flex alignItems={'baseline'} flexWrap={'wrap'} w={'100%'}>
          {options.map((v) => {
            const checkbox = getCheckboxProps({
              value: v.toString(),
              isDisabled,
            });

            return (
              <CheckboxCard key={v} {...checkbox}>
                {v}
              </CheckboxCard>
            );
          })}
        </Flex>
      )}
    </FormControl>
  );
};
