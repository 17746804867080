import { DealsAction, DealsActionType } from './actions';
import { DealsState } from '.';
import { exhaustive } from 'services/exhaustive';

export const dealsReducer = (
  state: DealsState,
  action: DealsAction,
): DealsState => {
  switch (action.type) {
    case DealsActionType.SET_YEAR:
      return {
        ...state,
        year: action.year,
      };

    case DealsActionType.SET_AGENT_ID:
      return {
        ...state,
        agentId: action.agentId,
      };

    case DealsActionType.SET_MONTH:
      return {
        ...state,
        month: action.month,
      };

    case DealsActionType.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case DealsActionType.SET_DEAL_STATUS:
      return {
        ...state,
        dealStatus: action.dealStatus,
      };

    case DealsActionType.SET_TABLE_SORT:
      return {
        ...state,
        tableSortSettings: action.tableSortSettings,
      };

    default:
      exhaustive(action);
  }
};
