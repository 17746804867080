import React, { ComponentPropsWithoutRef, ReactElement } from 'react';
import { SectionTitle } from './SectionTitle';
import { Text } from '@chakra-ui/react';

type Props = ComponentPropsWithoutRef<typeof SectionTitle> & {
  address: string;
  unit: string;
  city?: string;
  zip?: string;
  streets?: string;
  singleLine?: boolean;
};

export const ListingTitle = ({
  address,
  unit,
  city,
  zip,
  streets,
  singleLine = false,
  ...rest
}: Props): ReactElement => {
  return (
    <SectionTitle pl={'0'} {...rest}>
      <Text
        as={'span'}
        fontSize={{ base: '15px', sm: '16px', md: '17px', lg: '18px' }}
      >
        {address}, {unit}
        {city || zip ? (
          <>
            {singleLine ? ', ' : <br />}
            {city ? city : ''}
            {zip ? <>, {zip}</> : null}
          </>
        ) : null}
      </Text>
      <Text
        ml={{ base: 0, xl: 3 }}
        as={'span'}
        fontSize={'sm'}
        color={'pbGray.400'}
      >
        {streets}
      </Text>
    </SectionTitle>
  );
};
