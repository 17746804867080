import React, { ReactElement } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Box, Button, Tooltip } from '@chakra-ui/react';
import { capitalizeFirstLetter } from 'services/capitalizeFirstLetter';
import { SearchType } from 'services/apiTypes';

interface NewSearchActionsProps {
  searchTypes: SearchType[];
  existingSearchTypes: SearchType[];
}

export const NewSearchMenu: React.FC<NewSearchActionsProps> = ({
  searchTypes,
  existingSearchTypes,
}: NewSearchActionsProps): ReactElement | null => {
  const noOptions = searchTypes.length === existingSearchTypes.length;
  return (
    <Menu placement={'bottom-end'} matchWidth>
      <Tooltip
        label={'This client has both searches active'}
        backgroundColor={'pbGray.151'}
        p={1}
        px={2}
        placement={'top-end'}
        display={noOptions ? 'block' : 'none'}
      >
        <Box>
          <MenuButton
            as={Button}
            variant={'blueLight'}
            mt={{ base: 2, sm: 0 }}
            w={'auto'}
            h={'38px'}
            disabled={noOptions}
            pointerEvents={noOptions ? 'none' : 'auto'}
          >
            Add New Search
          </MenuButton>
        </Box>
      </Tooltip>
      <MenuList minW={'0'} w={'auto'} px={2}>
        {searchTypes.map((searchType) => {
          const isDisabled = existingSearchTypes.includes(searchType);
          return (
            <Tooltip
              key={searchType}
              label={`You already have active ${searchType} search`}
              display={isDisabled ? 'block' : 'none'}
            >
              <Box>
                <MenuItem
                  as={ReactRouterLink}
                  to={`/search/${searchType}`}
                  borderRadius={'lg'}
                  disabled={isDisabled}
                  pointerEvents={isDisabled ? 'none' : 'auto'}
                  cursor={isDisabled ? 'not-allowed' : 'pointer'}
                >
                  {capitalizeFirstLetter(searchType)}
                </MenuItem>
              </Box>
            </Tooltip>
          );
        })}
      </MenuList>
    </Menu>
  );
};
