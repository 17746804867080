import React, { ReactElement } from 'react';
import { Button, ButtonProps, HStack } from '@chakra-ui/react';
import { AddClientModal } from 'components/Clients/AddClientModal';
import { GmailMessageModal } from 'components/Global/GmailMessageModal';
import { AddListingModal } from 'components/MyListings/MyListingsNewModal';
import { useModal } from 'contexts/ModalContext';

const QuickActionButton = ({ onClick, children, ...rest }: ButtonProps) => {
  return (
    <Button onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

export const QuickActions: React.FC = (): ReactElement => {
  const { openModal, defaultModalProps } = useModal();

  return (
    <HStack ml={{ base: 'auto', md: 10 }} mr={'auto'} my={{ base: 2, md: 0 }}>
      <QuickActionButton
        onClick={() => openModal(<AddListingModal {...defaultModalProps} />)}
      >
        New Listing
      </QuickActionButton>
      <QuickActionButton
        onClick={() => openModal(<AddClientModal {...defaultModalProps} />)}
      >
        Add Client
      </QuickActionButton>
      <QuickActionButton
        onClick={() => openModal(<GmailMessageModal {...defaultModalProps} />)}
      >
        New Message
      </QuickActionButton>
    </HStack>
  );
};
