import { As, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';

export type DealsSummaryBoxProps = {
  headText: string;
  subTitleText: string;
  icon: As;
  subTitleColor?: string;
  bgColor?: string;
  headColor?: string;
};

const DealsSummaryBox = (props: DealsSummaryBoxProps) => {
  return (
    <Flex
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderRadius={'md'}
      bg={props.bgColor ? props.bgColor : 'pbGray.80'}
      minW={{ base: '0', '2xl': '355px' }}
      minH={{ base: 0, md: 35 }}
      px={10}
      py={{ base: 5, md: '30px' }}
    >
      <Icon mr={5} mt={'6px'} as={props.icon} boxSize={10} />

      <Flex direction={'column'}>
        <Text
          fontWeight={'extrabold'}
          fontSize={{ base: 25, md: '35px' }}
          color={props.headColor ? props.headColor : 'pbBlack.100'}
        >
          {props.headText}
        </Text>
        <Text
          fontWeight={'medium'}
          fontSize={{ base: 16, md: '18px' }}
          color={props.subTitleColor ? props.subTitleColor : 'pbGray.110'}
        >
          {props.subTitleText}
        </Text>
      </Flex>
    </Flex>
  );
};

export default React.memo(DealsSummaryBox);
