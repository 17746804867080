import React, { ReactElement, useRef } from 'react';
import {
  Button,
  Flex,
  FormLabel,
  VStack,
  useBoolean,
  useOutsideClick,
} from '@chakra-ui/react';
import { SuggestContactsInput } from './SuggestContactsInput';
import { Option } from 'services/apiTypes';
import { Dropdown } from 'components/Global/Dropdown';
import { MessageFormType } from 'types/MessageFormType';

export interface InputProps {
  withBorders?: boolean;
  withPlaceholders?: boolean;
}

interface WithLabels {
  to: boolean;
  cc: boolean;
}
interface ToFieldWithCCFieldProps {
  withLabels?: WithLabels;
  inputProps?: InputProps;
  labelWidth?: string;
  onFormTypeChange?: (formType: MessageFormType) => void;
  formType?: MessageFormType;
  toValue: string[] | undefined;
  ccValue: string[] | undefined;
  onToChange: (options: Option[]) => void;
  onCcChange: (options: Option[]) => void;
}

export const ToFieldWithCCField = ({
  withLabels = { to: true, cc: true },
  inputProps,
  labelWidth,
  formType,
  onFormTypeChange,
  toValue,
  ccValue,
  onToChange,
  onCcChange,
}: ToFieldWithCCFieldProps): ReactElement => {
  const [isCCFieldVisible, setIsCCFieldVisible] = useBoolean(false);
  const ccRef = useRef<HTMLDivElement>(null);

  const handleCcBlur = () => {
    if (!ccValue?.length) {
      return setIsCCFieldVisible.off();
    }
  };

  useOutsideClick({
    ref: ccRef,
    handler: handleCcBlur,
  });

  const options = [
    {
      label: 'Reply',
      value: 'reply',
    },
    {
      label: 'Reply to all',
      value: 'replyToAll',
    },
    {
      label: 'Forward',
      value: 'forward',
    },
  ];

  return (
    <VStack
      spacing={!inputProps?.withBorders ? 2 : 6}
      alignItems={'stretch'}
      w={'100%'}
    >
      <Flex alignItems={'center'} position={'relative'}>
        {formType && onFormTypeChange && (
          <Flex minW={100}>
            <Dropdown
              color={'pbGray.500'}
              p={0}
              fontSize={'md'}
              options={options.map(({ label }) => label)}
              onValueChange={(option) =>
                onFormTypeChange(
                  options.find((o) => o.label === option)
                    ?.value as MessageFormType,
                )
              }
              value={options.find((o) => o.value === formType)?.label}
            />
          </Flex>
        )}

        {withLabels.to && (
          <FormLabel minW={labelWidth ?? 140} alignSelf={'flex-start'}>
            To
          </FormLabel>
        )}
        <SuggestContactsInput
          onChange={onToChange}
          value={toValue}
          placeholder={'To'}
          inputProps={inputProps}
        />
        {!isCCFieldVisible && (
          <Button
            position={'absolute'}
            right={withLabels.to === false ? 0 : 2}
            variant={'blueLight'}
            size={'sm'}
            onClick={setIsCCFieldVisible.on}
            zIndex={3}
          >
            CC
          </Button>
        )}
      </Flex>
      {isCCFieldVisible && (
        <Flex mt={2} alignItems={'center'} ref={ccRef}>
          {withLabels.cc && (
            <FormLabel color={'pbGray.500'} minW={labelWidth ?? 140}>
              CC
            </FormLabel>
          )}
          <SuggestContactsInput
            onChange={onCcChange}
            value={ccValue}
            placeholder={'CC'}
            inputProps={inputProps}
          />
        </Flex>
      )}
    </VStack>
  );
};
