import React, { ReactElement } from 'react';
import { Box, Center, Flex, Link, Text } from '@chakra-ui/layout';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/button';

export const PageNotFound = (): ReactElement => (
  <Center minH={'300px'}>
    <Flex direction={'column'} justifyContent={'center'}>
      <Text align={'center'}>Page not found</Text>
      <Box mt={2}>
        <Link as={ReactRouterLink} to={'/'}>
          <Button>Go back to homepage</Button>
        </Link>
      </Box>
    </Flex>
  </Center>
);
