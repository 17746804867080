import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { Tags } from '../../../../components/Global/TagCloud';
import { stringifyParamsAccordingToAPI } from '../../../stringifyParamsAccordingToAPI';

export const useSearchListingsTagCloud = (
  searchParams: URLSearchParams,
  opts?: QueryObserverOptions<Tags>,
): UseQueryResult<Tags> => {
  const stringifiedParams = stringifyParamsAccordingToAPI(searchParams);

  return useQuery<Tags>(
    ['search-listings-tag-cloud', stringifiedParams],
    () => pbAgentFetcher(`v1/search-listings-tag-cloud?${stringifiedParams}`),
    opts,
  );
};
