import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailMessagesPostBody } from './apiTypes';

interface Params {
  body: GmailMessagesPostBody;
}

export const useGmailMessages = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['gmail-messages', 'post'],
    ({ body }: Params) =>
      pbAgentFetcher('v1/gmail-messages', {
        method: 'post',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: (r) => {
        queryClient.invalidateQueries(['gmail-threads', r.threadId]);
        queryClient.invalidateQueries(['gmail-threads']);
      },
    },
  );
};
