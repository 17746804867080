import {
  Flex,
  FormControl,
  FormLabel,
  FormLabelProps,
  Skeleton,
  useRadioGroup,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { radioCheckboxRow } from './styles';
import { RadioCard } from './TriggerCard';

type Props = {
  label?: string;
  labelStyles?: FormLabelProps['style'];
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange: (nextValue: string | number) => void;
  value?: string | number;
  options: (string | number)[] | null | undefined;
};

export const RadioRow = ({
  label,
  labelStyles,
  onChange,
  value,
  options,
  isLoading,
  isDisabled,
}: Props): ReactElement => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: label,
    onChange,
    value,
  });

  const group = getRootProps();

  return (
    <FormControl {...radioCheckboxRow}>
      {label && (
        <FormLabel pb={{ base: 2, md: 0 }} style={labelStyles}>
          {label}
        </FormLabel>
      )}

      {isLoading && (
        <Flex {...group} alignItems={'baseline'} flexWrap={'wrap'}>
          <Skeleton minW={70} h={38} borderRadius={'md'} mr={3} />
          <Skeleton minW={70} h={38} borderRadius={'md'} mr={3} />
        </Flex>
      )}

      {options && (
        <Flex {...group} alignItems={'baseline'} flexWrap={'wrap'} w={'100%'}>
          {options.map((v) => {
            // Has to ignore while getRadioProps() require 'enterKeyHint' from passed InputHTMLAttributes which is even not supported in some browsers.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const radio = getRadioProps({
              value: v.toString(),
              disabled: isDisabled,
              'aria-disabled': isDisabled,
            });
            return (
              <RadioCard key={v} {...radio}>
                {v}
              </RadioCard>
            );
          })}
        </Flex>
      )}
    </FormControl>
  );
};
