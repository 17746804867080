import React, { ReactElement } from 'react';
import { VStack } from '@chakra-ui/react';
import Setting from './Setting';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import SectionHeading from '../PersonalDetails/shared/SectionHeading';
import { Preferences } from 'services/hooks/api/agent-profile/apiTypes';
import { useCallback } from 'react';
import { TabWrapper } from '../TabWrapper';

type RenderItem = {
  apiProp: keyof Preferences;
  label: string;
  description?: string;
};

const listOfTogglableProperties: RenderItem[] = [
  {
    apiProp: 'agent_notification_email',
    label: 'Email Notifications',
    description: 'Receive email notifications.',
  },
  {
    apiProp: 'agent_notification_push',
    label: 'Push Notifications',
    description: 'Receive mobile notifications.',
  },
  {
    apiProp: 'agent_publish_ad_se',
    label: 'Publish in SE',
    description: 'Automatically publishes advert in SE.',
  },
  {
    apiProp: 'agent_publish_ad_na',
    label: 'Publish in NA',
    description: 'Automatically publishes advert in NA.',
  },
  {
    apiProp: 'agent_publish_ad_rh',
    label: 'Publish in RH',
    description: 'Automatically publishes advert in RH.',
  },
  {
    apiProp: 'agent_append_email_signature',
    label: 'Email Signature',
    description: 'Attach signature in emails.',
  },
  {
    apiProp: 'agent_rh_bot_enabled',
    label: 'RH Bot',
  },
  {
    apiProp: 'agent_signature_id',
    label: 'Signature ID',
  },
  {
    apiProp: 'agent_show_on_signin_form',
    label: 'Show on SignIn',
  },
];

const Settings = (): ReactElement => {
  const { data } = useAgentProfile();

  const mappedRenderItem = useCallback(
    (el: RenderItem) => {
      const foundSetting = data?.preferences[el.apiProp];

      // Just to make sure, because foundSetting can be 0 or 1 so it would be treated as boolean value
      // DEV COMMENT: Its better to filter array before map it rather than placing null in every place it's not available
      if (foundSetting === undefined || foundSetting === null) return null;

      return (
        <Setting
          apiProp={el.apiProp}
          label={el.label}
          description={el?.description}
          isActive={!!foundSetting}
          key={el.apiProp}
        />
      );
    },
    [data?.preferences],
  );

  return (
    <TabWrapper mb={5}>
      <SectionHeading>Push Notifications</SectionHeading>

      <VStack align={'flex'} spacing={{ base: 5, md: 7, lg: 12 }}>
        {listOfTogglableProperties.map(mappedRenderItem)}
      </VStack>
    </TabWrapper>
  );
};

export default Settings;
