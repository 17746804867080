import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const Dot = ({ mx = 1, ...props }: BoxProps): ReactElement => (
  <Box
    width={1}
    height={1}
    minW={1}
    minH={1}
    bg={'currentColor'}
    borderRadius={'full'}
    mx={mx}
    {...props}
  />
);
