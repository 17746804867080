import { Content } from 'services/apiTypes';

export enum AttachmentOriginType {
  ALL = 'all',
  RECEIVED = 'received',
  SENT = 'sent',
}

export enum AttachmentType {
  ALL = 'ALL',
  DOCUMENTS = 'DOCUMENTS',
  PICTURES = 'PICTURES',
}

export interface AttachmentInLibrary {
  id: string;
  threadId: string;
  filename: string;
  content: Content;
  extension: string;
  fileUrl: string;
  messageId: string;
  message_id: string;
  type: AttachmentOriginType;
  subject: string;
  receivedFrom?: string;
  sentTo?: string;
  receivedAt?: number;
  sendAt?: number;
}
