import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { CompsOverview } from './apiTypes';

export const useCompsOverview = (): UseQueryResult<CompsOverview[]> => {
  return useQuery<CompsOverview[]>(
    ['comps-overview'],
    () => pbAgentFetcher('v1/comps-overview'),
    {
      refetchOnWindowFocus: false,
    },
  );
};
