import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { PageParams } from 'types/ApiResponse';
import { pbAgentFetcher } from '../../../fetcher';
import {
  ListingInquiriesRespondInput,
  ListingInquiryResponse,
} from './apiTypes';

const defaultParams: ListingInquiriesRespondInput = {
  cc: [],
  body: '',
};

interface Params {
  inquiryId: string;
  params: ListingInquiriesRespondInput;
}

export const useListingInquiriesRespond = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['listing-inquiries-respond'],
    ({ inquiryId, params }: Params) =>
      pbAgentFetcher(`v1/listing-inquiries-respond/${inquiryId}`, {
        method: 'post',
        body: JSON.stringify({ ...defaultParams, ...params }),
      }),
    {
      onSuccess: ({ inquiry_id }) => {
        queryClient.invalidateQueries(['listing-inquiries-single', inquiry_id]);

        const queryData = queryClient.getQueryData<{
          pages: ListingInquiryResponse[];
          pageParams: PageParams;
        }>(['listing-inquiries']);

        if (!queryData) {
          return;
        }

        const inquiries = {
          pages: queryData.pages.map((page) => ({
            collection: page.collection.map((inquiry) => {
              if (inquiry.inquiry_id === inquiry_id) {
                inquiry.needs_response = false;
              }
              return inquiry;
            }),
            total: page.total,
          })),
          pageParams: queryData.pageParams,
        };

        queryClient.setQueryData(['listing-inquiries'], inquiries);
      },
    },
  );
};
