import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { SearchDetails } from './components/SearchDetails';
import { Apartments } from './components/Apartments';
import { ContactInformation } from './components/ContactInformation';

export const ClientDetails = (): ReactElement => (
  <Box pos={'relative'} flexGrow={1} display={'flex'} flexDir={'column'}>
    <ContactInformation />
    <SearchDetails />
    <Apartments />
  </Box>
);
