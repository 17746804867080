import { SimpleGrid } from '@chakra-ui/react';
import TabsWrapper from 'components/AgentProfile/AgentTabs/TabsWrapper';
import Sidebar from 'components/AgentProfile/Sidebar/Sidebar';
import { Main } from 'components/Global/Main';
import { PageHeading } from 'components/Global/PageHeading';
import { PageTitle } from 'components/Global/PageTitle';
import { AgentProfileContextProvider } from 'contexts/AgentProfileContext';
import React, { ReactElement } from 'react';

const AgentProfile = (): ReactElement => {
  return (
    <AgentProfileContextProvider>
      <Main>
        <PageHeading>
          <PageTitle>Agent Profile</PageTitle>
        </PageHeading>

        <SimpleGrid
          templateColumns={{
            base: '100%',
            lg: '380px minmax(0, 1fr)',
          }}
          gap={{ base: 3, xl: 5 }}
        >
          <Sidebar />
          <TabsWrapper />
        </SimpleGrid>
      </Main>
    </AgentProfileContextProvider>
  );
};

export default AgentProfile;
