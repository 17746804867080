export const getLeaseTermDuration = (leaseTerm: [number, number]): string => {
  const leaseTermDuration = leaseTerm?.[1] === 1 ? 'Month' : 'Months';

  return (!leaseTerm[0] || leaseTerm[0] === 0) &&
    (!leaseTerm[1] || leaseTerm[1] === 0)
    ? 'N/A'
    : leaseTerm[0] === leaseTerm[1]
    ? `${leaseTerm[0]} ${leaseTermDuration}`
    : `${leaseTerm[0]} - ${leaseTerm[1]} ${leaseTermDuration}`;
};
