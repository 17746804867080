import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { SuggestClientsResponse } from './apiTypes';

export const useSuggestClients = (
  inputValue = '',
): UseQueryResult<SuggestClientsResponse[]> => {
  return useQuery<SuggestClientsResponse[]>(
    ['suggest-clients', inputValue],
    () => pbAgentFetcher(`v1/suggest-clients?search=${inputValue}`),
    {
      refetchOnWindowFocus: false,
      enabled: inputValue !== '',
    },
  );
};
