import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import {
  BuildingsApartmentPostBody,
  BuildingsApartmentResponse,
} from './apiTypes';

export const useBuildingsApartment = (
  buildingId?: string,
  unit?: string,
  options?: UseQueryOptions<BuildingsApartmentResponse>,
): UseQueryResult<BuildingsApartmentResponse> => {
  return useQuery<BuildingsApartmentResponse>(
    ['buildings-apartment', buildingId, unit],
    () => pbAgentFetcher(`v1/buildings-apartment/${buildingId}/${unit}`),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!buildingId && !!unit,
      ...options,
    },
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBuildingsApartmentPost = () =>
  useMutation(
    ({
      buildingId,
      body,
    }: {
      buildingId?: string;
      body: BuildingsApartmentPostBody;
    }) =>
      pbAgentFetcher(`v1/buildings-apartment/${buildingId}`, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
  );
