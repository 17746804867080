import { Box, SimpleGrid } from '@chakra-ui/react';
import { attachmentDownloadUrl } from '../../../constants';
import { useMessage } from 'contexts/MessageContext';
import React, { ReactElement, useEffect } from 'react';
import { fetchFile } from 'services/fetchFile';
import { AttachmentTile } from './AttachmentTile';

interface FileUrl {
  filename: string;
  fileUrl: string;
}

export const MessageAttachments = (): ReactElement => {
  const [fileUrls, setFileUrls] = React.useState<FileUrl[]>([]);
  const {
    data: {
      message: { attachments, has_attachments, id },
      areAttachmentsVisible,
    },
  } = useMessage();

  const displayAttachments = has_attachments === 1 && areAttachmentsVisible;
  const getAttachmentPreview = async (filename: string) => {
    const data = await fetchFile(attachmentDownloadUrl(id, filename));

    const blob = await data.blob();
    const url = window.URL.createObjectURL(blob);
    return setFileUrls((prevState) => [
      ...prevState,
      { filename, fileUrl: url },
    ]);
  };

  useEffect(() => {
    if (displayAttachments) {
      attachments.forEach((attachment) => {
        getAttachmentPreview(attachment.filename);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SimpleGrid
      gap={4}
      columns={3}
      gridTemplateColumns={'repeat(3, minmax(auto, 33%))'}
      flexGrow={1}
      mt={4}
      flexWrap={'wrap'}
    >
      {displayAttachments &&
        attachments.map(({ filename, content: { attachmentId } }) => {
          const extension = filename.split('.').pop() || '';
          const fileUrl =
            fileUrls.find(
              ({ filename: fileUrlFilename }) => fileUrlFilename === filename,
            )?.fileUrl || '';
          return (
            <Box key={attachmentId}>
              <AttachmentTile
                extension={extension}
                filename={filename}
                tileText={filename}
                fileUrl={fileUrl}
              />
            </Box>
          );
        })}
    </SimpleGrid>
  );
};
