import React, { ReactElement } from 'react';
import {
  Avatar,
  Flex,
  HStack,
  Heading,
  Skeleton,
  Text,
  VStack,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { mediaServer } from '../../../../services/mediaServer';
import { PencilBlueIcon, TrashBlueIcon } from 'icons/icons';
import { useClientsMutationDelete } from 'services/hooks/api/clients/useClient';
import { ConfirmPopover } from 'components/Global/ConfirmPopover';
import { useModal } from 'contexts/ModalContext';
import EditClientModal from 'components/Clients/EditClientModal';
import IconButton from 'components/Global/IconButton';
import { useHistory, useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';

interface UserInfoHeadingProps {
  id: string;
  imageSrc?: string;
  name?: string;
  phone?: string;
  email?: string;
  isLoading?: boolean;
}

export const UserInfoHeading = ({
  id,
  name,
  email,
  imageSrc,
  phone,
  isLoading,
}: UserInfoHeadingProps): ReactElement => {
  const toast = useToast();
  const history = useHistory();
  const { clientType } = useParams<ClientRouteParams>();

  const { mutate: deleteClient, isLoading: isDeleting } =
    useClientsMutationDelete();
  const { openModal, defaultModalProps } = useModal();
  const [isConfirmOpen, setConfirmOpen] = useBoolean();

  const handleRemoveClient = () => {
    deleteClient(
      { clientId: id },
      {
        onSuccess: () => history.push(`/clients/${clientType}`),
        onError: async () =>
          toast({
            title: 'We could not remove this client',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
          }),
      },
    );
  };

  return (
    <Flex align={'center'} minH={'65px'}>
      {isLoading || !name ? (
        <>
          <Skeleton w={58} h={58} mr={3} />
          <VStack>
            <Skeleton w={150} h={13} />
            <Skeleton w={150} h={13} />
          </VStack>
        </>
      ) : (
        <>
          <Avatar
            src={imageSrc && mediaServer(imageSrc)}
            name={name}
            w={{ base: 38, xl: 58 }}
            h={{ base: 38, xl: 58 }}
            borderRadius={'md'}
            mr={{ base: 3, xl: 5 }}
          />

          <VStack spacing={1} alignItems={'flex-start'}>
            <Heading
              as={'h2'}
              fontSize={{ base: 14, lg: 16, xl: 18 }}
              wordBreak={'break-all'}
              lineHeight={'21.6px'}
            >
              {name}
            </Heading>

            <Text
              fontSize={{ base: 12, lg: 13, xl: 16 }}
              color={'pbBlue.100'}
              lineHeight={'16.8px'}
            >
              {phone}
            </Text>
            <Text
              fontSize={{ base: 12, lg: 13, xl: 16 }}
              color={'pbBlue.100'}
              lineHeight={'16.8px'}
            >
              {email}
            </Text>
          </VStack>

          <HStack ml={5}>
            <IconButton
              aria-label={'Edit client'}
              title={'Edit client'}
              icon={<PencilBlueIcon />}
              onClick={() =>
                openModal(
                  <EditClientModal {...defaultModalProps} clientId={id} />,
                )
              }
              fontSize={16}
            />
            <ConfirmPopover
              onClose={() => setConfirmOpen.off()}
              confirmLabel={'Yes, delete'}
              confirmMessage={'Are you sure you want to delete this client?'}
              onConfirm={handleRemoveClient}
              isOpen={isConfirmOpen}
              onOpen={() => setConfirmOpen.toggle()}
            >
              <IconButton
                title={'Remove client'}
                icon={<TrashBlueIcon />}
                isLoading={isDeleting}
                aria-label={'Remove client'}
                fontSize={16}
              />
            </ConfirmPopover>
          </HStack>
        </>
      )}
    </Flex>
  );
};
