import React from 'react';
import dayjs from 'dayjs';
import { Dispatch, FC, createContext, useContext, useReducer } from 'react';
import { ChildrenProps } from 'types/ChildrenProps';
import { DealsAction } from './actions';
import { dealsReducer } from './reducer';
import { SortDirection } from 'types/SortDirection';

export type DealsTableSortSettings = {
  label: string;
  sortDirection: SortDirection;
};

export type DealsState = {
  year: string;
  agentId?: string;
  month?: string;
  search?: string;
  dealStatus?: string;
  tableSortSettings?: DealsTableSortSettings;
};

export type DealsContextProps = {
  dealsState: DealsState;
  dealsDispatch: Dispatch<DealsAction>;
};

const initialState: DealsState = {
  year: dayjs().year().toString(),
};

export const DealsContext = createContext<DealsContextProps>({
  dealsState: initialState,
  dealsDispatch: () => {
    throw new Error('dispatch withing clients context is not defined');
  },
});

export const DealsContextProvider: FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [dealsState, dealsDispatch] = useReducer(dealsReducer, initialState);

  return (
    <DealsContext.Provider value={{ dealsState, dealsDispatch }}>
      {children}
    </DealsContext.Provider>
  );
};

export const useDealsContext = (): DealsContextProps => {
  const context = useContext<DealsContextProps>(DealsContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};
