import { ApplicationsWithContext } from 'contexts/ApplicationsContext';
import { MailboxWithContext } from 'contexts/MailboxContext';
import React from 'react';
import AgentProfile from 'scenes/AgentProfile';
import { Building } from 'scenes/Building';
import { Clients } from 'scenes/Clients';
import { Dashboard } from 'scenes/Dashboard';
import Deals from 'scenes/Deals';
import { Listing } from 'scenes/Listing';
import OpInvoices from 'scenes/OpInvoices';
import { PageNotFound } from 'scenes/PageNotFound';
import Results from 'scenes/Results';
import { Search } from 'scenes/Search';

export interface RouteData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
  exactPath?: boolean;
  privateRoute?: boolean;
}
const MyListings = React.lazy(async () => ({
  default: (await import('./scenes/MyListings')).MyListings,
}));

const mainRoutes: RouteData[] = [
  { component: Dashboard, path: '/', exactPath: true, privateRoute: true },
  {
    component: Clients,
    path: '/clients/:clientType/:clientId?/:tab?/:threadId?',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: MailboxWithContext,
    path: '/mailbox/:folder?/:threadId?',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: Search,
    path: '/search/:type',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: Results,
    path: '/results/:type',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: MyListings,
    path: '/my-listings',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: Listing,
    path: '/listing/:id/:page?',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: Building,
    path: '/building/:id',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: Deals,
    path: '/deals',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: AgentProfile,
    path: '/my-profile',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: OpInvoices,
    path: '/op-invoices',
    exactPath: false,
    privateRoute: true,
  },
  {
    component: ApplicationsWithContext,
    path: '/applications',
    exactPath: true,
    privateRoute: true,
  },
  {
    component: PageNotFound,
    path: '*',
    exactPath: false,
    privateRoute: false,
  },
];

export default mainRoutes;
