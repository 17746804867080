import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { badgeBackgroundColors } from '../../constants';

interface Props {
  ad_type: string;
}

const AdTypeLabel = ({ ad_type }: Props): ReactElement => (
  <Box
    display={'inline-block'}
    bgColor={badgeBackgroundColors[ad_type]}
    py={1}
    px={2}
    fontWeight={700}
    color={'white'}
    borderRadius={'md'}
    fontSize={10}
    minW={'80px'}
    textAlign={'center'}
  >
    {ad_type.toUpperCase()}
  </Box>
);

export default AdTypeLabel;
