import React, { ReactElement } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Skeleton } from '@chakra-ui/skeleton';
import { useGoogleMap } from '../../services/hooks/useGoogleMap';

interface ListingSidebarMapProps {
  lat: number;
  lng: number;
  height?: string;
}

export const ListingSidebarMap = ({
  lat,
  lng,
  height = '280px',
}: ListingSidebarMapProps): ReactElement => {
  const { isLoaded } = useGoogleMap();

  return (
    <Skeleton isLoaded={isLoaded} w={'100%'} h={height} mb={5}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height,
          }}
          zoom={12}
          center={{
            lat: lat,
            lng: lng,
          }}
        >
          <Marker
            position={{
              lat: lat,
              lng: lng,
            }}
          />
        </GoogleMap>
      )}
    </Skeleton>
  );
};
