import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ClientsSentListingBody, StringOrNumber } from '../../../apiTypes';
import { pbAgentFetcher } from '../../../fetcher';

interface Params {
  clientId: StringOrNumber;
  body: ClientsSentListingBody;
}

export const useClientsSentListings = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ['clients-sent-listings', 'post'],

    ({ clientId, body }: Params) =>
      pbAgentFetcher(`v1/clients-sent-listings/${clientId}`, {
        method: 'post',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: (e, { clientId }) => {
        queryClient.invalidateQueries(['clients', clientId]);

        // used in 'clients' tab
        const listingClientsInfoQueryKey = ['listing-clients-info'];
        // used in results page
        const clientListingsInfoQueryKey = ['clients-listings-info'];

        queryClient.invalidateQueries(listingClientsInfoQueryKey);
        queryClient.invalidateQueries(clientListingsInfoQueryKey);
      },
    },
  );
};
