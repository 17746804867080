import React, { ReactElement } from 'react';
import { RoundedBoxSection } from '../../../Global/RoundedBoxSection';
import { SectionSubTitle } from '../../../Global/SectionSubTitle';
import { Button, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import { useModal } from 'contexts/ModalContext';
import { AddParticipantModal } from 'components/Clients/AddParticipantModal';
import ParticipantTile from './ParticipantTile';
import { useClient } from 'services/hooks/api/clients/useClient';
import { useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';

export const ContactInformation = (): ReactElement => {
  const { openModal, defaultModalProps } = useModal();
  const { clientId } = useParams<ClientRouteParams>();

  const { data: clientData } = useClient(clientId);

  if (!clientData) return <Skeleton height={'100px'} w={'100%'} />;

  const { participants } = clientData;

  return (
    <RoundedBoxSection hasBorder pt={0}>
      <SectionSubTitle mb={4}>Participants</SectionSubTitle>

      <SimpleGrid
        gap={{ base: 2, xl: 5 }}
        columns={{ base: 1, md: 2, xl: 3, '2xl': 4 }}
      >
        {!participants ||
          (participants.length === 0 && (
            <Text pl={{ xl: 3 }}>No contacts</Text>
          ))}

        {participants?.map((participant) => (
          <ParticipantTile
            key={participant.user_id}
            clientId={clientId.toString()}
            participant={participant}
          />
        ))}
      </SimpleGrid>

      <Button
        onClick={() =>
          openModal(<AddParticipantModal {...defaultModalProps} />)
        }
        variant={'blueLight'}
        pos={{ base: 'relative', sm: 'absolute' }}
        right={{ base: 0, sm: 2, xl: 5 }}
        top={{ base: 0, sm: -3, xl: 1 }}
        mt={{ base: 2, sm: 0 }}
        h={'38px'}
      >
        Add Participant
      </Button>
    </RoundedBoxSection>
  );
};
