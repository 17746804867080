import { Flex, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { DottedRow, DottedRowProps } from '../DottedRow';
import { StringOrNumber } from '../../services/apiTypes';

export type TextElementType = {
  number?: StringOrNumber;
  label?: string;
};

export const InfoRowTextElement = ({
  number,
  label,
}: TextElementType): ReactElement => {
  return (
    <Text as={'span'} fontWeight={'bold'}>
      {number}
      {label && (
        <Text as={'span'} fontWeight={'normal'}>
          {` ${label}`}
        </Text>
      )}
    </Text>
  );
};

interface InfoRowProps extends DottedRowProps {
  elements: TextElementType[];
  dotted?: boolean;
}

export const InfoRow = ({
  elements,
  dotted = false,
  ...props
}: InfoRowProps): ReactElement => {
  return (
    <Flex align={'center'} color={'pbBlue.100'} lineHeight={6} fontSize={14}>
      <DottedRow
        {...props}
        wrap={'wrap'}
        items={elements.map((element) => {
          return (
            <InfoRowTextElement
              key={`${element.label}-${element.number}`}
              number={element.number}
              label={element.label}
            />
          );
        })}
      />

      {dotted && ' ...'}
    </Flex>
  );
};
