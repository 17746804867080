import React, { ReactElement, ReactNode } from 'react';
import { Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface Props {
  children: ReactNode;
  to: string;
  condition: boolean;
}
export const ConditionalLink = ({
  children,
  to,
  condition,
}: Props): ReactElement =>
  condition && to ? (
    <Link
      as={NavLink}
      to={to}
      _hover={{ textDecoration: 'none' }}
      _focus={{ outline: 'none' }}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
