import {
  Box,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { ReactElement, ReactNode } from 'react';

interface OwnPopoverProps {
  triggerContent: ReactNode;
  bodyContent: ReactNode;
  popoverProps?: PopoverProps;
  triggerProps?: BoxProps;
  popoverContentProps?: PopoverContentProps;
  bodyProps?: PopoverBodyProps;
}

// This Popover utilise functionality to disable pointer events for trigger whenever popover is already opened to
// prevent iOS issues where clicking trigger was consitantly opening body instead making it toggleable

export const OwnPopover = ({
  bodyContent,
  triggerContent,
  triggerProps,
  popoverProps,
  popoverContentProps,
  bodyProps,
}: OwnPopoverProps): ReactElement => {
  return (
    <Popover
      placement={'bottom-end'}
      variant={'fit-to-content'}
      {...popoverProps}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Box pointerEvents={isOpen ? 'none' : 'all'} {...triggerProps}>
              {triggerContent}
            </Box>
          </PopoverTrigger>
          <PopoverContent {...popoverContentProps}>
            <PopoverArrow />
            <PopoverBody {...bodyProps} onClick={onClose}>
              {bodyContent}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
