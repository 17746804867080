import React, { ReactElement, SyntheticEvent, useRef } from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverProps,
} from '@chakra-ui/popover';
import {
  Box,
  Button,
  ButtonGroup,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

type ConfirmPopoverProps = PopoverProps & {
  onConfirm: (e?: SyntheticEvent) => void;
  onClose: () => void;
  confirmLabel: string;
  confirmMessage?: string;
};

export const ConfirmPopover = ({
  children,
  confirmLabel,
  confirmMessage = 'Are you sure?',
  isOpen,
  placement,
  onClose,
  onConfirm,
  onOpen,
}: ConfirmPopoverProps): ReactElement => {
  const focusRef = useRef(null);

  return (
    <Popover
      isOpen={isOpen}
      returnFocusOnClose={false}
      placement={placement || 'bottom-start'}
      initialFocusRef={focusRef}
    >
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody py={4}>
          <Text fontSize={'sm'}>{confirmMessage}</Text>
        </PopoverBody>
        <PopoverFooter py={3} d={'flex'} justifyContent={'flex-end'}>
          <ButtonGroup size={'sm'}>
            <Button variant={'outline'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              backgroundColor={'rgb(250, 5, 32, 0.1)'}
              ref={focusRef}
              color={'pbRed.500'}
              onClick={() => {
                onClose();
                onConfirm();
              }}
            >
              {confirmLabel}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
      <Box onClick={onOpen}>
        <PopoverTrigger>{children}</PopoverTrigger>
      </Box>
    </Popover>
  );
};
