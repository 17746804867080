import { Flex, Link, Text } from '@chakra-ui/react';
import { Card } from 'components/Global/Card';
import { FileExtensionIcon } from 'components/Global/FileExtensionIcon';
import IconButton from 'components/Global/IconButton';
import { TrashBlueIcon } from 'icons/icons';
import React, { ReactElement } from 'react';

interface AttachmentTileProps {
  extension: string;
  fileUrl: string;
  filename: string;
  tileText: string;
  onAttachmentRemove?: () => void;
}

export const AttachmentTile = ({
  extension,
  fileUrl,
  tileText,
  onAttachmentRemove,
}: AttachmentTileProps): ReactElement => {
  return (
    <Card>
      <Flex align={'center'} flex={1} minW={0}>
        <FileExtensionIcon extension={extension} />
        {fileUrl ? (
          <Link
            href={fileUrl}
            target={'_blank'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflowX={'hidden'}
            maxW={'100%'}
          >
            {tileText}
          </Link>
        ) : (
          <Text
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflowX={'hidden'}
            maxW={'100%'}
          >
            {tileText}
          </Text>
        )}
        {onAttachmentRemove && (
          <IconButton
            minW={'32px'}
            icon={<TrashBlueIcon fontSize={'16px'} />}
            aria-label={'Remove attachment'}
            onClick={onAttachmentRemove}
            size={'sm'}
            ml={'auto'}
          />
        )}
      </Flex>
    </Card>
  );
};
