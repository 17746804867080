import {
  Box,
  Button,
  Divider,
  Input,
  InputProps,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuProps,
  Text,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { ChevronIcon } from '../../icons/icons';

export interface DropdownProps<T extends string> extends MenuButtonProps {
  options: T[];
  onValueChange: (option: T) => void;
  value?: T;
  variant?: string;
  placeholder?: string;
  inputFieldProps?: InputProps;
  menuProps?: Omit<MenuProps, 'children'>;
}

export const Dropdown = <T extends string>({
  options,
  value,
  onValueChange,
  inputFieldProps,
  variant,
  menuProps,
  placeholder,
  ...rest
}: DropdownProps<T>): ReactElement => {
  return (
    <Menu
      boundary={'scrollParent'}
      isLazy
      lazyBehavior={'keepMounted'}
      {...menuProps}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={<ChevronIcon width={'12px'} />}
            fontSize={'11px'}
            fontWeight={'900'}
            p={1}
            variant={variant || 'reset'}
            alignItems={'center'}
            sx={{
              svg: {
                mt: 1,
                ml: 2,
                transform: `rotate(${isOpen ? -180 : 0}deg)`,
                transition: 'transform 0.3s',
              },
            }}
            {...rest}
          >
            <Text transform={'translateY(2px)'}>{value || placeholder}</Text>
          </MenuButton>
          <MenuList px={2}>
            {inputFieldProps && (
              <Box p={1}>
                <Input {...inputFieldProps} />
                <Divider />
              </Box>
            )}
            {options.map((option) => (
              <MenuItem
                key={option}
                px={2}
                onClick={() => onValueChange(option as T)}
                _hover={{ borderRadius: 'lg', bgColor: 'pbBlue.510' }}
                _focus={{ borderRadius: 'lg', bgColor: 'pbBlue.510' }}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

type DropdownOption<T extends string> = T | { label: string; value: T };
type DropdownWithObjectOptionsProps<T extends string> = Omit<
  MenuButtonProps,
  'value'
> & {
  options: DropdownOption<T>[];
  value: DropdownOption<T>;
  onValueChange: (option: DropdownOption<T>) => void;
};

// TODO: This component could replace all occurrences of <Dropdown>.
export const DropdownWithObjectOptions = <T extends string>({
  options,
  value,
  onValueChange,
  ...rest
}: DropdownWithObjectOptionsProps<T>): ReactElement => {
  const chosenLabel = typeof value === 'string' ? value : value.label;

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={<ChevronIcon width={'12px'} />}
            fontSize={'11px'}
            fontWeight={'900'}
            p={1}
            variant={'reset'}
            alignItems={'center'}
            sx={{
              svg: {
                mt: 1,
                transform: `rotate(${isOpen ? -180 : 0}deg)`,
              },
            }}
            {...rest}
          >
            <Text transform={'translateY(2px)'}>{chosenLabel}</Text>
          </MenuButton>

          <MenuList>
            {options.map((option) => {
              const optionLabel =
                typeof option === 'string' ? option : option.label;
              const optionValue =
                typeof option === 'string' ? option : option.value;

              return (
                <MenuItem
                  key={optionValue}
                  onClick={() => onValueChange(option)}
                >
                  {optionLabel}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
