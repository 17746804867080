import { Clients, SearchType } from '../../../../services/apiTypes';
import { Box, Skeleton } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { SavedSearchTile } from '../../SavedSearchTile';
import { useClient } from '../../../../services/hooks/api/clients/useClient';
import { useClientContext } from '../../../../contexts/ClientContext';
import { useSavedSearchesMutationDelete } from '../../../../services/hooks/api/useSavedSearches';
import { onSetClient } from '../../../../contexts/ClientContext/actions';

export const ClientSavedSearches = ({
  client,
}: {
  client: Clients;
}): ReactElement => {
  const { type } = useParams<{ type: SearchType }>();
  const { data: clientData } = useClient(client.user_id);
  const {
    clientsState: { clientId },
    clientsDispatch,
  } = useClientContext();

  const searchData = clientData?.searches?.find(
    (s) => s.search.ad_type === type,
  );
  const { mutate } = useSavedSearchesMutationDelete();

  const onSearchRemove = () => {
    if (!searchData) {
      return;
    }
    mutate({
      clientId: client.user_id,
      searchId: searchData.search_id,
    });
  };
  return (
    <Box mt={2}>
      {!clientData && <Skeleton w={'100%'} h={20} borderRadius={'lg'} />}

      {searchData && (
        <SavedSearchTile
          client={client.name}
          search={searchData.search}
          title={searchData.title}
          results_count={searchData.results_count}
          onClick={() => clientsDispatch(onSetClient(client.user_id))}
          isActive={client.user_id === clientId}
          onRemove={onSearchRemove}
        />
      )}
    </Box>
  );
};
