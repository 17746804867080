import { Box, Flex, HStack, Heading, Skeleton, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { NumberBadge } from '../Global/NumberBadge';
import { objectToUrlParams } from '../../services/objectToUrlParams';
import { useSearchListingsAdvice } from '../../services/hooks/api/search-listings/useSearchListingsAdvice';
import { Search } from '../../services/apiTypes';
import { RemoveButton } from '../Global/RemoveButton';

const SearchValue = ({ label, count }: { label: string; count?: number }) => (
  <Flex align={'center'}>
    <Text as={'span'} color={'pbBlue.500'} fontSize={14}>
      {label}
    </Text>
    <Skeleton isLoaded={!!count} borderRadius={'lg'}>
      <NumberBadge number={count || 0} color={'pbGray.350'} />
    </Skeleton>
  </Flex>
);
interface SavedSearchTileProps {
  client: string;
  search: Search;
  title: string;
  results_count: number;
  onClick: () => void;
  onRemove: () => void;
  isActive?: boolean;
}

export const SavedSearchTile = ({
  client,
  search,
  title,
  results_count,
  onClick,
  onRemove,
  isActive,
}: SavedSearchTileProps): ReactElement => {
  const clientSearchParams = new URLSearchParams(objectToUrlParams(search));

  const { data: newCountData } = useSearchListingsAdvice(clientSearchParams);

  return (
    <Box
      pos={'relative'}
      border={'1px'}
      borderColor={isActive ? 'transparent' : 'gray.100'}
      borderRadius={'lg'}
      px={5}
      pt={5}
      pb={3}
      backgroundColor={isActive ? 'pbBlue.510' : 'transparent'}
    >
      <Heading as={'h3'} fontSize={16} onClick={onClick}>
        {client}
      </Heading>

      <Text fontSize={14} color={'pbGray.350'} my={1}>
        {title}
      </Text>

      <HStack>
        <SearchValue label={'Results'} count={results_count} />
        <SearchValue label={'New'} count={newCountData?.count} />
      </HStack>

      {/*FIXME: Remove on finish clients flow (or client would like to keep it?) */}
      <RemoveButton onClick={onRemove} />
    </Box>
  );
};
