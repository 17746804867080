import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { objectToUrlParams } from 'services/objectToUrlParams';
import { DealSheetsTypeheadResponse } from './apiTypes';

export const useDealSheetsTypeahead = (
  q?: string,
): UseQueryResult<DealSheetsTypeheadResponse> => {
  const params = new URLSearchParams(
    objectToUrlParams({
      type: 'address',
      q,
    }),
  );

  return useQuery<DealSheetsTypeheadResponse>(
    ['deal-sheets-typeahead', params.toString()],
    () => pbAgentFetcher(`v1/deal-sheets-typeahead?${params.toString()}`),
    {
      enabled: !!q,
      refetchOnWindowFocus: false,
    },
  );
};
