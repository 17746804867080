import {
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import React, { ReactElement, ReactNode } from 'react';
import { Option, StringOrNumber } from '../../../../services/apiTypes';

const PopoverListItemValue = ({
  content,
  label,
}: {
  label: StringOrNumber;
  content?: ReactNode;
}) => (
  <Text>
    <Text as={'span'}>{`${label}: `}</Text>
    <Text as={'span'} fontWeight={'bold'}>
      {content || '-'}
    </Text>
  </Text>
);

interface AddressPopoverProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  triggerItem: ReactNode;
  columns?: number;
  items?: Option[];
  placement?: Placement;
  heading?: string;
  notFoundText?: string;
}

export const AddressPopover = ({
  isOpen,
  isLoading,
  onClose,
  triggerItem,
  columns = 1,
  items,
  placement = 'bottom-start',
  heading = 'Info',
  notFoundText = 'Not Found',
}: AddressPopoverProps): ReactElement => {
  return (
    <Popover
      autoFocus={false}
      returnFocusOnClose={true}
      closeOnBlur={true}
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
    >
      <PopoverTrigger>{triggerItem}</PopoverTrigger>

      <PopoverContent>
        <PopoverHeader fontWeight={'bold'}>{heading}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          {isLoading ? (
            <SkeletonText />
          ) : !items || !items.length ? (
            <Text>{notFoundText}</Text>
          ) : (
            <SimpleGrid columns={columns} gap={2}>
              {items?.map((item, index) => (
                <PopoverListItemValue
                  key={`${item.label}-${item.value}-${index}`}
                  label={item.label}
                  content={item.value}
                />
              ))}
            </SimpleGrid>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
