import React, { ReactElement, useCallback, useRef } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  VStack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { useApplicationContext } from 'contexts/ApplicationContext';
import {
  ArrowLeftIcon,
  DownloadIcon,
  PencilBlueIcon,
  PlusIcon,
  TrashBlueIcon,
} from 'icons/icons';
import { kebabCase } from 'lodash';
import {
  useAFormDeleteFile,
  useAFormPost,
} from 'services/hooks/api/aform/useAFormMutation';
import { Card } from './Card';
import { ConfirmPopover } from './ConfirmPopover';
import IconButton from './IconButton';
import { FileExtensionIcon } from './FileExtensionIcon';
import {
  AttachmentInLibrary,
  AttachmentOriginType,
} from 'types/AttachmentInLibrary';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useAttachmentsLibrary } from 'contexts/AttachmentLibraryContext';
import saveAs from 'file-saver';
import { fetchFile } from 'services/fetchFile';

interface UploadedFileTileProps {
  isMailAttachment?: boolean;
  attachmentRemove?: (filename: string) => void;
  fieldName?: string;
  label: string;
  extension: string;
  fileUrl?: string;
  filename?: string;
}

const UploadedFileTile = ({
  fieldName,
  label,
  extension,
  fileUrl,
  filename,
  isMailAttachment,
  attachmentRemove,
}: UploadedFileTileProps): ReactElement => {
  const { onOpen, onClose, isOpen: isConfirmVisible } = useDisclosure();
  const {
    data: { selectedFiles, application_form_id },
    actions: { handleSelectFile },
  } = useApplicationContext();

  const { mutate: deleteFile, isLoading: isDeleting } = useAFormDeleteFile();
  const { mutate: updateFields, isLoading: isFieldUpdating } = useAFormPost();

  const handleDelete = () => {
    fieldName && deleteFile({ application_form_id, fieldName });
  };

  const handleUploadFile = (fieldName: string, file: File) => {
    updateFields({
      id: application_form_id,
      data: [
        {
          field: fieldName,
          value: file,
        },
      ],
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const triggerUpload = () => fileInputRef.current?.click();

  let tileText = 'No file attached';
  if (extension !== 'no-file') {
    tileText = isMailAttachment ? `${filename}` : `${label}.${extension}`;
  }

  const TileActions = (): ReactElement => {
    if (isMailAttachment && attachmentRemove) {
      return (
        <Box ml={'auto'}>
          <IconButton
            aria-label={'delete file'}
            icon={<TrashBlueIcon fontSize={16} />}
            onClick={() => attachmentRemove(label)}
            isLoading={isDeleting}
          />
        </Box>
      );
    }

    return (
      <Box ml={'auto'}>
        {extension !== 'no-file' ? (
          <HStack spacing={2}>
            <IconButton
              aria-label={'edit file'}
              icon={<PencilBlueIcon fontSize={16} />}
              onClick={triggerUpload}
              isLoading={isFieldUpdating}
            />
            <ConfirmPopover
              isOpen={isConfirmVisible}
              onConfirm={handleDelete}
              onClose={onClose}
              confirmLabel={'Yes, delete file'}
              placement={'top'}
            >
              <IconButton
                aria-label={'delete file'}
                icon={<TrashBlueIcon fontSize={16} />}
                onClick={onOpen}
                isLoading={isDeleting}
              />
            </ConfirmPopover>
          </HStack>
        ) : (
          <IconButton
            aria-label={'upload file'}
            icon={<PlusIcon fontSize={16} />}
            onClick={triggerUpload}
            isLoading={isFieldUpdating}
          />
        )}
      </Box>
    );
  };

  return (
    <Card>
      <Flex align={'center'} width={'100%'}>
        <HStack spacing={3}>
          {extension !== 'no-file' && (
            <Checkbox
              isChecked={selectedFiles.includes(
                fieldName || `attachedFile-${kebabCase(label)}`,
              )}
              onChange={() =>
                handleSelectFile(
                  fieldName || `attachedFile-${kebabCase(label)}`,
                )
              }
            />
          )}
          <FileExtensionIcon extension={extension} />
          <Flex flexDir={'column'} lineHeight={'16px'}>
            {fileUrl ? (
              <Link href={fileUrl} target={'_blank'} download={filename}>
                {tileText}
              </Link>
            ) : (
              <Text>{tileText}</Text>
            )}
          </Flex>
        </HStack>

        <TileActions />
      </Flex>

      {!isMailAttachment && fieldName && (
        <Input
          ref={fileInputRef}
          display={'none'}
          variant={'reset'}
          type={'file'}
          onChange={(e) =>
            e.target.files && handleUploadFile(fieldName, e.target.files[0])
          }
        />
      )}
    </Card>
  );
};

export default UploadedFileTile;

export const AttachmentInLibraryTile = ({
  id,
  extension,
  filename,
  subject,
  fileUrl,
  sendAt,
  receivedAt,
  sentTo,
  receivedFrom,
  type,
  threadId,
  message_id,
}: AttachmentInLibrary): ReactElement => {
  const [isLoadingFile, setIsLoadingFile] = useBoolean(false);
  const history = useHistory();
  const attachmentType =
    type === AttachmentOriginType.SENT ? 'Sent' : 'Received';
  const time = type === AttachmentOriginType.SENT ? sendAt : receivedAt;

  const senderOrRecipientText = `${attachmentType} ${
    attachmentType === 'Sent' ? 'to' : 'from'
  } ${attachmentType === 'Sent' ? sentTo : receivedFrom}`;

  const goToMessage = useCallback(() => {
    const folder = type === AttachmentOriginType.SENT ? 'sent' : 'inbox';
    history.push(`/mailbox/${folder}/${threadId}`, {
      messageToOpen: message_id,
      shouldOpenForm: false,
    });
  }, [history, message_id, threadId, type]);

  const downloadFile = async () => {
    setIsLoadingFile.on();
    const fileString = await fetchFile(fileUrl);
    const blob = await fileString.blob();
    setIsLoadingFile.off();
    saveAs(blob, filename);
  };

  const previewFile = async () => {
    setIsLoadingFile.on();
    const fileString = await fetchFile(fileUrl);
    const blob = await fileString.blob();
    setIsLoadingFile.off();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');
    link.click();
    link.remove();
  };

  const {
    data: { selectedAttachments },
    actions: { toggleAttachmentSelected },
  } = useAttachmentsLibrary();

  return (
    <Card p={3}>
      <Flex align={'center'} width={'100%'}>
        <HStack spacing={3} flex={1} minW={0}>
          <Checkbox
            isChecked={selectedAttachments.includes(id)}
            onChange={() => toggleAttachmentSelected(id)}
          />
          <FileExtensionIcon extension={extension} />
          <VStack alignItems={'flex-start'} spacing={0} flex={1} minW={0}>
            <Text color={'pbBlack.100'}>{subject}</Text>
            <Text
              fontWeight={'bold'}
              _hover={{ cursor: fileUrl ? 'pointer' : 'auto' }}
              onClick={fileUrl ? previewFile : undefined}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              maxW={'100%'}
            >
              {filename}
            </Text>
            <Text
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              maxW={'100%'}
              fontSize={'xs'}
              color={'pbBlue.100'}
              fontWeight={'bold'}
            >{`${dayjs(time).format(
              'MMM DD, h:mma, \u00A0\u00A0\u00A0',
            )} ${senderOrRecipientText}`}</Text>
          </VStack>
        </HStack>

        <Stack
          direction={{ base: 'row', lg: 'column', xl: 'row' }}
          spacing={3}
          ml={'auto'}
          flex={'0 0 auto'}
        >
          <IconButton
            icon={<DownloadIcon fontSize={'16px'} />}
            aria-label={'download attachment'}
            size={'sm'}
            tooltipMessage={'Download attachment'}
            isLoading={isLoadingFile}
            onClick={downloadFile}
          />

          <IconButton
            icon={
              <ArrowLeftIcon transform={'rotate(180deg)'} fontSize={'16px'} />
            }
            aria-label={'download attachment'}
            size={'sm'}
            onClick={goToMessage}
            tooltipMessage={'Go to message'}
          />
        </Stack>
      </Flex>
    </Card>
  );
};
