import { UseQueryResult, useQuery } from 'react-query';
import { SearchFormOptionsResponse } from './apiTypes';
import { pbAgentFetcher } from '../../../fetcher';

export const useSearchFormOptions =
  (): UseQueryResult<SearchFormOptionsResponse> =>
    useQuery<SearchFormOptionsResponse>(
      'search-form-options',
      () => pbAgentFetcher('v1/search-form-options'),
      {
        refetchOnWindowFocus: false,
      },
    );
