import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { UserInfoHeading } from './components/UserInfoHeading';
import { useClient } from '../../../services/hooks/api/clients/useClient';
import { ClientDetails } from '../ClientDetails';
import { SavedListings } from '../SavedListings';
import { Application } from '../Application';
import { Messages } from '../Messages';
import { useQueryClient } from 'react-query';
import { useGmailThreads } from 'services/hooks/api/gmail/useGmailThreads';
import { OwnBadge } from 'components/Global/OwnBadge';
import { ClientDetailsSkeleton } from './components/ClientDetailsSkeleton';
import { TabLabel } from 'components/Global/TabLabel';
import { useHistory, useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';
import { indexOf } from 'lodash';
import { ApplicationProvider } from 'contexts/ApplicationContext';
import { MailboxContextProvider } from 'contexts/MailboxContext';

export const ClientDashboard = (): ReactElement | null => {
  const queryClient = useQueryClient();
  const { clientId, clientType, tab } = useParams<ClientRouteParams>();
  const history = useHistory();

  const { data: clientData, isLoading: isLoadingClientData } =
    useClient(clientId);

  const { data: threadsData, isLoading } = useGmailThreads({
    q: clientData?.email,
  });

  const threads = useMemo(
    () => threadsData?.pages.flatMap((page) => page.collection.threads) || [],
    [threadsData],
  );
  const hasUnseenMessages =
    !isLoading && !!threads.find((thread) => thread.unseen_count > 0);

  useEffect(() => {
    queryClient.invalidateQueries('clients');
  }, [clientData, queryClient]);

  const tabs = useMemo(
    () => [
      {
        slug: 'details',
        label: 'Details',
        content: <ClientDetails />,
      },
      {
        slug: 'messages',
        label: <TabLabel tabName={'Messages'} showBadge={hasUnseenMessages} />,
        content: (
          <MailboxContextProvider clientEmail={clientData?.email}>
            <Messages />
          </MailboxContextProvider>
        ),
        badge: '',
      },
      {
        slug: 'saved-listings',
        label: 'Saved Listings',
        content: <SavedListings />,
      },
      {
        slug: 'application',
        label: 'Application',
        content: (
          <ApplicationProvider>
            <Application
              application_form_id={clientData?.application_form_id || ''}
            />
          </ApplicationProvider>
        ),
        disabled: !clientData?.application_form_id,
      },
    ],
    [clientData, hasUnseenMessages],
  );

  const currentTab = useMemo(
    () =>
      indexOf(
        tabs,
        tabs.find((tabObject) => tabObject.slug === tab),
      ) || 0,
    [tab, tabs],
  );

  if (isLoadingClientData) return <ClientDetailsSkeleton />;
  if (!clientData) return null;

  const switchTab = (index: number) =>
    history.replace(`/clients/${clientType}/${clientId}/${tabs[index].slug}`);

  return (
    <Tabs isLazy defaultIndex={0} index={currentTab} onChange={switchTab}>
      <Flex
        justifyContent={'space-between'}
        pt={{ base: 2, xl: 5 }}
        px={{ base: 2, xl: 5 }}
        pb={2}
        direction={{ base: 'column', xl: 'row' }}
      >
        <UserInfoHeading
          id={clientData.user_id}
          imageSrc={clientData.photo}
          name={clientData.name}
          phone={clientData.phone}
          email={clientData.email}
          isLoading={isLoadingClientData}
        />

        <TabList alignItems={'center'} mt={{ base: 4, xl: 0 }}>
          {tabs.map(({ label, disabled, badge }) => (
            <Tab
              key={label.toString()}
              mx={{ base: 0.5, xl: 1 }}
              isDisabled={disabled}
              _disabled={{
                opacity: 0.3,
              }}
              position={'relative'}
            >
              {label}
              {badge && <OwnBadge top={0} right={0} position={'absolute'} />}
            </Tab>
          ))}
        </TabList>
      </Flex>

      <TabPanels>
        {tabs.map(({ label, content }) => (
          <TabPanel py={4} px={0} key={label.toString()}>
            {content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
