import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface FormSectionProps extends BoxProps {
  hasBorder?: boolean;
}
export const RoundedBoxSection = ({
  hasBorder,
  children,
  ...props
}: FormSectionProps): ReactElement => (
  <Box
    borderBottom={hasBorder ? '1px' : 0}
    borderColor={'pbGray.100'}
    p={{ base: 3, xl: 5 }}
    {...props}
  >
    {children}
  </Box>
);
