import { difference } from 'lodash';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import {
  GmailInfiniteQueryResponse,
  GmailThreadUpdateResponse,
} from './apiTypes';
interface Params {
  flag: string;
  id: string;
}

interface MultipleThreadsFlagParams {
  flag: string;
  ids: string[];
}

enum Labels {
  'starred' = 'STARRED',
  'deleted' = 'TRASH',
}

const updateLabels = (
  data: GmailInfiniteQueryResponse,
  threadWithUpdatedLabels: GmailThreadUpdateResponse,
  addOrRemove: 'add' | 'remove',
  label: string,
) => {
  const folderLabel = label === 'starred' ? Labels.starred : Labels.deleted;

  return {
    ...data,
    pages: data.pages.map((page) => ({
      ...page,
      collection: {
        ...page.collection,
        threads: page.collection.threads.map((thread) => {
          if (thread.id === threadWithUpdatedLabels.id) {
            return {
              ...thread,
              messages: thread.messages.map((message) => ({
                ...message,
                label_ids:
                  addOrRemove === 'add'
                    ? [...message.label_ids, folderLabel]
                    : difference(message.label_ids, [folderLabel]),
              })),
            };
          }
          return thread;
        }),
      },
    })),
  };
};
interface UseGmailFlagsReturnType {
  addFlag: UseMutationResult<
    GmailThreadUpdateResponse,
    unknown,
    Params,
    unknown
  >;
  addFlagToMultipleThreads: UseMutationResult<
    Response,
    unknown,
    MultipleThreadsFlagParams,
    unknown
  >;
  removeFlag: UseMutationResult<
    GmailThreadUpdateResponse,
    unknown,
    Params,
    unknown
  >;
  removeFlagFromMultipleThreads: UseMutationResult<
    Response,
    unknown,
    MultipleThreadsFlagParams,
    unknown
  >;
}

export const useGmailFlags = (
  currentFolder: string,
): UseGmailFlagsReturnType => {
  const refetchOtherFolders = () => {
    queryClient.invalidateQueries('gmail-threads', {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      predicate: ({ queryKey }: { queryKey: any }) => {
        const label = queryKey[1].label as string;
        return label !== currentFolder.toUpperCase() || label === undefined;
      },
    });
  };

  const threadsQueryKey = [
    'gmail-threads',
    { label: currentFolder.toUpperCase(), query: '' },
  ];
  const queryClient = useQueryClient();

  const addFlag = useMutation(
    ['gmail-flags'],
    ({ flag, id }: Params) =>
      pbAgentFetcher(`v1/gmail-flags/${id}/${flag}`, {
        method: 'post',
      }),
    {
      onSuccess: (thread: GmailThreadUpdateResponse, { flag }) => {
        const data =
          queryClient.getQueryData<GmailInfiniteQueryResponse>(threadsQueryKey);

        if (!data) {
          return;
        }

        queryClient.setQueryData(
          threadsQueryKey,
          updateLabels(data, thread, 'add', flag),
        );
        queryClient.invalidateQueries(['gmail-labels']);

        refetchOtherFolders();
      },
    },
  );

  const addFlagToMultipleThreads = useMutation(
    ['gmail-flags', 'multiple-fields'],
    ({ flag, ids }: MultipleThreadsFlagParams) =>
      pbAgentFetcher(`v1/gmail-flags/${flag}?message_ids=${ids}`, {
        method: 'post',
      }),
  );

  const removeFlagFromMultipleThreads = useMutation(
    ['gmail-flags', 'multiple-fields'],
    ({ flag, ids }: MultipleThreadsFlagParams) =>
      pbAgentFetcher(`v1/gmail-flags/${flag}?message_ids=${ids}`, {
        method: 'delete',
      }),
  );

  const removeFlag = useMutation(
    ['gmail-flags-remove'],
    ({ flag, id }: Params) =>
      pbAgentFetcher(`v1/gmail-flags/${id}/${flag}`, {
        method: 'delete',
      }),
    {
      onSuccess: (thread: GmailThreadUpdateResponse, { flag }) => {
        const data =
          queryClient.getQueryData<GmailInfiniteQueryResponse>(threadsQueryKey);

        if (!data) {
          return;
        }
        queryClient.setQueryData(
          threadsQueryKey,
          updateLabels(data, thread, 'remove', flag),
        );
        queryClient.invalidateQueries(['gmail-labels']);
        refetchOtherFolders();
      },
    },
  );

  return {
    addFlag,
    addFlagToMultipleThreads,
    removeFlag,
    removeFlagFromMultipleThreads,
  };
};
