import {
  Button,
  Flex,
  HStack,
  Input,
  SimpleGrid,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RadioRow } from '../../Form/RadioRow';
import {
  Commission,
  DealSheetSingleQuery,
  SearchType,
  StringOrNumber,
} from '../../../services/apiTypes';
import { ActionModal } from '../../Global/ActionModal';
import {
  useDealSheet,
  useDealSheetPost,
} from '../../../services/hooks/api/deals/useDealSheet';
import { useClients } from '../../../services/hooks/api/clients/useClients';
import { AutoCompleteInput } from '../../Form/AutoCompleteInput';
import SectionSubHeading from '../../AgentProfile/AgentTabs/components/PersonalDetails/shared/SectionSubHeading';
import { ApartmentTile } from '../../Global/ApartmentTile';
import { DayPicker } from '../../Global/DayPicker';
import dayjs from 'dayjs';
import { useDealSheetsPost } from '../../../services/hooks/api/deals/useDealSheets';
import { CheckboxesRow } from '../../Form/CheckboxesRow';
import { ContactBox } from '../../Global/ContactBox';
import { useDealSheetsTypeahead } from '../../../services/hooks/api/deals/useDealSheetsTypeahead';
import { CommissionBreakdown } from './components/CommissionBreakdown';
import FormRow from './components/FormRow';
import { Documents } from './components/Documents';
import { PriceInput } from './components/PriceInput';
import { RadioColumn } from '../../Form/RadioColumn';
import { machineDateFormat } from '../../../services/dateFormat';
import { useDealSheetsAdvice } from '../../../services/hooks/api/deals/useDealSheetsAdvice';
import { ModalProps } from 'types/ModalProps';

export type CommissionFeeKey = 'op' | 'fee';
export type CommissionKeyMap = {
  label: string;
  key: CommissionFeeKey;
};

const commissionKeysMap: CommissionKeyMap[] = [
  {
    label: 'Owner Paid Commission',
    key: 'op',
  },
  {
    label: 'Tenant Paid Commission',
    key: 'fee',
  },
];

export const NewDealModal = ({ onClose, isOpen }: ModalProps): ReactElement => {
  const [type, setType] = useState<SearchType>();
  const [isInvoiceGenerating, setInvoiceGenerating] = useState(false);
  const [isClientNotFound, setNotFoundClient] = useState(false);
  const [addressField, setAddressField] = useState('');
  const [selectedClient, setSelectedClient] = useState<string[]>([]);
  const [clientField, setClientField] = useState('');
  const [newDeal, setNewDeal] = useState<DealSheetSingleQuery>({});

  const { data: clientsData } = useClients({
    search: clientField,
  });

  const { data: suggestedBuildings } = useDealSheetsTypeahead(addressField);

  const {
    status: mutationDealSheetPostStatus,
    mutate: postDealSheet,
    isLoading: isUploadingDealSheet,
  } = useDealSheetPost();

  const {
    data: dealSheetClientData,
    mutate: getDealSheetClient,
    isLoading: isLoadingDealSheetClient,
  } = useDealSheetsPost();

  const { deal_id } = dealSheetClientData || {};
  const { data: dealSheetsAdvice } = useDealSheetsAdvice();
  const { data: dealSheetData } = useDealSheet(deal_id);
  const { deal_sheet_id: dealId } = dealSheetData || {};

  useEffect(() => {
    if (!dealSheetData) return;

    setNewDeal(dealSheetData.deal_sheet);
  }, [dealSheetData]);

  useEffect(() => {
    if (dealSheetData || !dealSheetsAdvice) return;

    setNewDeal(dealSheetsAdvice);
  }, [dealSheetData, dealSheetsAdvice]);

  const isFormValidated = useMemo(() => {
    if (!dealId) {
      return false;
    }

    if (!newDeal.basic_info?.client_name || !newDeal.basic_info?.client_email) {
      return false;
    }

    return true;
  }, [newDeal, dealId]);

  useEffect(() => {
    if (mutationDealSheetPostStatus === 'success') {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationDealSheetPostStatus]);

  const onSubmit = () =>
    isFormValidated &&
    postDealSheet({
      dealId,
      body: {
        deal_sheet: newDeal,
      },
    });

  const [commissionType, setCommissionType] = useState<CommissionFeeKey>('op');

  const onSetCommissionField = useCallback(
    (field: string, value: StringOrNumber, agentId?: string) =>
      setNewDeal((prev) => {
        const newCommissions: Commission[] = !prev?.commissions?.length
          ? []
          : [...prev.commissions];
        const foundIndex = newCommissions.findIndex(
          (c) => c.description === field,
        );

        const newCommission: Commission = {
          description: field,
          [commissionType]: value,
        };

        if (agentId) {
          newCommission['agent_id'] = agentId;
        }

        if (foundIndex < 0) {
          newCommissions.push(newCommission);
        } else {
          newCommissions[foundIndex] = {
            ...newCommissions[foundIndex],
            ...newCommission,
          };
        }

        return {
          ...prev,
          commissions: newCommissions,
        };
      }),
    [commissionType],
  );

  const commissionAmount = useMemo(
    () =>
      newDeal?.commissions?.find(
        (c) => c.description === 'Amount of check received at signing',
      )?.[commissionType as keyof Commission],
    [newDeal, commissionType],
  );

  const onClientsChange = (itemsList: string[]) => {
    setSelectedClient(itemsList);

    const found = clientsData?.find((b) => itemsList.includes(b.name));

    setNotFoundClient(!found);

    setNewDeal((prev) => ({
      ...prev,
      basic_info: {
        ...prev.basic_info,
        client_name: found?.name || itemsList[0],
        client_phone: found?.phone,
        client_email: found?.email,
      },
    }));

    if (found) {
      getDealSheetClient({
        body: {
          client_id: found.user_id,
        },
      });
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={'New Deal'}
      isDisabled={!isFormValidated}
      isLoading={isUploadingDealSheet || isLoadingDealSheetClient}
      onSubmit={onSubmit}
      size={'10xl'}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} minH={400}>
        <VStack pr={5} align={'stretch'}>
          <RadioRow
            label={'Type'}
            onChange={(e) => setType(e as SearchType)}
            value={type}
            options={['rental', 'sale']}
          />

          <FormRow label={'Client'}>
            <Flex wrap={'wrap'} w={'100%'} align={'stretch'}>
              <AutoCompleteInput
                value={selectedClient}
                onChange={onClientsChange}
                onSearch={setClientField}
                placeholder={'Load Client'}
                items={clientsData?.map(({ name, email }) => ({ name, email }))}
                required
                isSingleInput
              />

              {isClientNotFound && (
                <SimpleGrid columns={2} gap={2} mt={2} w={'100%'}>
                  <Input
                    placeholder={'Client email'}
                    onChange={(e) =>
                      setNewDeal((prev) => ({
                        ...prev,
                        basic_info: {
                          ...prev.basic_info,
                          client_email: e.target.value,
                        },
                      }))
                    }
                    value={newDeal.basic_info?.client_email}
                  />
                  <Input
                    placeholder={'Client phone'}
                    onChange={(e) =>
                      setNewDeal((prev) => ({
                        ...prev,
                        basic_info: {
                          ...prev.basic_info,
                          client_phone: e.target.value,
                        },
                      }))
                    }
                    value={newDeal.basic_info?.client_phone}
                  />
                </SimpleGrid>
              )}
            </Flex>
          </FormRow>

          <FormRow label={'Address'}>
            <VStack w={'100%'} align={'stretch'}>
              <SimpleGrid templateColumns={'1fr 100px'} gap={2}>
                <AutoCompleteInput
                  onChange={(itemsList) => {
                    const found = suggestedBuildings?.find(
                      (b) => b === itemsList[0],
                    );

                    if (found) {
                      setNewDeal((prev) => ({
                        ...prev,
                        basic_info: {
                          ...prev.basic_info,
                          apt_address: found,
                        },
                      }));
                    }
                  }}
                  onSearch={setAddressField}
                  value={
                    newDeal.basic_info?.apt_address
                      ? [newDeal.basic_info?.apt_address]
                      : []
                  }
                  placeholder={'Address'}
                  required
                  items={suggestedBuildings?.map((name) => ({ name }))}
                  isSingleInput
                  isDisabled={!newDeal.basic_info?.client_email}
                />

                <Input
                  placeholder={'Apt #'}
                  onChange={(e) =>
                    setNewDeal((prev) => ({
                      ...prev,
                      basic_info: {
                        ...prev.basic_info,
                        apt_unit: e.target.value,
                      },
                    }))
                  }
                  value={newDeal.basic_info?.apt_unit}
                  isDisabled={!newDeal.basic_info?.apt_address}
                />
              </SimpleGrid>

              <SimpleGrid columns={2} gap={2}>
                <Input
                  placeholder={'Price'}
                  onChange={(e) =>
                    setNewDeal((prev) => ({
                      ...prev,
                      basic_info: {
                        ...prev.basic_info,
                        apt_price: parseFloat(e.target.value) || 0,
                      },
                    }))
                  }
                  value={newDeal.basic_info?.apt_price || ''}
                  type={'number'}
                  isDisabled={!newDeal.basic_info?.apt_unit}
                />
                <PriceInput
                  placeholder={'Commission'}
                  onChange={(e) =>
                    onSetCommissionField(
                      'Amount of check received at signing',
                      parseFloat(e.target.value),
                    )
                  }
                  value={commissionAmount}
                  isDisabled={!newDeal.basic_info?.apt_unit}
                />
              </SimpleGrid>
            </VStack>
          </FormRow>

          <FormRow label={'Commission Type'}>
            <VStack align={'stretch'} w={'100%'}>
              <HStack justifyContent={'space-between'}>
                <RadioColumn
                  onChange={(e) => {
                    const found = commissionKeysMap.find(
                      (m) => m.label === e,
                    )?.key;

                    if (found) {
                      setCommissionType(found);
                    }
                  }}
                  value={
                    commissionKeysMap.find((m) => m.key === commissionType)
                      ?.label || ''
                  }
                  options={['Owner Paid Commission', 'Tenant Paid Commission']}
                />

                <Button
                  variant={'blueLight'}
                  onClick={() => setInvoiceGenerating((prev) => !prev)}
                  isDisabled={commissionType === 'fee'}
                >
                  Generate Invoice
                </Button>
              </HStack>
            </VStack>
          </FormRow>

          {isInvoiceGenerating && commissionType !== 'fee' && (
            <FormRow label={'Billing'} className={'notImplemented'}>
              <VStack w={'100%'} align={'stretch'}>
                <AutoCompleteInput
                  placeholder={'Management Company'}
                  onChange={() => console.log('TODO')}
                  onSearch={() => console.log('TODO')}
                  value={[]}
                  items={[]}
                  required
                  isSingleInput
                />
                <Input
                  placeholder={'Contact Name'}
                  onChange={(e) => () => console.log('TODO', e)}
                  value={''}
                  required
                />
                <HStack>
                  <Input
                    placeholder={'Email'}
                    onChange={(e) => console.log('TODO', e)}
                    value={''}
                    required
                  />
                  <Input
                    placeholder={'Phone'}
                    onChange={(e) => console.log('TODO', e)}
                    value={''}
                    required
                  />
                </HStack>
              </VStack>
            </FormRow>
          )}

          <FormRow label={' '}>
            <SimpleGrid columns={3} gap={2}>
              <DayPicker
                placeholder={'Date Closed'}
                onDayChange={(day) =>
                  setNewDeal((prev) => ({
                    ...prev,
                    basic_info: {
                      ...prev.basic_info,
                      date: dayjs(day).format(machineDateFormat),
                    },
                  }))
                }
                value={newDeal?.basic_info?.date}
                formatDate={(date) => dayjs(date).format(machineDateFormat)}
                isDisabled={!newDeal.basic_info?.client_email}
              />
              {type === 'rental' && (
                <>
                  <DayPicker
                    placeholder={'Lease Start'}
                    onDayChange={(day) =>
                      setNewDeal((prev) => ({
                        ...prev,
                        basic_info: {
                          ...prev.basic_info,
                          lease_start: dayjs(day).format(machineDateFormat),
                        },
                      }))
                    }
                    value={newDeal?.basic_info?.lease_start}
                    formatDate={(date) => dayjs(date).format(machineDateFormat)}
                    isDisabled={!newDeal.basic_info?.client_email}
                  />

                  <DayPicker
                    placeholder={'Lease End'}
                    onDayChange={(day) =>
                      setNewDeal((prev) => ({
                        ...prev,
                        basic_info: {
                          ...prev.basic_info,
                          lease_end: dayjs(day).format(machineDateFormat),
                        },
                      }))
                    }
                    value={newDeal?.basic_info?.lease_end}
                    formatDate={(date) => dayjs(date).format(machineDateFormat)}
                    isDisabled={!newDeal.basic_info?.client_email}
                  />
                </>
              )}
            </SimpleGrid>
          </FormRow>

          <CheckboxesRow
            label={'Paid By'}
            onChange={(e) =>
              setNewDeal((prev) => {
                const methods =
                  prev.funds?.methods.map((m) => ({
                    ...m,
                    checked: e.includes(m.label),
                  })) || [];

                return {
                  ...prev,
                  funds: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ...prev.funds!,
                    methods,
                  },
                };
              })
            }
            value={
              newDeal.funds?.methods
                .filter(({ checked }) => checked)
                .map(({ label }) => label) || []
            }
            options={newDeal.funds?.methods.map(({ label }) => label) || []}
            isDisabled={!newDeal.basic_info?.client_email}
          />

          {
            //TODO: The are disabled always whenever we want to add new client instead use existing
            // one because client is needed to retrieve deal_id.
            // asked on https://activecollab.chop-chop.org/projects/335?modal=Task-17452-335
          }
          <Documents
            paperwork={newDeal.paperwork}
            isDisabled={!newDeal.basic_info?.client_email}
            dealId={deal_id}
          />

          <FormRow label={'Notes'}>
            {/*TODO: Ask client about description field */}
            <Textarea
              placeholder={'Type Your Notes Here'}
              px={'8'}
              py={'6'}
              minH={'110px'}
              bg={'pbGray.50'}
              border={'none'}
              borderRadius={'lg'}
              value={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newDeal.basic_info?.description || ''
              }
              isDisabled={!newDeal.basic_info?.client_email}
              onChange={(event) => {
                setNewDeal((prev) => ({
                  ...prev,
                  basic_info: {
                    ...prev.basic_info,
                    description: event.target.value,
                  },
                }));
              }}
            />
          </FormRow>
        </VStack>

        <VStack
          borderLeftWidth={1}
          borderColor={'pbGray.100'}
          align={'stretch'}
        >
          <VStack pl={5} align={'stretch'}>
            <SectionSubHeading>Deal Sheet</SectionSubHeading>

            {newDeal.basic_info?.apt_address && (
              <ApartmentTile
                // imageSrc={mediaServer(photo_featured)}
                price={newDeal.basic_info?.apt_price || 0}
                title={`${newDeal.basic_info?.apt_address}${
                  newDeal.basic_info?.apt_unit &&
                  `, ${newDeal.basic_info?.apt_unit}`
                }`}
                rental={type === 'rental'}
              />
            )}

            {newDeal.basic_info?.client_email && (
              <ContactBox
                photo={newDeal.basic_info?.client_name}
                phone={newDeal.basic_info?.client_phone}
                email={newDeal.basic_info?.client_email}
                name={newDeal.basic_info?.client_name}
              />
            )}
          </VStack>

          <VStack
            pl={5}
            borderTopWidth={1}
            borderColor={'pbGray.100'}
            align={'stretch'}
          >
            <CommissionBreakdown
              commissions={newDeal.commissions}
              onSetField={onSetCommissionField}
              commissionType={commissionType}
              isDisabled={!newDeal.basic_info?.client_email}
            />
          </VStack>
        </VStack>
      </SimpleGrid>
    </ActionModal>
  );
};
