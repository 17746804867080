import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

type Modal = ReactElement | null;

type ModalContextType = {
  modal: ReactElement | null;
  openModal: (arg0: Modal) => void;
  closeModal: () => void;
  defaultModalProps: {
    isOpen: boolean;
    onClose: () => void;
  };
};
type Props = {
  children: ReactNode;
};

const ModalContextDefaultValues: ModalContextType = {
  modal: null,
  openModal: () => ({}),
  closeModal: () => null,
  defaultModalProps: {
    isOpen: true,
    onClose: () => ({}),
  },
};

const ModalContext = createContext<ModalContextType>(ModalContextDefaultValues);

export function useModal(): ModalContextType {
  return useContext(ModalContext);
}

export function ModalContextProvider({ children }: Props): ReactElement {
  const [modal, setModal] = useState<Modal>(null);

  const openModal = (modal: Modal) => setModal(modal);
  const closeModal = () => setModal(null);
  const defaultModalProps = { isOpen: true, onClose: closeModal };

  const value = {
    modal,
    openModal,
    closeModal,
    defaultModalProps,
  };

  return (
    <>
      <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    </>
  );
}
