import {
  Flex,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { StringOrNumber } from '../../../services/apiTypes';
import { NumberBadge } from '../NumberBadge';
import { isNumeric } from '../../../services/isNumeric';
import { ResponsiveValue } from '@chakra-ui/system';

export type TabsType = {
  label: ReactNode;
  abbreviation: string;
  badgeCount?: number;
  content: ReactNode;
  disabled?: boolean;
  omit?: boolean;
};

export type OwnTabsProps = {
  tabs: TabsType[];
  customHeader?: ReactNode;
  onChange?: (value: StringOrNumber) => void;
  value?: StringOrNumber;
  spacingBetween?: ResponsiveValue<StringOrNumber>;
  tabListStyles?: TabListProps;
  tabPanelStyles?: TabPanelProps;
};

export const OwnTabs: FC<OwnTabsProps> = ({
  tabs,
  customHeader = null,
  value,
  onChange,
  tabListStyles: tabList,
  tabPanelStyles: tabPanel,
  spacingBetween = 0,
}: OwnTabsProps) => {
  const defaultIndex = tabs.findIndex((tab) => tab.abbreviation === value);
  return (
    <Tabs
      isLazy
      defaultIndex={0}
      index={defaultIndex === -1 ? 0 : defaultIndex}
      onChange={onChange ? (v) => onChange(tabs[v]?.abbreviation) : undefined}
    >
      <TabList px={1} mb={spacingBetween} {...tabList}>
        {tabs
          .filter((tab) => !tab.omit)
          .map((tab) => (
            <Tab key={tab.abbreviation} isDisabled={tab.disabled}>
              {isNumeric(tab.badgeCount) ? (
                <Flex align={'center'}>
                  {tab.label}{' '}
                  <NumberBadge
                    number={tab.badgeCount}
                    isColorReverted={value === tab.abbreviation}
                  />
                </Flex>
              ) : (
                tab.label
              )}
            </Tab>
          ))}
      </TabList>
      {customHeader}
      <TabPanels {...tabPanel}>
        {tabs.map((tab) => (
          <TabPanel py={2} px={0} key={tab.abbreviation}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
