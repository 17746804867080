import { hexToRgb } from '../hexToRgb';

export const colors = {
  pbBlack: {
    '100': '#1F1E25',
    '200': '#353535',
  },
  pbGray: {
    '30': '#AFAEB3',
    '40': '#A7B4C3',
    '50': '#f5f8fb',
    '60': '#fafafa',
    '70': '#EEF1F4',
    '80': '#F8F9FC',
    '90': '#E7E3E3',
    F4: '#F4F4F4',
    '100': '#ECECED',
    '110': '#E2E2E8',
    '150': '#8899AD',
    '151': '#8C9CB0',
    '200': '#5b7594',
    '250': '#CDCBD7',
    '300': '#C9C8D7',
    '350': '#B0ACC6',
    '390': '#ADB9C9',
    '400': '#979797',
    '500': '#5f5e67',
    '700': '#37373b',
    '800': '#ABA9BD',
  },
  pbBlue: {
    '100': '#9B99B4',
    '200': '#5b7594',
    '205': `rgba(${hexToRgb('#5b7594')}, 0.5)`,
    '500': '#0076FF',
    '510': `rgba(${hexToRgb('#0076FF')}, 0.10)`,
    '800': '#02254F',
    '850': '#00285780',
  },
  pbRed: {
    '300': '#FE4901',
    '500': '#FE011F',
  },
  pbGreen: {
    '600': '#229651',
    '500': '#00743580',
    '400': '#6ABB11',
    '300': '#36C678',
    '310': '#36C6781A',
    '200': '#EAF9F1',
  },
  pbYellow: {
    '500': '#FFC205',
    '510': '#FFC2051A',
  },
  pbPurple: {
    '200': '#F3E7FE',
    '400': '#36006580',
    '500': '#9013FE',
    '505': '#9013FE0D',
    '510': '#9013FE1A',
    '700': '#9013FE',
    '710': '#9013FE1A',
    '800': '#490386',
  },
};
