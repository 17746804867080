import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { EmailExists } from './apiTypes';

export const useEmailExists = (
  email: string,
  opts?: QueryObserverOptions<EmailExists>,
): UseQueryResult<EmailExists> => {
  return useQuery<EmailExists>(
    ['email-exists', email],
    () => pbAgentFetcher(`v1/valid-exists-email/${email}`),
    {
      enabled: email !== '',
      refetchOnWindowFocus: false,
      ...opts,
    },
  );
};
