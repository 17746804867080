import React, { ReactElement, useContext } from 'react';
import { Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import { SearchType } from '../../../../services/apiTypes';
import { SectionSubTitle } from '../../../Global/SectionSubTitle';
import { RoundedBoxSection } from '../../../Global/RoundedBoxSection';
import { SearchDetailsBox } from './SearchDetailsBox';
import { NewSearchMenu } from './NewSearchMenu';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import { useClient } from 'services/hooks/api/clients/useClient';
import { useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';

export const SearchDetails = (): ReactElement => {
  const authContext = useContext(AuthContext);
  const { data } = useAgentProfile(authContext?.sessionId);
  const { clientId } = useParams<ClientRouteParams>();

  const { data: clientData } = useClient(clientId);

  if (!clientData) return <Skeleton />;

  const { searches } = clientData;

  const mySavedSearches = searches.filter(
    (search) => search.created_by === data?.user_id,
  );
  const mySavedSearchesTypes = [
    ...new Set(mySavedSearches.map((search) => search.search.ad_type)),
  ] as SearchType[];
  const searchesToRender = mySavedSearches.length ? mySavedSearches : searches;
  return (
    <RoundedBoxSection hasBorder>
      <Flex
        alignItems={{ base: 'flex-start', sm: 'center' }}
        justifyContent={'space-between'}
        mb={3}
        flexDirection={{ base: 'column', sm: 'row' }}
        minH={'38px'}
      >
        <SectionSubTitle mt={1}>Search Details</SectionSubTitle>

        <NewSearchMenu
          searchTypes={['rental', 'sale']}
          existingSearchTypes={mySavedSearchesTypes}
        />
      </Flex>

      <VStack
        spacing={{ base: 2, xl: 5 }}
        alignItems={'stretch'}
        minHeight={100}
      >
        {!searchesToRender.length && (
          <Text ml={{ xl: 3 }}>No saved searches yet</Text>
        )}
        {searchesToRender.map((search) => (
          <SearchDetailsBox
            search={search}
            clientId={clientId}
            key={search.search_id}
          />
        ))}
      </VStack>
    </RoundedBoxSection>
  );
};
