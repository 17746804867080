import React, { ReactElement, ReactNode } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';

interface MessageProps {
  message: ReactNode;
  onReply?: () => void;
}

const ListingInquiryMessage = ({
  message,
  onReply,
}: MessageProps): ReactElement => (
  <Flex
    bg={'pbGray.F4'}
    fontSize={14}
    lineHeight={1.4}
    letterSpacing={'tighter'}
    px={4}
    py={3}
    borderRadius={'2xl'}
    borderTopLeftRadius={0}
    justifyContent={'space-between'}
  >
    <Text>{message}</Text>
    {onReply && (
      <Button
        role={'button'}
        variant={'link'}
        h={5}
        onClick={onReply}
        _focus={{ outline: 'none' }}
        minW={'50px'}
      >
        Reply
      </Button>
    )}
  </Flex>
);

export default ListingInquiryMessage;
