import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface RoundedBoxType extends BoxProps {
  isSide?: boolean;
  marginAlignment?: 'none' | 'left' | 'right';
}
export const RoundedBox = ({
  isSide = false,
  marginAlignment = 'none',
  ...props
}: RoundedBoxType): ReactElement => {
  const responsiveSpacing = { base: 3, xl: 5 };

  const internalProps: BoxProps = {
    p: responsiveSpacing,
    ml: marginAlignment === 'left' ? responsiveSpacing : 0,
    mr: marginAlignment === 'right' ? responsiveSpacing : 0,
    w: isSide ? { base: '100%', xl: '380px' } : '100%',
    maxW: isSide ? { base: '100%', xl: '380px' } : '100%',
    as: isSide ? 'aside' : 'div',
  };
  return (
    <Box borderRadius={'lg'} bg={'white'} {...internalProps} {...props}>
      {props.children}
    </Box>
  );
};
