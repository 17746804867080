import { useToast } from '@chakra-ui/react';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import generateSuccessToast from 'services/generateToast';
import { useAFormDeleteFile } from 'services/hooks/api/aform/useAFormMutation';
import { pluralizeWithCount } from 'services/pluralize';

type ApplicationContextType = {
  data: {
    application_form_id: string;
    selectedFiles: string[];
    fieldsWithFiles: string[];
    allFilesAreSelected: boolean;
    isDeleting: boolean;
  };
  actions: {
    handleSelectFile: (file: string) => void;
    setFieldsWithFiles: (fields: string[]) => void;
    toggleAllFilesSelected: () => void;
    setApplication_form_id: (application_form_id: string) => void;
    deleteMultipleFiles: () => void;
  };
};
type Props = {
  children: ReactNode;
};

const applicationContextDefaultValues: ApplicationContextType = {
  data: {
    application_form_id: '',
    selectedFiles: [],
    fieldsWithFiles: [],
    allFilesAreSelected: false,
    isDeleting: false,
  },
  actions: {
    handleSelectFile: (file: string) => file,
    setFieldsWithFiles: (fields: string[]) => fields,
    toggleAllFilesSelected: () => null,
    setApplication_form_id: (application_form_id: string) =>
      application_form_id,
    deleteMultipleFiles: () => null,
  },
};

const ApplicationContext = createContext<ApplicationContextType>(
  applicationContextDefaultValues,
);

export function useApplicationContext(): ApplicationContextType {
  return useContext(ApplicationContext);
}

export function ApplicationProvider({ children }: Props): ReactElement {
  const toast = useToast();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [fieldsWithFiles, setFieldsWithFiles] = useState<string[]>([]);
  const [application_form_id, setApplication_form_id] = useState<string>('');

  const { mutateAsync: deleteFile, isLoading: isDeleting } =
    useAFormDeleteFile();

  const handleSelectFile = (fieldName: string) => {
    const newSelectedFiles = selectedFiles.includes(fieldName)
      ? selectedFiles.filter((file) => file !== fieldName)
      : [...selectedFiles, fieldName];
    setSelectedFiles(newSelectedFiles);
  };

  const allFilesAreSelected = useMemo(() => {
    if (!fieldsWithFiles.length) return false;
    return selectedFiles.length === fieldsWithFiles.length;
  }, [fieldsWithFiles.length, selectedFiles.length]);

  const toggleAllFilesSelected = () => {
    setSelectedFiles(allFilesAreSelected ? [] : fieldsWithFiles);
  };

  const deleteMultipleFiles = useCallback(async () => {
    await deleteFile({
      application_form_id,
      fieldName: selectedFiles.map((file) => file).join(','),
    });
    setSelectedFiles([]);
    toast(
      generateSuccessToast(
        `${pluralizeWithCount(selectedFiles.length, 'File')} deleted`,
      ),
    );
  }, [application_form_id, deleteFile, selectedFiles, toast]);

  const value = {
    data: {
      application_form_id,
      selectedFiles,
      fieldsWithFiles,
      allFilesAreSelected,
      isDeleting,
    },
    actions: {
      handleSelectFile,
      setFieldsWithFiles,
      toggleAllFilesSelected,
      setApplication_form_id,
      deleteMultipleFiles,
    },
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
}
