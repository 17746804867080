import { SearchListingResponse, StringOrNumber } from '../../../apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

export const useCompsListing = (
  listingId?: StringOrNumber,
): UseQueryResult<SearchListingResponse[]> => {
  return useQuery<SearchListingResponse[]>(
    ['comps-listing', listingId],
    () => pbAgentFetcher(`v1/comps-listing/${listingId}`),
    {
      enabled: !!listingId,
      refetchOnWindowFocus: false,
      // While this query can return 404 intentional we would like to prevent to much retries if so
      retry: 0,
    },
  );
};
