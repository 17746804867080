import { FormControl, FormControlProps, FormLabel } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { radioCheckboxRow } from '../../../Form/styles';

interface FormRowProps extends FormControlProps {
  label?: string;
  isLabelBlack?: boolean;
}

const FormRow = ({
  children,
  label,
  isLabelBlack,
  ...props
}: FormRowProps): ReactElement => {
  return (
    <FormControl
      justifyContent={'space-between'}
      {...radioCheckboxRow}
      {...props}
    >
      {label && (
        <FormLabel
          color={isLabelBlack ? '#000' : 'pbGray.500'}
          fontSize={16}
          letterSpacing={'tight'}
        >
          {label}
        </FormLabel>
      )}
      {children}
    </FormControl>
  );
};

export default FormRow;
