import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query';
import { SearchListingsAdvice } from '../../../apiTypes';
import { pbAgentFetcher } from '../../../fetcher';
import { stringifyParamsAccordingToAPI } from '../../../stringifyParamsAccordingToAPI';

export const useSearchListingsAdvice = (
  searchParams: URLSearchParams,
  opts?: QueryObserverOptions<SearchListingsAdvice>,
): UseQueryResult<SearchListingsAdvice> => {
  const stringifiedParams = stringifyParamsAccordingToAPI(searchParams);

  return useQuery<SearchListingsAdvice>(
    ['search-listings-advice', stringifiedParams],
    () => pbAgentFetcher(`v1/search-listings-advice?${stringifiedParams}`),
    {
      enabled: !!stringifiedParams,
      ...opts,
    },
  );
};
