import {
  MutationFunction,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';
import { pbAgentFetcher } from '../../fetcher';
import { SearchParams, StringOrNumber } from '../../apiTypes';

export type SavedSearchesMutationProps = {
  clientId: StringOrNumber;
  searchId?: StringOrNumber;
  searchParams: SearchParams;
};

export const saveClientSearch = (
  { clientId, searchParams, searchId }: SavedSearchesMutationProps,
  method = 'post',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const url = searchId
    ? `v1/clients-saved-searches/${clientId}/${searchId}`
    : `v1/clients-saved-searches/${clientId}`;

  return pbAgentFetcher(url, {
    method,
    body: JSON.stringify(searchParams),
  });
};

export const useSavedSearchesMutation = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(saveClientSearch as MutationFunction, {
    onSuccess: () => queryClient.invalidateQueries('clients'),
  });
};

export const useSavedSearchesMutationPut = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(
    ((v) =>
      saveClientSearch(
        v as SavedSearchesMutationProps,
        'put',
      )) as MutationFunction,
    {
      onSuccess: () => queryClient.invalidateQueries('clients'),
    },
  );
};

export const useSavedSearchesMutationDelete = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(
    ((v) =>
      saveClientSearch(
        v as SavedSearchesMutationProps,
        'delete',
      )) as MutationFunction,
    {
      onSuccess: () => queryClient.invalidateQueries('clients'),
    },
  );
};
