/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { Dispatch, FC, createContext, useContext, useReducer } from 'react';
import { consoleAssert } from 'services/consoleAssert';
import { CustomFile } from '../../components/MyListings/MyListingMedia/services/types';
import { MyListingsAction } from './actions';
import { myListingsReducer } from './reducer';
import { SearchType } from '../../services/apiTypes';
import { ChildrenProps } from 'types/ChildrenProps';

export type MyListingsState = {
  listingId?: string;
  adType: SearchType;
  uploadMediaMode: boolean;
  filesToUpload: CustomFile[];
};

export type MyListingsContextValue =
  | (MyListingsState & {
      dispatch: Dispatch<MyListingsAction>;
    })
  | undefined;

export const MyListingsContext =
  createContext<MyListingsContextValue>(undefined);

export const useMyListingsContext = (): MyListingsState & {
  dispatch: Dispatch<MyListingsAction>;
} => {
  const context = useContext(MyListingsContext);

  consoleAssert(
    context,
    'Tried to use MyListingsContext without using a context provider first.',
  );

  return context;
};

export const MyListingsContextProvider: FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [state, dispatch] = useReducer(myListingsReducer, {
    uploadMediaMode: false,
    filesToUpload: [],
    adType: 'rental',
  });

  return (
    <MyListingsContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </MyListingsContext.Provider>
  );
};
