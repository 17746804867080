import { SearchListingResponse } from './apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { stringifyParamsAccordingToAPI } from '../../../stringifyParamsAccordingToAPI';

export const listingsFetcher = (
  searchParams: string,
): Promise<SearchListingResponse[]> =>
  pbAgentFetcher(`v1/search-listings?${searchParams}`);

export const useSearchListings = (
  searchParams: URLSearchParams,
  page: number,
): UseQueryResult<SearchListingResponse[]> => {
  const stringifiedParams = stringifyParamsAccordingToAPI(searchParams);

  return useQuery<SearchListingResponse[]>(
    ['search-listings', stringifiedParams, page],
    () => listingsFetcher(stringifiedParams),
    { enabled: !!searchParams.get('ad_type') },
  );
};
