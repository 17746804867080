import { useToast } from '@chakra-ui/react';
import {
  IndexedDraft,
  findDraftByMessageId,
  saveDraftIntoIndexedDB,
  updateIndexedDraft,
} from 'services/draftsDb';
import { GmailMessagesPostBody } from './api/gmail/apiTypes';
import { useDraftsCreate } from './api/gmail/useDraftsCreate';
import { useDraftsUpdate } from './api/gmail/useDraftsUpdate';
import { isEqual } from 'lodash';

interface ReturnType {
  isSaving: boolean;
  upsertDraft: (
    body: GmailMessagesPostBody,
    threadId?: string,
    messageId?: string,
  ) => void;
}

const draftFieldsHasChanged = (
  draft: IndexedDraft,
  body: GmailMessagesPostBody,
) => {
  if (!draft) return true;

  const { to_email, to_name, subject, body: draftBody } = draft;

  const {
    to,
    to_name: bodyTo_name,
    subject: bodySubject,
    body: bodyBody,
  } = body;

  return (
    !isEqual(to_email?.split(', '), to) ||
    !isEqual(to_name?.split(', '), bodyTo_name) ||
    subject !== bodySubject ||
    draftBody !== bodyBody
  );
};

const useDraftUpsert = (formOpen: boolean): ReturnType => {
  const toast = useToast();
  const { mutateAsync: createNewDraft, isLoading: isCreatingDraft } =
    useDraftsCreate();
  const { mutateAsync: updateDraft, isLoading: isUpdatingDraft } =
    useDraftsUpdate();

  if (!formOpen) {
    return {
      isSaving: false,
      upsertDraft: async () => null,
    };
  }

  const upsertDraft: ReturnType['upsertDraft'] = async (
    body,
    threadId = '',
    messageId = '',
  ) => {
    try {
      const draft = await findDraftByMessageId(messageId);

      if (draft?.draftId && draftFieldsHasChanged(draft, body)) {
        const {
          id,
          message: { id: draftMessageId },
        } = await updateDraft({
          draftId: draft.draftId,
          body: {
            ...body,
            body: body.body,
          },
        });

        await updateIndexedDraft(draft.draftId, {
          draftId: id,
          draftMessageId,
          threadId,
          messageId,
          body: body.body,
          to_email: body.to.join(', '),
          to_name: body.to_name?.join(', '),
          subject: body.subject,
        });

        toast({
          description: 'Draft saved',
          status: 'info',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
        });
      }

      if (!draft?.draftId) {
        const {
          id,
          message: { id: draftMessageId },
        } = await createNewDraft({ body });

        await saveDraftIntoIndexedDB({
          draftId: id,
          draftMessageId,
          threadId,
          messageId,
          body: body.body,
          to_email: body.to.join(', '),
          to_name: body.to_name?.join(', '),
          subject: body.subject,
        });

        toast({
          description: 'Draft saved',
          status: 'info',
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isSaving = isCreatingDraft || isUpdatingDraft;

  return {
    isSaving,
    upsertDraft,
  };
};

export default useDraftUpsert;
