import {
  CheckboxGroup,
  FormControl,
  FormLabel,
  SimpleGrid,
  useCheckboxGroup,
} from '@chakra-ui/react';

import React, { ReactElement } from 'react';
import { CheckboxItem } from './TriggerCard';

type Props = {
  label?: string;
  onChange: (value: (string | number)[]) => void;
  value: (string | number)[];
  columns?: number;
  options: string[] | null | undefined;
  size?: 'small' | 'normal';
};

export const CheckboxesColumn = ({
  label,
  onChange,
  value,
  options,
  columns = 1,
  size = 'normal',
}: Props): ReactElement => {
  const { getCheckboxProps } = useCheckboxGroup({
    onChange,
    value,
  });

  return (
    <FormControl flexWrap={{ base: 'wrap', md: 'nowrap' }}>
      {label && <FormLabel as={'h3'}>{label}</FormLabel>}

      {options && (
        <CheckboxGroup defaultValue={value} onChange={onChange}>
          <SimpleGrid
            mt={{ base: 2, md: 5 }}
            columnGap={1}
            rowGap={{ base: 2, md: '14px' }}
            columns={columns}
            align={'left'}
          >
            {options.map((v) => {
              const checkbox = getCheckboxProps({
                value: v,
                size,
              });

              return (
                <CheckboxItem key={v} {...checkbox}>
                  {v}
                </CheckboxItem>
              );
            })}
          </SimpleGrid>
        </CheckboxGroup>
      )}
    </FormControl>
  );
};
