import { Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { useMessage } from 'contexts/MessageContext';
import { ForwardIcon, PaperPlaneIcon } from 'icons/icons';
import React, { ReactElement } from 'react';

export const MessageActions = (): ReactElement => {
  const {
    data: {
      message: { attachments, has_attachments },
      areAttachmentsVisible,
      formState: { isOpen },
    },
    actions: { openMessageForm, setAreAttachmentsVisible },
  } = useMessage();

  const toggleButtonMessage = `${
    areAttachmentsVisible ? 'Hide' : 'Show'
  } attachments (${attachments.length})`;

  return (
    <Flex justifyContent={has_attachments ? 'space-between' : 'flex-end'}>
      {has_attachments ? (
        <Button
          variant={'link'}
          onClick={() => setAreAttachmentsVisible.toggle()}
        >
          {toggleButtonMessage}
        </Button>
      ) : (
        ''
      )}
      <ButtonGroup
        visibility={isOpen ? 'hidden' : 'visible'}
        spacing={2}
        alignSelf={'flex-end'}
      >
        <Button
          variant={'blueLight'}
          size={'sm'}
          leftIcon={<ForwardIcon fontSize={16} />}
          onClick={() => openMessageForm('forward')}
        >
          Forward
        </Button>
        <Button
          variant={'blueLight'}
          size={'sm'}
          leftIcon={<PaperPlaneIcon fontSize={16} />}
          onClick={() => openMessageForm('replyToAll')}
        >
          Reply to all
        </Button>
        <Button
          variant={'blueLight'}
          size={'sm'}
          leftIcon={<PaperPlaneIcon fontSize={16} />}
          onClick={() => openMessageForm('reply')}
        >
          Reply
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
