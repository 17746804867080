/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Center, Link, Spinner } from '@chakra-ui/react';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useMutation } from 'react-query';
import { Link as ReactRouterLink } from 'react-router-dom';
import { LoginGoogleResponse } from 'services/hooks/api/agent-profile/apiTypes';
import { AuthContext } from '../contexts/AuthContext/AuthContext';
import { consoleAssert } from '../services/consoleAssert';
import { pbAuthFetcher } from '../services/fetcher';

if (
  process.env.NODE_ENV !== 'production' &&
  !process.env.REACT_APP_GOOGLE_CLIENT_ID
) {
  throw new Error('Please set REACT_APP_GOOGLE_CLIENT_ID variable in .env');
}

const SCOPE = 'https://mail.google.com/';

export const LoginPage = (): ReactElement => {
  const authContext = useContext(AuthContext);

  consoleAssert(authContext, 'Use <AuthContext> before using <LoginPage>');

  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState<boolean>();

  const loginMutation = useMutation<LoginGoogleResponse>(
    // @ts-ignore FIXME: Look into this type error
    (code: string) =>
      pbAuthFetcher('v1/login-google', {
        method: 'post',
        body: JSON.stringify({ code }),
      }),
    {
      onSuccess: (data) => {
        authContext.onLogin(data);
      },
      onError: () => {
        // TODO: Handle the error
      },
    },
  );

  const onGoogleLoginFailure = (e: Error) => {
    // TODO: handle google login error
    console.error('google login failed', e);
  };

  useEffect(() => {
    if (authContext.sessionId) {
      setAlreadyLoggedIn(true);
      return;
    }
    setAlreadyLoggedIn(false);
  }, [authContext.sessionId]);

  // Displaying a link to go back to home is way easier than dealing
  // redirects.
  if (alreadyLoggedIn) {
    return (
      <Center h={'100vh'}>
        <Box textAlign={'center'}>
          <Box>You&apos;re already logged in</Box>
          {/* TODO: Write styles for a link */}
          <Link as={ReactRouterLink} to={'/'}>
            Go to homepage
          </Link>
        </Box>
      </Center>
    );
  }

  if (alreadyLoggedIn === undefined) {
    return (
      <Center w={'100%'} h={'100vh'}>
        <Spinner />
      </Center>
    );
  }

  return (
    <Center w={'100%'} h={'100vh'}>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
        scope={SCOPE}
        onSuccess={({ code }) => loginMutation.mutate(code)}
        onFailure={onGoogleLoginFailure}
        responseType={'code'}
      />
    </Center>
  );
};
