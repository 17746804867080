import { GmailMessageModal } from 'components/Global/GmailMessageModal';
import { useModal } from 'contexts/ModalContext';
import React, {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Mailbox } from 'scenes/Mailbox';
import {
  GmailDraft,
  GmailMessagesPostBody,
  GmailThread,
} from 'services/apiTypes';
import { useDraftsGetAll } from 'services/hooks/api/gmail/useDraftsGetAll';

import { useGmailThreadsReadState } from 'services/hooks/api/gmail/useGmailMessagesThread';
import {
  useGmailThreads,
  useGmailThreadsDelete,
} from 'services/hooks/api/gmail/useGmailThreads';
import { useGmailTrash } from 'services/hooks/api/gmail/useGmailTrash';

import { ClientRouteParams } from 'types/ClientRouteParams';
import {
  MailboxLocationState,
  MailboxRouteParams,
} from 'types/MailboxRouteParams';

type MailboxContextType = {
  data: {
    currentFolder: string;
    currentThreadId: string;
    selectedThreads: string[];
    threads: GmailThread[];
    areAllThreadsChecked: boolean;
    isDeletingMultipleThreads: boolean;
    isLoadingThreads: boolean;
    isLoadingNextPage: boolean;
    isFetchingThreads: boolean;
    searchValue: string;
    hasNextPage?: boolean;
    currentPage: number | null;
    hasPreviousPageData: boolean;
    isTogglingReadState: boolean;
    canGoToNextPage: boolean;
    areSomeThreadsUnread: boolean;
    isDeletingSelectedThreadsPermanently: boolean;
    drafts: GmailDraft[];
  };
  actions: {
    toggleSelectThread: (threadId: string) => void;
    goBackToThreadsList: () => void;
    setThreads: (threads: GmailThread[]) => void;
    checkAllThreads: () => void;
    deleteSelectedThreads: () => void;
    setSearchValue: (searchValue: string) => void;
    refetchThreads: () => void;
    clearSelectedThreads: () => void;
    fetchNextPage: () => void;
    goToNextPage: () => void;
    goToPreviousPage: () => void;
    markAsReadSelectedThreads: () => void;
    markAsUnreadSelectedThreads: () => void;
    forwardThread: (e: SyntheticEvent, threadId: string) => void;
    deleteSelectedThreadsPermanently: () => void;
    findDraft: (draftMessageId: string) => GmailDraft | null;
  };
};
type Props = {
  children: ReactNode;
  clientEmail?: string;
};

const mailboxContextDefaultValues: MailboxContextType = {
  data: {
    currentFolder: 'INBOX',
    currentThreadId: '',
    selectedThreads: [],
    threads: [],
    areAllThreadsChecked: false,
    isDeletingMultipleThreads: false,
    isLoadingThreads: false,
    isLoadingNextPage: false,
    isFetchingThreads: false,
    searchValue: '',
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPageData: false,
    isTogglingReadState: false,
    canGoToNextPage: false,
    areSomeThreadsUnread: false,
    isDeletingSelectedThreadsPermanently: false,
    drafts: [],
  },
  actions: {
    toggleSelectThread: (threadId: string) => threadId,
    goBackToThreadsList: () => null,
    setThreads: (threads: GmailThread[]) => threads,
    checkAllThreads: () => null,
    deleteSelectedThreads: () => null,
    setSearchValue: (searchValue: string) => searchValue,
    refetchThreads: () => null,
    clearSelectedThreads: () => null,
    fetchNextPage: () => null,
    goToNextPage: () => null,
    goToPreviousPage: () => null,
    markAsReadSelectedThreads: () => null,
    markAsUnreadSelectedThreads: () => null,
    forwardThread: (e, threadId) => threadId,
    deleteSelectedThreadsPermanently: () => null,
    findDraft: () => null,
  },
};

const MailboxContext = createContext<MailboxContextType>(
  mailboxContextDefaultValues,
);

export function useMailbox(): MailboxContextType {
  return useContext(MailboxContext);
}

export const MailboxContextProvider = ({
  children,
  clientEmail,
}: Props): ReactElement => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { openModal, defaultModalProps } = useModal();
  const { folder, threadId } = useParams<MailboxRouteParams>();
  const location = useLocation<MailboxLocationState>();
  const { tab, clientId, clientType } = useParams<ClientRouteParams>();
  const [selectedThreads, setSelectedThreads] = useState<string[]>([]);
  const [threads, setThreads] = useState<GmailThread[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const currentPage = location.state?.page || 1;
  const path = tab
    ? `/clients/${clientType}/${clientId}/${tab}`
    : `/mailbox/${folder}`;

  const { mutate: deleteThreads, isLoading: isDeletingMultipleThreads } =
    useGmailThreadsDelete();

  const {
    markAsRead: {
      mutate: markAsReadMultiple,
      isLoading: isMarkingAsReadMultiple,
    },
    markAsUnread: {
      mutate: markAsUnreadMultipleThreads,
      isLoading: isMarkingAsUnreadMultiple,
    },
  } = useGmailThreadsReadState({ currentFolder: folder });

  const {
    deleteThreadPermanently: {
      mutateAsync: deleteThreadPermanently,
      isLoading: isDeletingSelectedThreadsPermanently,
    },
  } = useGmailTrash();

  const { data: drafts, isLoading: isLoadingDrafts } = useDraftsGetAll();

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGmailThreads(
    {
      label: tab ? 'INBOX' : folder?.toUpperCase(),
      q: tab ? `from:${clientEmail}` : searchValue,
    },
    { enabled: folder !== 'attachments' },
  );

  const hasPreviousPageData = useMemo(() => {
    if (!data?.pages?.length) {
      return false;
    }
    return data.pages[currentPage - 2] !== undefined;
  }, [currentPage, data]);

  useEffect(() => {
    setSelectedThreads([]);
    setSearchValue('');
  }, [folder]);

  useEffect(() => {
    if (!data || !currentPage) {
      return;
    }

    if (data.pages[currentPage - 1]) {
      setThreads(data.pages[currentPage - 1].collection.threads);
    }

    if (data.pages.length === 1) {
      setThreads(data.pages[0].collection.threads);
    }
  }, [currentPage, data, history, isFetching, location.pathname, setThreads]);

  const handleToggleSelectThread = (threadId: string): void => {
    const index = selectedThreads.indexOf(threadId);
    if (index === -1) {
      setSelectedThreads([...selectedThreads, threadId]);
    } else {
      setSelectedThreads(
        selectedThreads.filter((thread) => thread !== threadId),
      );
    }
  };

  const goBackToThreadsList = () =>
    history.push(`/mailbox/${folder || 'inbox'}`);

  const areAllThreadsChecked = useMemo(() => {
    if (!selectedThreads || !selectedThreads.includes(threads[0]?.id)) {
      return false;
    }
    return selectedThreads.length === threads?.length;
  }, [selectedThreads, threads]);

  const areSomeThreadsUnread = useMemo(() => {
    if (!threads || !selectedThreads) {
      return false;
    }
    return threads
      .filter((thread) => selectedThreads.includes(thread.id))
      .some((thread) => !thread.seen);
  }, [selectedThreads, threads]);

  const checkAllThreads = useCallback(
    () =>
      setSelectedThreads(
        areAllThreadsChecked || !threads ? [] : threads.map((t) => t.id),
      ),
    [threads, areAllThreadsChecked],
  );

  const deleteSelectedThreads = useCallback(() => {
    selectedThreads.length &&
      deleteThreads(
        {
          threadsIds: selectedThreads.map((t) => t),
        },
        { onSuccess: () => refetch() },
      );
  }, [deleteThreads, refetch, selectedThreads]);

  const clearSelectedThreads = useCallback(() => setSelectedThreads([]), []);
  const selectedThreadsMessagesIds = useMemo(
    () =>
      threads.reduce((acc, thread) => {
        if (selectedThreads.includes(thread.id)) {
          return [...acc, ...thread.messages.map((m) => m.id)];
        }
        return acc;
      }, [] as string[]),
    [selectedThreads, threads],
  );

  const markAsReadSelectedThreads = useCallback(() => {
    const messagesIds = selectedThreadsMessagesIds;
    markAsReadMultiple(
      { message_ids: messagesIds.join(',') },
      {
        onSuccess: () => {
          setSelectedThreads([]);
        },
      },
    );
  }, [markAsReadMultiple, selectedThreadsMessagesIds]);

  const markAsUnreadSelectedThreads = useCallback(() => {
    const messagesIds = selectedThreadsMessagesIds;

    markAsUnreadMultipleThreads(
      { message_ids: messagesIds.join(',') },
      {
        onSuccess: () => {
          setSelectedThreads([]);
        },
      },
    );
  }, [markAsUnreadMultipleThreads, selectedThreadsMessagesIds]);

  const deleteSelectedThreadsPermanently = useCallback(async () => {
    const promises = Promise.all(
      selectedThreads.map(async (threadId) =>
        deleteThreadPermanently({
          threadId,
        }),
      ),
    );

    await promises;
    refetch();
  }, [deleteThreadPermanently, refetch, selectedThreads]);

  const goToNextPage = () => {
    if (!currentPage) return '';
    if (hasNextPage && !data?.pages[currentPage]) {
      fetchNextPage();
    }
    history.push(path, { page: currentPage + 1 });
  };

  const goToPreviousPage = () => history.push(path, { page: currentPage - 1 });

  const forwardThread = (e: SyntheticEvent, threadId: string) => {
    e.stopPropagation();
    const threadToForward = threads.find((t) => t.id === threadId);

    if (!threadToForward) {
      console.error('Something went wrong, no thread found');
      return;
    }

    const { messages, subject } = threadToForward;

    const initObject: GmailMessagesPostBody = {
      to: [''],
      subject: subject.replace(/^(Re: )?/, 'Fwd: '),
      // TODO: convert html messages to .eml attachment
      body: messages.map((m) => m.email_html).join('\n'),
    };

    openModal(
      <GmailMessageModal
        {...defaultModalProps}
        modalTitle={'Forward thread'}
        initObject={initObject}
      />,
    );
  };

  const canGoToNextPage = useMemo(() => {
    if (isFetchingNextPage) {
      return false;
    }

    if (hasNextPage || data?.pages[currentPage] !== undefined) {
      return true;
    }

    return false;
  }, [currentPage, data?.pages, hasNextPage, isFetchingNextPage]);

  const refetchThreads = useCallback(() => {
    refetch();
    queryClient.invalidateQueries('gmail-labels');
    setSelectedThreads([]);
  }, [queryClient, refetch]);

  const findDraft = useCallback(
    (draftMessageId) => {
      if (!drafts || isLoadingDrafts) {
        return null;
      }

      return (
        drafts.messages.find((draft) => (draft.id = draftMessageId)) || null
      );
    },
    [drafts, isLoadingDrafts],
  );

  const value = {
    data: {
      currentFolder: folder || 'INBOX',
      currentThreadId:
        threadId || mailboxContextDefaultValues.data.currentThreadId,
      selectedThreads,
      threads,
      areAllThreadsChecked,
      isDeletingMultipleThreads,
      isLoadingThreads: isLoading,
      isLoadingNextPage: isFetchingNextPage,
      isFetchingThreads: isFetching || isFetchingNextPage,
      searchValue,
      hasNextPage,
      currentPage,
      hasPreviousPageData,
      isTogglingReadState: isMarkingAsReadMultiple || isMarkingAsUnreadMultiple,
      canGoToNextPage,
      areSomeThreadsUnread,
      isDeletingSelectedThreadsPermanently,
      drafts: drafts?.messages || [],
    },
    actions: {
      toggleSelectThread: handleToggleSelectThread,
      goBackToThreadsList,
      setThreads,
      checkAllThreads,
      deleteSelectedThreads,
      setSearchValue,
      refetchThreads,
      clearSelectedThreads,
      fetchNextPage,
      goToNextPage,
      goToPreviousPage,
      markAsReadSelectedThreads,
      markAsUnreadSelectedThreads,
      forwardThread,
      deleteSelectedThreadsPermanently,
      findDraft,
    },
  };
  return (
    <MailboxContext.Provider value={value}>{children}</MailboxContext.Provider>
  );
};

export const MailboxWithContext = (): ReactElement => (
  <MailboxContextProvider>
    <Mailbox />
  </MailboxContextProvider>
);
