import React, { ReactElement, useState } from 'react';
import { OwnTabs, TabsType } from '../Global/OwnTabs';
import { StringOrNumber } from 'services/apiTypes';
import { Notifications } from './Notifications';
import { Messages } from './Messages';
import { Skeleton, useBreakpointValue } from '@chakra-ui/react';
import { useGmailLabels } from 'services/hooks/api/gmail/useGmailLabels';
import { useGetNotifications } from 'services/hooks/api/notifications/useNotifications';
import { TabLabel } from 'components/Global/TabLabel';
import { Calendar } from './RightSidebar';

export const LeftSidebar = (): ReactElement => {
  const [activeTab, setActiveTab] = useState<StringOrNumber>(0);
  const omit = useBreakpointValue({ base: false, xl: true });

  const { data: labels, isLoading: isLoadingLabels } = useGmailLabels();
  const { data: notifications, isLoading: isLoadingNotifications } =
    useGetNotifications();
  const hasUnreadMessages = isLoadingLabels
    ? false
    : labels?.find((d) => d.id === 'INBOX')?.threadsUnread != 0;
  let hasUnreadNotifications = false;
  if (notifications) {
    hasUnreadNotifications = parseInt(notifications?.pages[0].unreadCount) > 0;
  }

  const tabs: TabsType[] = [
    {
      label: (
        <Skeleton isLoaded={!isLoadingLabels}>
          <TabLabel tabName={'Messages'} showBadge={hasUnreadMessages} />
        </Skeleton>
      ),
      abbreviation: 'messages',
      content: <Messages />,
    },
    {
      label: (
        <Skeleton isLoaded={!isLoadingNotifications}>
          <TabLabel
            tabName={'Notifications'}
            showBadge={hasUnreadNotifications}
          />
        </Skeleton>
      ),
      abbreviation: 'notifications',
      content: <Notifications scrollWrapperId={'sidebarWrapper'} />,
    },
    {
      label: <TabLabel tabName={'Calendar'} />,
      abbreviation: 'calendar',
      content: <Calendar />,
      omit,
    },
  ];

  const onTabChange = (e: StringOrNumber) => setActiveTab(e);

  return (
    <OwnTabs
      tabs={tabs}
      value={activeTab}
      onChange={onTabChange}
      tabListStyles={{ justifyContent: 'space-around' }}
    />
  );
};
