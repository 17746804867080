import { useBreakpointValue } from '@chakra-ui/media-query';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type HeightContextType = {
  availableHeight: number;
  setAvailableHeight: (height: number) => void;
};
type Props = {
  children: ReactNode;
};

const heightContextDefaultValues: HeightContextType = {
  availableHeight: 0,
  setAvailableHeight: () => 0,
};

const HeightContext = createContext<HeightContextType>(
  heightContextDefaultValues,
);

export function useHeight(): HeightContextType {
  return useContext(HeightContext);
}

const calculateHeight = (smallScreen = false): number => {
  const headerHeight =
    document.querySelector('header')?.getBoundingClientRect().height || 65;
  const quickLinksSectionHeight =
    document.querySelector('main > div:first-child')?.clientHeight || 90;

  const verticalPaddingTotal = smallScreen ? (12 + 8) * 2 : (20 + 12) * 2;
  const paddingBottom = 28;
  const tabsHeaderHeight =
    document.querySelector('aside div[rol]')?.getBoundingClientRect().height ||
    48;
  const extraMargin = 6;

  return (
    window.innerHeight -
    (headerHeight +
      verticalPaddingTotal +
      tabsHeaderHeight +
      paddingBottom +
      quickLinksSectionHeight +
      extraMargin)
  );
};

export function HeightContextProvider({ children }: Props): JSX.Element {
  const [height, setHeight] = useState(0);
  const dashboardHasTwoColumns = useBreakpointValue({ base: true, xl: false });

  const memoizedHeight = useMemo(
    () => calculateHeight(dashboardHasTwoColumns),
    [dashboardHasTwoColumns],
  );

  useEffect(() => {
    setTimeout(() => {
      setHeight(memoizedHeight);
    }, 1000);
  }, [memoizedHeight]);

  const onHeightChange = (height: number) => {
    setHeight(height);
  };

  const value = {
    availableHeight: height,
    setAvailableHeight: onHeightChange,
  };

  return (
    <HeightContext.Provider value={value}>{children}</HeightContext.Provider>
  );
}
