import React, { ReactElement } from 'react';
import { Avatar, Flex, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { mediaServer } from '../../../../services/mediaServer';
import { DottedRow } from '../../../DottedRow';
import { Card } from '../../../Global/Card';
import { Client, SearchInfoParts } from '../../../../services/apiTypes';
import { OwnBadge } from 'components/Global/OwnBadge';
import { useHistory } from 'react-router';

const imageSize = 'jango-contact';
interface ClientTileProps {
  isNewClient: boolean;
  name: string;
  avatar: string;
  time: string;
  isActive?: boolean;
  onClick: () => void;
  searchInfoParts?: SearchInfoParts;
}

type SimpleClientTileProps = Pick<Client, 'user_id' | 'photo' | 'name'>;

export const SimpleClientTile = ({
  photo,
  name,
  user_id,
}: SimpleClientTileProps): ReactElement => {
  const history = useHistory();

  const redirectToClient = (): void => {
    history.push(`/clients/rental/${user_id}/details`);
  };
  return (
    <Card
      w={'100%'}
      h={'58px'}
      _hover={{ cursor: 'pointer' }}
      onClick={redirectToClient}
    >
      <Flex alignItems={'center'}>
        <Avatar
          src={mediaServer(photo, imageSize)}
          name={name}
          w={30}
          h={30}
          borderRadius={'md'}
          mr={3}
        />
        <Text fontSize={14}>{name}</Text>
      </Flex>
    </Card>
  );
};

export const ClientTile = ({
  isNewClient,
  name,
  isActive = false,
  onClick,
  avatar,
  time,
  searchInfoParts,
}: ClientTileProps): ReactElement => {
  const hasSearchInfo = searchInfoParts?.line_1 || searchInfoParts?.line_2;

  return (
    <Card
      align={hasSearchInfo ? 'start' : 'center'}
      w={'100%'}
      onClick={onClick}
      position={'relative'}
      isActive={isActive}
      pointerEvents={'all'}
      _hover={{ cursor: 'pointer' }}
    >
      <Avatar
        src={mediaServer(avatar, imageSize)}
        name={name}
        w={38}
        mr={3}
        h={38}
        onClick={onClick}
        borderRadius={'md'}
      />

      <VStack align={'start'} spacing={0} lineHeight={1} onClick={onClick}>
        <Heading
          as={'h3'}
          fontSize={{ base: 13, md: 14, lg: 16 }}
          mb={hasSearchInfo ? 2 : 0}
          color={isActive ? 'pbBlue.500' : 'pbGray.500'}
          wordBreak={'break-word'}
        >
          {name}
        </Heading>

        {searchInfoParts?.line_1 && (
          <DottedRow
            items={searchInfoParts.line_1}
            dotMx={2}
            fontSize={{ base: 12, md: 14 }}
            color={isActive ? 'pbBlue.200' : 'pbGray.400'}
            mb={1}
          />
        )}

        {searchInfoParts?.line_2 && (
          <DottedRow
            items={searchInfoParts.line_2}
            dotMx={2}
            fontSize={{ base: 12, md: 14 }}
            color={isActive ? 'pbBlue.200' : 'pbGray.400'}
            mb={1}
          />
        )}
      </VStack>

      <HStack ml={'auto'}>
        <Text fontSize={12} color={isNewClient ? 'red' : 'pbBlue.100'}>
          {time}
        </Text>
        <OwnBadge hidden={!isNewClient} />
      </HStack>
    </Card>
  );
};
