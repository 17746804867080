import { SearchListingResponse, StringOrNumber } from '../../../apiTypes';
import {
  QueryObserverOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';
import { SavedSearchesMutationProps } from '../useSavedSearches';

export const defaultValues = {
  limit: 9,
  sort_by: 'time_published',
  sort_dir: 'desc',
};
export const useClientsSavedSearch = (
  id?: StringOrNumber,
  opts?: QueryObserverOptions<SearchListingResponse[]>,
): UseQueryResult<SearchListingResponse[]> => {
  const clientParams = new URLSearchParams(objectToUrlParams(defaultValues));
  return useQuery<SearchListingResponse[]>(
    ['clients-saved-search', id],
    () =>
      pbAgentFetcher(
        `v1/clients-saved-search/${id}?${clientParams.toString()}`,
      ),
    {
      enabled: !!id,
      ...opts,
    },
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useClientsSavedSearchDelete = ({
  searchId,
  clientId,
}: Omit<SavedSearchesMutationProps, 'searchParams'>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      pbAgentFetcher(`v1/clients-saved-searches/${clientId}/${searchId}`, {
        method: 'delete',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['clients-saved-search', clientId]);
      queryClient.invalidateQueries(['clients', clientId]);
    },
  });
};
