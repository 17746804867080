import React, { ReactElement, SyntheticEvent } from 'react';
import { HStack, Icon, useDisclosure } from '@chakra-ui/react';
import { ConfirmPopover } from 'components/Global/ConfirmPopover';
import { useMailbox } from 'contexts/MailboxContext';
import { useThread } from 'contexts/ThreadContext';
import {
  ForwardIcon,
  MarkAsReadIcon,
  MarkAsUnreadIcon,
  RestoreIcon,
  TrashBlueIcon,
} from 'icons/icons';
import IconButton from 'components/Global/IconButton';

export const ThreadCardActions = (): ReactElement => {
  const {
    onOpen: onThreadDelete,
    onClose: onThreadDeleteClose,
    isOpen: isThreadDeleteConfirmVisible,
  } = useDisclosure();

  const {
    data: { currentFolder },
    actions: { forwardThread },
  } = useMailbox();

  const {
    data: {
      isTogglingFlag,
      isTogglingReadState,
      isDeletingThread,
      thread: { id: threadId, seen, messages },
    },
    actions: { toggleReadState, handleDeleteThread, handleRestoreThread },
  } = useThread();

  const isUnread = seen !== 1;
  const isDraft = messages.some((message) =>
    message.label_ids.includes('DRAFT'),
  );

  const ThreadDeleteButton = (): ReactElement => {
    if (currentFolder === 'trash') {
      return (
        <ConfirmPopover
          onClose={onThreadDeleteClose}
          isOpen={isThreadDeleteConfirmVisible}
          onConfirm={(e) => handleDeleteThread(e as SyntheticEvent, threadId)}
          confirmLabel={'Delete'}
        >
          <IconButton
            fontSize={16}
            icon={<TrashBlueIcon />}
            aria-label={'delete thread'}
            tooltipMessage={'Delete permanently'}
            isLoading={isDeletingThread}
            onClick={onThreadDelete}
          />
        </ConfirmPopover>
      );
    }

    return (
      <IconButton
        fontSize={16}
        icon={<TrashBlueIcon />}
        aria-label={'delete thread'}
        tooltipMessage={`Delete ${isDraft ? 'permanently' : ''}`}
        isLoading={isDeletingThread}
        onClick={(e) => handleDeleteThread(e, threadId)}
      />
    );
  };

  return (
    <HStack
      visibility={'hidden'}
      _groupHover={{ visibility: 'visible' }}
      spacing={2}
      mr={3}
    >
      {!isDraft && (
        <>
          {currentFolder !== 'trash' ? (
            <IconButton
              icon={<ForwardIcon fontSize={'16px'} />}
              aria-label={'forward thread'}
              tooltipMessage={'Forward as an attachment'}
              onClick={(e) => forwardThread(e, threadId)}
            />
          ) : (
            <IconButton
              icon={<RestoreIcon fontSize={'16px'} />}
              aria-label={'restore thread'}
              tooltipMessage={'Restore thread'}
              isLoading={isTogglingFlag}
              onClick={(e) => handleRestoreThread(e, threadId)}
            />
          )}
        </>
      )}

      {currentFolder !== 'sent' && (
        <IconButton
          fontSize={16}
          icon={
            <Icon
              color={'currentColor'}
              as={isUnread ? MarkAsUnreadIcon : MarkAsReadIcon}
            />
          }
          tooltipMessage={isUnread ? 'Mark as read' : 'Mark as unread'}
          aria-label={'toggle read state'}
          isLoading={isTogglingReadState}
          onClick={(e) => toggleReadState(e, isUnread)}
        />
      )}
      <ThreadDeleteButton />
    </HStack>
  );
};
