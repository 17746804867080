import { Box, Button, Flex, Text, useCheckbox } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { RangeSlider, Value } from './RangeSlider';
import { trigger, triggerBox } from './styles';
import { UseCheckboxProps } from '@chakra-ui/checkbox/dist/types/use-checkbox';
import { RangesValues } from './RangesRow';
import { ChevronIcon } from '../../icons/icons';
import { useOutsideClick } from '../../services/hooks/useOutsideClick';
import { ITrackProps } from 'react-range/lib/types';

export type RangesKey = 'streets' | 'avenues';

export type OnDropdownRangeChange = (key: RangesKey, value: Value) => void;

interface RangesDropdownType
  extends UseCheckboxProps,
    RangesValues,
    Partial<ITrackProps> {
  label: string;
  streets?: string[];
  avenues?: string[];
  hasRangesSelected?: boolean;
  onDropdownRangeChange: OnDropdownRangeChange;
}

export const RangesDropdown = ({
  label,
  streets = [],
  avenues = [],
  streetsValues = [0, streets?.length - 1 || 0],
  avenuesValues = [0, avenues?.length - 1 || 0],
  onDropdownRangeChange,
  hasRangesSelected = false,
  ...props
}: RangesDropdownType): ReactElement => {
  const refBox = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { getInputProps, getCheckboxProps } = useCheckbox(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const isChecked = props.isChecked;
  const [isDropdownActive, setDropdownActivity] = useState(false);

  useOutsideClick(refBox, () => setDropdownActivity(false));

  const [dropdownPlacement, setDropdownPlacement] = useState<'left' | 'right'>(
    'left',
  );

  useEffect(() => {
    if (!dropdownRef?.current) {
      return;
    }

    const b = dropdownRef.current.getBoundingClientRect();
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    if (b.right > windowWidth) {
      setDropdownPlacement('right');
    }
  }, [isDropdownActive]);

  return (
    <Box
      pos={{ base: 'static', md: 'relative' }}
      zIndex={isDropdownActive ? 2 : 1}
      {...triggerBox}
      ref={refBox}
    >
      <Box as={'label'} cursor={'pointer'}>
        <input {...input} />

        <Flex
          {...trigger}
          bg={isChecked ? 'pbBlue.500' : 'pbGray.80'}
          color={isChecked ? 'white' : 'pbGray.150'}
          alignItems={'center'}
          zIndex={1}
          pos={'relative'}
        >
          <Text mr={2} {...checkbox}>
            {label}
            {hasRangesSelected && '*'}
          </Text>

          <Button
            variant={'link'}
            alignSelf={'stretch'}
            size={'none'}
            minW={0}
            onClick={() => setDropdownActivity((prev) => !prev)}
            sx={{
              svg: {
                transform: `rotate(${isDropdownActive ? '180' : '0'}deg)`,
                mx: 1,
              },
              'svg *': {
                fill: isChecked ? 'white' : 'pbGray.150',
              },
            }}
          >
            <ChevronIcon width={'10px'} />
          </Button>
        </Flex>
      </Box>
      {isDropdownActive && (
        <Box
          pos={'absolute'}
          left={{
            base: '0',
            md: dropdownPlacement === 'left' ? '-20px' : 'auto',
          }}
          right={{
            base: '0',
            md: dropdownPlacement === 'right' ? '-20px' : 'auto',
          }}
          top={{ base: 'auto', md: '-20px' }}
          w={{ base: '100%', md: '240px' }}
          p={5}
          pt={{ base: 3, md: '70px' }}
          pb={3}
          mt={{ base: 1, md: 0 }}
          boxShadow={'0px 2px 30px rgba(0, 39, 85, 0.102)'}
          borderRadius={10}
          bg={'white'}
          ref={dropdownRef}
        >
          {streets.length > 1 && (
            <RangeSlider
              label={'Streets'}
              max={streets.length - 1}
              values={streetsValues}
              valuesMap={streets}
              onChange={(v) => onDropdownRangeChange('streets', v)}
            />
          )}

          {avenues.length > 1 && (
            <RangeSlider
              label={'Avenues'}
              max={avenues.length - 1}
              values={avenuesValues}
              valuesMap={avenues}
              onChange={(v) => onDropdownRangeChange('avenues', v)}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
