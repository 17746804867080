import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const TwoInputsRow = ({
  children,
  ...props
}: SimpleGridProps): ReactElement => (
  <SimpleGrid columns={{ base: 1, sm: 2 }} gap={2} {...props}>
    {children}
  </SimpleGrid>
);
