import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { colors } from './colors';
import { components } from './components';

export const borderRadius = {
  none: '0',
  sm: '2px',
  base: '5px',
  md: '8px',
  lg: '10px',
  xl: '15px',
  '2xl': '20px',
  '3xl': '30px',
  full: '50%',
};

const letterSpacings = {
  area: '-.33px',
  tight: '-.43px',
  tighter: '-.58px',
};

const styles = {
  global: {
    body: {
      bg: 'pbGray.50',
      color: 'pbBlack.100',
    },

    '#root': {
      overflowX: 'hidden',
    },

    '.notImplemented': {
      cursor: 'not-allowed',
      opacity: 0.4,
      boxShadow: '0 0 5px 0 red',
    },
  },
};

const fonts = {
  heading: 'BwNistaInternational',
  body: 'BwNistaInternational',
};

const breakpoints = createBreakpoints({
  sm: '425px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  // Base size from client's design.
  '2xl': '1620px',
});

export const theme = extendTheme({
  colors,
  styles,
  borderRadius,
  fonts,
  components,
  letterSpacings,
  breakpoints,
});
