import { Box, HStack, Skeleton, SkeletonText } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const ClientDetailsSkeleton = (): ReactElement => (
  <Box
    w={'100%'}
    minH={'500px'}
    borderRadius={'lg'}
    flexGrow={1}
    pt={{ base: 2, xl: 5 }}
    px={{ base: 2, xl: 5 }}
    pb={2}
  >
    <HStack>
      <SkeletonText height={'70px'} w={'75%'} />
      <SkeletonText height={'70px'} w={'25%'} />
    </HStack>

    <HStack my={5}>
      <SkeletonText height={'70px'} w={'75%'} />
      <SkeletonText height={'70px'} w={'25%'} />
    </HStack>

    <HStack my={10}>
      <Skeleton height={'300px'} w={'75%'} />
      <Skeleton h={'300px'} w={'25%'} />
    </HStack>

    <HStack flexGrow={1}>
      <Skeleton minHeight={'600px'} w={'100%'} />
    </HStack>
  </Box>
);
