import { UseQueryResult, useQuery } from 'react-query';
import { ClientListingInfo } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

interface UseListingClientsInfoParams {
  listingIds: string[];
  clientId: string;
}

export const useClientListingsInfo = ({
  listingIds,
  clientId,
}: UseListingClientsInfoParams): UseQueryResult<ClientListingInfo[]> =>
  useQuery<ClientListingInfo[]>(
    ['clients-listings-info', clientId, listingIds],
    () =>
      pbAgentFetcher(
        `v1/clients-listings-info/${clientId}?listing_ids=${listingIds.join(
          ',',
        )}`,
      ),
    {
      staleTime: 1 * 60 * 1000, // 1 minute
    },
  );
