import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { AgentProfile } from './apiTypes';

const updateAgent = async (agent?: Partial<AgentProfile>) => {
  const data = JSON.stringify(agent);

  return pbAgentFetcher('v1/agent-profile', {
    body: data,
    method: 'PUT',
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAgentProfilePut = (
  agent?: Partial<AgentProfile>,
  options?: UseMutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => updateAgent(agent), {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries('agent-profile');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
    },
  });
};
