const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmailValid = (email?: string): boolean =>
  email ? emailPattern.test(email) : false;

// react-hook-form validation
export const validationPatterns = {
  email: {
    value: emailPattern,
    message: 'Invalid email address',
  },
};
