import { Input, InputProps } from '@chakra-ui/input';
import React, { forwardRef } from 'react';

export type BaseInputProps = InputProps;

const BaseInput: React.FC<BaseInputProps> = forwardRef(
  (
    {
      placeholder,
      onChange,
      value,
      name,
      isRequired,
      isDisabled,
      ...props
    }: BaseInputProps,
    ref: React.LegacyRef<HTMLInputElement>,
  ) => (
    <Input
      ref={ref}
      placeholder={placeholder}
      _placeholder={{ color: 'gray.500' }}
      onChange={onChange}
      required={isRequired}
      isDisabled={isDisabled}
      value={value}
      name={name}
      minH={'50px'}
      {...props}
    />
  ),
);

BaseInput.displayName = 'BaseInput';

export { BaseInput };
