import React, { ReactElement } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Flex, Link, SkeletonText, Text } from '@chakra-ui/react';
import { ImageWithPlaceholder } from 'components/Global/ImageWithPlaceholder';
import { mediaServer } from '../../services/mediaServer';
import { DottedRow } from '../DottedRow';
import dayjs from 'dayjs';
import { ListingInquiryProps } from '../../services/apiTypes';
import { useClient } from 'services/hooks/api/clients/useClient';
import { responsiveImageHeight, responsiveImageWidth } from '../../constants';
import { ConditionalLink } from 'components/Global/ConditionalLink';
import { InfoRowTextElement } from 'components/Global/InfoRow';
import { pluralize } from 'services/pluralize';
import { currencyFormat } from 'services/currencyFormat';
import { useModal } from 'contexts/ModalContext';
import ReplyModal from './ReplyModal';
import AdTypeLabel from 'components/Global/AdTypeLabel';
import SenderDetails from 'components/Global/SenderDetails';
import ListingInquiryMessage from './ListingInquiryMessage';

type Props = {
  inquiry: ListingInquiryProps;
};

export const ListingInquiry = ({ inquiry }: Props): ReactElement => {
  const {
    address,
    unit,
    last_message_time,
    src_type,
    last_message: { message },
    listing,
    listing_id,
    client_id,
    ad_type,
  } = inquiry;

  const { openModal, defaultModalProps } = useModal();

  const addressString = listing
    ? `${listing?.address}, ${listing.unit}`
    : `${address}, ${unit}`;
  const { data: client, isLoading: clientLoading } = useClient(client_id);

  const { size, price, bathrooms, bedrooms } = listing || {};
  const lastMessageTimeFormatted = dayjs(last_message_time * 1000).format(
    'MM/DD/YY h:mm a',
  );

  return (
    <Flex direction={'column'}>
      <Flex
        alignItems={{ md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
      >
        {/* image */}
        <ConditionalLink
          to={`/listing/${listing_id}/details`}
          condition={!!listing_id}
        >
          <Flex
            position={'relative'}
            direction={'column'}
            justifyContent={'flex-end'}
            w={responsiveImageWidth}
            h={responsiveImageHeight}
            maxH={180}
            overflow={'hidden'}
            borderRadius={'lg'}
          >
            <ImageWithPlaceholder
              src={
                listing?.photo_featured && mediaServer(listing.photo_featured)
              }
              fallbackTranscode={'wmap'}
              position={'absolute'}
              objectFit={'cover'}
              fit={'cover'}
              boxSize={'100%'}
            />
          </Flex>
        </ConditionalLink>

        {/* listing details */}
        <Flex
          direction={'column'}
          alignItems={'flex-start'}
          pl={{ base: 0, md: 3, '2xl': 7 }}
          flex={1}
          mt={{ base: 4, md: 0 }}
          ml={3}
        >
          {ad_type && <AdTypeLabel ad_type={ad_type} />}

          <Text fontWeight={700} fontSize={18} py={{ base: 2, '2xl': 3 }}>
            {addressString}
          </Text>

          <DottedRow
            fontSize={14}
            items={[
              <InfoRowTextElement
                key={'price'}
                number={`${currencyFormat(price)}`}
              />,
              <InfoRowTextElement
                key={'bathrooms'}
                label={pluralize(bathrooms || 0, 'Bath')}
                number={bathrooms}
              />,
              <InfoRowTextElement
                key={'bedrooms'}
                label={pluralize(bedrooms || 0, 'Bed')}
                number={bedrooms}
              />,
              <InfoRowTextElement key={'size'} label={'sqft'} number={size} />,
            ].filter(Boolean)}
            dotMx={2}
          />

          {!!listing_id && (
            <Link
              as={ReactRouterLink}
              to={`/listing/${listing_id}/details`}
              mt={3}
              color={'pbBlue.500'}
            >
              <Text fontSize={14} fontWeight={700}>
                Go to listing details
              </Text>
            </Link>
          )}
        </Flex>
      </Flex>

      {/* message */}
      <Flex mt={5}>
        <SkeletonText isLoaded={!clientLoading} w={'100%'}>
          {client && (
            <SenderDetails
              client={client}
              adType={ad_type || 'rental'}
              lastMessageTime={lastMessageTimeFormatted}
              inquirySource={src_type}
            />
          )}
        </SkeletonText>
      </Flex>

      {client && (
        <ListingInquiryMessage
          message={message}
          onReply={() =>
            openModal(
              <ReplyModal
                {...defaultModalProps}
                inquiry={inquiry}
                client={client}
              />,
            )
          }
        />
      )}
    </Flex>
  );
};
