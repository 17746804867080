import React, { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const SectionHeading: FC<TextProps> = ({ children, ...rest }: TextProps) => {
  return (
    <Text
      fontSize={'20px'}
      fontWeight={'bold'}
      mb={{ base: 2, md: 5, xl: '30px' }}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default SectionHeading;
