import React, {
  ChangeEvent,
  ChangeEventHandler,
  ReactElement,
  useCallback,
} from 'react';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import FormRow from './FormRow';
import { Paperwork } from '../../../../services/hooks/api/deals/apiTypes';
import { useDealSheetsFile } from '../../../../services/hooks/api/deals/useDealSheetsFile';

interface FileInputProps {
  fileLabel: string;
  value?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const FileInput = ({
  fileLabel,
  onChange,
  value,
  isDisabled,
  isLoading,
}: FileInputProps) => (
  <Box>
    <FormLabel htmlFor={fileLabel.replace(' ', '_').toLowerCase()}>
      <Flex
        w={'100%'}
        justify={'space-between'}
        align={'center'}
        px={2}
        pl={4}
        py={2}
        borderWidth={1}
        borderRadius={'lg'}
        color={!value ? 'pbBlue.205' : 'pbBlue.200'}
        opacity={isDisabled ? 0.5 : 1}
        pointerEvents={isDisabled ? 'none' : 'all'}
      >
        <Text fontSize={14} letterSpacing={'tight'} fontWeight={'normal'}>
          {value || fileLabel}
        </Text>

        <Button
          as={'span'}
          variant={'blueLight'}
          aria-label={'Attach File'}
          isDisabled={isDisabled}
          isLoading={isLoading}
        >
          Upload
        </Button>
      </Flex>
    </FormLabel>

    <Input
      type={'file'}
      id={fileLabel.replace(' ', '_').toLowerCase()}
      onChange={onChange}
      display={'none'}
    />
  </Box>
);

interface DocumentsProps {
  paperwork?: Paperwork;
  dealId?: string;
  isDisabled?: boolean;
}

export const Documents = ({
  paperwork,
  isDisabled,
  dealId,
}: DocumentsProps): ReactElement => {
  const {
    mutateAsync: uploadFile,
    isLoading: isLoadingFile,
    variables: uploadingFileVariables,
  } = useDealSheetsFile();

  const onFileInputChange = useCallback(
    (
      { target: { files } }: ChangeEvent<HTMLInputElement>,
      fileName: string,
    ) => {
      if (!files?.length || !dealId) {
        return;
      }

      Promise.all(
        [...files].map((file) =>
          uploadFile({
            file,
            fileName,
            dealId,
          }),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dealId],
  );

  return (
    <FormRow label={'Documents'}>
      <VStack align={'stretch'} w={'100%'}>
        <FileInput
          isLoading={
            isLoadingFile && uploadingFileVariables?.fileName === 'lease_pages'
          }
          isDisabled={!dealId || isDisabled}
          fileLabel={'Copy of Lease'}
          onChange={(e) => onFileInputChange(e, 'lease_pages')}
          value={paperwork?.lease_pages.filename}
        />

        <FileInput
          isLoading={
            isLoadingFile && uploadingFileVariables?.fileName === 'dos'
          }
          isDisabled={!dealId || isDisabled}
          fileLabel={'DOS Form'}
          onChange={(e) => onFileInputChange(e, 'dos')}
          value={paperwork?.dos.filename}
        />

        <FileInput
          isLoading={
            isLoadingFile &&
            uploadingFileVariables?.fileName === 'commission_check'
          }
          isDisabled={!dealId || isDisabled}
          fileLabel={'Copy of Check'}
          onChange={(e) => onFileInputChange(e, 'commission_check')}
          value={paperwork?.commission_check.filename}
        />
      </VStack>
    </FormRow>
  );
};
