import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { AttachmentsHeading } from './Heading';
import { useAttachmentsLibrary } from 'contexts/AttachmentLibraryContext';
import { AttachmentsPagination } from './AttachmentsPagination';
import { OwnTabs } from 'components/Global/OwnTabs';
import { AttachmentType } from 'types/AttachmentInLibrary';

const AttachmentLibrary = (): ReactElement => {
  const {
    data: { tabs, attachments, attachmentsFileType },
    actions: { setAttachmentsFileType },
  } = useAttachmentsLibrary();

  const handleTabChange = (tab: string): void => {
    const tabs: { [key: string]: AttachmentType } = {
      'all-files': AttachmentType.ALL,
      documents: AttachmentType.DOCUMENTS,
      pictures: AttachmentType.PICTURES,
    };

    setAttachmentsFileType(tabs[tab]);
  };

  return (
    <Flex flexDir={'column'}>
      <OwnTabs
        tabs={tabs}
        customHeader={<AttachmentsHeading />}
        tabPanelStyles={{ minH: 720 }}
        onChange={(tab) => handleTabChange(tab.toString())}
        value={attachmentsFileType.toLowerCase()}
      />
      {attachments.length > 0 && <AttachmentsPagination />}
    </Flex>
  );
};

export default AttachmentLibrary;
