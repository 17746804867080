import React, { ReactElement, useState } from 'react';
import { EventsCalendar } from './EventsCalendar';
import { EventsList } from './EventsList';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react';
import { CalendarIcon, SearchIcon } from '@chakra-ui/icons';
import { useHeight } from 'contexts/HeightContext';

// Fixme: this is mocked events object, get proper one from client.
const events: AppointmentModel[] = [
  {
    startDate: new Date(2021, 3, 3, 0, 0),
    endDate: new Date(2021, 3, 3, 23, 59),
    title: 'Meeting',
    status: 'confirmed',
  },
  {
    startDate: new Date(2021, 3, 8, 14, 0),
    endDate: new Date(2021, 3, 8, 15, 45),
    title: 'Meeting',
    status: 'confirmed',
  },
  {
    startDate: new Date(2021, 3, 8, 15, 0),
    endDate: new Date(2021, 3, 8, 17),
    title: 'Meeting',
    status: 'confirmed',
  },
  // According to docs (https://devexpress.github.io/devextreme-reactive/react/scheduler/docs/guides/appointments/)
  // we should be able to not provide `endDate` field, but AppointmentModel doesn't allow us to do so.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    startDate: new Date(2021, 3, 9, 13, 5),
    title: 'Meeting',
    status: 'confirmed',
  },
  {
    startDate: new Date(2021, 3, 26, 10, 0),
    endDate: new Date(2021, 3, 26, 11, 30),
    title: 'Meeting',
    status: 'confirmed',
  },
  {
    startDate: new Date(2021, 3, 26, 12, 0),
    endDate: new Date(2021, 3, 26, 13, 0),
    title: 'Go to a gym',
    status: 'unconfirmed',
  },
];

export const Calendar = (): ReactElement => {
  const { availableHeight } = useHeight();
  const [isLessThan1440] = useMediaQuery('(max-width: 1439px)');

  //TODO: Set current `new Date()` as a default state whenever this component will be used
  const [selectedDay, setSelectedDay] = useState(new Date(2021, 3, 26));
  return (
    <Flex flexDir={'column'}>
      <Flex justifyContent={'space-between'} align={'baseline'}>
        {!isLessThan1440 && (
          <Heading as={'h2'} fontSize={16} className={'notImplemented'}>
            My Calendar
          </Heading>
        )}

        <HStack spacing={2} mt={2} ml={'auto'}>
          <Button
            leftIcon={<CalendarIcon />}
            variant={'blueLight'}
            size={'sm'}
            onClick={() => {
              //TODO
            }}
          >
            Add Event
          </Button>

          <IconButton
            aria-label={'search'}
            icon={<SearchIcon boxSize={4} color={'pbBlue.200'} />}
            size={'sm'}
            onClick={() => {
              //TODO
            }}
          />
        </HStack>
      </Flex>

      <Box maxHeight={`${availableHeight}px`} overflowY={'scroll'}>
        <EventsCalendar
          selectedDays={events.map((event) => event.startDate as Date)}
          onDayClick={setSelectedDay}
        />

        <EventsList events={events} selectedDay={selectedDay} />
      </Box>
    </Flex>
  );
};

export const RightSidebar = (): ReactElement => <Calendar />;
