import { useCallback } from 'react';
import { ClientsSentListingBody } from 'services/apiTypes';
import { pluralize, pluralizeWithCount } from 'services/pluralize';
import { useClientsSentListings } from './api/clients/useClientsSentListings';

interface UseSendListingParams {
  onSuccess?: () => void;
  clientIds: string[];
  listingIds: string[];
  subject?: string;
  message?: string;
}

interface UseSendListingReturnType {
  send: ({
    onSuccess,
    clientIds,
    listingIds,
  }: UseSendListingParams) => Promise<void>;
  isSendingListing: boolean;
}
export const useSendListing = (): UseSendListingReturnType => {
  const { mutateAsync: sendEmailAsync, isLoading: isSendingEmail } =
    useClientsSentListings();

  const send = useCallback(
    async ({
      onSuccess,
      clientIds,
      listingIds,
      subject,
      message,
    }: UseSendListingParams) => {
      if (!subject) {
        subject = `New ${pluralize(listingIds.length, 'listing')} available`;
      }

      if (!message) {
        message = `Hi, there ${
          listingIds.length > 1 ? 'are' : 'is'
        } ${pluralizeWithCount(
          listingIds.length,
          'listing',
        )} available for you`;
      }
      const body: ClientsSentListingBody = {
        listing_ids: listingIds,
        user_ids: clientIds,
        message,
        subject,
      };
      sendEmailAsync({ body, clientId: clientIds[0] }).then(onSuccess);
    },
    [sendEmailAsync],
  );

  return { send, isSendingListing: isSendingEmail };
};
