import React, { ReactElement, useEffect, useState } from 'react';
import { ActionModal } from 'components/Global/ActionModal';
import { ModalProps } from 'types/ModalProps';
import { Client, ListingInquiryProps, Option } from 'services/apiTypes';
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Link,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import AdTypeLabel from 'components/Global/AdTypeLabel';
import { Link as ReactRouterLink } from 'react-router-dom';
import SenderDetails from 'components/Global/SenderDetails';
import dayjs from 'dayjs';
import ListingInquiryMessage from './ListingInquiryMessage';
import { SuggestContactsInput } from 'components/Form/SuggestContactsInput';
import { useGmailContextTemplates } from 'services/hooks/api/gmail/useGmailContextTemplates';
import { useListingInquiriesRespond } from 'services/hooks/api/listing-inquiries/useListingInquiriesRespond';
import { QuickResponses } from 'components/Mailbox/ThreadView/components/QuickResponses';
import { uniq } from 'lodash';
import { SectionDivider } from 'components/Global/SectionDivider';

type ReplyModalProps = ModalProps & {
  inquiry: ListingInquiryProps;
  client: Client;
};

const ReplyModal = ({
  isOpen,
  onClose,
  inquiry,
  client,
}: ReplyModalProps): ReactElement => {
  const {
    ad_type,
    address,
    unit,
    listing,
    listing_id,
    last_message_time,
    src_type,
    last_message: { message, ccs },
    inquiry_id,
  } = inquiry;

  const [{ body, cc }, setState] = useState({
    cc: [''],
    body: '',
  });

  const { data: gmailContextTemplates, isLoading: isLoadingQuickResponses } =
    useGmailContextTemplates();
  const { mutate, isLoading: isResponding } = useListingInquiriesRespond();

  const addressString = listing
    ? `${listing?.address}, ${listing.unit}`
    : `${address}, ${unit}`;
  const lastMessageTimeFormatted = dayjs(last_message_time * 1000).format(
    'MM/DD/YY h:mm a',
  );
  const quickResponses = gmailContextTemplates?.map(({ message }) => message);

  const handleBodyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, body: e.target.value }));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      cc: ccs,
    }));
  }, [ccs]);

  const onCcChange = (options: Option[]) => {
    if (!options) {
      return;
    }

    const newRecipients = options.map((o) => o.value);

    setState((prevState) => ({
      ...prevState,
      cc: uniq(newRecipients) as string[],
    }));
  };

  const onInquiryRespond = (inquiryId: string, body: string) => {
    mutate(
      {
        inquiryId,
        params: {
          cc,
          body,
        },
      },
      {
        onSuccess: onClose,
      },
    );
  };

  const handleSubmit = () => !!body && onInquiryRespond(inquiry_id, body);

  return (
    <ActionModal
      size={'3xl'}
      isOpen={isOpen}
      onClose={onClose}
      title={'Reply To Message'}
      submitLabel={'Send'}
      isLoading={isResponding}
      isDisabled={!body}
      status={isResponding ? 'Sending message...' : ''}
      onSubmit={() => handleSubmit()}
      modalFooterProps={{ py: 2 }}
    >
      <Flex flexDir={'column'} alignItems={'flex-start'}>
        <AdTypeLabel ad_type={ad_type || ''} />
        <Flex alignItems={'center'} mt={2}>
          <Text fontWeight={700} fontSize={18}>
            {addressString}
          </Text>

          <Link
            as={ReactRouterLink}
            target={'_blank'}
            rel={'noopener noreferrer'}
            to={`/listing/${listing_id}/details`}
            ml={5}
            color={'pbBlue.500'}
          >
            <Text fontSize={14} fontWeight={700}>
              Go to listing details
            </Text>
          </Link>
        </Flex>
      </Flex>
      <SectionDivider />

      <SenderDetails
        client={client}
        lastMessageTime={lastMessageTimeFormatted}
        inquirySource={src_type}
      />
      <ListingInquiryMessage message={message} />

      <SectionDivider />

      <Flex mt={5} alignItems={'center'}>
        <FormLabel minW={50}>CC:</FormLabel>
        <SuggestContactsInput onChange={onCcChange} value={cc} />
      </Flex>

      <Input
        value={body}
        onChange={handleBodyChange}
        placeholder={'Type your reply'}
        _placeholder={{ color: 'pbGray.500' }}
        py={5}
        px={4}
        mt={4}
        minH={70}
        bg={'pbGray.F4'}
        border={0}
      />

      <Box maxH={150} overflowY={'scroll'}>
        {isLoadingQuickResponses && (
          <Skeleton w={150} h={{ base: 30, lg: 38 }} borderRadius={'md'} />
        )}
        {quickResponses?.length && (
          <QuickResponses
            options={quickResponses}
            overflowX={'visible'}
            flexWrap={'wrap'}
            onClick={(e) => setState((prev) => ({ ...prev, body: e }))}
            py={2}
          />
        )}
      </Box>
    </ActionModal>
  );
};

export default ReplyModal;
