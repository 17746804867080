import React, { ReactElement } from 'react';
import { Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import TableHeading from 'components/Global/Table/TableHead';
import { capitalizeFirstLetter } from 'services/capitalizeFirstLetter';
import { applicationsGridOptions } from 'scenes/Applications';
import { SortDirection } from 'types/SortDirection';
import { useApplicationsContext } from 'contexts/ApplicationsContext';

export const toggleSortDirection = {
  [SortDirection.ASCENDING]: SortDirection.DESCENDING,
  [SortDirection.DESCENDING]: SortDirection.ASCENDING,
};

export const ListLabels = (): ReactElement => {
  const {
    data: { sort },
    actions: { setSort },
  } = useApplicationsContext();
  const labels =
    useBreakpointValue({
      base: ['client', 'type', 'building_address'],
      md: ['client', 'type', 'building_address', 'unit'],
      lg: ['client', 'type', 'building_address', 'unit', 'paid'],
      xl: [
        'client',
        'type',
        'building_address',
        'unit',
        'paid',
        'time_created',
      ],
    }) || [];

  const getLabelText = (label: string) => {
    if (label === 'type') return 'applicant_type';
    if (label === 'client') return 'email';

    return label;
  };

  const onSetSort = (label: string): void => {
    if (label === 'client') {
      return setSort({
        ['email']:
          toggleSortDirection[sort['email'] || SortDirection.DESCENDING],
      });
    }
    setSort({
      [getLabelText(label)]:
        toggleSortDirection[
          sort[getLabelText(label)] || SortDirection.DESCENDING
        ],
    });
  };

  return (
    <SimpleGrid
      px={5}
      mt={10}
      {...applicationsGridOptions}
      alignSelf={'center'}
    >
      {labels.map((label) => (
        <TableHeading
          key={label}
          label={capitalizeFirstLetter(label.replace('_', ' '))}
          flexWidth={''}
          isSortable
          justifiedLeft
          sortDirection={sort[getLabelText(label)]}
          onSort={onSetSort}
          toggleSortDirection={() => onSetSort(label)}
        />
      ))}
      <Box />
    </SimpleGrid>
  );
};
