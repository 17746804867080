import React, { ReactElement } from 'react';
import { Box, LayoutProps, Text } from '@chakra-ui/react';
import { isNumeric } from '../../services/isNumeric';

interface ListingSidebarBoxProps {
  label: string;
  value?: string | number | string[];
  w?: LayoutProps['w'];
}

export const ListingSidebarBox = ({
  label,
  value,
  w,
}: ListingSidebarBoxProps): ReactElement => {
  return (
    <Box
      w={w}
      fontSize={{ base: 12, md: 14 }}
      mb={{ base: 3, sm: 5 }}
      mr={{ base: 3, sm: 5, xl: 10 }}
    >
      <Text color={'pbBlue.100'}>{label}</Text>
      <Text>{!!value || isNumeric(value) ? value : 'N/A'}</Text>
    </Box>
  );
};
