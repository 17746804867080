import React, { FC, ReactElement } from 'react';
import { FilterActiveIcon, FilterInactiveIcon } from 'icons/icons';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { SortDirection } from 'types/SortDirection';

type SortIconProps = {
  sortType: SortDirection;
  label: string;
  isActive: boolean;
  onSort?: () => void;
};

const SortIcon: FC<SortIconProps> = ({
  sortType,
  isActive,
  onSort,
}: SortIconProps) => {
  return (
    <Icon
      boxSize={'11px'}
      transform={
        (sortType === SortDirection.ASCENDING && isActive) ||
        (sortType === SortDirection.DESCENDING && !isActive)
          ? 'rotate(180deg)'
          : undefined
      }
      onClick={onSort}
      as={isActive ? FilterActiveIcon : FilterInactiveIcon}
    />
  );
};

export type TableHeadingProps = {
  label: string;
  flexWidth: string;
  isSortable?: boolean;
  onSort?: (sortDirection: SortDirection) => void;
  sortDirection?: SortDirection;
  justifiedLeft?: boolean;
  toggleSortDirection?: () => void;
};

const TableHeading = ({
  label,
  flexWidth,
  isSortable = false,
  onSort,
  sortDirection,
  justifiedLeft,
  toggleSortDirection,
}: TableHeadingProps): ReactElement => (
  <HStack
    fontSize={'14px'}
    width={flexWidth}
    color={'pbBlue.100'}
    mb={3}
    pl={justifiedLeft ? 0 : 5}
    _last={{
      ml: 'auto',
      mr: 5,
    }}
    _hover={{ cursor: 'pointer' }}
  >
    <Text onClick={toggleSortDirection} userSelect={'none'}>
      {label}
    </Text>
    <VStack spacing={0}>
      {isSortable && !!onSort && (
        <>
          <SortIcon
            sortType={SortDirection.ASCENDING}
            isActive={sortDirection === SortDirection.ASCENDING}
            label={label}
            onSort={() => onSort(SortDirection.ASCENDING)}
          />
          <SortIcon
            sortType={SortDirection.DESCENDING}
            isActive={sortDirection === SortDirection.DESCENDING}
            label={label}
            onSort={() => onSort(SortDirection.DESCENDING)}
          />
        </>
      )}
    </VStack>
  </HStack>
);

export default TableHeading;
