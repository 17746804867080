import React, { ReactElement } from 'react';
import { Button, SimpleGrid, Text } from '@chakra-ui/react';
import { Main } from '../components/Global/Main';
import { PageHeading } from '../components/Global/PageHeading';
import { RoundedBox } from '../components/Global/RoundedBox';
import { PageTitle } from '../components/Global/PageTitle';
import { ClientsList } from '../components/Clients/ClientsList';
import { ClientDashboard } from '../components/Clients/ClientDashboard';
import { AddClientModal } from '../components/Clients/AddClientModal';
import { ClientIcon } from '../icons/icons';
import { useParams } from 'react-router-dom';
import { useModal } from 'contexts/ModalContext';
import { ClientRouteParams } from 'types/ClientRouteParams';

export const Clients = (): ReactElement => {
  const { clientId } = useParams<ClientRouteParams>();
  const { openModal, defaultModalProps } = useModal();

  return (
    <Main>
      <PageHeading justifyContent={'space-between'}>
        <PageTitle>Clients</PageTitle>

        <Button
          fontSize={12}
          leftIcon={<ClientIcon boxSize={5} />}
          variant={'blueLight'}
          onClick={() => openModal(<AddClientModal {...defaultModalProps} />)}
        >
          Add New Client
        </Button>
      </PageHeading>

      <SimpleGrid
        templateColumns={{ base: '100%', lg: '380px minmax(0, 1fr)' }}
        gap={{ base: 3, xl: 5 }}
      >
        <RoundedBox isSide marginAlignment={'right'}>
          <ClientsList />
        </RoundedBox>

        <RoundedBox p={0} order={{ base: -1, lg: 'initial' }}>
          {clientId ? (
            <ClientDashboard />
          ) : (
            <Text
              fontWeight={'bold'}
              pt={{ base: 2, xl: 5 }}
              px={{ base: 2, xl: 5 }}
              pb={2}
            >
              Chose client from the list{' '}
            </Text>
          )}
        </RoundedBox>
      </SimpleGrid>
    </Main>
  );
};
