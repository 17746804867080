import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const PageHeading = ({
  children,
  ...props
}: FlexProps): ReactElement => (
  <Flex
    alignItems={'center'}
    wrap={{ base: 'wrap', xl: 'nowrap' }}
    minHeight={{ md: 90 }}
    py={{ base: 3, md: 0 }}
    direction={{ base: 'column', md: 'row' }}
    {...props}
  >
    {children}
  </Flex>
);
