import React, { ReactElement } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useApplicationsContext } from 'contexts/ApplicationsContext';
import { SortDirection } from 'types/SortDirection';
import { ApplicationListItem } from './ApplicationListItem';
import { ApplicationListSkeleton } from './ApplicationListSkeleton';
import { orderBy } from 'lodash';

interface ListProps {
  loading: boolean;
}

export const List = ({ loading }: ListProps): ReactElement | null => {
  const {
    data: { applications, sort },
  } = useApplicationsContext();

  if (loading) {
    return <ApplicationListSkeleton />;
  }

  if (!applications || !applications.collection.length) {
    return (
      <Text
        width={'100%'}
        as={'h3'}
        fontSize={'md'}
        textAlign={'center'}
        mt={10}
      >
        No applications found
      </Text>
    );
  }

  const sortBy = Object.keys(sort)[0];

  const sortedApplications = orderBy(
    applications.collection.map((application) => ({
      ...application,
      email: application.client.name,
    })),
    [sortBy],
    [sort[sortBy] === SortDirection.ASCENDING ? 'asc' : 'desc'],
  );

  return (
    <VStack spacing={3} flexGrow={1} maxHeight={'100%'}>
      {sortedApplications.map((application) => (
        <ApplicationListItem
          key={application.application_form_id}
          {...application}
        />
      ))}
    </VStack>
  );
};
