import { Avatar, Flex, Heading, Text } from '@chakra-ui/react';
import React, { ReactElement, ReactNode } from 'react';
import { Card } from '../Global/Card';
import { Link } from 'react-router-dom';

interface MessageProps {
  text: ReactNode;
  name: string;
  threadId: string;
  avatar?: string;
  time: string;
  isHighlighted?: boolean;
}

export const Message = ({
  time,
  threadId,
  avatar,
  isHighlighted = false,
  name,
  text,
}: MessageProps): ReactElement => {
  return (
    <Card
      pb={4}
      as={Link}
      // Not easy to cast types from `as` prop to extended component
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      to={{
        pathname: `/mailbox/inbox/${threadId}`,
      }}
    >
      <Avatar src={avatar} name={name} mr={3} />

      <Flex direction={'column'} flex={1}>
        <Flex justifyContent={'space-between'}>
          <Heading
            as={'h3'}
            fontSize={16}
            color={isHighlighted ? 'pbGray.700' : 'pbGray.500'}
          >
            {name}
          </Heading>
          <Text
            as={'span'}
            display={'inline-flex'}
            alignItems={'center'}
            fontWeight={'bold'}
            fontSize={12}
            color={'pbBlue.100'}
            _after={{
              content: isHighlighted && '""',
              display: 'block',
              w: '6px',
              h: '6px',
              borderRadius: 'full',
              bg: 'pbRed.300',
              ml: 2,
            }}
          >
            {time}
          </Text>
        </Flex>

        <Text
          mt={1}
          fontSize={14}
          lineHeight={'20px'}
          fontWeight={isHighlighted ? 'bold' : 'normal'}
          letterSpacing={'tighter'}
        >
          {text}
        </Text>
      </Flex>
    </Card>
  );
};
