import React from 'react';
import { Flex } from '@chakra-ui/react';
import UserAvatar from 'components/Global/User/UserAvatar';
import { CameraIcon } from 'icons/icons';
import { FC } from 'react';

export type AgentAvatarProps = {
  openModal: (value: boolean) => void;
};

const AgentAvatar: FC<AgentAvatarProps> = ({ openModal }: AgentAvatarProps) => {
  return (
    <Flex position={'relative'}>
      <UserAvatar size={'xxl'} transcode={'wcontact-l'} />
      <Flex
        width={'34px'}
        height={'34px'}
        top={'10px'}
        right={'15px'}
        position={'absolute'}
        bg={'pbBlue.500'}
        borderRadius={'full'}
        justify={'center'}
        align={'center'}
        onClick={() => openModal(true)}
      >
        <CameraIcon boxSize={4} />
      </Flex>
    </Flex>
  );
};

export default AgentAvatar;
