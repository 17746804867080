import { Select, SelectProps, Skeleton, SkeletonProps } from '@chakra-ui/react';
import React, { ChangeEvent, ReactElement } from 'react';
import { ChevronIcon } from '../../icons/icons';
import { StringOrNumber } from '../../services/apiTypes';

type Props = SelectProps & {
  options?: { value: string; label: string }[];
  placeholder: string;
  value?: StringOrNumber;
  skeletonProps?: SkeletonProps;
  onChange: (v: ChangeEvent<HTMLSelectElement>) => void;
};

export const OwnSelect = ({
  options,
  placeholder,
  onChange,
  value,
  skeletonProps,
  ...rest
}: Props): ReactElement => {
  return (
    <Skeleton isLoaded={!!options} {...skeletonProps}>
      <Select
        icon={<ChevronIcon fontSize={10} />}
        placeholder={placeholder}
        onChange={onChange}
        h={38}
        borderColor={'pbGray.90'}
        borderRadius={'md'}
        color={'pbBlue.205'}
        fontSize={14}
        value={value || ''}
        {...rest}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Skeleton>
  );
};
