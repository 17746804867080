import { SearchListingResponse, StringOrNumber } from '../../../apiTypes';
import {
  QueryObserverOptions,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

export const useClientsSavedListings = (
  id: StringOrNumber,
  opts?: QueryObserverOptions<SearchListingResponse[]>,
): UseQueryResult<SearchListingResponse[]> =>
  useQuery<SearchListingResponse[]>(
    ['clients-saved-listings', id],
    () => pbAgentFetcher(`v1/clients-saved-listings/${id}`),
    {
      enabled: !!id,
      ...opts,
    },
  );

interface Params {
  clientId: StringOrNumber;
  listingId: StringOrNumber;
}

export const useClientSavedListingPost = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['clients-saved-listings-mutation', 'post'],
    ({ clientId, listingId }: Params) =>
      pbAgentFetcher(`v1/clients-saved-listings/${clientId}`, {
        method: 'post',
        body: JSON.stringify({
          listing_ids: [listingId],
        }),
      }),
    {
      onSuccess: (e, { clientId }) => {
        queryClient.invalidateQueries(['clients', clientId]);
        queryClient.invalidateQueries(['clients', clientId]);
        // used in 'clients' tab
        const listingClientsInfoQueryKey = ['listing-clients-info'];
        // used in results page
        const clientListingsInfoQueryKey = ['clients-listings-info'];

        queryClient.invalidateQueries(listingClientsInfoQueryKey);
        queryClient.invalidateQueries(clientListingsInfoQueryKey);
      },
    },
  );
};

export const useClientsSavedListingsDelete = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['clients-saved-listings-mutation', 'delete'],
    ({ clientId, listingId }: Params) =>
      pbAgentFetcher(`v1/clients-saved-listings/${clientId}`, {
        method: 'delete',
        body: JSON.stringify({
          listing_ids: [listingId],
        }),
      }),
    {
      onSuccess: (e, { clientId }) => {
        queryClient.invalidateQueries(['clients', clientId]);
        // used in 'clients' tab
        const listingClientsInfoQueryKey = ['listing-clients-info'];
        // used in results page
        const clientListingsInfoQueryKey = ['clients-listings-info'];

        queryClient.invalidateQueries(listingClientsInfoQueryKey);
        queryClient.invalidateQueries(clientListingsInfoQueryKey);
      },
    },
  );
};
