import { useEffect } from 'react';
import { useClient } from './api/clients/useClient';
import { SearchType, StringOrNumber } from '../apiTypes';
import { useURLSearchParams } from './useURLSearchParams';

interface UseClientSearchParams {
  clientId?: StringOrNumber;
  type?: SearchType;
}

export const useClientSearchParams = ({
  clientId,
  type,
}: UseClientSearchParams): void => {
  const { data: clientData } = useClient(clientId as StringOrNumber);

  const { setSearchParams, searchParams } = useURLSearchParams();
  // Set clients search params into URL whenever they are available
  useEffect(() => {
    if (!clientData) {
      if (!type || !searchParams.get('ad_type')) {
        setSearchParams();
      }
      return;
    }

    const found = clientData.searches.find((s) => s.search?.ad_type === type);

    if (!found) {
      setSearchParams();
      return;
    }

    setSearchParams(found.search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, type]);
};
