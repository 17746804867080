import { HStack, IconButton } from '@chakra-ui/react';
import { useResults } from 'contexts/ResultsContext';
import React, { ReactElement } from 'react';
import { GridIcon, ListIcon } from '../../icons/icons';

const activeIconStyles = {
  'svg path': {
    fill: 'pbBlue.500',
  },
  'svg .inside *': {
    fill: 'white',
  },
};

export const ViewToggler = (): ReactElement => {
  const {
    data: { layoutType },
    actions: { setLayoutType },
  } = useResults();

  return (
    <HStack>
      <IconButton
        sx={layoutType === 'list' ? activeIconStyles : {}}
        aria-label={'list'}
        icon={<ListIcon boxSize={{ base: 9, xl: 12 }} />}
        onClick={() => setLayoutType('list')}
        variant={'reset'}
        className={'notImplemented'}
        size={'md'}
      />
      <IconButton
        sx={layoutType === 'grid' ? activeIconStyles : {}}
        aria-label={'grid'}
        icon={<GridIcon boxSize={{ base: 9, xl: 12 }} />}
        onClick={() => setLayoutType('grid')}
        variant={'reset'}
        size={'md'}
      />
    </HStack>
  );
};
