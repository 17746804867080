import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { DealSheetPostParams, DealSheetResponse } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

export const useDealSheet = (
  dealId?: string,
): UseQueryResult<DealSheetResponse> =>
  useQuery<DealSheetResponse>(
    ['deal-sheets', dealId],
    () => pbAgentFetcher(`v1/deal-sheets/${dealId}`),
    {
      enabled: !!dealId,
      refetchOnWindowFocus: false,
    },
  );

interface Params {
  dealId?: string;
  body: DealSheetPostParams;
}

export const useDealSheetPost = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dealId, body }: Params) =>
      pbAgentFetcher(`v1/deal-sheets/${dealId}`, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['deal-sheets']),
    },
  );
};
