import React, {
  KeyboardEvent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Text,
  chakra,
} from '@chakra-ui/react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { radioCheckboxRow } from './styles';
import {} from '@choc-ui/chakra-autocomplete';
import { mediaServer } from 'services/mediaServer';

import { BaseInput } from './BaseInput';
import { InputProps } from './ToFieldWithCCField';

interface AutocompleteItem {
  name: string;
  [key: string]: string;
}
export interface AutoCompleteInputProps {
  onChange: (v: string[]) => void;
  value?: string[];
  label?: string;
  placeholder?: string;
  required?: boolean;
  isSingleInput?: boolean;
  isDisabled?: boolean;
  handleBlur?: ({
    currentTarget: { value: string },
  }: SyntheticEvent<HTMLInputElement>) => void;
}

type MergedAutoCompleteInputProps = {
  onSearch: (v: string) => void;
  items?: AutocompleteItem[];
  inputProps?: InputProps;
} & AutoCompleteInputProps &
  Omit<FlexProps, 'onChange'>;

export const AutoCompleteInput = ({
  onChange,
  onSearch,
  value,
  label,
  placeholder,
  items,
  isSingleInput,
  handleBlur,
  inputProps = { withBorders: true, withPlaceholders: true },
  ...props
}: MergedAutoCompleteInputProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    selectedItems: value || [],
    onStateChange: (v) => {
      //Fire change event only when changed state contains `selectedItems` object.
      onChange(v.selectedItems as string[]);
    },
  });

  const onInputSubmit = (value = '') => {
    setInputValue(value);

    if (!value) {
      return;
    }

    onSearch(value);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue,
    selectedItem: null,
    items:
      items && items?.length > 0
        ? items.filter((item) => !selectedItems.includes(item.email))
        : [],
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          onInputSubmit(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem.email);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (!value || !value.length) {
      return;
    }

    setInputValue('');
  }, [value]);

  return (
    <FormControl {...radioCheckboxRow} zIndex={isOpen ? '4' : '3'}>
      {label && <FormLabel>{label}</FormLabel>}

      <Flex
        role={'group'}
        flexWrap={'wrap'}
        alignItems={'center'}
        w={'100%'}
        border={inputProps?.withBorders ? '1px solid' : 'none'}
        pl={2}
        borderColor={'pbGray.90'}
        borderRadius={'lg'}
        _focusWithin={
          inputProps?.withBorders
            ? {
                borderColor: '#3182ce',
                boxShadow: '0 0 0 1px #3182ce',
              }
            : {}
        }
        {...props}
      >
        {value
          ?.filter((v) => v !== '')
          ?.map((selectedItem, index) => (
            <Text
              borderRadius={'md'}
              minH={26}
              bgColor={'#F8F8F8'}
              px={4}
              py={1}
              borderWidth={1}
              fontWeight={'bold'}
              fontSize={12}
              lineHeight={'26px'}
              ml={1}
              my={1}
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              {selectedItem}
              <Text
                as={'span'}
                cursor={'pointer'}
                ml={3}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
              >
                &#10005;
              </Text>
            </Text>
          ))}
        <Box {...getComboboxProps()} ml={1} flex={1}>
          <BaseInput
            border={0}
            pl={inputProps?.withBorders ? 0 : 2}
            _focus={{ border: 0 }}
            placeholder={inputProps?.withPlaceholders ? placeholder : ''}
            display={isSingleInput && !!value?.length ? 'none' : 'inline-block'}
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isOpen,
                onKeyDownCapture: (
                  e:
                    | KeyboardEvent<HTMLInputElement>
                    | KeyboardEvent<HTMLButtonElement>,
                ) => {
                  if (e.key === 'Enter' || e.key === 'Tab') {
                    onChange(value ? [...value, inputValue] : [inputValue]);
                  }
                },
              }),
            )}
            onBlur={handleBlur}
          />
        </Box>
      </Flex>

      <Box pos={'absolute'} left={0} top={'110%'} right={0}>
        <chakra.ul
          {...getMenuProps()}
          borderRadius={'lg'}
          backgroundColor={'white'}
          zIndex={1}
          pos={'relative'}
          borderWidth={1}
          borderColor={'pbGray.100'}
          bg={'white'}
          p={2}
          _empty={{
            display: 'none',
          }}
          maxH={200}
          overflowY={'auto'}
          boxShadow={'0px 10px 20px rgba(0, 25, 49, 0.08)'}
        >
          {isOpen &&
            items
              ?.filter((item) => !selectedItems.includes(item.email))
              .map((item, index) => (
                <chakra.li
                  sx={{
                    listStyle: 'none',
                  }}
                  px={3}
                  py={1}
                  backgroundColor={
                    highlightedIndex === index ? 'pbBlue.510' : 'white'
                  }
                  key={`${item.name}${index}`}
                  _hover={{
                    cursor: 'pointer',
                    borderRadius: 'lg',
                    bgColor: 'pbBlue.510',
                  }}
                  {...getItemProps({ item, index })}
                >
                  <Flex
                    justifyContent={item.email ? 'space-between' : 'flex-start'}
                    alignItems={'center'}
                  >
                    <Avatar
                      name={item.name}
                      src={item.photo && mediaServer(item.photo)}
                    />
                    <Text ml={5}>{item.name}</Text>{' '}
                    <Text ml={'auto'}>{item.email}</Text>
                  </Flex>
                </chakra.li>
              ))}
        </chakra.ul>
      </Box>
    </FormControl>
  );
};
