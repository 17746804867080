import { Neighborhood, StringOrNumber } from './apiTypes';
import { RangesKey } from '../components/Form/RangesDropdown';

type Values = {
  min: string;
  max: string;
};

export type NeighborhoodObject = {
  abbreviation: string;
  streets?: Values;
  avenues?: Values;
};

/**
 * Returns and abbreviation from string in API notation like : `{abbreviation}|str:{min}-{max}|ave:{min}-{max}`
 */
export const getAbbreviationFromString = (neighborhood: string): string =>
  new RegExp(/^[^|]*/).exec(neighborhood)?.[0] || '';

/**
 * Returns object containing min and max values for streets and avenues derrived from
 * string in API notation like : `{abbreviation}|str:{min}-{max}|ave:{min}-{max}`
 */
export const parseNeighborhoodToObject = (
  neighborhood: string,
): NeighborhoodObject => {
  const abbreviation = getAbbreviationFromString(neighborhood);

  const neighborhoodObject: NeighborhoodObject = {
    abbreviation,
  };

  const setValues = (key: RangesKey) => {
    const regExp = key === 'streets' ? /str:([a-zA-Z0-9- ,.]+)\|*/ : /ave:(.+)/;

    const getValues = new RegExp(regExp).exec(neighborhood)?.[1];

    if (!getValues) {
      return;
    }

    neighborhoodObject[key] = {
      min: getValues.split('-')[0],
      max: getValues.split('-')[1],
    };
  };

  setValues('streets');
  setValues('avenues');

  return neighborhoodObject;
};

/**
 * Returns string in API notation like : `{abbreviation}|str:{min}-{max}|ave:{min}-{max}` combined from object enerated by `parseNeighborhoodToObject()`
 */
export const stringifyNeighborhood = ({
  abbreviation,
  streets,
  avenues,
}: NeighborhoodObject): string => {
  let stringified = abbreviation;
  if (streets) {
    stringified += `|str:${streets.min}-${streets.max}`;
  }

  if (avenues) {
    stringified += `|ave:${avenues.min}-${avenues.max}`;
  }

  return stringified;
};

const isInterpolatable = (string: string): boolean => string.indexOf('-') > 0;

const getInterpolatedMinValue = (string: string): string =>
  string.split('-')[0];

const getInterpolatedMaxValue = (string: string): string =>
  string.split('-')[1];

export const generateInterpolatedArray = (string: string): string[] => {
  const min = parseInt(getInterpolatedMinValue(string), 10);
  const max = parseInt(getInterpolatedMaxValue(string), 10);
  let values: StringOrNumber[] = [
    getInterpolatedMinValue(string),
    getInterpolatedMaxValue(string),
  ];

  if (!isNaN(min) && !isNaN(max)) {
    values = [];
    for (let i = min; i <= max; i++) {
      values.push(i);
    }
  }
  return values.map(String);
};

const insertInstead = (arr: string[], index: number, newItem: string[]) => {
  if (arr.length < 2) {
    return newItem;
  }

  const newArr = [...arr.slice(0, index), ...newItem];

  if (index + 1 < arr.length) {
    newArr.push(...arr.slice(index + 1));
  }

  return [...new Set(newArr)];
};

/**
 * Take all streets and avenues from neighborhood object and interpolate all found ranges like etc "3-6" will be
 * switched to [3 ,4 ,5 ,6] and injected insead of found range on the same array index
 */
export const interpolateNeighborhoodValues = (
  neighborhood: Neighborhood,
): Neighborhood => {
  const newNeighborhood = { ...neighborhood };

  const setInterpolation = (key: RangesKey) => {
    const arrayToInterpolate = neighborhood[key].filter((s) =>
      isInterpolatable(s),
    );

    if (!arrayToInterpolate.length) {
      return;
    }

    arrayToInterpolate.forEach((s) => {
      const index = newNeighborhood[key].findIndex((ns) => ns === s);
      newNeighborhood[key] = insertInstead(
        newNeighborhood[key],
        index,
        generateInterpolatedArray(s),
      );
    });
  };

  setInterpolation('streets');
  setInterpolation('avenues');

  return newNeighborhood;
};
