import {
  Box,
  BoxProps,
  Divider,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useCompsListing } from '../../services/hooks/api/comps/useCompsListing';
import { ListingDetailsTile } from '../Global/ListingDetailsTile';
import { Link } from 'react-router-dom';

interface ListingCompsProps extends BoxProps {
  listingId?: string;
}

export const ListingComps = ({
  listingId,
  ...props
}: ListingCompsProps): ReactElement => {
  const { data: listings, isLoading, isError } = useCompsListing(listingId);

  return (
    <Box {...props}>
      {isError && (
        <Text>Comps are not available for the particular listing</Text>
      )}

      {listings?.length === 0 && (
        <Text>There are not matches Comps for the particular listing</Text>
      )}

      <VStack divider={<Divider />} spacing={5} align={'stretch'}>
        {isLoading && !listings && (
          <Skeleton w={'100%'} h={100} borderRadius={'md'} />
        )}

        {listings?.map((listing) => (
          <Link
            to={`/listing/${listing.advertisement_id}`}
            key={listing.advertisement_id}
          >
            <ListingDetailsTile listing={listing} />
          </Link>
        ))}
      </VStack>
    </Box>
  );
};
