import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import { SearchInput } from 'components/Form/SearchInput';
import { useApplicationsContext } from 'contexts/ApplicationsContext';
import { DownloadIcon, TrashBlueIcon } from 'icons/icons';

export const ListActions = (): ReactElement => {
  const {
    data: {
      areAllApplicationsChecked,
      selectedApplicationsIds,
      isGeneratingZip,
      isDeletingApplications,
    },
    actions: {
      toggleCheckAll,
      deleteApplications,
      downloadMultipleApplications,
      setSearchValue,
    },
  } = useApplicationsContext();

  return (
    <Flex pr={5} flexShrink={1}>
      <HStack spacing={3} width={'100%'}>
        <SearchInput
          placeholder={'Search by name'}
          onChange={(e) =>
            setSearchValue({ field: 'name', value: e.target.value })
          }
          maxWidth={'50%'}
        />
        <SearchInput
          placeholder={'Search by address'}
          onChange={(e) =>
            setSearchValue({ field: 'address', value: e.target.value })
          }
          maxWidth={'50%'}
        />
      </HStack>
      <HStack alignItems={'center'} ml={5} mt={{ base: 1, md: 0 }} flexGrow={1}>
        <Button
          leftIcon={<DownloadIcon fontSize={16} />}
          variant={'blueLight'}
          size={'sm'}
          isDisabled={!selectedApplicationsIds.length}
          isLoading={isGeneratingZip}
          loadingText={'Generating...'}
          onClick={downloadMultipleApplications}
        >
          Download PDF
        </Button>

        <Button
          leftIcon={<TrashBlueIcon fontSize={16} />}
          variant={'blueLight'}
          size={'sm'}
          isDisabled={!selectedApplicationsIds.length}
          isLoading={isDeletingApplications}
          loadingText={'Deleting...'}
          onClick={() => deleteApplications()}
        >
          Delete
        </Button>
        <Box display={'flex'} flexWrap={'nowrap'}>
          <FormLabel
            htmlFor={'check-all-listings'}
            fontSize={{ base: 'xs', xl: 'sm' }}
            display={{ base: 'block', md: 'none', xl: 'block' }}
            whiteSpace={'nowrap'}
            minW={30}
            color={'pbBlue.100'}
            cursor={'pointer'}
            ml={5}
          >
            Check all
          </FormLabel>
          <Tooltip label={'Check all'} placement={'top-end'}>
            <Box>
              <Checkbox
                id={'check-all-applications'}
                isChecked={areAllApplicationsChecked || false}
                onChange={toggleCheckAll}
                ml={3}
              />
            </Box>
          </Tooltip>
        </Box>
      </HStack>
    </Flex>
  );
};
