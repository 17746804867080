import { RoundedBox } from 'components/Global/RoundedBox';
import React, { ReactElement } from 'react';
import { Image } from '@chakra-ui/react';
import tempChart from './tempChart.png';

const UpperRightSection = (): ReactElement => {
  return (
    <RoundedBox className={'notImplemented'}>
      <Image src={tempChart} />
    </RoundedBox>
  );
};

export default UpperRightSection;
