import { MatchingClientTile } from './MatchingClientTile';
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  SimpleGrid,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { PaperPlaneIcon } from 'icons/icons';
import { Dropdown } from 'components/Global/Dropdown';
import { useMatchingClients } from 'services/hooks/api/search-listings/useMatchingClients';
import { useParams } from 'react-router-dom';
import { ListingRouteParams } from 'scenes/Listing';
import { useListingClientsInfo } from 'services/hooks/api/search-listings/useListingClientsInfo';
import { SearchInput } from 'components/Form/SearchInput';
import { useSendListing } from 'services/hooks/useSendListing';
import { mergeClientsData } from 'services/mergeClientsData';

const clientsFilters = [
  {
    label: 'All clients',
    value: null,
  },
  {
    label: 'Already offered',
    value: 1,
  },
  {
    label: 'Not Offered',
    value: 0,
  },
];

const MatchingClients = (): ReactElement => {
  const toast = useToast();
  const clientFilterOptions = clientsFilters.map((option) => option.label);
  const [activeFilter, setActiveFilter] = useState(clientsFilters[0]);
  const [selectedClientsIds, setSelectedClientsIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { id } = useParams<ListingRouteParams>();
  const [recentlySent, setRecentlySent] = useState<string[]>([]);
  const { send, isSendingListing } = useSendListing();
  const { data: matchingClients, isLoading } = useMatchingClients(id);

  const matchingClientsIds = useMemo(
    () => matchingClients?.map(({ user_id: id }) => id),
    [matchingClients],
  );
  const { data: matchingClientsInfo } = useListingClientsInfo({
    listingId: id,
    clientIds: matchingClientsIds || [],
  });

  const handleChangeInputFilter = (e: SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const handleChangeFilter = useCallback((value: string) => {
    const newFilter = clientsFilters.find((filter) => filter.label === value);
    setActiveFilter(newFilter || clientsFilters[0]);
  }, []);

  const handleSelectUser = useCallback(
    (id: string) => {
      if (selectedClientsIds.includes(id)) {
        setSelectedClientsIds(selectedClientsIds.filter((c) => c !== id));
      } else {
        setSelectedClientsIds([...selectedClientsIds, id]);
      }
    },
    [selectedClientsIds],
  );

  const handleClickSendListing = useCallback(async () => {
    if (isSendingListing) {
      return;
    }

    await send({
      listingIds: [id],
      clientIds: selectedClientsIds,
    });

    setRecentlySent(selectedClientsIds);
    toast({
      title: 'Listing sent',
      description: 'Listing was sent to selected clients',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right',
    });

    setTimeout(() => {
      setRecentlySent([]);
    }, 3000);
  }, [id, isSendingListing, selectedClientsIds, send, toast]);

  const areAllClientsChecked = useMemo(() => {
    if (!matchingClients) return false;
    return selectedClientsIds.length === matchingClients?.length;
  }, [matchingClients, selectedClientsIds]);

  const mergedClientsData = mergeClientsData(
    matchingClientsInfo,
    matchingClients,
  );

  const filteredClients = useMemo(() => {
    const filteredBySearchValue = mergedClientsData?.filter(
      (c) => c.name.includes(searchValue) || c.email.includes(searchValue),
    );
    if (activeFilter.value === null) {
      return filteredBySearchValue;
    }
    return filteredBySearchValue?.filter((c) => c.sent === activeFilter.value);
  }, [activeFilter, mergedClientsData, searchValue]);

  const toggleCheckAll = useCallback(() => {
    if (!filteredClients) return;
    if (selectedClientsIds.length === filteredClients.length) {
      return setSelectedClientsIds([]);
    }
    setSelectedClientsIds(filteredClients.map((c) => c.user_id));
  }, [filteredClients, selectedClientsIds.length]);

  const tabHeader = useMemo(
    (): ReactElement | null => (
      <Flex w={'100%'} alignItems={'center'} mb={6}>
        <Box flexGrow={1} mr={2.5}>
          <SearchInput
            onChange={handleChangeInputFilter}
            placeholder={'Search by email or name'}
            maxW={'none'}
          />
        </Box>
        <Flex
          w={{ base: '70%', xl: '50%' }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Dropdown
            flexGrow={1}
            options={clientFilterOptions}
            onValueChange={handleChangeFilter}
            value={activeFilter.label}
            fontSize={'14px'}
            variant={'grayDropdown'}
            menuProps={{
              placement: 'bottom-end',
            }}
          />
          <Checkbox
            label={'Check all'}
            role={'checkbox'}
            onChange={toggleCheckAll}
            isDisabled={!filteredClients?.length}
            isChecked={filteredClients?.length ? areAllClientsChecked : false}
            mx={5}
            data-testid={'check-all-clients'}
          >
            <Text fontSize={'sm'}>Check all</Text>
          </Checkbox>
          <Button
            size={'sm'}
            variant={'blueLight'}
            leftIcon={<PaperPlaneIcon />}
            disabled={!selectedClientsIds.length}
            onClick={handleClickSendListing}
            isLoading={isSendingListing}
            loadingText={'Send to selected'}
          >
            Send to selected
          </Button>
        </Flex>
      </Flex>
    ),
    [
      activeFilter.label,
      areAllClientsChecked,
      clientFilterOptions,
      filteredClients?.length,
      handleChangeFilter,
      handleClickSendListing,
      isSendingListing,
      selectedClientsIds.length,
      toggleCheckAll,
    ],
  );

  const clientsList = useMemo(
    () =>
      filteredClients?.map(({ user_id, name, photo, email, sent, saved }) => (
        <MatchingClientTile
          user_id={user_id}
          name={name}
          photo={photo}
          email={email}
          key={user_id}
          hasBeenSent={sent}
          hasBeenRecentlySent={recentlySent.includes(user_id)}
          hasBeenSaved={saved}
          isSelected={selectedClientsIds.includes(user_id)}
          onSelect={handleSelectUser}
        />
      )),
    [filteredClients, handleSelectUser, recentlySent, selectedClientsIds],
  );

  return (
    <Skeleton isLoaded={!isLoading}>
      {tabHeader}
      <SimpleGrid columns={2} gap={2}>
        {clientsList}
      </SimpleGrid>
    </Skeleton>
  );
};

export default MatchingClients;
