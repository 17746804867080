import { Flex, FormControl, FormLabel, useRadioGroup } from '@chakra-ui/react';

import React, { ReactElement } from 'react';
import { RadioItem } from './TriggerCard';
import { radioCheckboxRow } from './styles';

type Props = {
  label?: string;
  onChange: (value: string | number) => void;
  value: string | number;
  options: string[] | null | undefined;
};

export const RadioColumn = ({
  label,
  onChange,
  value,
  options,
}: Props): ReactElement => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: label,
    onChange,
    value,
  });

  const group = getRootProps();

  return (
    <FormControl
      {...radioCheckboxRow}
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
    >
      {label && <FormLabel as={'h3'}>{label}</FormLabel>}

      {options && (
        <Flex
          {...group}
          alignItems={'baseline'}
          flexWrap={'wrap'}
          w={'100%'}
          direction={'column'}
        >
          {options.map((v) => {
            // Has to ignore while getRadioProps() require 'enterKeyHint' from passed InputHTMLAttributes which is even not supported in some browsers.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const radio = getRadioProps({
              value: v.toString(),
            });
            return (
              <RadioItem key={v} {...radio}>
                {v}
              </RadioItem>
            );
          })}
        </Flex>
      )}
    </FormControl>
  );
};
