import { Text, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';

const SectionSubHeading: FC<TextProps> = ({ children, ...rest }: TextProps) => {
  return (
    <Text
      fontSize={'16px'}
      fontWeight={'bold'}
      color={'pbGray.500'}
      pt={2}
      w={{ base: 'initial', md: '100px' }}
      minW={{ base: 'initial', md: '100px' }}
      mr={'55px'}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default SectionSubHeading;
