import React, {
  ComponentProps,
  ReactElement,
  useContext,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Image,
  Link,
  Stack,
  useBoolean,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  NavLink,
  Link as ReactRouterLink,
  useLocation,
} from 'react-router-dom';
import logo from './logo.png';
import { UserDropdown } from './components/UserDropdown';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';
import { consoleAssert } from '../../../services/consoleAssert';
import { ExpandableSearchInput } from '../../Form/SearchInput';
import { ChevronIcon, NotificationsIcon } from '../../../icons/icons';
import { OwnPopover } from '../OwnPopover';
import { Notifications } from 'components/Dashboard/Notifications';

const NavigationLink = (props: ComponentProps<typeof NavLink>) => (
  <Link
    as={NavLink}
    fontWeight={'500'}
    color={'#8899ad'}
    fontSize={{ base: '13px', xl: 'sm' }}
    letterSpacing={'tight'}
    _hover={{ color: 'pbBlue.500' }}
    activeClassName={'active'}
    sx={{
      '&.active': {
        fontWeight: 700,
        color: 'pbBlue.500',
      },
    }}
    {...props}
  />
);

const HeaderIconDropdown = ({
  label,
  icon,
  children,
}: {
  label: string;
  icon: React.ReactElement;
  children: React.ReactNode;
}) => (
  <OwnPopover
    triggerContent={
      <IconButton
        bg={'transparent'}
        variant={'ghost'}
        aria-label={label}
        icon={icon}
      />
    }
    bodyContent={children}
  />
);

const Navigation = () => (
  <Stack
    as={'nav'}
    pl={{
      base: 0,
      lg: 5,
    }}
    mb={{
      base: 5,
      lg: 0,
    }}
    spacing={{ base: 3, lg: 5, xl: 8 }}
    direction={{
      base: 'column',
      lg: 'row',
    }}
    align={'center'}
  >
    <NavigationLink to={{ pathname: '/mailbox/inbox', state: { page: 1 } }}>
      Mailbox
    </NavigationLink>
    <NavigationLink to={'/search/sale'}>Sales</NavigationLink>
    <NavigationLink to={'/search/rental'}>Rentals</NavigationLink>
    <NavigationLink to={'/clients/rental'}>Clients</NavigationLink>
    <NavigationLink to={'/my-listings'}>My Listings</NavigationLink>
    <NavigationLink to={'/stats'}>Stats</NavigationLink>
    <NavigationLink to={'/livestreaming'}>Live Streaming</NavigationLink>
    <NavigationLink to={'/deals'}>Deals Supervisor</NavigationLink>
    <NavigationLink to={'/op-invoices'}>OP Invoices</NavigationLink>
    <NavigationLink to={'/applications'}>Applications</NavigationLink>
  </Stack>
);
export const Header = (): ReactElement => {
  const authContext = useContext(AuthContext);
  consoleAssert(authContext, 'Use <AuthContext> before using <Header>');

  const { pathname } = useLocation();
  const [isLessThan1200] = useMediaQuery('(max-width: 1200px)');
  const [mobileMenuVisible, setMobileMenuVisibility] = useBoolean(false);

  // Close menu on route change
  useEffect(() => {
    setMobileMenuVisibility.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box
      as={'header'}
      bg={'white'}
      position={'relative'}
      zIndex={2}
      borderBottom={'1px solid'}
      borderColor={'gray.200'}
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        px={{ base: 0, sm: 3 }}
        py={2}
        maxW={1620}
        mx={'auto'}
      >
        <Link
          as={ReactRouterLink}
          to={'/'}
          pos={'relative'}
          top={'5px'}
          ml={2}
          minW={'110px'}
        >
          <Image src={logo} />
        </Link>

        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          p={'3'}
          bg={mobileMenuVisible ? 'white' : 'inherit'}
          left={0}
          w={'100%'}
          display={{
            base: mobileMenuVisible ? 'flex' : 'none',
            lg: 'flex',
          }}
          direction={{
            base: 'column',
            lg: 'row',
          }}
          position={{
            base: 'absolute',
            lg: 'relative',
          }}
          top={{ base: '100%', lg: 0 }}
        >
          <Navigation />
        </Flex>

        <ButtonGroup
          variant={'ghost'}
          spacing={'1'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          position={'relative'}
          minW={{
            base: '190px',
            lg: isLessThan1200 ? '190px' : '200px',
            xl: '300px',
            '2xl': '350px',
          }}
        >
          <ExpandableSearchInput
            onChange={(v) => console.log('typed: ', v.target.value)}
          />
          <HeaderIconDropdown
            label={'Notifications'}
            icon={<NotificationsIcon boxSize={{ base: '15px', sm: '17px' }} />}
          >
            <Notifications isInDropdown scrollWrapperId={'headerDropdown'} />
          </HeaderIconDropdown>

          <UserDropdown />
        </ButtonGroup>
      </Flex>

      <Button
        onClick={() => setMobileMenuVisibility.toggle()}
        rightIcon={
          <ChevronIcon
            boxSize={'12px'}
            transform={`rotate(${mobileMenuVisible ? '180' : 0}deg)`}
          />
        }
        w={'100%'}
        display={{
          base: 'flex',
          lg: 'none',
        }}
        variant={'blueLight'}
        borderRadius={'none'}
      >
        {mobileMenuVisible ? 'Close' : 'Menu'}
      </Button>
    </Box>
  );
};
