import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const Main = ({ children, ...props }: BoxProps): ReactElement => (
  <Box
    as={'main'}
    maxW={1620}
    w={'100%'}
    mx={'auto'}
    px={{ base: 3 }}
    pb={7}
    {...props}
  >
    {children}
  </Box>
);
