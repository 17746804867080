import React, { ReactElement } from 'react';
import { Textarea, VStack } from '@chakra-ui/react';
import { useAgentProfileContext } from 'contexts/AgentProfileContext';
import { onSetModifiedAgent } from 'contexts/AgentProfileContext/actions';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import { useContext } from 'react';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import InputEditor from '../shared/InputEditor';
import SectionContainer from '../shared/SectionContainer';
import SectionHeading from '../shared/SectionHeading';
import SectionSubHeading from '../shared/SectionSubHeading';

const BasicInfo = (): ReactElement => {
  const authContext = useContext(AuthContext);
  const { data } = useAgentProfile(authContext?.sessionId);
  const { state, dispatch } = useAgentProfileContext();

  return (
    <>
      <SectionHeading>Basic Information</SectionHeading>

      <SectionContainer>
        <SectionSubHeading>Short Bio</SectionSubHeading>

        <Textarea
          placeholder={'description'}
          defaultValue={data?.description}
          value={state?.modifiedAgent?.description}
          onChange={(e) =>
            dispatch(onSetModifiedAgent({ description: e.target.value }))
          }
          w={'100%'}
          pr={{ base: 3, xl: '118px' }}
          pl={{ base: 3, xl: 5 }}
          pt={'5px'}
          borderColor={'pbGray.90'}
          m={0}
          ml={'auto'}
        />
      </SectionContainer>

      <SectionContainer mt={{ base: 2, md: 5, xl: 10 }}>
        <SectionSubHeading>Contact Info</SectionSubHeading>

        <VStack m={0} w={'100%'} align={'stretch'}>
          {/* TODO: Remove wrapper for email input editor when implemented */}
          <InputEditor
            stackProps={{
              className: 'notImplemented',
            }}
            placeholder={'your e-mail'}
            apiValue={data?.email}
            onSubmit={(value) => dispatch(onSetModifiedAgent({ email: value }))}
          />

          <InputEditor
            placeholder={'your phone number'}
            apiValue={data?.phone}
            onSubmit={(value) => {
              dispatch(onSetModifiedAgent({ phone: value }));
            }}
          />
        </VStack>
      </SectionContainer>
    </>
  );
};

export default BasicInfo;
