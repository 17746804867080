import { useBoolean, useToast } from '@chakra-ui/react';
import { applicationDownloadUrl } from '../../constants';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Applications } from '../../scenes/Applications';
import dayjs from 'dayjs';
import { useAFormDelete } from 'services/hooks/api/aform/useAFormMutation';
import generateSuccessToast from 'services/generateToast';
import { pluralize } from 'services/pluralize';
import { SortDirection } from 'types/SortDirection';
import { Applications as ApplicationsInterface } from 'services/apiTypes';
import { debounce } from 'lodash';

type Sort = {
  [key: string]: SortDirection;
};

interface SearchValue {
  [key: string]: string;
}

const defaultSort: Sort = {
  email: SortDirection.ASCENDING,
};

type ApplicationsContextType = {
  data: {
    applications: ApplicationsInterface;
    selectedApplicationsIds: string[];
    areAllApplicationsChecked: boolean;
    isGeneratingZip: boolean;
    isDeletingApplications: boolean;
    page: number;
    sort: Sort;
    defaultPageSize: number;
    searchValue: SearchValue;
  };
  actions: {
    setApplications: (applications: ApplicationsInterface) => void;
    setSelectedApplicationsIds: (ids: string[]) => void;
    toggleCheckAll: () => void;
    handleSelectApplication: (id: string) => void;
    deleteApplications: () => void;
    deleteSingleApplication: (id: string) => void;
    setPage: (page: number) => void;
    downloadMultipleApplications: () => void;
    setSort: (sort: Sort) => void;
    setSearchValue: ({ field, value }: SearchValue) => void;
  };
};
type Props = {
  children: ReactNode;
};

const applicationsContextDefaultValues: ApplicationsContextType = {
  data: {
    applications: { collection: [], total: 0 },
    selectedApplicationsIds: [],
    areAllApplicationsChecked: false,
    isGeneratingZip: false,
    isDeletingApplications: false,
    page: 1,
    sort: defaultSort,
    defaultPageSize: 8,
    searchValue: {
      name: '',
      address: '',
    },
  },
  actions: {
    setApplications: (applications: ApplicationsInterface) => applications,
    setSelectedApplicationsIds: (ids: string[]) => ids,
    toggleCheckAll: () => true,
    handleSelectApplication: (id: string) => id,
    deleteApplications: () => null,
    deleteSingleApplication: (id: string) => id,
    setPage: (page: number) => page,
    downloadMultipleApplications: () => null,
    setSort: (sort: Sort) => sort,
    setSearchValue: ({ field, value }: SearchValue) => ({ [field]: value }),
  },
};

const ApplicationsContext = createContext<ApplicationsContextType>(
  applicationsContextDefaultValues,
);

export function useApplicationsContext(): ApplicationsContextType {
  return useContext(ApplicationsContext);
}

export function ApplicationsContextProvider({ children }: Props): ReactElement {
  const { mutate: deleteApplication, isLoading: isDeletingApplications } =
    useAFormDelete();
  const toast = useToast();
  const [selectedApplicationsIds, setSelectedApplicationsIds] = useState<
    string[]
  >([]);
  const [isGeneratingZip, setIsGeneratingZip] = useBoolean(false);
  const [sort, setSort] = useState(defaultSort);
  const [applications, setApplications] = useState<ApplicationsInterface>({
    collection: [],
    total: 0,
  });
  const [searchValue, setSearchValue] = useState<SearchValue>({});

  const [page, setPage] = useState<number>(1);

  const applicationsIds = useMemo(
    () =>
      applications.collection.map(
        ({ application_form_id }) => application_form_id,
      ),
    [applications],
  );

  const areAllApplicationsChecked = useMemo(() => {
    if (!applicationsIds.length) return false;

    return selectedApplicationsIds.length === applicationsIds?.length;
  }, [applicationsIds.length, selectedApplicationsIds.length]);

  const toggleCheckAll = useCallback(() => {
    if (!applicationsIds) return;

    if (selectedApplicationsIds.length === applicationsIds.length) {
      return setSelectedApplicationsIds([]);
    }
    setSelectedApplicationsIds(applicationsIds);
  }, [applicationsIds, selectedApplicationsIds.length]);

  const handleSelectApplication = useCallback(
    (id: string) => {
      if (selectedApplicationsIds.includes(id)) {
        setSelectedApplicationsIds(
          selectedApplicationsIds.filter((a) => a !== id),
        );
      } else {
        setSelectedApplicationsIds([...selectedApplicationsIds, id]);
      }
    },
    [selectedApplicationsIds],
  );

  const deleteApplications = useCallback(() => {
    const allPromises = selectedApplicationsIds.map((id) =>
      deleteApplication({ id }),
    );

    Promise.all(allPromises)
      .then(() => {
        toast(
          generateSuccessToast(
            `${pluralize(
              selectedApplicationsIds.length,
              'Application',
            )} deleted successfully`,
          ),
        );
        setSelectedApplicationsIds([]);
      })
      .catch((error) => console.log(error));
  }, [deleteApplication, selectedApplicationsIds, toast]);

  const deleteSingleApplication = useCallback(
    (id: string) => {
      deleteApplication(
        { id },
        {
          onSuccess: () => {
            toast(generateSuccessToast('Application deleted successfully'));
          },
        },
      );
    },
    [deleteApplication, toast],
  );

  const downloadMultipleApplications = useCallback(() => {
    setIsGeneratingZip.on();
    const urls = selectedApplicationsIds.map((id) => ({
      url: applicationDownloadUrl(id),
      filename: `${id}`,
    }));

    const zipFile = new JSZip();

    const filePromises = urls.map(async (e) => {
      const res = await fetch(e.url);
      const blob = await res.blob();
      zipFile.file(e.filename, blob);
    });

    Promise.all(filePromises).then(() => {
      zipFile
        .generateAsync({ type: 'blob' })
        .then((content) =>
          saveAs(
            content,
            `applications_${dayjs(new Date()).format('MM-DD-YYYY')}.zip`,
          ),
        );
      setIsGeneratingZip.off();
    });
  }, [selectedApplicationsIds, setIsGeneratingZip]);

  const onSetSearchValue = ({ field, value }: SearchValue) =>
    setSearchValue((prev) => ({ ...prev, [field]: value }));

  const value = {
    data: {
      applications,
      applicationsIds,
      selectedApplicationsIds,
      areAllApplicationsChecked,
      isGeneratingZip,
      isDeletingApplications,
      page,
      sort,
      defaultPageSize: 8,
      searchValue,
    },
    actions: {
      setSelectedApplicationsIds,
      toggleCheckAll,
      handleSelectApplication,
      setApplications,
      deleteApplications,
      deleteSingleApplication,
      setPage,
      downloadMultipleApplications,
      setSort,
      setSearchValue: debounce(onSetSearchValue, 500),
    },
  };

  return (
    <ApplicationsContext.Provider value={value}>
      {children}
    </ApplicationsContext.Provider>
  );
}

export const ApplicationsWithContext = (): ReactElement => (
  <ApplicationsContextProvider>
    <Applications />
  </ApplicationsContextProvider>
);
