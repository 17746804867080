import { Button, ButtonGroup, Flex, FlexProps, Text } from '@chakra-ui/react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useMailbox } from 'contexts/MailboxContext';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGmailLabels } from 'services/hooks/api/gmail/useGmailLabels';
import { ClientRouteParams } from 'types/ClientRouteParams';

type PaginationComponent = Omit<FlexProps, 'onChange'> & {
  page: number;
  maxNumPages: number;
  onChange: (page: number) => void;
  disabled?: boolean;
};

export const Pagination = ({
  maxNumPages,
  page,
  onChange,
  disabled = false,
  ...rest
}: PaginationComponent): ReactElement | null => {
  const pagesArray = [...Array(maxNumPages || 1).keys()];
  const offset = 5;

  return (
    <Flex
      wrap={'wrap'}
      justifyContent={'center'}
      alignItems={'center'}
      {...rest}
    >
      <Button
        aria-label={'previous page'}
        variant={'blueLight'}
        onClick={() => onChange(page - 1)}
        mr={2}
        size={'sm'}
        maxW={'38px'}
        fontSize={'md'}
        disabled={page === 1}
      >
        <ChevronLeft fontSize={'small'} />
      </Button>

      {pagesArray.map((p) => {
        const realPage = p + 1;
        const buttonActive = page === realPage;
        if (realPage === maxNumPages) {
          return (
            <React.Fragment key={'last-page'}>
              {maxNumPages - page > 2 && (
                <Button
                  w={'10px'}
                  variant={'ghost'}
                  mx={0}
                  pointerEvents={'none'}
                >
                  ...
                </Button>
              )}
              <Button
                onClick={() => onChange(realPage)}
                variant={buttonActive ? 'blueLight' : 'outline'}
                color={buttonActive ? 'white' : 'gray.500'}
                backgroundColor={buttonActive ? 'pbBlue.500' : ''}
                mr={2}
                size={'sm'}
                fontSize={'sm'}
                disabled={disabled}
              >
                {realPage}
              </Button>
            </React.Fragment>
          );
        }

        if (realPage === 1) {
          return (
            <React.Fragment key={'first-page'}>
              <Button
                onClick={() => onChange(realPage)}
                variant={buttonActive ? 'blueLight' : 'outline'}
                color={buttonActive ? 'white' : 'gray.500'}
                backgroundColor={buttonActive ? 'pbBlue.500' : ''}
                mr={2}
                size={'sm'}
                fontSize={'sm'}
                disabled={disabled}
              >
                {realPage}
              </Button>
              {page - 1 > 2 && (
                <Button
                  w={'10px'}
                  variant={'ghost'}
                  mx={0}
                  pointerEvents={'none'}
                >
                  ...
                </Button>
              )}
            </React.Fragment>
          );
        }

        return (
          realPage > page - 2 &&
          realPage < page + 2 && (
            <Button
              key={realPage}
              onClick={() => onChange(realPage)}
              variant={buttonActive ? 'blueLight' : 'outline'}
              color={buttonActive ? 'white' : 'gray.500'}
              backgroundColor={buttonActive ? 'pbBlue.500' : ''}
              mr={realPage === offset ? 0 : 2}
              disabled={disabled}
              size={'sm'}
              fontSize={'sm'}
            >
              {realPage}
            </Button>
          )
        );
      })}
      <Button
        size={'sm'}
        fontSize={'md'}
        maxW={'38px'}
        aria-label={'next page'}
        variant={'blueLight'}
        icon={<ChevronRight />}
        onClick={() => onChange(page + 1)}
        disabled={page === maxNumPages}
      >
        <ChevronRight />
      </Button>
    </Flex>
  );
};

interface SimplePaginationProps {
  paginationText: string;
  prevButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  canGoToNextPage?: boolean;
  goToPreviousPage?: () => void;
  goToNextPage?: () => void;
  flexProps?: FlexProps;
}

export const SimplePagination = ({
  paginationText,
  prevButtonDisabled = false,
  nextButtonDisabled = false,
  goToPreviousPage,
  goToNextPage,
  flexProps,
}: SimplePaginationProps): ReactElement => (
  <Flex alignItems={'center'} alignSelf={'flex-end'} mt={5} {...flexProps}>
    <Text> {paginationText}</Text>
    <ButtonGroup ml={5}>
      <Button
        aria-label={'previous page'}
        variant={'blueLight'}
        mr={2}
        size={'sm'}
        maxW={'38px'}
        fontSize={'md'}
        onClick={goToPreviousPage}
        disabled={prevButtonDisabled}
      >
        <ChevronLeft fontSize={'small'} />
      </Button>
      <Button
        onClick={goToNextPage}
        size={'sm'}
        fontSize={'md'}
        maxW={'38px'}
        aria-label={'next page'}
        variant={'blueLight'}
        isDisabled={nextButtonDisabled}
      >
        <ChevronRight fontSize={'small'} />
      </Button>
    </ButtonGroup>
  </Flex>
);

export const InboxPagination = (): ReactElement | null => {
  const {
    data: {
      currentPage,
      hasPreviousPageData,
      canGoToNextPage,
      currentFolder,
      threads,
    },
    actions: { goToPreviousPage, goToNextPage },
  } = useMailbox();
  const { tab } = useParams<ClientRouteParams>();

  const labels = useGmailLabels();

  const totalThreads =
    labels.data?.find((label) => label.id === currentFolder.toUpperCase())
      ?.threadsTotal ?? 0;
  const defaultPageSize = 10;

  let paginationText = '';
  if (currentPage && threads && totalThreads > 0) {
    const pageFirstItem = (currentPage - 1) * defaultPageSize + 1;
    const pageLastItem = Math.min(
      currentPage * defaultPageSize, // default case
      totalThreads, // last page
      (currentPage - 1) * defaultPageSize + threads.length, // less on page than default page size
    );

    const maxItemsText = tab ? 'many' : `${totalThreads} threads`;
    paginationText = `Showing ${pageFirstItem} to ${pageLastItem} of ${maxItemsText}`;
  }

  if (!currentPage || totalThreads <= defaultPageSize || !threads?.length) {
    return null;
  }

  return (
    <SimplePagination
      paginationText={paginationText}
      prevButtonDisabled={currentPage === 1 || !hasPreviousPageData}
      nextButtonDisabled={!canGoToNextPage}
      goToPreviousPage={goToPreviousPage}
      goToNextPage={goToNextPage}
    />
  );
};
