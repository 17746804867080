import React, { ReactElement, useState } from 'react';
import { Box, HStack, Stack } from '@chakra-ui/layout';
import { useBuilding } from '../../services/hooks/api/buildings/useBuilding';
import { Dropdown } from '../Global/Dropdown';
import { Button } from '@chakra-ui/button';
import { SearchInput } from '../Form/SearchInput';
import { BuildingListingsTable } from '../BuildingListingsTable';
import { ListingRowType } from '../BuildingListingsTable/components/ListingRow';

type FilterLabels = 'Rent' | 'Sale' | 'Rent Past' | 'Sale Past';
type FilterTimes = 'past' | 'future';

const filterOptions: {
  label: FilterLabels;
  value: {
    type: ListingRowType;
    time: FilterTimes;
  };
}[] = [
  { label: 'Sale', value: { type: 'sales', time: 'future' } },
  { label: 'Rent', value: { type: 'rentals', time: 'future' } },
  { label: 'Sale Past', value: { type: 'sales', time: 'past' } },
  { label: 'Rent Past', value: { type: 'rentals', time: 'past' } },
];

export const BuildingDetails = ({
  buildingID,
}: {
  buildingID: string;
}): ReactElement => {
  const [activeFilter, setActiveFilter] = useState<number>(0);

  const inactiveToo = filterOptions[activeFilter].value.time === 'past' ? 1 : 0;

  const { data: buildingData, isLoading } = useBuilding(buildingID, {
    inactive_too: inactiveToo,
  });

  const [searchValue, setSearchValue] = useState<string>('');

  const { type } = filterOptions[activeFilter].value;

  const tableData =
    buildingData &&
    buildingData[type]
      .filter(
        ({ unit }) =>
          unit?.toLowerCase().search(searchValue.toLowerCase()) !== -1,
      )
      .filter(
        ({ active }) =>
          (inactiveToo === 0 && active === 1) ||
          (inactiveToo === 1 && active === 0),
      );

  const onValueChange = (option: FilterLabels) => {
    setActiveFilter(filterOptions.findIndex((el) => el.label === option));
  };

  return (
    <>
      <Stack
        justifyContent={'flex-end'}
        alignItems={'center'}
        spacing={0}
        w={'100%'}
        mb={{ base: 5, md: 10 }}
        mt={{ base: 2, md: 5 }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <SearchInput
          onChange={(v) => setSearchValue(v.target.value)}
          maxW={{ base: '100%', md: '235px' }}
          defaultValue={searchValue}
          mr={{ base: 0, md: 2 }}
          mb={{ base: 2, md: 0 }}
        />

        <HStack
          spacing={2}
          justifyContent={'flex-end'}
          w={{ base: '100%', md: 'auto' }}
        >
          <Button
            variant={'blueLight'}
            height={'38px'}
            className={'notImplemented'}
          >
            Export Data
          </Button>

          <Dropdown
            options={filterOptions.map((option) => option.label)}
            onValueChange={onValueChange}
            value={filterOptions[activeFilter].label}
            ml={'auto'}
            fontSize={14}
            py={'12px'}
            minW={'150px'}
            variant={'grayDropdown'}
          />
        </HStack>
      </Stack>

      <Box h={'100%'} overflowX={'auto'}>
        <BuildingListingsTable
          data={tableData}
          type={filterOptions[activeFilter].value.type}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};
