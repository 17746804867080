/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApiResponse } from 'types/ApiResponse';
import { HTTPError } from './HTTPError';

export const fetcher = async (
  url: string,
  init?: RequestInit,
  attachCountFromHeader?: boolean,
  hasOmittedContentType?: boolean,
): Promise<ApiResponse> => {
  const headers = {
    'Adm-App-Auth': `${process.env.REACT_APP_APP_KEY}-${process.env.REACT_APP_APP_SECRET}`,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Adm-Session-ID': localStorage.getItem('session_id') || '',
    ...init?.headers,
  };

  if (hasOmittedContentType) {
    // @ts-ignore: "The operand of a 'delete' operator must be optional."
    delete headers['Content-Type'];
  }

  const response = await fetch(url, {
    ...init,
    headers,
  });

  if (!response.ok) {
    throw new HTTPError(response, 'Response not successful');
  }

  if (!attachCountFromHeader) {
    return response.json();
  }

  const unreadCountHeader = response.headers.get('Adm-Result-Unread-Count');
  const totalCountHeader = response.headers.get('Adm-Result-Total-Count');

  return {
    collection: await response.json(),
    total: totalCountHeader,
    unreadCount: unreadCountHeader,
  };
};

export const pbAuthFetcher = (
  path: string,
  init?: RequestInit,
): Promise<ApiResponse> =>
  fetcher(`${process.env.REACT_APP_PB_AUTH_API}/${path}`, init);

export const pbAgentFetcher = (
  path: string,
  init?: RequestInit,
  attachCountFromHeader?: boolean,
  hasOmittedContentType?: boolean,
): Promise<ApiResponse> =>
  fetcher(
    `${process.env.REACT_APP_PB_AGENT_API}/${path}`,
    init,
    attachCountFromHeader,
    hasOmittedContentType,
  );
