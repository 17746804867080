import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import {
  GmailInfiniteQueryResponse,
  GmailMessagesPostBody,
  GmailThreadUpdateResponse,
} from './apiTypes';
import { pbAgentFetcher } from '../../../fetcher';
import { useParams } from 'react-router-dom';
import { ClientRouteParams } from 'types/ClientRouteParams';
import { useClient } from '../clients/useClient';

const defaultParams = {
  cc: [],
  bcc: [],
  body: '',
};

interface Params {
  threadId: string;
  data: GmailMessagesPostBody;
}

const updateReadState = (
  data: GmailInfiniteQueryResponse,
  idsToUpdate: string[],
  newSeenState: number,
) => ({
  ...data,
  pages: data.pages.map((page) => ({
    ...page,
    collection: {
      ...page.collection,
      threads: page.collection.threads.map((thread) => {
        if (idsToUpdate.includes(thread.id)) {
          return {
            ...thread,
            seen: newSeenState,
          };
        }
        return thread;
      }),
    },
  })),
});

export const useGmailMessagesThread = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['gmail-messages'],
    ({ threadId, data }: Params) =>
      pbAgentFetcher(`v1/gmail-messages/${threadId}`, {
        method: 'post',
        body: JSON.stringify({ ...defaultParams, ...data }),
      }),
    {
      onSuccess: (e, { threadId }) => {
        queryClient.invalidateQueries(['gmail-threads', threadId]);
        queryClient.invalidateQueries(['gmail-threads']);
      },
    },
  );
};

interface ToggleStateParams {
  message_ids: string;
}

interface UseGmailThreadsStateReturnType {
  markAsUnread: UseMutationResult<
    GmailThreadUpdateResponse,
    unknown,
    ToggleStateParams,
    unknown
  >;
  markAsRead: UseMutationResult<
    GmailThreadUpdateResponse,
    unknown,
    ToggleStateParams,
    unknown
  >;
}

export const useGmailThreadsReadState = ({
  currentFolder,
}: {
  currentFolder: string;
}): UseGmailThreadsStateReturnType => {
  const queryClient = useQueryClient();
  const { clientId } = useParams<ClientRouteParams>();
  const { data: clientData } = useClient(clientId);

  const mailboxQueryKey = [
    'gmail-threads',
    { label: currentFolder?.toUpperCase(), query: '' },
  ];
  const clientMessagesQueryKey = [
    'gmail-threads',
    { label: 'INBOX', query: `from:${clientData?.email}` },
  ];

  const queryKey = clientId ? clientMessagesQueryKey : mailboxQueryKey;

  const onMutate = async () => {
    const data = queryClient.getQueryData<GmailInfiniteQueryResponse>(queryKey);
    return {
      data,
    };
  };

  const markAsUnread = useMutation(
    ['mark-thread-as-unread'],
    ({ message_ids }: ToggleStateParams) =>
      pbAgentFetcher(`v1/gmail-flags/seen?message_ids=${message_ids}`, {
        method: 'delete',
      }),
    {
      onMutate,
      onSuccess: (_res: GmailThreadUpdateResponse, { message_ids }) => {
        const data =
          queryClient.getQueryData<GmailInfiniteQueryResponse>(queryKey);

        if (!data) {
          return;
        }
        queryClient.setQueryData(
          queryKey,
          updateReadState(data, message_ids.split(','), 0),
        );
      },
      onError: (e, { message_ids }, context) => {
        console.error(e, message_ids);
        queryClient.setQueryData(queryKey, context?.data);
      },
    },
  );

  const markAsRead = useMutation(
    ['mark-thread-as-read'],
    ({ message_ids }: ToggleStateParams) =>
      pbAgentFetcher(`v1/gmail-flags/seen?message_ids=${message_ids}`, {
        method: 'post',
      }),
    {
      onMutate,
      onSuccess: (_res: GmailThreadUpdateResponse, { message_ids }) => {
        const data =
          queryClient.getQueryData<GmailInfiniteQueryResponse>(queryKey);

        if (!data) {
          return;
        }
        queryClient.setQueryData(
          queryKey,
          updateReadState(data, message_ids.split(','), 1),
        );
      },
      onError: (e, { message_ids }, context) => {
        console.error(e, message_ids);
        queryClient.setQueryData(queryKey, context?.data);
      },
    },
  );

  return { markAsUnread, markAsRead };
};
