import dayjs from 'dayjs';

export const getLastYearsInRangeOf = (range: number): string[] => {
  const listOfLastYears: string[] = [];

  const currentYear = dayjs().year();
  const lastYearToInclude = currentYear - range;

  for (let i = currentYear; i > lastYearToInclude; i--) {
    listOfLastYears.push(i.toString());
  }

  return listOfLastYears;
};
