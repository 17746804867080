import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react';
import React, { ChangeEvent, ReactElement } from 'react';
import { RentalPrice, SalePrice } from '../../services/apiTypes';
import { InputProps } from '@chakra-ui/input/dist/types/input';
import { radioCheckboxRow } from './styles';

interface PriceRangeProps {
  priceDefaults: false | SalePrice | RentalPrice;
  from?: number;
  to?: number;
  onFromChange: (v: ChangeEvent<HTMLInputElement>) => void;
  onToChange: (v: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}

export const PriceRange = ({
  from,
  priceDefaults,
  onFromChange,
  onToChange,
  to,
  label,
}: PriceRangeProps): ReactElement => {
  const inputProps: InputProps = {
    min: (priceDefaults && priceDefaults.min_price) || 0,
    max: (priceDefaults && priceDefaults.max_price) || 0,
    type: 'number',
  };
  return (
    <FormControl {...radioCheckboxRow} zIndex={2}>
      {label && <FormLabel>{label}</FormLabel>}

      <HStack spacing={{ base: 3, md: 5 }} mb={3} w={'100%'}>
        <Input
          placeholder={'$ Min'}
          value={from || ''}
          onChange={onFromChange}
          {...inputProps}
        />
        <Input
          placeholder={'$ Max'}
          onChange={onToChange}
          value={to || ''}
          {...inputProps}
        />
      </HStack>
    </FormControl>
  );
};
