import { Box, Button } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import CreditCards from './CreditCards/CreditCards';
import ReceivePayments from './ReceivePayments/ReceivePayments';
import { TabWrapper } from '../TabWrapper';
import { SectionDivider } from 'components/Global/SectionDivider';

const Payments = (): ReactElement => {
  return (
    <TabWrapper pb={{ base: 0, lg: '50px' }} className={'notImplemented'}>
      <ReceivePayments />

      <SectionDivider />

      <CreditCards />

      <Box py={5}>
        <Button variant={'blue'}>Save</Button>
      </Box>
    </TabWrapper>
  );
};

export default Payments;
