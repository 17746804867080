import { UseToastOptions } from '@chakra-ui/react';

const toastDefaultValues: Pick<
  UseToastOptions,
  'position' | 'duration' | 'isClosable'
> = {
  position: 'bottom-right',
  duration: 5000,
  isClosable: true,
};

export default function generateSuccessToast(
  description: string,
): UseToastOptions {
  return {
    title: 'Success',
    description,
    status: 'success',
    ...toastDefaultValues,
  };
}
