import { useBoolean } from '@chakra-ui/react';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { LayoutType } from 'scenes/Results';

type ResultsContextType = {
  data: {
    selectedListingIds: string[];
    areAllListingsChecked: boolean;
    clientId: string;
    listingIds: string[];
    layoutType: string;
    filtersVisible: boolean;
  };
  actions: {
    setSelectedListingIds: (ids: string[]) => void;
    toggleCheckAll: () => void;
    handleSelectListing: (id: string) => void;
    setLayoutType: (layoutType: LayoutType) => void;
    setFiltersVisible: {
      readonly on: () => void;
      readonly off: () => void;
      readonly toggle: () => void;
    };
    setListingIds: (listingIds: string[]) => void;
    setClientId: (clientId: string) => void;
  };
};
type Props = {
  children: ReactNode;
};

const resultsContextDefaultValues: ResultsContextType = {
  data: {
    selectedListingIds: [],
    areAllListingsChecked: false,
    clientId: '',
    listingIds: [],
    layoutType: '',
    filtersVisible: false,
  },
  actions: {
    setSelectedListingIds: (ids: string[]) => ids,
    toggleCheckAll: () => true,
    handleSelectListing: (id: string) => id,
    setLayoutType: (layoutType: LayoutType) => layoutType,
    setFiltersVisible: {
      on: () => true,
      off: () => false,
      toggle: () => true,
    },
    setListingIds: (listingIds: string[]) => listingIds,
    setClientId: (clientId: string) => clientId,
  },
};

const ResultsContext = createContext<ResultsContextType>(
  resultsContextDefaultValues,
);

export function useResults(): ResultsContextType {
  return useContext(ResultsContext);
}

export function ResultsContextProvider({ children }: Props): ReactElement {
  const [selectedListingIds, setSelectedListingIds] = useState<string[]>([]);
  const [layoutType, setLayoutType] = useState<LayoutType>('grid');
  const [filtersVisible, setFiltersVisible] = useBoolean(false);
  const [listingIds, setListingIds] = useState<string[]>([]);
  const [clientId, setClientId] = useState<string>('');

  const areAllListingsChecked = useMemo(() => {
    if (!listingIds) return false;

    return selectedListingIds.length === listingIds?.length;
  }, [listingIds, selectedListingIds.length]);

  const toggleCheckAll = useCallback(() => {
    if (!listingIds) return;

    if (selectedListingIds.length === listingIds.length) {
      return setSelectedListingIds([]);
    }
    setSelectedListingIds(listingIds);
  }, [listingIds, selectedListingIds.length]);

  const handleSelectListing = useCallback(
    (id: string) => {
      if (selectedListingIds.includes(id)) {
        setSelectedListingIds(selectedListingIds.filter((c) => c !== id));
      } else {
        setSelectedListingIds([...selectedListingIds, id]);
      }
    },
    [selectedListingIds, setSelectedListingIds],
  );

  const value = {
    data: {
      selectedListingIds,
      areAllListingsChecked,
      clientId,
      listingIds,
      layoutType,
      filtersVisible,
    },
    actions: {
      setSelectedListingIds,
      toggleCheckAll,
      handleSelectListing,
      setLayoutType,
      setFiltersVisible,
      setClientId,
      setListingIds,
    },
  };

  return (
    <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>
  );
}
