import { StringOrNumber } from './apiTypes';

// Should return true if value of param is 0
export const isNumeric = (n?: StringOrNumber | null): boolean => {
  if (typeof n === 'undefined' || n === null) {
    return false;
  }
  const number = typeof n === 'number' ? n : parseFloat(n);
  return !isNaN(number) && isFinite(number);
};
