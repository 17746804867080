import { Badge } from '@chakra-ui/react';
import { badgeBackgroundColors } from '../../constants';
import React from 'react';
import { ListingTypeBadgeProps } from 'types/ListingBadge';

export const ListingTypeBadge: React.FC<ListingTypeBadgeProps> = ({
  type,
  text = '',
  color,
  ...props
}: ListingTypeBadgeProps): JSX.Element => {
  let bgColor = type && badgeBackgroundColors[type];

  if (color) {
    bgColor = color;
  }
  return (
    <Badge
      role={'status'}
      py={1}
      px={6}
      fontSize={10}
      borderRadius={'md'}
      color={'white'}
      bgColor={bgColor}
      {...props}
    >
      {text.toUpperCase() || type?.toUpperCase()}
    </Badge>
  );
};
