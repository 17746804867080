import React, { ReactElement, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Header } from './components/Global/Header';
import { LoginPage } from './scenes/LoginPage';
import { Global } from '@emotion/react';
import { fonts } from './services/fonts';
import { Flex, Spinner } from '@chakra-ui/react';
import mainRoutes, { RouteData } from 'routes';
import { PrivateRoute } from 'components/PrivateRoute';
import { useModal } from 'contexts/ModalContext';

export function App(): ReactElement {
  const { modal } = useModal();

  return (
    <Flex flexDir={'column'} minH={'100vh'}>
      <Global styles={fonts} />
      <Switch>
        <Route path={'/login'}>
          <LoginPage />
        </Route>
        <Route>
          <Header />

          <Suspense fallback={<Spinner mt={'100px'} mx={'auto'} d={'block'} />}>
            <Switch>
              {mainRoutes.map(
                ({ component, path, exactPath, privateRoute }: RouteData) => {
                  const Component = component;
                  return privateRoute ? (
                    <PrivateRoute exact={exactPath} path={path} key={path}>
                      <Component />
                    </PrivateRoute>
                  ) : (
                    <Route key={path} exact={exactPath} path={path}>
                      <Component />
                    </Route>
                  );
                },
              )}
            </Switch>
          </Suspense>
        </Route>
      </Switch>

      {modal}
      <ReactQueryDevtools initialIsOpen={false} />
    </Flex>
  );
}
