import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { GmailDraft } from './apiTypes';

interface DraftsResponse {
  messages: GmailDraft[];
}

export const useDraftsGetAll = (): UseQueryResult<DraftsResponse> =>
  useQuery<DraftsResponse>(
    ['gmail-draft'],
    () => pbAgentFetcher('v1/gmail-drafts'),
    {
      refetchOnWindowFocus: false,
    },
  );
