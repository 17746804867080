import { Box, VStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { ChildrenProps } from 'types/ChildrenProps';

const TileBadgeContainer = ({ children }: ChildrenProps): ReactElement => {
  return (
    <Box pos={'absolute'} top={5} left={5}>
      <VStack spacing={1} alignItems={'flex-start'}>
        {children}
      </VStack>
    </Box>
  );
};

export default TileBadgeContainer;
