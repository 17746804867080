import { useBoolean } from '@chakra-ui/react';
import { GmailMessageModal } from 'components/Global/GmailMessageModal';
import DOMPurify from 'dompurify';
import React, {
  ChangeEventHandler,
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  GmailMessagesFile,
  GmailMessagesPostBody,
  Message,
  Option,
} from 'services/apiTypes';
import { findDraftByMessageId } from 'services/draftsDb';
import { useGmailBody } from 'services/hooks/api/gmail/useGmailBody';
import { useGmailThread } from 'services/hooks/api/gmail/useGmailThread';
import { MessageFormType } from 'types/MessageFormType';
import { useModal } from './ModalContext';

export type AllMessageRecipients = Option[];
interface FormState {
  isOpen: boolean;
  type: MessageFormType;
  recipients?: string[];
  cc?: string[];
  body?: string;
  attachments?: GmailMessagesFile[];
}

type MessageContextType = {
  data: {
    message: Message;
    previousMessages: string;
    currentMessage: string;
    areAttachmentsVisible: boolean;
    formState: FormState;
    // allThreadRecipients: AllMessageRecipients;
  };
  actions: {
    setAreAttachmentsVisible: {
      readonly on: () => void;
      readonly off: () => void;
      readonly toggle: () => void;
    };
    setMessageForm: (state: FormState) => void;
    onRecipientChange: (options: Option[]) => void;
    onCcChange: (options: Option[]) => void;
    handleUploadAttachment: ChangeEventHandler<HTMLInputElement>;
    onAttachmentRemove: (filename: string) => void;
    quickForwardMessage: () => void;
    quickReplyToMessage: () => void;
    quickReplyToMessageWithAll: () => void;
    setMessageBody: (body: string) => void;
    openMessageForm: (type: MessageFormType) => void;
  };
};
type Props = {
  children: ReactNode;
  message: Message;
};

const messageContextDefaultValues: MessageContextType = {
  data: {
    message: {} as Message,
    previousMessages: '',
    currentMessage: '',
    areAttachmentsVisible: false,
    formState: {
      isOpen: false,
      type: 'reply',
      body: '',
      recipients: [],
      cc: [],
      attachments: [],
    },
  },
  actions: {
    setAreAttachmentsVisible: {
      on: () => true,
      off: () => false,
      toggle: () => true,
    },
    setMessageForm: (state) => state,
    onRecipientChange: (recipients) => recipients,
    onCcChange: (cc) => cc,
    handleUploadAttachment: (attachment) => attachment,
    onAttachmentRemove: (attachment) => attachment,
    quickForwardMessage: () => null,
    quickReplyToMessage: () => null,
    quickReplyToMessageWithAll: () => null,
    setMessageBody: (body) => body,
    openMessageForm: (type) => type,
  },
};

const MessageContext = createContext<MessageContextType>(
  messageContextDefaultValues,
);

export function useMessage(): MessageContextType {
  return useContext(MessageContext);
}

export function MessageContextProvider({
  message,
  children,
}: Props): ReactElement {
  const { openModal, defaultModalProps } = useModal();
  const [areAttachmentsVisible, setAreAttachmentsVisible] = useBoolean(true);
  const [formState, setFormState] = React.useState<FormState>(
    messageContextDefaultValues.data.formState,
  );

  const {
    state: [body, setBody],
    onRecipientChange,
    onFileInputChange,
    onAttachmentRemove,
    onCcChange,
  } = useGmailBody();

  const { data: thread, isLoading: isLoadingThread } = useGmailThread(
    message.thread_id,
  );

  const findExistingDraft = useCallback(async () => {
    const draft = await findDraftByMessageId(message.message_id);

    if (draft?.body) {
      setBody((prev) => ({
        ...prev,
        body: draft.body,
        to: draft.to_email?.split(', ') || [''],
        to_name: draft.to_name?.split(', ') || [''],
        subject: draft.subject,
      }));
    } else {
      setBody((prev) => ({
        ...prev,
        recipients: [],
        body: '',
      }));
    }
  }, [message.message_id, setBody]);

  useEffect(() => {
    findExistingDraft();
  }, [findExistingDraft]);

  const previousMessages = useMemo(() => {
    if (isLoadingThread || !thread) {
      return '';
    }

    const previousMessage =
      thread.messages[
        thread.messages.findIndex((m) => m.id === message.id) - 1
      ];

    if (!previousMessage) return '';
    return (
      DOMPurify.sanitize(previousMessage.email_html) ||
      previousMessage.email_chat
    );
  }, [isLoadingThread, message.id, thread]);

  const currentMessage =
    DOMPurify.sanitize(message.email_html) || message.email_chat;

  const subject = message.subject;

  const setMessageForm = (state: FormState) => {
    setFormState(state);
  };

  const quickForwardMessage = () => {
    const initObject: GmailMessagesPostBody = {
      to: [''],
      subject: `Fwd: ${subject}`,
      body: message.email_chat,
      attachments: message.attachments.map(
        ({ filename, content_type, size, content }) => ({
          name: filename,
          type: content_type,
          size_estimate: size?.toString(),
          data: content?.toString(),
        }),
      ),
    };

    return openModal(
      <GmailMessageModal
        {...defaultModalProps}
        modalTitle={'Forward message'}
        initObject={initObject}
      />,
    );
  };

  const quickReplyToMessage = () => {
    const initObject: GmailMessagesPostBody = {
      to: [message.from_email],
      to_name: [message.from_email],
      subject: `Re: ${subject}`,
      body: `${previousMessages}`,
    };

    return openModal(
      <GmailMessageModal
        {...defaultModalProps}
        modalTitle={`Reply to ${message.from_name}`}
        initObject={initObject}
      />,
    );
  };

  const quickReplyToMessageWithAll = () => {
    const initObject: GmailMessagesPostBody = {
      to: [message.from_email],
      to_name: [message.from_email],
      subject: `Re: ${subject}`,
      body: `${previousMessages}`,
    };

    return openModal(
      <GmailMessageModal
        {...defaultModalProps}
        modalTitle={'Reply to all'}
        initObject={initObject}
      />,
    );
  };

  const setMessageBody = (body: string) => {
    setBody((prev) => ({
      ...prev,
      body,
    }));
  };

  const openMessageForm = async (formType: MessageFormType) => {
    setMessageForm({
      isOpen: true,
      type: formType,
    });

    switch (formType) {
      case 'reply':
        return onRecipientChange([
          { label: message.from_name, value: message.from_email },
        ]);
      case 'replyToAll':
        return onRecipientChange([
          {
            label: message.from_name,
            value: message.from_email,
          },
        ]);
      default:
        return onRecipientChange([]);
    }
  };

  const value = {
    data: {
      message,
      previousMessages,
      currentMessage,
      areAttachmentsVisible,
      formState: {
        ...formState,
        recipients: body.to,
        cc: body.cc,
        body: body.body,
        attachments: body.attachments,
      },
    },
    actions: {
      setAreAttachmentsVisible,
      setFormState,
      setMessageForm,
      onRecipientChange,
      onCcChange,
      handleUploadAttachment: onFileInputChange,
      onAttachmentRemove,
      quickForwardMessage,
      quickReplyToMessage,
      quickReplyToMessageWithAll,
      setMessageBody,
      openMessageForm,
    },
  };
  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
}
