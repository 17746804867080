import React, { ReactElement } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ITrackProps } from 'react-range/lib/types';

export type Value = [number, number];
interface RangeSliderProps extends Partial<ITrackProps> {
  min?: number;
  max?: number;
  step?: number;
  label: string;
  values: Value;
  valuesMap?: string[];
  onChange: (values: Value) => void;
}

export const RangeSlider = ({
  min = 0,
  max = 1,
  step = 1,
  label,
  values,
  valuesMap = [],
  onChange,
}: RangeSliderProps): ReactElement => {
  const theme = useTheme();
  const [internalValues, setInternalValues] = useState(
    values && values.length ? values : [min || 0, max || 0],
  );
  const minValueLabel = valuesMap?.length
    ? valuesMap[internalValues[0]]
    : internalValues[0];
  const maxValueLabel = valuesMap?.length
    ? valuesMap[internalValues[1]]
    : internalValues[1];

  // Rerender ranges when values has changed by other component
  useEffect(() => {
    setInternalValues(values);
  }, [values]);

  return (
    <Flex justifyContent={'center'} flexWrap={'wrap'}>
      <Flex
        justifyContent={'space-between'}
        as={'output'}
        width={'100%'}
        alignItems={'baseline'}
      >
        <Text fontSize={14} fontWeight={'bold'}>
          {label}
        </Text>
        <Text
          fontSize={12}
          fontWeight={'500'}
          color={'pbBlue.100'}
        >{`${minValueLabel}-${maxValueLabel}`}</Text>
      </Flex>

      <Range
        values={internalValues}
        step={step}
        min={min}
        max={max}
        onChange={setInternalValues}
        onFinalChange={(v: number[]) => onChange(v as Value)}
        renderTrack={({ props, children }) => (
          <Flex
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            height={'20px'}
            width={'100%'}
            my={2}
            style={{ ...props.style }}
          >
            <Box
              ref={props.ref}
              background={getTrackBackground({
                values: internalValues,
                colors: [
                  theme.colors.pbGray['110'],
                  theme.colors.pbBlue['500'],
                  theme.colors.pbGray['110'],
                ],
                min,
                max,
              })}
              backgroundColor={'pbBlue.500'}
              height={'2px'}
              width={'100%'}
              alignSelf={'center'}
            >
              {children}
            </Box>
          </Flex>
        )}
        renderThumb={({ props, isDragged }) => (
          <Flex
            {...props}
            style={{
              ...props.style,
            }}
            height={'20px'}
            width={'20px'}
            borderRadius={'50%'}
            backgroundColor={isDragged ? '#CCC' : 'pbBlue.500'}
            justifyContent={'center'}
            alignItems={'center'}
            boxShadow={'0px 2px 6px #AAA'}
            _focus={{
              outline: '0',
            }}
          />
        )}
      />
    </Flex>
  );
};
