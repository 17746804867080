import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';

export type ContactInfoBoxProps = {
  icon: ReactElement;
  text?: string;
};

const ContactInfoBox: FC<ContactInfoBoxProps> = ({
  icon,
  text,
  ...rest
}: ContactInfoBoxProps) => {
  return (
    <HStack
      spacing={4}
      w={'100%'}
      p={4}
      borderRadius={'lg'}
      border={'1px solid'}
      borderColor={'pbGray.100'}
      fontSize={'14px'}
      fontWeight={'bold'}
      {...rest}
    >
      {icon}
      <Text letterSpacing={'tighter'} color={'pbGray.500'}>
        {text || '-'}
      </Text>
    </HStack>
  );
};

export default ContactInfoBox;
