import { Flex, VStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import InputEditor from '../../PersonalDetails/shared/InputEditor';
import SectionHeading from '../../PersonalDetails/shared/SectionHeading';
import { TwoInputsRow } from '../../PersonalDetails/shared/TwoInputsRow';

const ReceivePayments = (): ReactElement => {
  //TODO
  const onSubmit = () => undefined;

  return (
    <Flex direction={'column'}>
      <SectionHeading>Receive ACH Payments</SectionHeading>
      <VStack spacing={'10px'} align={'stretch'} maxW={'500px'}>
        <InputEditor placeholder={'Bank Routing Number'} onSubmit={onSubmit} />
        <InputEditor placeholder={'Bank Account Number'} onSubmit={onSubmit} />
        <InputEditor placeholder={'Address Line 1'} onSubmit={onSubmit} />
        <InputEditor placeholder={'Address Line 2'} onSubmit={onSubmit} />
        <TwoInputsRow>
          <InputEditor placeholder={'City'} onSubmit={onSubmit} />
          <InputEditor placeholder={'State'} onSubmit={onSubmit} />
        </TwoInputsRow>
        <TwoInputsRow>
          <InputEditor placeholder={'Postal Code'} onSubmit={onSubmit} />
          <InputEditor placeholder={'Country'} onSubmit={onSubmit} />
        </TwoInputsRow>
      </VStack>
    </Flex>
  );
};

export default ReceivePayments;
