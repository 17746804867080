import { Input, InputProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { currencyFormat } from '../../../../services/currencyFormat';

export const PriceInput = ({
  onChange,
  value,
  ...props
}: InputProps): ReactElement => {
  return (
    <Input
      placeholder={'Commission'}
      onChange={(e) =>
        onChange &&
        onChange({
          ...e,
          target: {
            ...e.target,
            value: e.target.value.replace('$', '').replace(',', ''),
          },
        })
      }
      value={value ? currencyFormat(value as string) : ''}
      {...props}
    />
  );
};
