/* 
This is exact response type from Google-login. 
TODO: Maybe it will be used during UserProfile/AgentProfile creation. 
Google response differs from Client's API response

*/
// export type LoginGoogleUserResponse = Pick<
//         AgentProfile,
//         | 'first_name'
//         | 'last_name'
//         | 'email'
//         | 'gender'
//         | 'phone'
//         | 'photo'
//         | 'user_id'
//         | 'year_born'
//         | 'roles'
//     > & {
//         client_type: string;
//         enabled: 1 | 0;
//         preferences: AgentProfile['preferences'] & {
//             new_password: string;
//             old_password: string;
//             test: 0 | 1;
//         };
//     };

import { CustomBoolean } from '../../../apiTypes';

export type Gender = 'M' | 'F';

export type Preference = CustomBoolean;

export enum UserRole {
  AGENT = 'AGENT',
  DEVELOPER = 'DEVELOPER',
  SUPERVISOR = 'SUPERVISOR',
}

export type Preferences = {
  agent_notification_email: Preference;
  agent_notification_push: Preference;
  agent_publish_ad_se: Preference;
  agent_publish_ad_na: Preference;
  agent_publish_ad_rh: Preference;
  agent_rh_bot_enabled: Preference;
  agent_append_email_signature: Preference;
  agent_rh_bot_username: string | null;
  agent_rh_bot_password: string | null;
  agent_email_signature: string;
  agent_license_number: Preference;
  agent_signature_id: Preference;
  agent_show_on_signin_form: Preference;
};

export type AgentProfile = {
  user_id: string;
  first_name: string;
  last_name: string;
  slug: string;
  name: string;
  email: string;
  phone: string;
  roles: UserRole[];
  gender: Gender;
  year_born: string;
  time_created: number;
  description: string;
  position: string;
  preferences: Preferences;
  signature: string;
  photo: string;
};

export type LoginGoogleResponse = {
  session: {
    is_fb: number;
    parent_session_id: null | string; // Not sure about string
    user_session_id: string;
  };
  user: AgentProfile;
};

export interface User {
  user_id: string;
  enabled: string;
  type: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  gender: string;
  year_born: string;
  client_type: string;
  source_type?: string;
  source_value?: string;
  time_created: number;
  last_activity: number;
  name: string;
  photo: string;
  status: string;
  status_color: string;
  deleted: number;
}

export interface Agent {
  user_id: string;
  enabled: string;
  type: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  gender: string;
  year_born: string;
  client_type: string;
  source_type?: string;
  source_value?: string;
  time_created: number;
  last_activity: number;
  name: string;
  photo: string;
  deleted: number;
}
