import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { DotsIcon } from 'icons/icons';
import React, { ReactElement } from 'react';

export interface Action {
  name: string;
  fn: () => void;
  icon?: ReactElement;
}
export interface ItemActionsProps {
  actions: Action[];
  isLoading: boolean;
}

const ItemActions = ({
  actions,
  isLoading,
}: ItemActionsProps): ReactElement => (
  <Menu isLazy placement={'bottom-end'}>
    <MenuButton
      as={IconButton}
      icon={<DotsIcon fontSize={16} />}
      borderRadius={'md'}
      isLoading={isLoading}
      size={'sm'}
      aria-label={'item actions'}
      w={{ base: 5, lg: '32px', xl: '32px' }}
      minW={0}
      h={30}
      backgroundColor={'inherit'}
      _hover={{ backgroundColor: 'blackAlpha.50' }}
    />
    <MenuList px={2}>
      {actions.map(({ fn, name, icon }, index) => (
        <MenuItem
          key={index}
          onClick={fn}
          aria-label={name}
          icon={icon}
          borderRadius={'md'}
        >
          {name}
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
);
export default ItemActions;
