import React, { FC, ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { BoxProps, Button, useToast } from '@chakra-ui/react';
import BasicInfo from './BasicInfo/BasicInfo';
import Details from './Details/Details';
import Licenses from './Licenses/Licenses';
import { useAgentProfileContext } from 'contexts/AgentProfileContext';
import { useAgentProfilePut } from 'services/hooks/api/agent-profile/useAgentProfilePut';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import { TabWrapper } from '../TabWrapper';
import { SectionDivider } from 'components/Global/SectionDivider';

const SectionContainer: FC<BoxProps> = ({ children }: BoxProps) => (
  <Flex direction={'column'} maxW={{ base: 'none', xl: '655px' }} w={'100%'}>
    {children}
  </Flex>
);

const PersonalDetails = (): ReactElement => {
  const toast = useToast();
  const { data: currentAgentProfileData } = useAgentProfile();
  const { state } = useAgentProfileContext();
  const { mutate, isLoading } = useAgentProfilePut(
    { ...currentAgentProfileData, ...state.modifiedAgent },
    {
      onSuccess: async () => {
        toast({
          title: 'Personal data updated!.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      onError: async () =>
        toast({
          title: 'Server error - could not update profile',
          status: 'error',
          duration: 2000,
          isClosable: true,
        }),
    },
  );

  const handleSubmitChanges = () => {
    mutate();
  };

  return (
    <TabWrapper>
      <SectionContainer>
        <Licenses />
      </SectionContainer>
      <SectionDivider />
      <SectionContainer>
        <BasicInfo />
      </SectionContainer>
      <SectionDivider />
      <SectionContainer>
        <Details />
      </SectionContainer>

      <Box
        pl={{ base: 0, xl: '155px' }}
        mt={'26px'}
        mb={'30px'}
        textAlign={{ base: 'center', xl: 'left' }}
      >
        <Button
          isLoading={isLoading}
          disabled={!state.modifiedAgent}
          variant={'blue'}
          onClick={handleSubmitChanges}
          justifySelf={'flex-start'}
        >
          Save Changes
        </Button>
      </Box>
    </TabWrapper>
  );
};

export default PersonalDetails;
