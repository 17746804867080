import { SearchListing, StringOrNumber } from '../../../apiTypes';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

export const useSearchListing = (
  id: StringOrNumber,
): UseQueryResult<SearchListing> => {
  return useQuery<SearchListing>(
    ['search-listings', id],
    () => pbAgentFetcher(`v1/search-listings/${id}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};

interface Params {
  advertisementId: string;
  reason: string;
}

export const useSearchListingsDelete = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ advertisementId, reason }: Params) =>
      pbAgentFetcher(`v1/search-listings/${advertisementId}/reason/${reason}`, {
        method: 'DELETE',
      }),

    {
      onSuccess: () => {
        queryClient.invalidateQueries(['search-listings']);
        queryClient.invalidateQueries(['preview-listings']);
        queryClient.invalidateQueries(['search-created-by-me']);
      },
    },
  );
};
