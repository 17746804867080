import React, { ReactElement } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { SectionSubTitle } from '../Global/SectionSubTitle';

interface ApartmentFeaturesProps {
  data?: string[];
  type: 'normal' | 'sidebar' | 'inline';
  title: string;
}

export const ApartmentFeatures = ({
  data,
  type,
  title,
}: ApartmentFeaturesProps): ReactElement => {
  return (
    <Box py={15} maxWidth={type === 'inline' ? '100%' : 550}>
      <SectionSubTitle pl={0} mb={{ base: 2, md: 5 }}>
        {title}
      </SectionSubTitle>
      <Flex fontSize={{ base: 12, md: 14 }} color={'pbBlack.100'} wrap={'wrap'}>
        {data?.map((text, i) => (
          <Center
            key={i}
            width={type === 'inline' ? 'auto' : '100%'}
            maxWidth={
              type === 'normal' ? '33.3%' : type === 'inline' ? '100%' : '50%'
            }
            justifyContent={'left'}
            mb={type === 'normal' ? 2 : 3}
            pr={5}
          >
            <Box
              width={'6px'}
              height={'6px'}
              bg={'pbGray.250'}
              borderRadius={'full'}
              mr={'10px'}
            />
            {text}
          </Center>
        ))}
      </Flex>
    </Box>
  );
};
