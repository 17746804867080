import { Flex, Skeleton, SkeletonText, VStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { RoundedBox } from '../components/Global/RoundedBox';
import { BuildingDetails } from '../components/ListingDetails/BuildingDetails';
import { useBuilding } from '../services/hooks/api/buildings/useBuilding';
import { SectionTitle } from '../components/Global/SectionTitle';
import { ListingSidebarBox } from '../components/ListingDetails/ListingSidebarBox';
import { currencyFormat } from '../services/currencyFormat';
import { ApartmentFeatures } from '../components/ListingDetails/ApartamentFeatures';
import { ListingSidebarMap } from '../components/ListingDetails/ListingSidebarMap';
import { Main } from '../components/Global/Main';
import { PageHeading } from '../components/Global/PageHeading';
import { PageTitle } from '../components/Global/PageTitle';

const Loader = () => (
  <>
    <SkeletonText />
    <Skeleton mt={10} borderRadius={'lg'} w={'100%'} h={'300px'} />
    <VStack>
      <Skeleton borderRadius={'lg'} w={'100%'} h={'300px'} />
    </VStack>
  </>
);

export const Building = (): ReactElement => {
  const { id: buildingID } = useParams<{
    id: string;
  }>();

  const { data: buildingData, isLoading } = useBuilding(buildingID);

  return (
    <Main>
      <PageHeading>
        <PageTitle>Building Details</PageTitle>
      </PageHeading>

      <VStack spacing={{ base: 3, xl: 5 }}>
        <RoundedBox>
          {!buildingData || isLoading ? (
            <Loader />
          ) : (
            <>
              {buildingData.address && buildingData.ownership && (
                <SectionTitle
                  pl={0}
                  as={'h3'}
                  fontSize={18}
                  mb={{ base: 2, md: 5 }}
                >
                  {`${buildingData.address}, ${buildingData.ownership}`}
                </SectionTitle>
              )}

              <Flex fontSize={14} align={'start'} wrap={'wrap'}>
                <ListingSidebarBox
                  label={'Units'}
                  value={buildingData.units_total}
                />
                <ListingSidebarBox
                  label={'Floors'}
                  value={buildingData.floors}
                />
                <ListingSidebarBox
                  label={'Built'}
                  value={buildingData.year_built}
                />

                <ListingSidebarBox
                  label={'Pets'}
                  value={buildingData.pet_policy}
                />

                <ListingSidebarBox
                  label={'Neighborhood'}
                  value={buildingData.neighborhood}
                />

                <ListingSidebarBox
                  label={'Sale (avg $/sqft)'}
                  value={currencyFormat(buildingData.sales_avg)}
                />

                <ListingSidebarBox
                  label={'Rent (avg $/sqft)'}
                  value={currencyFormat(buildingData.rentals_avg)}
                />
              </Flex>

              <ApartmentFeatures
                type={'inline'}
                data={buildingData?.amenities}
                title={'Building Features'}
              />

              <ListingSidebarMap
                lat={buildingData.latitude}
                lng={buildingData.longitude}
                height={'333px'}
              />
            </>
          )}
        </RoundedBox>

        <RoundedBox>
          <BuildingDetails buildingID={buildingID} />
        </RoundedBox>
      </VStack>
    </Main>
  );
};
