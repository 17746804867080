// without `transcode` param you'll get full image. For transcoded sizes check the "/v1/util-transcode-profiles" endpoint
export const mediaServer = (
  url: string,
  transcode?: string,
): string | undefined => {
  if (!url) {
    return;
  }
  const mediaApi = process.env.REACT_APP_PB_MEDIA_API || '';
  const intermediateUrl = transcode ? `/transcode/${transcode}/` : '/media/';
  return `${mediaApi}${intermediateUrl}${url}`;
};
