import { exhaustive } from 'services/exhaustive';
import { ClientsAction, ClientsActionType } from './actions';
import { ClientsState } from './index';

export const clientsReducer = (
  state: ClientsState,
  action: ClientsAction,
): ClientsState => {
  switch (action.type) {
    case ClientsActionType.SET_CLIENT:
      return {
        ...state,
        clientId: action.clientId,
        isClientSearchActive: !!action?.clientId,
      };

    case ClientsActionType.SEARCH_ACTIVE:
      return {
        ...state,
        isClientSearchActive: action.flag,
      };

    default:
      exhaustive(action);
  }
};
