import { SimpleGrid, Tooltip } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { ListingDetails } from '../components/ListingDetails';
import { OwnTabs, TabsType } from '../components/Global/OwnTabs';
import { useHistory, useParams } from 'react-router-dom';
import { ListingSidebar } from '../components/ListingDetails/ListingSidebar';
import { RoundedBox } from '../components/Global/RoundedBox';
import { ListingHistory } from '../components/ListingDetails/ListingHistory';
import { useSearchListing } from '../services/hooks/api/search-listings/useSearchListing';
import { BuildingDetails } from '../components/ListingDetails/BuildingDetails';
import { ListingComps } from '../components/ListingDetails/ListingComps';
import { Main } from '../components/Global/Main';
import { PageTitle } from '../components/Global/PageTitle';
import { PageHeading } from 'components/Global/PageHeading';
import MatchingClients from 'components/ListingDetails/MatchingClients';
import { useMatchingClients } from 'services/hooks/api/search-listings/useMatchingClients';

type ListingPageType = 'details' | 'history' | 'building' | 'combs';
export interface ListingRouteParams {
  id: string;
  page: ListingPageType;
}
export const Listing = (): ReactElement => {
  const { id, page } = useParams<ListingRouteParams>();
  const { data: listingData, isLoading, isFetching } = useSearchListing(id);
  const { data: matchingClients } = useMatchingClients(id);
  const history = useHistory();

  const [activeTab, setActiveTab] = useState<ListingPageType>(
    page || 'details',
  );

  const noMatchingClients =
    (matchingClients && matchingClients.length === 0) || false;

  const changeTabHandler = (tab: ListingPageType) => {
    setActiveTab(tab);
    history.push(`/listing/${id}/${tab}`);
  };

  // Reset tab on listing switch
  useEffect(() => {
    setActiveTab(page || 'details');
  }, [id, page]);

  const tabs: TabsType[] = [
    {
      label: 'Listing Details',
      abbreviation: 'details',
      content: (
        <ListingDetails
          listingData={listingData}
          isLoading={isLoading || isFetching}
        />
      ),
    },
    {
      label: noMatchingClients ? (
        <Tooltip label={'No matching clients for this listing'}>
          Clients
        </Tooltip>
      ) : (
        'Clients'
      ),
      abbreviation: 'clients',
      content: <MatchingClients />,
      disabled: noMatchingClients,
      badgeCount: noMatchingClients ? 0 : undefined,
    },
    {
      label: 'History',
      abbreviation: 'history',
      content: <ListingHistory />,
      disabled: true,
    },
    {
      label: 'Building Listings',
      abbreviation: 'building',
      content: listingData && (
        <BuildingDetails buildingID={listingData.building_id} />
      ),
    },
    {
      label: 'Comps',
      abbreviation: 'comps',
      content: (
        <ListingComps listingId={listingData?.advertisement_id} pt={5} />
      ),
    },
  ];

  return (
    <Main>
      <PageHeading>
        <PageTitle>Listing Details</PageTitle>
      </PageHeading>

      <SimpleGrid
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: 'minmax(0, 1fr) 380px',
        }}
        gap={{ base: 3, xl: 5 }}
      >
        <RoundedBox>
          <OwnTabs
            tabs={tabs}
            onChange={(e) => changeTabHandler(e as typeof activeTab)}
            value={activeTab}
          />
        </RoundedBox>

        <RoundedBox isSide marginAlignment={'none'}>
          {listingData && (
            <ListingSidebar buildingID={listingData.building_id} />
          )}
        </RoundedBox>
      </SimpleGrid>
    </Main>
  );
};
