import React, { useEffect, useState } from 'react';
import { ActionModal, ActionModalProps } from 'components/Global/ActionModal';
import ClientDetailsForm from '../ClientDetailsForm';
import {
  useClient,
  useClientsMutationPut,
} from 'services/hooks/api/clients/useClient';
import {
  ClientsMutationInput,
  SearchType,
  StringOrNumber,
} from 'services/apiTypes';
import { useToast } from '@chakra-ui/toast';
import { FormProvider, useForm } from 'react-hook-form';
import { isString, mapValues } from 'lodash';

interface EditClientModalProps
  extends Omit<ActionModalProps, 'onSubmit' | 'children'> {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  title?: string;
}

const EditClientModal: React.FC<EditClientModalProps> = ({
  onClose,
  isOpen,
  clientId,
  title = 'Edit client',
  submitLabel = 'Save',
}: EditClientModalProps) => {
  const toast = useToast();
  const { data: clientData, isLoading: isLoadingClientData } =
    useClient(clientId);
  const methods = useForm({ mode: 'onChange' });
  const [adType, setAdType] = useState<string>();

  const { mutate, isLoading: isUpdating } = useClientsMutationPut(
    clientId as StringOrNumber,
  );

  useEffect(() => {
    if (!clientData) {
      return;
    }

    methods.setValue('email', clientData.email);
    methods.setValue('first_name', clientData.first_name);
    methods.setValue('last_name', clientData.last_name);
    methods.setValue('phone', clientData.phone);
    setAdType(clientData.searches[0]?.search.ad_type);
  }, [clientData, methods]);

  const handleSubmit = () => {
    const newClient = {
      ...methods.getValues(),
      search: {
        ad_type: adType as SearchType,
      },
    };

    const sanitizedClient = mapValues(newClient, (value) =>
      isString(value) ? value.replace('\n', '') : value,
    ) as ClientsMutationInput;

    mutate(
      {
        ...sanitizedClient,
        year_born: clientData?.year_born,
        gender: clientData?.gender,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Success',
            description: 'Profile successfully updated',
            status: 'success',
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
          });

          onClose();
        },
      },
    );
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      isLoading={isLoadingClientData || isUpdating}
      isDisabled={!methods.formState.isValid}
      onSubmit={methods.handleSubmit(handleSubmit)}
      submitLabel={submitLabel}
      size={'2xl'}
    >
      <FormProvider {...methods}>
        <ClientDetailsForm
          isEditing={true}
          adType={adType}
          onRadioChange={(newAddType) =>
            setAdType((newAddType as string).toLowerCase())
          }
        />
      </FormProvider>
    </ActionModal>
  );
};

export default EditClientModal;
