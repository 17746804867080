import {
  IconProps,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import React, { ComponentType, ReactElement, useMemo } from 'react';
import { CalendarIcon } from '../../icons/icons';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker/types/Props';
import 'react-day-picker/lib/style.css';
import { ErrorMessage } from '@hookform/error-message';
import { ValidationError } from 'yup';
import { BaseInput } from 'components/Form/BaseInput';

type DateTimeInputProps = InputGroupProps & {
  icon: ComponentType<IconProps>;
  inputProps?: InputProps;
  errors?: ValidationError[];
};
const DateTimeInput = React.forwardRef<HTMLDivElement, DateTimeInputProps>(
  ({ icon: Icon, inputProps, errors, ...rest }: DateTimeInputProps, ref) => {
    return (
      <InputGroup {...rest} ref={ref} position={'relative'}>
        <BaseInput {...inputProps} _hover={{ cursor: 'pointer' }} />
        <InputRightElement height={'100%'} mr={1}>
          <Icon color={'pbBlue.100'} boxSize={{ base: '14px', xl: '16px' }} />
        </InputRightElement>

        <ErrorMessage
          errors={errors}
          name={inputProps?.name || ''}
          render={({ message }) => (
            <Text
              fontSize={10}
              color={'pbRed.500'}
              alignSelf={'flex-start'}
              pl={2}
              pos={'absolute'}
              bottom={-5}
            >
              {message}
            </Text>
          )}
        />
      </InputGroup>
    );
  },
);

DateTimeInput.displayName = 'DateTimeInput';

export const DayPicker = ({
  placeholder = 'Pick a Date',
  isDisabled,
  ...rest
}: DayPickerInputProps & { isDisabled?: boolean }): ReactElement => {
  const CalendarInput = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<HTMLDivElement, InputProps>((props, ref) => (
        <DateTimeInput
          icon={CalendarIcon}
          inputProps={{ ...props, isDisabled }}
          ref={ref}
        />
      )),
    [isDisabled],
  );

  return (
    <DayPickerInput
      placeholder={placeholder}
      component={CalendarInput}
      {...rest}
    />
  );
};
