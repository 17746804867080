import React, { ReactElement } from 'react';

import { Flex, HStack, Skeleton, Text } from '@chakra-ui/react';
import IconButton from 'components/Global/IconButton';
import { ArrowLeftIcon } from 'icons/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useGmailThread } from 'services/hooks/api/gmail/useGmailThread';
import { MailboxRouteParams } from 'types/MailboxRouteParams';
import { ThreadsActions } from 'components/Mailbox/ThreadsActions';
import { ClientRouteParams } from 'types/ClientRouteParams';

export const ThreadHeading = (): ReactElement | null => {
  const history = useHistory();
  const { threadId, folder } = useParams<MailboxRouteParams>();
  const { clientType, clientId, tab } = useParams<ClientRouteParams>();
  const { data: thread, isLoading } = useGmailThread(threadId);

  const backToThreads = () =>
    history.push(
      clientId
        ? `/clients/${clientType}/${clientId}/${tab}`
        : `/mailbox/${folder}`,
    );
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      backgroundColor={'white'}
      boxShadow={'0px 4px 10px rgba(204, 208, 218, 0.15)'}
      pt={1}
      pb={5}
      px={{ base: 3, xl: 5 }}
      mx={{ base: -3, xl: -5 }}
    >
      <Skeleton isLoaded={!isLoading}>
        <HStack spacing={4}>
          <IconButton
            onClick={backToThreads}
            icon={<ArrowLeftIcon />}
            aria-label={'back to threads'}
          />
          <Text fontWeight={'bold'} fontSize={'md'} whiteSpace={'break-spaces'}>
            {thread?.subject}
          </Text>
        </HStack>
      </Skeleton>
      <ThreadsActions />
    </Flex>
  );
};
