import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import {
  ClientsSavedSearchesSuspendBody,
  StringOrNumber,
} from '../../../apiTypes';
import { pbAgentFetcher } from '../../../fetcher';

export interface ClientsSavedSearchesSuspendProps {
  clientId?: StringOrNumber;
  searchId?: StringOrNumber;
  body?: ClientsSavedSearchesSuspendBody;
  suspend?: boolean;
}

export const useClientsSavedSearchesSuspend = (): UseMutationResult<
  Response,
  unknown,
  ClientsSavedSearchesSuspendProps,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      clientId,
      searchId,
      suspend = true,
      body = {
        days: 30,
      },
    }: ClientsSavedSearchesSuspendProps) => {
      const opts: RequestInit = {
        method: 'POST',
      };

      if (!suspend) {
        opts.method = 'DELETE';
      }

      if (body) {
        opts.body = JSON.stringify(body);
      }

      return pbAgentFetcher(
        `v1/clients-saved-searches-suspend/${clientId}/${searchId}`,
        opts,
      );
    },
    {
      onSuccess: (e, props) =>
        queryClient.invalidateQueries(['clients', props.clientId]),
    },
  );
};
