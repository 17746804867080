import { IconButton } from '@chakra-ui/button';
import React, { ReactElement, useMemo } from 'react';
import { Badge, HStack } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import {
  CheckSmallIcon,
  HeartIcon,
  HeartOutlineIcon,
  PaperPlaneIcon,
} from 'icons/icons';
import { sendListingTooltipLabel } from 'services/getSendListingTooltipLabel';
import { saveListingTooltipLabel } from 'services/getSaveListingTooltipLabel';

interface ClientListingActionsProps {
  hasBeenSaved: boolean;
  hasBeenSent: boolean;
  isSaving: boolean;
  isSending: boolean;
  onSave: () => void;
  onSend: () => void;
  checkmarkVisible: boolean;
  checkboxOnLeft?: boolean;
}

export const ClientListingActions = ({
  hasBeenSaved,
  hasBeenSent,
  isSaving,
  isSending,
  onSave,
  onSend,
  checkmarkVisible,
}: ClientListingActionsProps): ReactElement => {
  const saveTooltipLabel = useMemo(
    () => saveListingTooltipLabel({ hasBeenSaved, hasBeenSent }),
    [hasBeenSaved, hasBeenSent],
  );

  const sendTooltipLabel = useMemo(
    () => sendListingTooltipLabel({ checkmarkVisible, hasBeenSent }),
    [checkmarkVisible, hasBeenSent],
  );
  return (
    <HStack alignItems={'center'} ml={'auto'} width={'auto'}>
      <HStack>
        {hasBeenSent && (
          <Badge
            color={'white'}
            bgColor={'#8C9CB0'}
            borderRadius={'lg'}
            px={2}
            py={1}
            display={'flex'}
            alignItems={'center'}
            mr={10}
          >
            <CheckSmallIcon mr={1} /> Sent
          </Badge>
        )}
        <Tooltip label={saveTooltipLabel} placement={'top'}>
          <IconButton
            variant={'blueLight'}
            borderRadius={'lg'}
            size={'sm'}
            fontSize={16}
            aria-label={'Save listing for client'}
            icon={hasBeenSaved ? <HeartIcon /> : <HeartOutlineIcon />}
            isLoading={isSaving}
            onClick={onSave}
          />
        </Tooltip>
        <Tooltip label={sendTooltipLabel} placement={'top'}>
          <IconButton
            variant={'blueLight'}
            borderRadius={'lg'}
            size={'sm'}
            fontSize={16}
            aria-label={'Send listing to client'}
            icon={checkmarkVisible ? <CheckSmallIcon /> : <PaperPlaneIcon />}
            isLoading={isSending}
            onClick={onSend}
          />
        </Tooltip>
      </HStack>
    </HStack>
  );
};
