import React, { ReactElement, useCallback } from 'react';
import {
  Avatar,
  Checkbox,
  Flex,
  Text,
  VStack,
  useBoolean,
} from '@chakra-ui/react';
import { Card } from 'components/Global/Card';
import { Clients, CustomBoolean } from 'services/apiTypes';
import { mediaServer } from 'services/mediaServer';
import {
  useClientSavedListingPost,
  useClientsSavedListingsDelete,
} from 'services/hooks/api/clients/useClientsSavedListings';
import { useParams } from 'react-router-dom';
import { ListingRouteParams } from 'scenes/Listing';
import { ClientListingActions } from 'components/Global/ClientListingActions';
import { useSendListing } from 'services/hooks/useSendListing';

export type MatchingClientTileProps = Pick<
  Clients,
  'name' | 'photo' | 'email' | 'user_id'
> & {
  isSelected: boolean;
  onSelect: (id: string) => void;
  hasBeenSaved: CustomBoolean;
  hasBeenSent: CustomBoolean;
  hasBeenRecentlySent: boolean;
};
export const MatchingClientTile = ({
  name,
  photo,
  email,
  isSelected,
  onSelect,
  user_id,
  hasBeenSaved,
  hasBeenSent,
  hasBeenRecentlySent,
}: MatchingClientTileProps): ReactElement => {
  const { mutate: saveForClient, isLoading: isSavingForClient } =
    useClientSavedListingPost();
  const { mutate: removeFromSaved, isLoading: isRemovingFromSaved } =
    useClientsSavedListingsDelete();
  const { send, isSendingListing } = useSendListing();
  const [checkmarkVisible, setCheckmarkVisible] = useBoolean(false);
  const { id } = useParams<ListingRouteParams>();

  const showCheckmark = useCallback((): void => {
    setCheckmarkVisible.on();
    setTimeout(() => setCheckmarkVisible.off(), 3000);
  }, [setCheckmarkVisible]);

  const handleSendToClient = async () => {
    if (isSendingListing) {
      return;
    }

    await send({
      listingIds: [id],
      clientIds: [user_id],
    });
    showCheckmark();
  };

  const toggleSavedForClient = () => {
    if (hasBeenSent) {
      return;
    }
    if (hasBeenSaved) {
      removeFromSaved({ clientId: user_id, listingId: id });
    } else {
      saveForClient({ clientId: user_id, listingId: id });
    }
  };

  const imageSize = 'jango-contact';
  return (
    <Card role={'listitem'}>
      <Flex alignItems={'center'} w={'100%'}>
        <Checkbox
          label={'Check'}
          isChecked={isSelected}
          onChange={() => onSelect(user_id)}
          mr={4}
        />

        <Avatar
          src={mediaServer(photo, imageSize)}
          name={name}
          w={38}
          mr={3}
          h={38}
          borderRadius={'md'}
          alignSelf={'center'}
        />

        <VStack spacing={0} alignItems={'flex-start'}>
          <Text>{name}</Text>
          <Text fontSize={'xs'}>{email}</Text>
        </VStack>

        <ClientListingActions
          hasBeenSaved={!!+hasBeenSaved}
          hasBeenSent={!!+hasBeenSent}
          isSaving={isSavingForClient || isRemovingFromSaved}
          checkmarkVisible={checkmarkVisible || hasBeenRecentlySent}
          onSave={toggleSavedForClient}
          onSend={handleSendToClient}
          isSending={isSendingListing}
        />
      </Flex>
    </Card>
  );
};
