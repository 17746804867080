import { StringOrNumber } from './apiTypes';

export const pluralize = (
  count: StringOrNumber,
  noun: string,
  suffix = 's',
): string =>
  `${noun}${
    (typeof count === 'string' ? parseInt(count, 10) : count) !== 1
      ? suffix
      : ''
  }`;

export const pluralizeWithCount = (
  count: StringOrNumber,
  noun: string,
  suffix = 's',
): string => `${count} ${pluralize(count, noun, suffix)}`;
