import React, { ReactElement } from 'react';
import { SectionSubTitle } from '../../../Global/SectionSubTitle';
import { Box, SimpleGrid, Skeleton } from '@chakra-ui/react';
import {
  ApartmentTile,
  ApartmentTileSkeleton,
} from '../../../Global/ApartmentTile';
import { SearchType } from '../../../../services/apiTypes';
import { mediaServer } from '../../../../services/mediaServer';
import { useHistory, useParams } from 'react-router-dom';
import { RoundedBoxSection } from '../../../Global/RoundedBoxSection';
import { Dropdown } from '../../../Global/Dropdown';
import { useClientsSavedSearch } from '../../../../services/hooks/api/clients/useClientsSavedSearch';
import { useClient } from 'services/hooks/api/clients/useClient';
import { ClientRouteParams } from 'types/ClientRouteParams';

export const Apartments = (): ReactElement => {
  const [searchType, setSearchType] = React.useState<SearchType>('sale');
  const { clientId } = useParams<ClientRouteParams>();

  const history = useHistory();

  const redirect = (listing: string) => {
    history.push(`/listing/${listing}/details`);
  };

  const { data: clientData } = useClient(clientId);

  const searches = clientData?.searches || [];

  const { data: clientSavedSearch, isLoading } = useClientsSavedSearch(
    searches.find((s) => s.search.ad_type === searchType)?.search_id || '',
  );

  if (!clientData) return <Skeleton />;

  const typeOptions = [...new Set(searches.map((s) => s.search.ad_type))];

  return (
    <RoundedBoxSection>
      <Box pos={'relative'}>
        {typeOptions.length > 1 && (
          <Dropdown
            options={typeOptions}
            value={searchType}
            onValueChange={(val) => setSearchType(val as SearchType)}
            pos={'absolute'}
            right={0}
          />
        )}

        <Box>
          <SectionSubTitle mt={1} mb={4}>
            Newest Apartments
          </SectionSubTitle>

          <SimpleGrid
            gap={{ base: 2, lg: 3, xl: 5 }}
            columns={{ base: 0, lg: 2, xl: 3 }}
          >
            {isLoading && (
              <>
                <ApartmentTileSkeleton />
                <ApartmentTileSkeleton />
                <ApartmentTileSkeleton />
              </>
            )}

            {clientSavedSearch?.map(
              ({
                advertisement_id,
                photo_featured,
                bathrooms,
                bedrooms,
                price,
                open_house_info,
                address,
              }) => (
                <ApartmentTile
                  key={advertisement_id}
                  imageSrc={mediaServer(photo_featured)}
                  price={price}
                  title={address}
                  openHouseInfo={open_house_info}
                  baths={bathrooms}
                  beds={bedrooms}
                  onClick={() => redirect(advertisement_id)}
                  cursor={'pointer'}
                />
              ),
            )}
          </SimpleGrid>
        </Box>
      </Box>
    </RoundedBoxSection>
  );
};
