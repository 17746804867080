import { Flex, FlexProps, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useMemo, useState } from 'react';
import SectionSubHeading from '../../../AgentProfile/AgentTabs/components/PersonalDetails/shared/SectionSubHeading';
import { currencyFormat } from '../../../../services/currencyFormat';
import AgentsDropdown from '../../LowerSection/AgentsDropdown';
import FormRow from './FormRow';
import { Commission } from '../../../../services/hooks/api/deals/apiTypes';
import { Dropdown } from '../../../Global/Dropdown';
import { PriceInput } from './PriceInput';
import { CommissionFeeKey } from '../index';

interface AmountBoxProps extends FlexProps {
  colorScheme?: 'green' | 'purple';
  amount?: number;
}

const AmountBox = ({ colorScheme, amount = 0, ...props }: AmountBoxProps) => {
  const color = {
    bg: 'transparent',
    c: 'pbGray.500',
  };

  if (colorScheme === 'green') {
    color.bg = 'pbGreen.200';
    color.c = 'pbGreen.300';
  }

  if (colorScheme === 'purple') {
    color.bg = 'pbPurple.200';
    color.c = 'pbPurple.500';
  }

  return (
    <Flex
      borderRadius={'md'}
      bg={color.bg}
      align={'center'}
      justify={'center'}
      minW={90}
      px={2}
      h={50}
      {...props}
    >
      <Text fontWeight={'bold'} color={color.c}>
        {currencyFormat(amount || 0)}
      </Text>
    </Flex>
  );
};

const commissionsFieldsMap = [
  {
    label: 'Streeteasy',
    description: 'StreetEasy',
  },
  {
    label: 'Messenger',
    description: 'Messenger',
  },
  {
    label: 'Cards',
    description: 'Cards',
  },
  {
    label: 'Misc Fees',
    description: 'Miscellaneous fees',
  },
  {
    label: 'Referral Fee',
    description: 'Referral fee',
  },
];

const splitDropdown = [
  '0%',
  '5%',
  '10%',
  '15%',
  '20%',
  '25%',
  '30%',
  '33%',
  '35%',
  '40%',
  '45%',
  '50%',
];

interface CommissionBreakdownProps {
  onSetField: (field: string, value: string, agentId?: string) => void;
  commissions?: Commission[];
  commissionType: CommissionFeeKey;
  isDisabled?: boolean;
}

export const CommissionBreakdown = ({
  commissions,
  onSetField,
  commissionType,
  isDisabled,
}: CommissionBreakdownProps): ReactElement => {
  const [splitValue, setSplitValue] = useState<string>(splitDropdown[0]);

  const commissionAmount = useMemo(
    () =>
      commissions?.find(
        (c) => c.description === 'Amount of check received at signing',
      )?.[commissionType],
    [commissions, commissionType],
  );

  const commissionAfterDeductions = useMemo<number>(() => {
    const commissionsSum =
      commissions
        ?.filter((c) => c?.deducts)
        ?.filter((c) => Boolean(c[commissionType]))
        ?.map((c) => parseInt(c[commissionType]))
        .reduce((a, b) => a + b, 0) || 0;

    return commissionAmount - commissionsSum;
  }, [commissionAmount, commissions, commissionType]);

  const splitToCompany = useMemo<number>(() => {
    return !splitValue
      ? commissionAfterDeductions
      : commissionAfterDeductions * (parseInt(splitValue) / 100);
  }, [commissionAfterDeductions, splitValue]);

  const netToAgent = useMemo<number>(() => {
    return commissionAfterDeductions - splitToCompany;
  }, [splitToCompany, commissionAfterDeductions]);
  const [agentsSearch, setAgentsSearch] = useState('');

  return (
    <>
      <SectionSubHeading>Commission Breakdown</SectionSubHeading>

      <VStack align={'stretch'}>
        <FormRow label={'Gross Commission Amount'} isLabelBlack>
          <AmountBox amount={commissionAmount} colorScheme={'green'} />
        </FormRow>
        {commissionsFieldsMap.map((field) => (
          <FormRow label={field.label} key={field.label}>
            {field.label === 'Referral Fee' && (
              <AgentsDropdown
                search={agentsSearch}
                onSetSearch={setAgentsSearch}
                onSelect={(agentId) =>
                  onSetField(
                    field.description,
                    commissions?.find((c) => c.description === field.label)?.[
                      commissionType
                    ] || '',
                    agentId,
                  )
                }
              />
            )}

            <PriceInput
              ml={'auto'}
              placeholder={currencyFormat('50.5')}
              w={90}
              onChange={(e) => onSetField(field.description, e.target.value)}
              value={
                commissions?.find((c) => c.description === field.description)?.[
                  commissionType
                ]
              }
              isDisabled={isDisabled}
            />
          </FormRow>
        ))}

        <FormRow label={'Commission Amount After Deductions'}>
          <AmountBox amount={commissionAfterDeductions} />
        </FormRow>

        <FormRow label={'Split to Company'}>
          <Dropdown
            options={splitDropdown}
            onValueChange={setSplitValue}
            value={splitValue}
            variant={'grayDropdown'}
            minW={{ base: '0', lg: 0 }}
          />

          <AmountBox amount={splitToCompany} ml={'auto'} />
        </FormRow>

        <FormRow label={'Net to Agent'} isLabelBlack>
          <AmountBox amount={netToAgent} colorScheme={'purple'} />
        </FormRow>
      </VStack>
    </>
  );
};
