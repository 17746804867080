import React, { ReactElement } from 'react';
import { SimpleGrid, Skeleton } from '@chakra-ui/react';
import { MergedListingInfo } from '../../services/apiTypes';
import { SearchListingTile } from './SearchListingTile';

interface SearchResultsGridProps {
  data?: MergedListingInfo[];
  isShrinked?: boolean;
  isLoading?: boolean;
}

export const SearchResultGrid = ({
  data,
  isLoading = true,
  isShrinked = false,
}: SearchResultsGridProps): ReactElement => {
  const columnsCount = isShrinked
    ? { md: 2, '2xl': 3 }
    : { md: 2, lg: 3, '2xl': 4 };

  return (
    <SimpleGrid columns={columnsCount} gridGap={{ base: 3, lg: 5 }}>
      {isLoading && <Skeleton height={'300px'} />}

      {data?.map((el) => (
        <SearchListingTile key={el.advertisement_id} element={el} />
      ))}
    </SimpleGrid>
  );
};
