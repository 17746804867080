import { Box, Text } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { BuildingListings } from '../../services/apiTypes';
import { ListingRow, ListingRowType } from './components/ListingRow';

interface BuildingListingsTableProps {
  data?: BuildingListings[];
  type: ListingRowType;
  isLoading: boolean;
}

export const BuildingListingsTable = ({
  data,
  type,
  isLoading,
}: BuildingListingsTableProps): ReactElement => {
  return (
    <Box minW={'fit-content'}>
      {isLoading && <Skeleton h={74} mb={3} borderRadius={'lg'} />}

      {!isLoading && !data?.length && <Text m={5}>No listings</Text>}

      {!!data && data.length > 0 && (
        <>
          <ListingRow isHeader type={type} />

          {data.map((row) => (
            <ListingRow data={row} type={type} key={row.advertisement_id} />
          ))}
        </>
      )}
    </Box>
  );
};
