import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import Slider, { Settings } from 'react-slick';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';

import 'slick-carousel/slick/slick-theme.css';
import { mediaServer } from '../../services/mediaServer';

interface PhotoSliderPopupProps {
  isOpen: boolean;
  onClose: () => void;
  clickedPhoto?: number;
  photos?: string[];
  transcodePhotos?: string;
}

export const PhotoSliderPopup = ({
  onClose,
  isOpen,
  clickedPhoto = 0,
  photos,
  transcodePhotos,
}: PhotoSliderPopupProps): ReactElement => {
  const settings: Settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    centerMode: true,
    centerPadding: '0',
    initialSlide: clickedPhoto,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc
      closeOnOverlayClick
      blockScrollOnMount
      isCentered
      scrollBehavior={'outside'}
      size={'full'}
    >
      <ModalOverlay />
      <ModalContent bgColor={'transparent'} boxShadow={'none'}>
        <ModalCloseButton zIndex={1} bgColor={'white'} />
        <ModalBody
          py={0}
          px={5}
          d={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box w={'90%'} maxH={'90%'}>
            <Slider {...settings}>
              {photos?.map((photo) => (
                <ImageWithPlaceholder
                  key={photo}
                  src={mediaServer(photo, transcodePhotos)}
                  w={'540px'}
                  h={'auto'}
                  minHeight={'333px'}
                  objectFit={'contain'}
                  borderRadius={'10px'}
                  maxH={'600px'}
                />
              ))}
            </Slider>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
