import React, { ReactElement } from 'react';
import { Button, SimpleGrid } from '@chakra-ui/react';
import LowerSection from 'components/Deals/LowerSection/LowerSection';
import UpperLeftSection from 'components/Deals/UpperLeftSection/UpperLeftSection';
import UpperRightSection from 'components/Deals/UpperRightSection/UpperRightSection';
import { Main } from 'components/Global/Main';
import { PageHeading } from 'components/Global/PageHeading';
import { PageTitle } from 'components/Global/PageTitle';
import { DealsContextProvider } from 'contexts/DealsContext';
import { NewDealModal } from '../components/Deals/NewDealModal';
import { useModal } from 'contexts/ModalContext';

const Deals = (): ReactElement => {
  const { openModal, defaultModalProps } = useModal();
  return (
    <DealsContextProvider>
      <Main>
        <PageHeading justifyContent={'space-between'}>
          <PageTitle>Deals</PageTitle>

          <Button
            fontSize={12}
            variant={'blueLight'}
            onClick={() => openModal(<NewDealModal {...defaultModalProps} />)}
          >
            Add New Deal
          </Button>
        </PageHeading>
        <SimpleGrid
          columns={{ base: 1, xl: 2 }}
          gap={{ base: 3, xl: 5 }}
          mb={{ base: 3, xl: 5 }}
        >
          <UpperLeftSection />
          <UpperRightSection />
        </SimpleGrid>

        <LowerSection />
      </Main>
    </DealsContextProvider>
  );
};

export default Deals;
