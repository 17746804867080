import React, { ReactElement } from 'react';
import { Neighborhood, StringOrNumber } from '../../services/apiTypes';
import { RangesRow } from './RangesRow';
import { CheckboxesRow } from './CheckboxesRow';
import { interpolateNeighborhoodValues } from '../../services/neighborhoods';
import { FormSectionRow } from '../Search/SearchForm';

type Props = {
  sections?: string[];
  value: string[];
  neighborhoods: Neighborhood[];
  onChange: (values: StringOrNumber[]) => void;
};

export const RangesSections = ({
  sections,
  neighborhoods,
  onChange,
  value,
}: Props): ReactElement => {
  return (
    <>
      {sections && sections.length > 0 ? (
        sections.map((section, index) => {
          const sectionNeighborhoods = neighborhoods.filter(
            (neighborhood) => neighborhood.section === index,
          );

          return (
            <FormSectionRow key={section}>
              <RangesRow
                label={section}
                neighborhoods={sectionNeighborhoods.map((s) =>
                  interpolateNeighborhoodValues(s),
                )}
                onChange={onChange}
                value={value}
              />
            </FormSectionRow>
          );
        })
      ) : (
        <FormSectionRow>
          <CheckboxesRow
            onChange={onChange}
            value={value}
            options={neighborhoods.map(({ abbreviation }) => abbreviation)}
          />
        </FormSectionRow>
      )}
    </>
  );
};
