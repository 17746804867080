import React, { ReactElement, useRef, useState } from 'react';
import { RefreshIcon } from '../../icons/icons';
import { SearchInput } from '../Form/SearchInput';
import { useMailbox } from 'contexts/MailboxContext';
import { useKeyboardClick } from 'services/hooks/useKeyboardClick';
import { ThreadsActions } from './ThreadsActions';
import { Button, ButtonGroup, Checkbox, Flex } from '@chakra-ui/react';

export const MailboxHeading = (): ReactElement => {
  const headingRef = useRef<HTMLDivElement>(null);
  const {
    data: { areAllThreadsChecked, isFetchingThreads, searchValue },
    actions: {
      checkAllThreads,
      refetchThreads,
      clearSelectedThreads,
      setSearchValue,
    },
  } = useMailbox();

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  useKeyboardClick(() => setSearchValue(searchInputValue), 'Enter');

  return (
    <Flex
      pl={{ base: 2, xl: 5 }}
      mb={4}
      wrap={{ base: 'wrap', xl: 'nowrap' }}
      ref={headingRef}
      alignItems={'center'}
    >
      <Checkbox
        isChecked={areAllThreadsChecked}
        onChange={areAllThreadsChecked ? clearSelectedThreads : checkAllThreads}
        mr={18}
        fontSize={14}
        sx={{
          '.chakra-checkbox__label': {
            fontSize: { base: 12, lg: 14 },
          },
        }}
      >
        Check All
      </Checkbox>

      <ButtonGroup spacing={2}>
        <Button
          fontSize={{ base: 12, lg: 14 }}
          variant={'blueLight'}
          leftIcon={<RefreshIcon fontSize={16} />}
          onClick={refetchThreads}
          isLoading={isFetchingThreads}
          loadingText={'Loading threads...'}
          minW={85}
          size={'sm'}
        >
          Refresh
        </Button>

        <ThreadsActions />
      </ButtonGroup>

      <SearchInput
        onChange={(e) => setSearchInputValue(e.currentTarget.value)}
        defaultValue={searchValue}
        ml={{ base: 0, sm: 'auto' }}
        mb={{ base: 2, xl: 0 }}
        maxWidth={250}
        fontSize={5}
      />
    </Flex>
  );
};
