import {
  ClientListingInfo,
  MergedListingInfo,
  SearchListingResponse,
} from './apiTypes';

export const mergeListingsData = (
  listingInfo?: ClientListingInfo[] | undefined,
  listings?: SearchListingResponse[],
): MergedListingInfo[] | [] => {
  if (!listings) {
    return [];
  }

  if (!listingInfo?.length) {
    return listings as MergedListingInfo[];
  }
  return listingInfo?.map((listingData) => {
    const foundListing = listings?.find(
      (listing: SearchListingResponse) =>
        listing.advertisement_id === listingData.advertisement_id,
    );

    return { ...foundListing, ...listingData };
  }) as MergedListingInfo[];
};
