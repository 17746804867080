import { useEffect } from 'react';

export const useKeyboardClick = (
  action: () => void,
  key: string | string[] = 'Esc',
): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (Array.isArray(key) && key.includes(event.key)) ||
        (!Array.isArray(key) && event.key === key)
      ) {
        action();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [action, key]);
};
