import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { ReactComponent as D360SVG } from './360-degrees.svg';
import { ReactComponent as AllInboxSVG } from './all-inbox.svg';
import { ReactComponent as ArrowSVG } from './arrow.svg';
import { ReactComponent as AttachSVG } from './attach.svg';
import { ReactComponent as CalendarSVG } from './calendar.svg';
import { ReactComponent as ChevronSVG } from './chevron-down.svg';
import { ReactComponent as ClientSVG } from './client.svg';
import { ReactComponent as DotsSVG } from './dots.svg';
import { ReactComponent as EnvelopSVG } from './envelop.svg';
import { ReactComponent as FiltersSVG } from './filters.svg';
import { ReactComponent as FloorPlanSVG } from './floor-plan.svg';
import { ReactComponent as ForwardSVG } from './forward.svg';
import { ReactComponent as GridSVG } from './grid-icon.svg';
import { ReactComponent as ListSVG } from './list-icon.svg';
import { ReactComponent as MessageReadSVG } from './message-read.svg';
import { ReactComponent as PaperPlaneSVG } from './paperplane.svg';
import { ReactComponent as PencilSVG } from './pencil.svg';
import { ReactComponent as PencilBlueSVG } from './pencil-blue.svg';
import { ReactComponent as PlusInCircleSVG } from './plus-in-a-circle.svg';
import { ReactComponent as PrintSVG } from './print.svg';
import { ReactComponent as RefreshSVG } from './refresh.svg';
import { ReactComponent as SentSVG } from './sent.svg';
import { ReactComponent as StarSVG } from './star.svg';
import { ReactComponent as TrashSVG } from './trash.svg';
import { ReactComponent as TrashBlueSVG } from './trash-blue.svg';
import { ReactComponent as CheckSVG } from './check.svg';
import { ReactComponent as HeartSVG } from './heart.svg';
import { ReactComponent as HeartOutlineSVG } from './heart-outline.svg';
import { ReactComponent as CheckSmallSVG } from './check-small.svg';
import { ReactComponent as ClockSVG } from './clock.svg';
import { ReactComponent as VisibleSVG } from './visible.svg';
import { ReactComponent as LandSalesSVG } from './land-sales.svg';
import { ReactComponent as SellPropertySVG } from './sell-property.svg';
import { ReactComponent as TodaySVG } from './today.svg';
import { ReactComponent as WalletSVG } from './wallet.svg';
import { ReactComponent as CameraSVG } from './camera.svg';
import { ReactComponent as PhoneSVG } from './phone.svg';
import { ReactComponent as EnvelopEmptySVG } from './envelop-empty.svg';
import { ReactComponent as MessagesSVG } from './messages.svg';
import { ReactComponent as NotificationsSVG } from './notifications.svg';
import { ReactComponent as FilterActiveSVG } from './filter-active.svg';
import { ReactComponent as FilterInactiveSVG } from './filter-inactive.svg';
import { ReactComponent as CloseSVG } from './close.svg';
import { ReactComponent as PencilGrayShadowSVG } from './pencil-gray-shadow.svg';
import { ReactComponent as PencilGraySVG } from './pencil-gray.svg';
import { ReactComponent as VisaSVG } from './visa.svg';
import { ReactComponent as AmexSVG } from './amex.svg';
import { ReactComponent as MastercardSVG } from './mastercard.svg';
import { ReactComponent as ReadSVG } from './read.svg';
import { ReactComponent as UnreadSVG } from './unread.svg';
import { ReactComponent as SearchSVG } from './search.svg';
import { ReactComponent as SearchGraySVG } from './search-gray.svg';
import { ReactComponent as DownloadSVG } from './download.svg';
import { ReactComponent as DocSVG } from './doc-ext.svg';
import { ReactComponent as PdfSVG } from './pdf-ext.svg';
import { ReactComponent as ImgSVG } from './img-ext.svg';
import { ReactComponent as UnknownSVG } from './unknown-ext.svg';
import { ReactComponent as TxtSVG } from './txt-ext.svg';
import { ReactComponent as XlsxSVG } from './xlsx-ext.svg';
import { ReactComponent as ZipSVG } from './zip-ext.svg';
import { ReactComponent as NoFileSVG } from './no-file.svg';
import { ReactComponent as ArrowLeftSVG } from './arrow-left.svg';
import { ReactComponent as RestoreSVG } from './restore.svg';

import { ReactComponent as PlusSVG } from './plus.svg';

export const PlusInACircle = (props: IconProps): ReactElement => (
  <Icon viewBox={'0 0 18 18'} {...props}>
    <path
      fill={'currentColor'}
      d={
        'M8.613 4.304v4.31H4.304v.783h4.31v4.309h.783v-4.31h4.309v-.783h-4.31V4.304zM9.005.783a8.216 8.216 0 018.222 8.222 8.216 8.216 0 01-8.222 8.222A8.216 8.216 0 01.783 9.005 8.216 8.216 0 019.005.783zM9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z'
      }
    />
  </Icon>
);

export const ChevronDown = (props: IconProps): ReactElement => (
  <Icon viewBox={'0 0 12 7'} color={'pbBlue.100'} {...props}>
    <path
      fill={'currentColor'}
      d={
        'M11.443 0a.532.532 0 00-.381.18L5.999 5.594.935.18A.53.53 0 00.544.004.545.545 0 00.04.368a.61.61 0 00.125.636l5.448 5.825a.52.52 0 00.77 0l5.45-5.825a.61.61 0 00.125-.644.546.546 0 00-.515-.36z'
      }
    />
  </Icon>
);

export const DotsIcon = (props: IconProps): ReactElement => (
  <Icon as={DotsSVG} h={4} color={'pbBlue.100'} {...props} />
);
export const PaperPlaneIcon = (props: IconProps): ReactElement => (
  <Icon as={PaperPlaneSVG} {...props} />
);
export const PrintIcon = (props: IconProps): ReactElement => (
  <Icon as={PrintSVG} {...props} />
);
export const CalendarIcon = (props: IconProps): ReactElement => (
  <Icon as={CalendarSVG} {...props} />
);
export const AllInboxIcon = (props: IconProps): ReactElement => (
  <Icon as={AllInboxSVG} {...props} />
);
export const AttachIcon = (props: IconProps): ReactElement => (
  <Icon as={AttachSVG} {...props} />
);
export const PencilIcon = (props: IconProps): ReactElement => (
  <Icon as={PencilSVG} {...props} />
);
export const SentIcon = (props: IconProps): ReactElement => (
  <Icon as={SentSVG} {...props} />
);
export const StarIcon = (props: IconProps): ReactElement => (
  <Icon as={StarSVG} {...props} />
);
export const TrashIcon = (props: IconProps): ReactElement => (
  <Icon as={TrashSVG} {...props} />
);
export const TrashBlueIcon = (props: IconProps): ReactElement => (
  <Icon as={TrashBlueSVG} {...props} />
);
export const RefreshIcon = (props: IconProps): ReactElement => (
  <Icon as={RefreshSVG} {...props} />
);
export const FiltersIcon = (props: IconProps): ReactElement => (
  <Icon as={FiltersSVG} {...props} />
);
export const MessageReadIcon = (props: IconProps): ReactElement => (
  <Icon as={MessageReadSVG} {...props} />
);
export const ArrowIcon = (props: IconProps): ReactElement => (
  <Icon as={ArrowSVG} {...props} />
);
export const D360Icon = (props: IconProps): ReactElement => (
  <Icon as={D360SVG} {...props} />
);
export const ChevronIcon = (props: IconProps): ReactElement => (
  <Icon as={ChevronSVG} {...props} />
);
export const ClientIcon = (props: IconProps): ReactElement => (
  <Icon as={ClientSVG} {...props} />
);
export const EnvelopIcon = (props: IconProps): ReactElement => (
  <Icon as={EnvelopSVG} {...props} />
);
export const FloorPlanIcon = (props: IconProps): ReactElement => (
  <Icon as={FloorPlanSVG} {...props} />
);
export const ForwardIcon = (props: IconProps): ReactElement => (
  <Icon as={ForwardSVG} {...props} />
);
export const GridIcon = (props: IconProps): ReactElement => (
  <Icon as={GridSVG} {...props} />
);
export const ListIcon = (props: IconProps): ReactElement => (
  <Icon as={ListSVG} {...props} />
);
export const PlusInCircleIcon = (props: IconProps): ReactElement => (
  <Icon as={PlusInCircleSVG} {...props} />
);
export const CheckIcon = (props: IconProps): ReactElement => (
  <Icon as={CheckSVG} {...props} />
);
export const CheckSmallIcon = (props: IconProps): ReactElement => (
  <Icon as={CheckSmallSVG} {...props} />
);
export const HeartIcon = (props: IconProps): ReactElement => (
  <Icon as={HeartSVG} {...props} />
);
export const HeartOutlineIcon = (props: IconProps): ReactElement => (
  <Icon as={HeartOutlineSVG} {...props} />
);
export const ClockIcon = (props: IconProps): ReactElement => (
  <Icon as={ClockSVG} {...props} />
);
export const VisibleIcon = (props: IconProps): ReactElement => (
  <Icon as={VisibleSVG} {...props} />
);
export const LandSalesIcon = (props: IconProps): ReactElement => (
  <Icon as={LandSalesSVG} {...props} />
);
export const SellPropertyIcon = (props: IconProps): ReactElement => (
  <Icon as={SellPropertySVG} {...props} />
);
export const TodayIcon = (props: IconProps): ReactElement => (
  <Icon as={TodaySVG} {...props} />
);
export const WalletIcon = (props: IconProps): ReactElement => (
  <Icon as={WalletSVG} {...props} />
);
export const CameraIcon = (props: IconProps): ReactElement => (
  <Icon as={CameraSVG} {...props} />
);
export const PhoneIcon = (props: IconProps): ReactElement => (
  <Icon as={PhoneSVG} {...props} />
);
export const EnvelopEmptyIcon = (props: IconProps): ReactElement => (
  <Icon as={EnvelopEmptySVG} {...props} />
);
export const MessagesIcon = (props: IconProps): ReactElement => (
  <Icon as={MessagesSVG} {...props} />
);
export const NotificationsIcon = (props: IconProps): ReactElement => (
  <Icon as={NotificationsSVG} {...props} />
);
export const FilterActiveIcon = (props: IconProps): ReactElement => (
  <Icon as={FilterActiveSVG} {...props} />
);
export const FilterInactiveIcon = (props: IconProps): ReactElement => (
  <Icon as={FilterInactiveSVG} {...props} />
);
export const CloseIcon = (props: IconProps): ReactElement => (
  <Icon as={CloseSVG} {...props} />
);
export const PencilGrayShadowIcon = (props: IconProps): ReactElement => (
  <Icon as={PencilGrayShadowSVG} {...props} />
);
export const PencilGrayIcon = (props: IconProps): ReactElement => (
  <Icon as={PencilGraySVG} {...props} />
);
export const PencilBlueIcon = (props: IconProps): ReactElement => (
  <Icon as={PencilBlueSVG} {...props} />
);
export const VisaIcon = (props: IconProps): ReactElement => (
  <Icon as={VisaSVG} {...props} />
);
export const AmexIcon = (props: IconProps): ReactElement => (
  <Icon as={AmexSVG} {...props} />
);
export const MastercardIcon = (props: IconProps): ReactElement => (
  <Icon as={MastercardSVG} {...props} />
);
export const MarkAsReadIcon = (props: IconProps): ReactElement => (
  <Icon as={ReadSVG} {...props} />
);
export const MarkAsUnreadIcon = (props: IconProps): ReactElement => (
  <Icon as={UnreadSVG} {...props} />
);
export const DownloadIcon = (props: IconProps): ReactElement => (
  <Icon as={DownloadSVG} {...props} />
);
export const SearchIcon = (props: IconProps): ReactElement => (
  <Icon as={SearchSVG} {...props} />
);
export const SearchGrayIcon = (props: IconProps): ReactElement => (
  <Icon as={SearchGraySVG} {...props} />
);
export const PlusIcon = (props: IconProps): ReactElement => (
  <Icon as={PlusSVG} {...props} />
);
export const ArrowLeftIcon = (props: IconProps): ReactElement => (
  <Icon as={ArrowLeftSVG} {...props} />
);
export const RestoreIcon = (props: IconProps): ReactElement => (
  <Icon as={RestoreSVG} {...props} />
);

// file icons
export const DocIcon = (props: IconProps): ReactElement => (
  <Icon as={DocSVG} {...props} />
);
export const PdfIcon = (props: IconProps): ReactElement => (
  <Icon as={PdfSVG} {...props} />
);
export const ImgIcon = (props: IconProps): ReactElement => (
  <Icon as={ImgSVG} {...props} />
);
export const TxtIcon = (props: IconProps): ReactElement => (
  <Icon as={TxtSVG} {...props} />
);
export const XlsxIcon = (props: IconProps): ReactElement => (
  <Icon as={XlsxSVG} {...props} />
);
export const ZipIcon = (props: IconProps): ReactElement => (
  <Icon as={ZipSVG} {...props} />
);
export const UnknownExtensionIcon = (props: IconProps): ReactElement => (
  <Icon as={UnknownSVG} {...props} />
);
export const NoFileIcon = (props: IconProps): ReactElement => (
  <Icon as={NoFileSVG} {...props} />
);
