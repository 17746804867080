import React, { ReactElement } from 'react';
import { Flex, HStack } from '@chakra-ui/layout';
import { Button, Tab, TabList, Tabs } from '@chakra-ui/react';
import { SearchInput } from 'components/Form/SearchInput';
import { RoundedBox } from 'components/Global/RoundedBox';
import LowerSectionTable, { LowerSectionTableProps } from './LowerSectionTable';
import { useContext, useState } from 'react';
import { useDealsContext } from 'contexts/DealsContext';
import {
  onSetAgentId,
  onSetDealStatus,
  onSetSearch,
} from 'contexts/DealsContext/actions';
import { Dropdown } from 'components/Global/Dropdown';
import { useEffect } from 'react';
import { DealStatus } from 'services/apiTypes';
import { checkIfUserHasRole } from 'services/checkIfUserHasRole';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import AgentsDropdown from './AgentsDropdown';
import { UserRole } from 'services/hooks/api/agent-profile/apiTypes';

const mappedDealStatuses = Object.values(DealStatus)
  .filter((el) => typeof el === 'string')
  .map((el) => el.toString().replaceAll('_', ' '));

const lowerSectionColumns: LowerSectionTableProps = {
  columns: [
    {
      width: '164px',
      headerLabel: 'Client (Status)',
    },
    {
      width: '126px',
      headerLabel: 'Agent',
    },
    {
      width: '141px',
      headerLabel: 'Fee Amount',
    },
    {
      width: '306px',
      headerLabel: 'Address Management',
    },
    {
      width: '138px',
      headerLabel: 'Move In Date',
    },
    {
      width: '159px',
      headerLabel: 'Move Out Date',
    },
    {
      width: '169px',
      headerLabel: 'Money Received',
    },
    {
      width: '149px',
      headerLabel: 'Paid to Agent',
    },
    {
      width: '153px',
      headerLabel: 'Days Outstanding',
    },
  ],
};

const LowerSection = (): ReactElement => {
  const [activeTab, setActiveTab] = useState(0);

  const { dealsState, dealsDispatch } = useDealsContext();
  const authContext = useContext(AuthContext);

  const isSupervisor = checkIfUserHasRole(
    UserRole.SUPERVISOR,
    authContext?.user,
  );

  useEffect(() => {
    if (authContext?.user) {
      dealsDispatch(
        onSetAgentId(isSupervisor ? 'all' : authContext?.user.user_id),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext?.user]);

  return (
    <RoundedBox>
      <Flex justify={'space-between'} mb={{ base: 5, md: 10 }} wrap={'wrap'}>
        <Flex wrap={'wrap'}>
          <Tabs
            isLazy
            defaultIndex={0}
            index={activeTab}
            onChange={setActiveTab}
            mr={'10px'}
          >
            <TabList>
              <Tab h={'38px'}>Deals</Tab>
              <Tab className={'notImplemented'} h={'38px'}>
                2020 Total
              </Tab>
            </TabList>
          </Tabs>

          <HStack mb={{ base: 1, sm: 0 }}>
            {isSupervisor && (
              <AgentsDropdown
                search={dealsState.search}
                onSelect={(id) => dealsDispatch(onSetAgentId(id))}
                onSetSearch={(s) => dealsDispatch(onSetSearch(s))}
              />
            )}
            <Dropdown
              options={mappedDealStatuses}
              onValueChange={(value) => dealsDispatch(onSetDealStatus(value))}
              value={dealsState.dealStatus || 'Status'}
              fontSize={'14px'}
              variant={'grayDropdown'}
              menuProps={{
                placement: 'bottom-end',
              }}
            />
          </HStack>
        </Flex>

        <HStack spacing={'10px'} ml={'auto'}>
          <SearchInput
            onChange={(event) => dealsDispatch(onSetSearch(event.target.value))}
          />
          {/* TODO: wait for endpoint */}
          <Button
            className={'notImplemented'}
            bg={'pbBlue.510'}
            color={'pbBlue.500'}
            h={'38px'}
          >
            Export Data
          </Button>
        </HStack>
      </Flex>

      <LowerSectionTable columns={lowerSectionColumns.columns} />
    </RoundedBox>
  );
};

export default LowerSection;
