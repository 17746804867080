import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { OpInvoicesPutBody } from './apiTypes';
interface Params {
  body: OpInvoicesPutBody;
  invoiceId: string;
}

export const useOpInvoicesPut = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ invoiceId, body }: Params) => {
      return pbAgentFetcher(`v1/op-invoices/${invoiceId}`, {
        body: JSON.stringify(body),
        method: 'PUT',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('op-invoices');
      },
    },
  );
};
