import React, { ReactElement, forwardRef } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { BaseInput, BaseInputProps } from './BaseInput';
import { useFormContext } from 'react-hook-form';
import { existingClientText } from '../../constants';
import { Client } from 'services/apiTypes';
import { validationPatterns } from 'services/validation';

export interface FormFieldProps extends BaseInputProps {
  inputType?: string;
  placeholder?: string;
  name: string;
  requiredMessage?: string;
  required?: boolean;
  validationPattern?: {
    value: RegExp;
    message: string;
  };
  rightElement?: ReactElement;
}

const FormField = forwardRef(
  (
    {
      inputType = 'text',
      name,
      placeholder = name,
      required,
      requiredMessage,
      validationPattern,
      rightElement,
      ...rest
    }: FormFieldProps,
    ref: React.LegacyRef<HTMLDivElement>,
  ): ReactElement => {
    const {
      formState: { errors, touchedFields, dirtyFields },
      register,
    } = useFormContext();

    const shouldBeValidated = touchedFields[name] || dirtyFields[name];

    return (
      <FormControl ref={ref} pos={'relative'}>
        <InputGroup>
          <BaseInput
            type={inputType}
            placeholder={`${placeholder}${required ? '*' : ''}`}
            {...register(name, {
              required: required ? requiredMessage : false,
              pattern: validationPattern,
            })}
            _focus={{
              borderColor:
                shouldBeValidated && errors[name] ? 'pbRed.500' : 'gray.300',
            }}
            borderColor={
              shouldBeValidated && errors[name] ? 'pbRed.500' : 'gray.300'
            }
            {...rest}
          />
          {rightElement}
        </InputGroup>
        {shouldBeValidated && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text
                fontSize={10}
                color={'pbRed.500'}
                alignSelf={'flex-start'}
                pl={2}
                pos={'absolute'}
              >
                {message}
              </Text>
            )}
          />
        )}
      </FormControl>
    );
  },
);

FormField.displayName = 'FormField';

export default FormField;
interface EmailFieldProps extends FormFieldProps {
  shouldSuggestExistingUser: boolean;
  fieldsDisabled: boolean;
  onExistingClientClick: () => void;
  existingClient?: Client;
  existingClientVisible: boolean;
}

export const EmailField = forwardRef(
  (
    {
      shouldSuggestExistingUser,
      existingClient,
      existingClientVisible,
      fieldsDisabled,
      onExistingClientClick,
      ...rest
    }: EmailFieldProps,
    ref: React.Ref<HTMLDivElement>,
  ): ReactElement => {
    const rightElement = fieldsDisabled ? (
      <InputRightElement width={'auto'} h={'50px'} pr={4}>
        <Text color={'pbGray.400'} fontSize={'xs'}>
          {existingClientText}
        </Text>
      </InputRightElement>
    ) : undefined;

    return shouldSuggestExistingUser ? (
      <Popover
        placement={'bottom'}
        isOpen={existingClientVisible}
        matchWidth={true}
        autoFocus={false}
      >
        <PopoverTrigger>
          <FormField
            inputType={'email'}
            required={true}
            requiredMessage={'Email is required'}
            validationPattern={validationPatterns.email}
            rightElement={rightElement}
            ref={ref}
            onBlur={existingClientVisible ? onExistingClientClick : undefined}
            {...rest}
          />
        </PopoverTrigger>

        <PopoverContent
          w={'100%'}
          p={2}
          h={'50px'}
          boxShadow={'0px 10px 20px rgba(0, 25, 49, 0.08)'}
        >
          <Flex
            alignItems={'center'}
            px={2}
            h={'100%'}
            _hover={{
              cursor: 'pointer',
              borderRadius: 'lg',
              bgColor: 'pbBlue.510',
            }}
            onClick={onExistingClientClick}
          >
            <Text fontSize={14} flexGrow={1} fontWeight={'bold'}>
              {existingClient?.email}
            </Text>
            <Text fontSize={'xs'} color={'pbGray.400'}>
              {existingClientText}
            </Text>
          </Flex>
        </PopoverContent>
      </Popover>
    ) : (
      <FormField
        inputType={'email'}
        required={true}
        requiredMessage={'Email is required'}
        validationPattern={validationPatterns.email}
        rightElement={rightElement}
        {...rest}
      />
    );
  },
);

EmailField.displayName = 'EmailField';

interface LabeledFormFieldProps extends FormFieldProps {
  label: string;
  labelMinWidth?: string;
}

export const LabeledFormField = forwardRef(
  (
    { label, labelMinWidth, placeholder, name, ...rest }: LabeledFormFieldProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <Flex alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
      <FormLabel htmlFor={name} minW={labelMinWidth}>
        {label}
      </FormLabel>
      <FormField placeholder={placeholder} name={name} {...rest} ref={ref} />
    </Flex>
  ),
);

LabeledFormField.displayName = 'LabeledFormField';
