import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as H from 'history';
import { SearchInput, SearchType, StringOrNumber } from '../apiTypes';
import { objectToUrlParams } from '../objectToUrlParams';
import { useClientContext } from '../../contexts/ClientContext';
import { onSearchActivate } from '../../contexts/ClientContext/actions';

export type AddSearchParam = (
  key: string,
  val: StringOrNumber | StringOrNumber[],
) => void;

type Props = {
  searchParams: URLSearchParams;
  history: H.History;
  addSearchParam: AddSearchParam;
  setSearchParams: (s?: SearchInput) => void;
};

export const defaultValues: SearchInput = {
  ad_type: 'rental',
  offset: 0,
  sort_by: 'time_published',
  sort_dir: 'desc',
  limit: 20,
};

export const useURLSearchParams = (): Props => {
  const { clientsDispatch } = useClientContext();
  const { type } = useParams<{ type: SearchType }>();
  const history = useHistory();
  const { pathname, search } = useLocation();
  let searchParams = new URLSearchParams(search);

  const setSearchParam = (
    key: string,
    val: StringOrNumber | StringOrNumber[],
  ) => {
    searchParams.delete(key);

    if (Array.isArray(val)) {
      val.forEach((v) => searchParams.append(key, v.toString()));
    } else {
      searchParams.append(key, val?.toString());
    }
  };

  const addSearchParam = (
    key: string,
    val: StringOrNumber | StringOrNumber[],
  ) => {
    setSearchParam(key, val);

    history.replace(`${pathname}?${searchParams.toString()}`);
    clientsDispatch(onSearchActivate(false));
  };

  const setSearchParams = (s?: SearchInput) => {
    const defaultSearchObject: SearchInput = {
      ...defaultValues,
      ad_type: type,
    };

    searchParams = new URLSearchParams(objectToUrlParams(defaultSearchObject));

    if (s) {
      Object.keys(s).forEach((key) =>
        setSearchParam(key, s[key as keyof SearchInput] as StringOrNumber),
      );
    }

    history.replace(`${pathname}?${searchParams.toString()}`);
  };

  return {
    searchParams,
    history,
    addSearchParam,
    setSearchParams,
  };
};
