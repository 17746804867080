import React, { ReactElement } from 'react';
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';

export interface ActionModalProps extends ModalProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  onSubmit: () => void;
  title?: string;
  status?: string;
  submitLabel?: string;
  modalBodyProps?: ModalBodyProps;
  modalFooterProps?: ModalFooterProps;
}

export const ActionModal = ({
  isOpen,
  onClose,
  title,
  children,
  onSubmit,
  isDisabled,
  isLoading,
  status,
  size = 'md',
  submitLabel,
  modalBodyProps,
  modalFooterProps,
  ...modalProps
}: ActionModalProps): ReactElement => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    closeOnEsc
    closeOnOverlayClick
    blockScrollOnMount
    isCentered
    scrollBehavior={'inside'}
    size={size}
    motionPreset={'slideInBottom'}
    {...modalProps}
  >
    <ModalOverlay />

    <ModalContent px={10} py={4} overflow={'hidden'}>
      {title && (
        <ModalHeader fontWeight={'bold'} fontSize={'24px'}>
          {title}
        </ModalHeader>
      )}

      <ModalCloseButton top={8} right={16} fontSize={20} />

      <ModalBody {...modalBodyProps} overflowX={'hidden'}>
        {children}
      </ModalBody>

      <Divider
        mt={10}
        mb={8}
        mx={-10}
        w={'150%'}
        backgroundColor={'pbGray.100'}
      />

      <ModalFooter {...modalFooterProps} justifyContent={'center'}>
        {status && (
          <Text mr={3} fontStyle={'italic'} fontSize={12}>
            {status}
          </Text>
        )}
        <Button
          variant={'blue'}
          onClick={onSubmit}
          type={'submit'}
          disabled={isDisabled}
          isLoading={isLoading}
          px={12}
          py={7}
        >
          {submitLabel || 'Submit'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
