import { UseQueryResult, useQuery } from 'react-query';
import { OpInvoice } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';
import { objectToURLSearchParams } from 'services/objectToURLSearchParams';

export type OpInvoiceParams = {
  type: 'rental' | 'sale';
  year?: string;
  month?: string;
};

export const useOpInvoices = (
  params: OpInvoiceParams,
): UseQueryResult<OpInvoice[]> => {
  const urlSearchParams = objectToURLSearchParams<OpInvoiceParams>(params);

  return useQuery<OpInvoice[]>(
    ['op-invoices', urlSearchParams.toString()],
    () => pbAgentFetcher(`v1/op-invoices?${urlSearchParams.toString()}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};
