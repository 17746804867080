import React, { ReactElement } from 'react';
import { Avatar } from '@chakra-ui/avatar';
import { Box, HStack, Heading, Text } from '@chakra-ui/layout';
import { mediaServer } from '../../services/mediaServer';
import { Dot } from '../DottedRow/components/Dot';

// FIXME: Adjust type when we have api endpoint
type listingRowDataType = {
  year: string;
  items: {
    date: string;
    title: string;
    agent: {
      last_name: string;
      first_name: string;
      company: string;
      photo?: string;
    };
  }[];
};

export const ListingHistoryRow = ({
  data,
}: {
  data: listingRowDataType;
}): ReactElement => {
  return (
    <Box mb={5}>
      <HStack>
        <Heading as={'h4'} fontSize={'14px'} color={'pbGray.500'}>
          {data.year}
        </Heading>
        {data.items.length === 0 && (
          <Text fontSize={'14px'} color={'pbGray.400'}>
            No Records
          </Text>
        )}
      </HStack>
      {data?.items?.map((item, index: number) => (
        <HStack
          border={'1px solid'}
          borderColor={'pbGray.100'}
          p={5}
          borderRadius={10}
          my={5}
          fontSize={'14px'}
          key={index}
        >
          <Text fontWeight={'bold'} w={'82px'}>
            {item.date}
          </Text>
          <Dot mx={2} />
          <Text flex={'1'} mr={2} letterSpacing={'tighter'}>
            {item.title}
          </Text>
          <HStack flex={'0.7'}>
            <Avatar
              src={
                !!item?.agent.photo
                  ? mediaServer(item.agent.photo, 'jango-contact')
                  : undefined
              }
              name={
                item.agent.first_name
                  ? `${item.agent.first_name} ${item.agent.last_name}`
                  : undefined
              }
              mr={'10px'}
            />
            <Text>{`${item.agent.first_name} ${item.agent.last_name}, ${item.agent.company}`}</Text>
          </HStack>
        </HStack>
      ))}
    </Box>
  );
};
