import { ActionModal } from '../../Global/ActionModal';
import { FormClient, Report } from '../../../services/apiTypes';
import {
  Avatar,
  Box,
  Divider,
  Flex,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useGmailMessages } from '../../../services/hooks/api/gmail/useGmailMessages';
import { CheckboxItem } from 'components/Form/TriggerCard';

import { useGmailBody } from '../../../services/hooks/api/gmail/useGmailBody';
import { ModalProps } from 'types/ModalProps';
import { mediaServer } from 'services/mediaServer';
import { useAForm } from 'services/hooks/api/aform/useAForm';
import generateSuccessToast from 'services/generateToast';

import { ToFieldWithCCField } from 'components/Form/ToFieldWithCCField';
import { NewMessageAttachments } from 'components/Global/NewMessageAttachments';

type SendApplicationModalProps = ModalProps & {
  client: FormClient;
  files?: Report[];
  application_form_id: string;
};

export const SendApplicationModal = ({
  onClose,
  isOpen,
  client,
  application_form_id,
}: SendApplicationModalProps): ReactElement => {
  const toast = useToast();
  const { data: applicationData } = useAForm(application_form_id);
  const [attachFormPDF, setAttachFormPDF] = useBoolean(true);

  const attachmentFields = applicationData
    ? applicationData.report.reduce((acc, currentObject) => {
        if (currentObject.field.section === 'Files') {
          return acc.concat(currentObject);
        }
        return acc;
      }, [] as Report[])
    : [];

  const {
    state: [body, setBody],
    onAttachmentRemove,
    onFileInputChange,
    onRecipientChange,
    onCcChange,
  } = useGmailBody({
    payload: {
      listing_ids: [],
      type: 'aform',
      aform_id: application_form_id,
      file_list: [],
    },
  });

  const { mutate: sendEmail, isLoading: isSendingEmail } = useGmailMessages();

  const onSubmit = () => {
    sendEmail(
      {
        body: {
          ...body,
          payload: {
            ...body.payload,
            attach_aform_pdf: attachFormPDF,
          },
        },
      },
      {
        onSuccess: () => {
          toast(generateSuccessToast('Application has been sent'));
          onClose();
        },
      },
    );
  };

  const onFileToggle = (file: string) => {
    setBody((prev) => {
      const fileList = prev.payload?.file_list
        ? [...prev.payload.file_list]
        : [];
      const found = fileList.find((f) => f === file);
      const newFileList = found
        ? fileList.filter((f) => f !== file)
        : [...fileList, file];

      return {
        ...prev,
        payload: {
          ...prev.payload,
          file_list: newFileList,
        },
      };
    });
  };

  return (
    <ActionModal
      isOpen={isOpen}
      isDisabled={isSendingEmail || !body.subject || !body.to.length}
      onClose={onClose}
      onSubmit={onSubmit}
      title={'Send an application'}
      isLoading={isSendingEmail}
      submitLabel={'Send'}
      size={'4xl'}
    >
      <Flex flexDir={'column'}>
        <Flex alignItems={'center'}>
          <Avatar
            src={mediaServer(client.photo, 'jango-contact')}
            name={client.name}
            borderRadius={'md'}
            mr={3}
          />
          <VStack spacing={2} alignItems={'flex-start'} lineHeight={'15px'}>
            <Text
              fontSize={'md'}
              fontWeight={'bold'}
            >{`${client.name} application`}</Text>
            <Text color={'pbBlue.100'} fontWeight={'semibold'}>
              {client.email}
            </Text>
          </VStack>
        </Flex>

        <Divider
          mt={10}
          mb={8}
          mx={-10}
          w={'150%'}
          backgroundColor={'pbGray.100'}
        />

        <ToFieldWithCCField
          toValue={body.to_name}
          ccValue={body.cc_name}
          onToChange={onRecipientChange}
          onCcChange={onCcChange}
        />

        <Flex mt={2} alignItems={'center'}>
          <FormLabel minW={140}>Subject</FormLabel>
          <Input
            placeholder={'Subject'}
            onChange={(e) =>
              setBody((prev) => ({
                ...prev,
                subject: e.target.value,
              }))
            }
            value={body.subject}
            minH={'50px'}
            required
          />
        </Flex>

        <Flex mt={2} alignItems={'center'}>
          <FormLabel minW={140}>Message</FormLabel>
          <Textarea
            onChange={(e) =>
              setBody((prev) => ({
                ...prev,
                body: e.target.value,
              }))
            }
            value={body.body}
            placeholder={'Write your message here...'}
            resize={'vertical'}
          />
        </Flex>

        <Divider
          mt={10}
          mb={8}
          mx={-10}
          w={'150%'}
          backgroundColor={'pbGray.100'}
        />

        <Flex>
          <FormLabel minW={140}>Attachments</FormLabel>
          {attachmentFields && (
            <Flex flexDir={'column'} width={'100%'}>
              <SimpleGrid
                columns={2}
                gridGap={5}
                rowGap={2}
                gridTemplateColumns={'repeat(2, 40%)'}
              >
                <CheckboxItem
                  onChange={setAttachFormPDF.toggle}
                  isChecked={attachFormPDF}
                >
                  Attach application PDF
                </CheckboxItem>

                {attachmentFields
                  .filter((f) => f.value)
                  .map((f) => (
                    <CheckboxItem
                      key={f.field.title}
                      isDisabled={!f.value}
                      onChange={() => onFileToggle(f.field.field)}
                      isChecked={
                        body.payload?.file_list?.length
                          ? body.payload.file_list.includes(f.field.field)
                          : false
                      }
                    >
                      {`${f.field.title}`}
                    </CheckboxItem>
                  ))}
              </SimpleGrid>

              <Box border={'1px solid'} my={5} borderColor={'pbGray.100'} />

              <SimpleGrid
                columns={2}
                columnGap={5}
                rowGap={2}
                gridTemplateColumns={'repeat(2, 40%)'}
              >
                {attachmentFields
                  .filter((f) => !f.value)
                  .map((f) => (
                    <CheckboxItem
                      key={f.field.title}
                      isDisabled={!f.value}
                      isChecked={false}
                    >
                      {`${f.field.title}`}
                    </CheckboxItem>
                  ))}
              </SimpleGrid>
            </Flex>
          )}
        </Flex>

        <NewMessageAttachments
          attachments={body.attachments}
          onAttachmentRemove={onAttachmentRemove}
          onFileInputChange={onFileInputChange}
        />
      </Flex>
    </ActionModal>
  );
};
