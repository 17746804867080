import { Skeleton, VStack } from '@chakra-ui/react';
import React, { ReactElement, useMemo } from 'react';
import { Message } from './Message';
import { useGmailThreads } from '../../services/hooks/api/gmail/useGmailThreads';
import dayjs from 'dayjs';
import { useHeight } from 'contexts/HeightContext';

export const Messages = (): ReactElement => {
  const { availableHeight } = useHeight();
  const { data, isLoading } = useGmailThreads({
    label: 'INBOX',
  });
  const messagesThreads = useMemo(
    () => data?.pages.flatMap((page) => page.collection.threads),
    [data],
  );

  return (
    <VStack
      spacing={2}
      mt={3}
      maxH={`${availableHeight}px`}
      overflowY={'scroll'}
    >
      {isLoading && <Skeleton borderRadius={'md'} w={'100%'} h={20} />}
      {messagesThreads?.map(
        ({ id, subject, unseen_count, time_created, from_name }) => (
          <Message
            key={id}
            threadId={id}
            name={from_name}
            time={
              dayjs(time_created).isToday()
                ? dayjs(time_created).format('h:mm a')
                : dayjs(time_created).format('MMM D')
            }
            text={subject}
            isHighlighted={unseen_count > 0}
          />
        ),
      )}
    </VStack>
  );
};
