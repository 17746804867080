import React, { ReactElement, useState } from 'react';
import { useSuggestCompanyQuery } from '../../services/hooks/api/suggest/useSuggestCompany';
import { AutoCompleteInput, AutoCompleteInputProps } from './AutoCompleteInput';

export const CompanyInput = ({
  onChange,
  value,
  label,
  placeholder,
}: AutoCompleteInputProps): ReactElement => {
  const [search, setSearchValue] = useState<string>('');

  const { data: items } = useSuggestCompanyQuery(search);

  return (
    <AutoCompleteInput
      onChange={onChange}
      value={value}
      label={label}
      placeholder={placeholder}
      onSearch={setSearchValue}
      items={items?.map((item) => ({ name: item }))}
    />
  );
};
