/* eslint-disable react/jsx-no-comment-textnodes */
import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { ImageWithPlaceholder } from 'components/Global/ImageWithPlaceholder';
import { RemoveButton } from 'components/Global/RemoveButton';
import React, { FC, MouseEventHandler, useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import { useAgentProfilePhoto } from 'services/hooks/api/agent-profile/useAgentProfilePhoto';

export type AvatarChangerProps = {
  isOpen: boolean;
  onModalClose: (value: boolean) => void;
};

export const AvatarChanger: FC<AvatarChangerProps> = ({
  isOpen,
  onModalClose,
}: AvatarChangerProps) => {
  const toast = useToast();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const { mutate, isLoading } = useAgentProfilePhoto(filesToUpload[0], {
    onSuccess: async () => {
      toast({
        title: 'Photo updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onCloseHandler();
    },
    onError: async () =>
      toast({
        title: 'Server error - could not update photo',
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const l = acceptedFiles.length;
      setFilesToUpload([acceptedFiles[l - 1]]);
    },

    [],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const onCloseHandler = () => {
    setFilesToUpload([]);
    onModalClose(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update your photo</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box bg={'pbGray.100'} p={5} borderRadius={'lg'} mb={'20px'}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Box
                p={5}
                borderWidth={'3px'}
                borderStyle={'dashed'}
                borderColor={isDragActive ? 'pbGreen.400' : 'pbBlue.500'}
                mb={'20px'}
                textAlign={'center'}
                cursor={'pointer'}
                fontWeight={isDragActive ? 'bold' : undefined}
              >
                Drag and drop new avatar or click to select
              </Box>
            </div>
            <HStack>
              {filesToUpload.map((f) => (
                <Box pos={'relative'} key={f.name + f.size}>
                  <AspectRatio ratio={1.5} w={'150px'}>
                    <ImageWithPlaceholder
                      src={URL.createObjectURL(f)}
                      onLoad={() => {
                        URL.revokeObjectURL(URL.createObjectURL(f));
                      }}
                    />
                  </AspectRatio>
                  <RemoveButton
                    bg={'pbRed.300'}
                    onClick={() => setFilesToUpload([])}
                  />
                </Box>
              ))}
            </HStack>
          </Box>
          <DndProvider backend={HTML5Backend} />
        </ModalBody>

        <ModalFooter as={HStack} spacing={5}>
          <Button colorScheme={'red'} onClick={onCloseHandler}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            loadingText={'Uploading'}
            disabled={filesToUpload.length <= 0}
            colorScheme={filesToUpload.length > 0 ? 'green' : undefined}
            onClick={mutate as unknown as MouseEventHandler<HTMLButtonElement>}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AvatarChanger;
