import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';
import { AgentProfile } from './apiTypes';

export const useAgentProfile = (
  sessionId?: string,
): UseQueryResult<AgentProfile> => {
  return useQuery<AgentProfile>(
    'agent-profile',
    () => pbAgentFetcher('v1/agent-profile'),
    /*
            Disabled by default, because we only want to fetch it on demand.
            Example: if user enters page and has valid session_id in localStorage 
                     we have to fetch user's data manually
        */
    {
      refetchOnWindowFocus: false,
      enabled: !!sessionId,
    },
  );
};
