import React, { ReactElement } from 'react';
import { Badge, Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import { SearchListingResponse, StringOrNumber } from '../../services/apiTypes';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';
import { LabeledValue, LabeledValuesGrid } from './LabeledValue';
import { ListingTitle } from 'components/Global/ListingTitle';
import { ShortListingSummary } from 'components/Global/ShortListingSummary';
import { mediaServer } from 'services/mediaServer';
import { ResponsiveValue } from '@chakra-ui/system';
import { responsiveImageHeight, responsiveImageWidth } from '../../constants';
import { getLeaseTermDuration } from '../../services/getLeaseTermDuration';
import { CheckSmallIcon } from 'icons/icons';
import { ListingTypeBadge } from './ListingTypeBadge';
import { ConditionalLink } from './ConditionalLink';
import TileBadgeContainer from './TileBadgeContainer';
import { DottedRow } from 'components/DottedRow';

interface ListingDetailsTileProps {
  listing: SearchListingResponse;
  isChecked?: boolean;
  onCheck?: (advertisement_id: StringOrNumber) => void;
  gridColumns?: ResponsiveValue<number>;
  isClientSaved?: boolean;
}

export const ListingDetailsTile = ({
  listing,
  isChecked,
  onCheck,
  gridColumns = { base: 2, lg: 3 },
  isClientSaved,
}: ListingDetailsTileProps): ReactElement => {
  const {
    advertisement_id,
    neighborhood,
    city,
    address2,
    price,
    bedrooms,
    bathrooms,
    size,
    ribbon,
    featured_photo,
    address,
    unit,
    ad_type,
    sent,
    furnished,
    exposures,
    condition,
    concession,
    max_lease_period,
    min_lease_period,
    available_from,
    pet_policy,
    streets,
    zip,
  } = listing;

  return (
    <Flex
      alignItems={{ base: 'stretch', md: 'flex-start' }}
      w={'100%'}
      position={'relative'}
      direction={{
        base: 'column',
        md: 'row',
      }}
    >
      <ConditionalLink
        to={`/listing/${advertisement_id}/details`}
        condition={!!advertisement_id}
      >
        <Box
          as={'figure'}
          h={responsiveImageHeight}
          w={responsiveImageWidth}
          maxH={180}
          pos={'relative'}
          mr={{ base: 0, md: 3, '2xl': 5 }}
          mb={{ base: 5, md: 3, '2xl': 0 }}
          borderRadius={'md'}
          overflow={'hidden'}
          alignSelf={'center'}
        >
          <TileBadgeContainer>
            {isClientSaved && (
              <ListingTypeBadge
                text={'Client Saved Listing'}
                color={'pbGray.150'}
              />
            )}
            {ribbon?.text && (
              <ListingTypeBadge
                type={ad_type}
                text={ribbon?.text}
                color={ribbon?.color}
              />
            )}
          </TileBadgeContainer>
          <ImageWithPlaceholder
            src={featured_photo ? mediaServer(featured_photo) : ''}
            fallbackTranscode={'mcontact'}
            objectFit={'cover'}
            w={'100%'}
            h={'100%'}
          />
        </Box>
      </ConditionalLink>
      <Flex direction={'column'} align={'start'} flex={'1'}>
        <ConditionalLink
          to={`/listing/${advertisement_id}/details`}
          condition={!!advertisement_id}
        >
          <ListingTitle
            address={address}
            unit={unit}
            py={0}
            display={'flex'}
            alignItems={{ base: 'flex-start', xl: 'flex-end' }}
            flexDir={{ base: 'column', xl: 'row' }}
          />
        </ConditionalLink>
        <DottedRow
          mt={1}
          fontSize={{ base: 12, md: 14 }}
          fontWeight={'bold'}
          color={'pbBlue.100'}
          dotMx={{ base: 1.5, md: 2, xl: 2.5 }}
          items={[
            city ||
              (address2 && (
                <Text key={'address'}>
                  {[city || address2, zip].filter(Boolean).join(', ')}
                </Text>
              )),
            <Text key={'neighborhood'}>{neighborhood}</Text>,
            <Text key={'streets'}>{streets}</Text>,
          ].filter(Boolean)}
        />

        <ShortListingSummary
          price={price}
          furnished={furnished}
          bathrooms={bathrooms}
          bedrooms={bedrooms}
          size={size}
          mt={1}
          mb={{ base: 2, '2xl': 5 }}
        />

        <LabeledValuesGrid
          columns={gridColumns}
          gridTemplateColumns={{
            base: 'minmax(0, 150px) minmax(0, 150px)',
            md: 'minmax(0, 150px) minmax(0, 150px) minmax(0, auto)',
          }}
          gap={{ base: 2, '2xl': 4 }}
          width={{ base: '100%', lg: 'auto' }}
        >
          <LabeledValue label={'Condition'} value={condition} />
          <LabeledValue label={'Move In Date'} value={available_from} />
          <LabeledValue label={'Pets'} value={pet_policy} />
          <LabeledValue
            label={'Lease Term'}
            value={getLeaseTermDuration([min_lease_period, max_lease_period])}
          />
          <LabeledValue label={'Exposure'} value={exposures} />
          <LabeledValue
            whiteSpace={'nowrap'}
            overflowX={'visible'}
            label={'Concession'}
            value={concession}
            hasPossibleTooltip
          />
        </LabeledValuesGrid>
      </Flex>
      <Box display={'flex'} alignSelf={{ base: 'flex-end', md: 'flex-start' }}>
        {sent ? (
          <Badge
            color={'white'}
            bgColor={'#8C9CB0'}
            borderRadius={'lg'}
            px={2}
            py={1}
            display={'flex'}
            alignItems={'center'}
          >
            <CheckSmallIcon mr={1} /> Sent
          </Badge>
        ) : null}
        {!!onCheck && (
          <Checkbox
            isChecked={isChecked}
            onChange={() => onCheck(advertisement_id)}
            ml={4}
            zIndex={1}
          />
        )}
      </Box>
    </Flex>
  );
};
