import { useEffect, useState } from 'react';

export const useGeolocation = (): Pick<
  GeolocationCoordinates,
  'latitude' | 'longitude'
> => {
  const [location, setLocation] = useState<
    Pick<GeolocationCoordinates, 'latitude' | 'longitude'>
  >({
    // New York as a default location
    latitude: 40.73061,
    longitude: -73.935242,
  });

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      return;
    }
    let isSubscribed = true;

    navigator.geolocation.getCurrentPosition((position) => {
      isSubscribed &&
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  return location;
};
