import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { pbAgentFetcher } from 'services/fetcher';

interface Params {
  file: File;
  fileName: string;
  dealId: string;
}

export const useDealSheetsFile = (): UseMutationResult<
  Response,
  unknown,
  Params,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ file, fileName, dealId }: Params) => {
      const formData = new FormData();
      formData.append(fileName, file);
      return pbAgentFetcher(
        `v1/deal-sheets-file/${dealId}?file_name=${fileName}`,
        {
          body: formData,
          method: 'POST',
        },
        undefined,
        true,
      );
    },
    {
      onSuccess: (e) => {
        queryClient.invalidateQueries(['deal-sheets', e.deal_sheet_id]);
      },
    },
  );
};
