import {
  DocIcon,
  ImgIcon,
  NoFileIcon,
  PdfIcon,
  TxtIcon,
  UnknownExtensionIcon,
  XlsxIcon,
  ZipIcon,
} from 'icons/icons';
import React, { ReactElement } from 'react';

export const FileExtensionIcon = ({
  extension,
}: {
  extension: string;
}): ReactElement => {
  switch (extension.toLowerCase()) {
    case 'doc':
    case 'docx':
      return <DocIcon fontSize={'32px'} />;
    case 'txt':
    case 'eml':
      return <TxtIcon fontSize={'32px'} />;
    case 'zip':
    case 'rar':
    case '7z':
      return <ZipIcon fontSize={'32px'} />;
    case 'xlsx':
      return <XlsxIcon fontSize={'32px'} />;
    case 'pdf':
      return <PdfIcon fontSize={'32px'} />;
    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'svg':
      return <ImgIcon fontSize={'32px'} />;
    case 'no-file':
      return <NoFileIcon fontSize={'32px'} />;
    default:
      return <UnknownExtensionIcon fontSize={'32px'} />;
  }
};
