import { useEffect, useState } from 'react';
import { IWeatherData } from '../../components/Dashboard/Weather/types';

type useWeatherReturnType = {
  weather?: IWeatherData;
  loading: boolean;
};

export const useWeather = (
  location: Pick<GeolocationCoordinates, 'latitude' | 'longitude'>,
): useWeatherReturnType => {
  const appId = process.env.REACT_APP_WEATHER_API_KEY;
  const [weather, setWeather] = useState<IWeatherData>();
  const [loading, setLoading] = useState(false);

  const fetchWeather = async (weatherUrl: string, isSubscribed: boolean) => {
    fetch(weatherUrl)
      .then((r) => r.json().then((r) => isSubscribed && setWeather(r)))
      .then(() => isSubscribed && setLoading(false));
  };

  useEffect(() => {
    if (!appId || !location.latitude || !location.longitude) {
      return;
    }
    let isSubscribed = true;
    setLoading(true);
    const protocol = window.location.protocol || 'http';

    const apiUrl = `${protocol}//api.openweathermap.org/data/2.5/`;

    const params = new URLSearchParams({
      appId,
      lat: location.latitude.toString(),
      lon: location.longitude.toString(),
      lang: 'en',
      units: 'imperial',
    });

    const weatherUrl = `${apiUrl}weather?${params.toString()}`;
    fetchWeather(weatherUrl, isSubscribed);

    return () => {
      isSubscribed = false;
    };
  }, [appId, location.latitude, location.longitude]);

  return { weather, loading };
};
