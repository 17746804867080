import React, { ReactElement, useCallback } from 'react';
import {
  Box,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  SearchInput,
  SearchesResponse,
  StringOrNumber,
} from '../../../../services/apiTypes';
import { SavedSearchNotificationToggler } from '../../SavedSearchNotificationToggler';
import { useHistory } from 'react-router-dom';
import { DottedRow } from '../../../DottedRow';
import { currencyFormat } from '../../../../services/currencyFormat';
import { Label, LabeledValue, Value } from '../../../Global/LabeledValue';
import { useClientsSavedSearchesMutation } from 'services/hooks/api/clients/useClientsSavedSearchMutation';
import dayjs from 'dayjs';
import { useClientsSavedSearchDelete } from 'services/hooks/api/clients/useClientsSavedSearch';
import ItemActions, { Action } from 'components/Global/ItemActions';
import AdTypeLabel from 'components/Global/AdTypeLabel';
import FormField from 'components/Form/FormField';
import { FormProvider, useForm } from 'react-hook-form';
import { SearchIcon } from 'icons/icons';
import { autoGeneratedSearchText } from '../../../../constants';
import IconButton from 'components/Global/IconButton';
import { HTTPError } from 'services/HTTPError';

interface SearchDetailsBoxProps {
  search: SearchesResponse;
  clientId: StringOrNumber;
}

export const SearchDetailsBox = ({
  search,
  clientId,
}: SearchDetailsBoxProps): ReactElement => {
  const history = useHistory();
  const toast = useToast();

  const {
    search: { max_price, min_price, ad_type, pets, notes, move_in_date },
    search_id,
    suspended,
    last_modified,
    created_by,
  } = search;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { notes, move_in_date },
  });

  const { mutate: addClientsSavedSearch } = useClientsSavedSearchesMutation();
  const { mutate: removeSavedSearch, isLoading: isDeleting } =
    useClientsSavedSearchDelete({
      clientId,
      searchId: search_id,
    });

  const navigateToSearch = useCallback(() => {
    history.push(`/search/${ad_type}`);
  }, [ad_type, history]);

  const navigateToResults = useCallback(() => {
    history.push(`/results/${ad_type}`);
  }, [ad_type, history]);

  const onMutate = (field: keyof SearchInput, value: string | number) => {
    addClientsSavedSearch(
      {
        clientId,
        savedSearchId: search_id,
        searchInput: {
          ...search.search,
          [field]: value,
        },
      },
      {
        onError: (e) => {
          (e as HTTPError).response?.json()?.then((a: { errorMsg: string }) => {
            toast({
              title: 'Something went wrong',
              description: a.errorMsg,
              status: 'error',
              position: 'bottom-right',
              duration: 7000,
              isClosable: true,
            });
          });
        },
      },
    );
  };
  const isAutoGenerated = created_by === '0'; // 0 stands for 'system' user
  const handleRemove = () => removeSavedSearch();

  const savedSearchActions: Action[] = [
    {
      name: 'Edit search',
      fn: navigateToSearch,
    },
  ];

  if (!isAutoGenerated) {
    savedSearchActions.push({
      name: 'Remove search',
      fn: handleRemove,
    });
  }

  const searchDetails = [
    {
      label: 'Created',
      item: dayjs(last_modified * 1000).format('MMM D, YYYY'),
    },

    {
      label: 'Move in Date',
      item: (
        <FormField
          placeholder={'yyyy-mm-dd'}
          name={'move_in_date'}
          onBlur={(e) => onMutate('move_in_date', e.target.value)}
          variant={'reset'}
          minHeight={'20px'}
        />
      ),
    },

    {
      label: 'Price Range',
      item: `${currencyFormat(min_price || 0)} –
      ${(max_price && currencyFormat(max_price)) || currencyFormat(0)}`,
    },
    {
      label: 'Rooms',
      item: (
        <DottedRow
          items={['1 Bed ', '1 Bath']}
          dotColor={'pbBlue.100'}
          dotMx={2}
        />
      ),
    },

    {
      label: 'Pets',
      item: pets,
    },
  ];

  return (
    <Box
      borderRadius={'lg'}
      borderWidth={1}
      borderColor={'pbGray.100'}
      px={{ base: 2, xl: 5 }}
      py={{ base: 2, xl: 5 }}
    >
      <FormProvider {...methods}>
        <SimpleGrid
          templateColumns={{ base: 'auto', xl: 'minmax(0, 1fr) auto' }}
          alignItems={'center'}
          gap={{ base: 1, lg: 2, '2xl': 16 }}
        >
          <SimpleGrid
            columns={{ base: 3, sm: 4, md: 5, lg: 6 }}
            gap={4}
            order={{ base: 2, xl: 1 }}
          >
            <VStack spacing={2} alignItems={'flex-start'}>
              <AdTypeLabel ad_type={ad_type} />
              {isAutoGenerated && <AdTypeLabel ad_type={'generated'} />}
            </VStack>
            {searchDetails.map((detail) => (
              <LabeledValue
                key={detail.label}
                label={detail.label}
                value={detail.item}
              />
            ))}
          </SimpleGrid>
          <HStack spacing={4} ml={'auto'} order={{ base: 1, xl: 2 }}>
            <SavedSearchNotificationToggler
              searchId={search_id}
              clientId={clientId}
              isSuspended={suspended}
            />
            <IconButton
              title={'Show search results'}
              icon={<SearchIcon />}
              aria-label={'Show search results'}
              fontSize={16}
              w={8}
              h={8}
              borderRadius={'lg'}
              onClick={navigateToResults}
            />
            {!isAutoGenerated && (
              <ItemActions
                actions={savedSearchActions}
                isLoading={isDeleting}
              />
            )}
          </HStack>
        </SimpleGrid>
        <Flex flexDir={'column'} mt={3}>
          {isAutoGenerated ? (
            <Flex letterSpacing={'-0.5px'} fontSize={'sm'}>
              <Text color={'pbGray.800'}>{autoGeneratedSearchText}</Text>
              <Link
                ml={2}
                color={'pbBlue.500'}
                fontWeight={'bold'}
                onClick={navigateToSearch}
              >
                Create my custom search
              </Link>
            </Flex>
          ) : (
            <>
              <Label>Notes</Label>
              <Value>
                <FormField
                  placeholder={''}
                  name={'notes'}
                  onBlur={(e) => onMutate('notes', e.target.value)}
                  variant={'reset'}
                  minHeight={'20px'}
                />
              </Value>
            </>
          )}
        </Flex>
      </FormProvider>
    </Box>
  );
};
