import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ParticipantInput, StringOrNumber } from '../../../apiTypes';
import { pbAgentFetcher } from '../../../fetcher';

interface PostParams {
  clientId: StringOrNumber;
  participantData: ParticipantInput;
}

export const useParticipantMutationPost = (): UseMutationResult<
  Response,
  unknown,
  PostParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['clients/participant/post'],
    ({ clientId, participantData }: PostParams) =>
      pbAgentFetcher(`v1/clients/${clientId}/participant`, {
        method: 'post',
        body: JSON.stringify(participantData),
      }),
    {
      onSuccess: (e, { clientId }) =>
        queryClient.invalidateQueries(['clients', clientId]),
    },
  );
};

interface DeleteParams {
  clientId: StringOrNumber;
  participantId: StringOrNumber;
}

export const useParticipantMutationDelete = (): UseMutationResult<
  Response,
  unknown,
  DeleteParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ['clients/participant/delete'],
    ({ clientId, participantId }: DeleteParams) =>
      pbAgentFetcher(`v1/clients/${clientId}/participant/${participantId}`, {
        method: 'delete',
      }),
    {
      onSuccess: (e, { clientId }) =>
        queryClient.invalidateQueries(['clients', clientId]),
    },
  );
};
