import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';

export const useSuggestCompanyQuery = (
  inputValue = '',
): UseQueryResult<string[]> => {
  return useQuery<string[]>(
    ['suggest-company', inputValue],
    () => pbAgentFetcher(`v1/suggest-company?name=${inputValue}`),
    {
      refetchOnWindowFocus: false,
      enabled: inputValue !== '',
    },
  );
};
