import { SimplePagination } from 'components/Global/Pagination';
import { useAttachmentsLibrary } from 'contexts/AttachmentLibraryContext';
import React, { ReactElement } from 'react';

export const AttachmentsPagination = (): ReactElement => {
  const {
    data: { attachments, page },
    actions: { goToNextPage, goToPreviousPage },
  } = useAttachmentsLibrary();

  const defaultPageSize = 14;
  const pageFirstItem = (page - 1) * defaultPageSize + 1;
  const pageLastItem =
    attachments.length < page * defaultPageSize
      ? attachments.length
      : page * defaultPageSize;
  const totalPages = Math.ceil(attachments.length / defaultPageSize);
  const paginationText = `Showing ${pageFirstItem} to ${pageLastItem} of ${attachments.length} attachments`;

  return (
    <SimplePagination
      paginationText={paginationText}
      prevButtonDisabled={page === 1}
      nextButtonDisabled={page === totalPages}
      goToPreviousPage={goToPreviousPage}
      goToNextPage={goToNextPage}
      flexProps={{ justifyContent: 'flex-end' }}
    />
  );
};
