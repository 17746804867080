import { Badge } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { BadgeProps } from '@chakra-ui/layout/dist/types/badge';

interface OwnBadgeType extends BadgeProps {
  color?: string;
  text?: string;
}
export const OwnBadge = ({
  color = 'red',
  text = '',
  ...props
}: OwnBadgeType): ReactElement => (
  <Badge variant={color} p={1} fontSize={10} borderRadius={'base'} {...props}>
    {text}
  </Badge>
);
