import React, { ReactElement, useEffect, useState } from 'react';
import { useGmailMessages } from '../../services/hooks/api/gmail/useGmailMessages';
import { GmailMessagesPostBody } from '../../services/hooks/api/gmail/apiTypes';
import { Input, Textarea, VStack, useToast } from '@chakra-ui/react';
import { ActionModal } from './ActionModal';
import { SuggestContactsInput } from '../Form/SuggestContactsInput';

interface ContactFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  to?: string[];
  subject?: string;
}

export const ContactFormModal = ({
  onClose,
  isOpen,
  subject,
  to = [],
}: ContactFormModalProps): ReactElement => {
  const toast = useToast();

  const [body, setBody] = useState<GmailMessagesPostBody>({
    body: '',
    subject,
    to,
    to_name: to,
  });

  useEffect(() => {
    if (subject) {
      setBody((prev) => ({ ...prev, subject }));
    }
    if (to) {
      setBody((prev) => ({ ...prev, to, to_name: to }));
    }
  }, [subject, to]);

  const { mutate, isLoading } = useGmailMessages();

  const onSubmit = () => {
    mutate(
      {
        body,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Success',
            description: 'Your message has been sent',
            status: 'success',
            position: 'bottom-right',
            duration: 5000,
            isClosable: true,
          });
          onClose();
        },
      },
    );
  };

  return (
    <ActionModal
      isOpen={isOpen}
      isDisabled={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
      title={'Add New Message'}
    >
      <VStack>
        <SuggestContactsInput
          onChange={(options) =>
            setBody((prev) => ({
              ...prev,
              to: options.map((o) => o.value) as string[],
              to_name: options.map((o) => o.label) as string[],
            }))
          }
          value={body.to_name?.length ? body.to_name : body.to}
          placeholder={'To'}
        />
        <Input
          placeholder={'Subject'}
          onChange={(e) =>
            setBody((prev) => ({
              ...prev,
              subject: e.target.value,
            }))
          }
          value={body.subject}
          required
        />

        <Textarea
          onChange={(e) =>
            setBody((prev) => ({
              ...prev,
              body: e.target.value,
            }))
          }
          value={body.body}
          resize={'vertical'}
          placeholder={'Message'}
          mt={3}
        />
      </VStack>
    </ActionModal>
  );
};
