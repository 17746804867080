import { BadgeBackgroundColors, BadgeColors } from 'types/ListingBadge';

export const responsiveImageWidth = {
  base: '100%',
  md: 200,
  lg: 230,
  '2xl': 280,
};
export const responsiveImageHeight = {
  base: '56vw',
  md: 130,
  lg: 150,
  '2xl': 180,
};

export const badgeBackgroundColors: BadgeBackgroundColors = {
  sale: BadgeColors.SALE,
  rental: BadgeColors.RENTAL,
  generated: BadgeColors.GENERATED,
};

export const existingClientText = 'This client already exists in database';
export const autoGeneratedSearchText =
  'This is an automatically generated search. You can create your custom search based on this one.';

export const applicationDownloadUrl = (id: string): string =>
  `${process.env.REACT_APP_PB_AGENT_API}/v1/aform/${id}/download`;

export const attachmentDownloadUrl = (
  messageId: string,
  filename: string,
): string =>
  `${process.env.REACT_APP_PB_AGENT_API}/v1/gmail-messages/${messageId}/attachment/${filename}`;
