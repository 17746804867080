import { Box, Flex, StackProps, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useMemo } from 'react';

export type Header = [string, string] | string | ReactElement | undefined;

export type TableRowProps = {
  content: Header[];
  columnsWidth: string[];
};

const TableRow = ({ content, columnsWidth }: TableRowProps) => {
  const rowStyle: StackProps = {
    border: '1px solid',
    borderColor: 'pbGray.100',
    borderRadius: 'lg',
    mb: 2,
    minH: 74,
  };

  const mappedContent = useMemo(
    () =>
      content.map((el, i) => (
        <Box
          fontSize={'14px'}
          width={columnsWidth[i]}
          p={5}
          pr={0}
          key={`${Array.isArray(el) ? el[0] : el}_${i}`}
          _last={{
            pr: 5,
            ml: 'auto',
          }}
        >
          {Array.isArray(el) ? (
            <VStack spacing={0} align={'flex-start'}>
              <Text fontWeight={'bold'} letterSpacing={'tighter'}>
                {el[0]}
              </Text>
              <Text color={'pbBlue.100'}>{el[1]}</Text>
            </VStack>
          ) : typeof el === 'string' ? (
            <Text>{el}</Text>
          ) : (
            el
          )}
        </Box>
      )),
    [content, columnsWidth],
  );

  return <Flex {...rowStyle}>{mappedContent}</Flex>;
};

export default React.memo(TableRow);
