import React, { createContext, useEffect, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { LoginGoogleResponse } from 'services/hooks/api/agent-profile/apiTypes';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import { ChildrenProps } from 'types/ChildrenProps';
import { pbAuthFetcher } from '../../services/fetcher';

// If we went through the whole google sign in login flow, then
// we will have session and user data available from our login-google
// endpoint, so we'll store that. However, if the valid token is already
// in the localStorage, then just store the token in context and move on.
export type AuthContextData = {
  session?: LoginGoogleResponse['session'];
  user?: LoginGoogleResponse['user'];
  sessionId?: string;
  onLogin: (data: LoginGoogleResponse) => void;
  onLogout: () => void;
};

export const AuthContext = createContext<AuthContextData | null>(null);

// TODO: Refactor this giant piece of code to meet the other contexts standards -> actions / index / reducer
export const AuthContextProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [loginGoogleResponse, setLoginGoogleResponse] =
    useState<LoginGoogleResponse>();
  const [sessionId, setSessionId] = useState<string>();
  const [authState, setAuthState] = useState<
    'logged_in' | 'logged_out' | undefined
  >();
  const { data: userProfileData } = useAgentProfile(
    localStorage.getItem('session_id') || undefined,
  );
  const history = useHistory<{ from: { pathname: string } } | undefined>();

  const logoutMutation = useMutation(
    () => pbAuthFetcher('v1/logout', { method: 'post' }),
    {
      onSuccess: () => {
        localStorage.removeItem('session_id');
        setSessionId(undefined);
        setAuthState('logged_out');
      },
      onError: (e) => {
        console.error(e);
      },
    },
  );

  const { signOut: logoutFromGoogle } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    onLogoutSuccess: logoutMutation.mutate,
  });

  const onLogin = (data: LoginGoogleResponse) => {
    const { user_session_id } = data.session;
    setLoginGoogleResponse(data);
    localStorage.setItem('session_id', user_session_id);
    setSessionId(user_session_id);
    setAuthState('logged_in');
  };

  /**
   * On the page visit we need to check if there is a session id
   * in the local storage. If the id is there, then we set the state
   * and go on. If not we also set the state, but to an empty string
   * and then the <PrivateRoute> component will take care of redirecting
   * the user to the login page.
   */
  useEffect(() => {
    const fromLocalStorage = localStorage.getItem('session_id');

    if (fromLocalStorage) {
      setSessionId(fromLocalStorage);
      return;
    }
    setSessionId('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If google login succeeded we want to redirect the user back to
   * a page from which he was taken to the login.
   */
  useEffect(() => {
    if (!loginGoogleResponse) {
      return;
    }

    const { from } = history.location.state || { from: { pathname: '/' } };

    history.replace(from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginGoogleResponse]);

  useEffect(() => {
    if (authState === 'logged_out') {
      history.push('/login', {
        from: { pathname: window.location.pathname },
      });
    }
  }, [authState, history]);

  return (
    <AuthContext.Provider
      value={{
        session: loginGoogleResponse?.session,
        user: loginGoogleResponse?.user || userProfileData,
        sessionId,
        onLogin,
        onLogout: logoutFromGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
