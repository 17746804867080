import React, { ReactElement, forwardRef } from 'react';
import {
  IconButton as ChakraIconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react';

type Props = IconButtonProps & {
  tooltipMessage?: string;
};

const IconButton = forwardRef(
  (
    { onClick, icon, title, isLoading, tooltipMessage, ...rest }: Props,
    ref: React.LegacyRef<HTMLButtonElement>,
  ): ReactElement => (
    <Tooltip label={tooltipMessage || ''} placement={'top'}>
      <ChakraIconButton
        size={'sm'}
        ref={ref}
        title={title}
        w={'32px'}
        h={'32px'}
        p={0}
        minW={0}
        icon={icon}
        borderRadius={'lg'}
        color={'#0076FF'}
        backgroundColor={'rgba(0, 118, 255, 0.1)'}
        onClick={onClick}
        isLoading={isLoading}
        {...rest}
      />
    </Tooltip>
  ),
);

IconButton.displayName = 'IconButton';

export default IconButton;
