import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { AutoCompleteInput, AutoCompleteInputProps } from './AutoCompleteInput';
import { useSuggestContacts } from '../../services/hooks/api/suggest/useSuggestContacts';
import { Option } from '../../services/apiTypes';
import { isEmailValid } from '../../services/validation';
import { InputProps } from './ToFieldWithCCField';

type SuggestContactsInputProps = {
  onChange: (v: Option[]) => void;
  inputProps?: InputProps;
} & Omit<AutoCompleteInputProps, 'onChange'>;

export const SuggestContactsInput = ({
  onChange,
  value,
  label,
  placeholder,
  inputProps,
}: SuggestContactsInputProps): ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: items } = useSuggestContacts({
    search: searchValue,
  });

  const handleChange = async (labels: string[]) => {
    if (!labels) {
      return;
    }

    onChange(
      labels.map((label) => ({
        label,
        value: label,
      })),
    );
  };

  const handleBlur = ({
    currentTarget: { value: currentValue },
  }: SyntheticEvent<HTMLInputElement>) => {
    if (!isEmailValid(currentValue)) {
      return;
    }

    const mergedValue = value ? [...value, currentValue] : [currentValue];
    return (
      currentValue &&
      onChange(mergedValue?.map((label) => ({ label, value: label })))
    );
  };

  return (
    <AutoCompleteInput
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      label={label}
      items={items?.map(({ name, email, photo }) => ({ name, email, photo }))}
      onSearch={setSearchValue}
      handleBlur={handleBlur}
      inputProps={inputProps}
    />
  );
};
