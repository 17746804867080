import React, { ReactElement } from 'react';
import { Avatar, Flex, Heading } from '@chakra-ui/react';
import { DottedRow } from 'components/DottedRow';
import { mediaServer } from 'services/mediaServer';
import { Client } from 'services/apiTypes';
import { useHistory } from 'react-router-dom';

interface Props {
  client: Client;
  lastMessageTime: string;
  inquirySource: string;
  adType?: string;
}

const SenderDetails = ({
  client: { name, user_id, photo, phone, email },
  lastMessageTime,
  inquirySource,
  adType,
}: Props): ReactElement => {
  const history = useHistory();

  const redirectToClient = () => {
    history.push(`/clients/${adType || 'rental'}/${user_id}/details`);
  };

  return (
    <Flex align={'center'} mb={{ base: 2, '2xl': 4 }} flexShrink={1}>
      <Avatar
        src={mediaServer(photo)}
        name={name}
        w={38}
        h={38}
        borderRadius={'full'}
        mr={3}
      />

      <Flex direction={'column'} flexGrow={1}>
        <Heading
          as={'h2'}
          fontSize={16}
          _hover={{ cursor: 'pointer' }}
          onClick={() => redirectToClient()}
        >
          {name}
        </Heading>

        <Flex justifyContent={'space-between'}>
          <DottedRow
            color={'pbBlue.100'}
            items={[phone, email].filter(Boolean)}
            dotMx={2}
            fontSize={14}
          />

          <DottedRow
            mr={0}
            color={'pbBlue.100'}
            items={[inquirySource, lastMessageTime].filter(Boolean)}
            dotMx={2}
            fontSize={14}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SenderDetails;
