import { Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { NumberBadge } from './NumberBadge';
import { useSearchFormOptions } from '../../services/hooks/api/search-form/useSearchFormOptions';

export type Tags = { [key: string]: number };

export const TagCloud = ({ tags }: { tags: Tags }): ReactElement => {
  const { data: dataSearchForm } = useSearchFormOptions();
  const tag_cloud_definition = dataSearchForm?.tag_cloud_definition;

  const getTagTitle = (tag: string) =>
    tag_cloud_definition?.find((t) => t.type === tag)?.title;

  return (
    <Flex
      wrap={'wrap'}
      spacing={0}
      justifyContent={{ base: 'center', md: 'start' }}
    >
      {Object.keys(tags).map((tag) => (
        <Skeleton isLoaded={!!dataSearchForm} key={tag} mr={'10px'} mb={'10px'}>
          <Button type={'button'} size={'sm'} variant={'blueLightTextGray'}>
            <Text>{getTagTitle(tag)}</Text>
            <NumberBadge number={tags[tag]} />
          </Button>
        </Skeleton>
      ))}
    </Flex>
  );
};
