import {
  Divider,
  Flex,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { Fragment, ReactElement, useState } from 'react';
import { DashboardNewListing } from '../../services/apiTypes/dashboard';
import { ListingItemWithGallery } from './ListingItemWithGallery';
import { ContactFormModal } from '../Global/ContactFormModal';
import { SendListingsModal } from '../Global/SendListingsModal';

interface NewApartmentsProps {
  listings?: DashboardNewListing[];
  isLoading?: boolean;
}

export const NewApartments = ({
  listings,
  isLoading,
}: NewApartmentsProps): ReactElement => {
  const [openedModalAdvertId, setModalAdvertVisibility] = useState<
    string | false
  >(false);
  const [openedModalListingId, setModalListingVisibility] = useState<
    string | false
  >(false);

  const currentListingSelected = listings?.find(
    (l) =>
      l.advertisement_id === openedModalAdvertId ||
      l.advertisement_id === openedModalListingId,
  );

  return (
    <>
      <ContactFormModal
        isOpen={!!openedModalAdvertId}
        onClose={() => setModalAdvertVisibility(false)}
        to={
          currentListingSelected ? [currentListingSelected.contact_address] : []
        }
        subject={currentListingSelected?.address}
      />
      {currentListingSelected?.clients?.length && (
        <SendListingsModal
          isOpen={!!openedModalListingId}
          onClose={() => setModalListingVisibility(false)}
          clients={currentListingSelected.clients}
          listings={[currentListingSelected]}
        />
      )}

      <Flex direction={'column'} align={'stretch'}>
        {isLoading && (
          <VStack spacing={3}>
            <SkeletonText noOfLines={5} />
            <Skeleton w={'100%'} h={200} borderRadius={'lg'} />
          </VStack>
        )}

        {!listings && !isLoading && <Text>No new listings</Text>}

        {listings?.map((listing, i) => (
          <Fragment key={listing.advertisement_id}>
            <ListingItemWithGallery
              listing={listing}
              onMessageSend={() =>
                setModalAdvertVisibility(listing.advertisement_id)
              }
              onListingSend={() =>
                setModalListingVisibility(listing.advertisement_id)
              }
              containerProps={{
                py: 5,
              }}
            />

            {i < listings?.length - 1 && (
              <Divider orientation={'horizontal'} mx={-5} width={'auto'} />
            )}
          </Fragment>
        ))}
      </Flex>
    </>
  );
};
