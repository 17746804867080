import {
  Flex,
  FormControl,
  FormLabel,
  useCheckboxGroup,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { radioCheckboxRow } from './styles';
import { Neighborhood, StringOrNumber } from '../../services/apiTypes';
import { RangesDropdown, RangesKey } from './RangesDropdown';
import { CheckboxCard } from './TriggerCard';
import { Value } from './RangeSlider';
import {
  getAbbreviationFromString,
  parseNeighborhoodToObject,
  stringifyNeighborhood,
} from '../../services/neighborhoods';

export type RangesValues = {
  streetsValues?: Value;
  avenuesValues?: Value;
};

export type OnRangeChange = (
  neighborhood: Neighborhood,
  key: RangesKey,
  value: Value,
) => void;

type Props = {
  label: string;
  neighborhoods: Neighborhood[];
  onChange: (value: StringOrNumber[]) => void;
  value: string[];
};

export const RangesRow = ({
  label,
  neighborhoods,
  onChange,
  value,
}: Props): ReactElement => {
  const { getCheckboxProps, setValue } = useCheckboxGroup({
    onChange,
    value,
  });

  const areRangesSelectedSameAsInitial = (
    neighborhood: Neighborhood,
    v: string,
  ): boolean => {
    const neighborhoodToObject = parseNeighborhoodToObject(v);

    const checkByKey = (key: RangesKey) => {
      if (!neighborhoodToObject[key]) {
        return true;
      }

      const arrLength = neighborhood[key].length - 1;
      const firstIndex = neighborhood[key].findIndex(
        (s) => s === neighborhoodToObject[key]?.min,
      );
      const lastIndex = neighborhood[key].findIndex(
        (s) => s === neighborhoodToObject[key]?.max,
      );
      return firstIndex === 0 && lastIndex === arrLength;
    };

    return checkByKey('streets') && checkByKey('avenues');
  };

  const onRangeChange: OnRangeChange = (neighborhood, key, v) => {
    const { abbreviation } = neighborhood;
    const otherNeighborhoods = value.filter((n) => !n.includes(abbreviation));

    const min = neighborhood[key][v[0]];
    const max = neighborhood[key][v[1]];

    const found = value.find((n) => n.includes(abbreviation));

    const changedObject = {
      abbreviation,
      [key]: {
        min,
        max,
      },
    };

    const changedValue: StringOrNumber = found
      ? stringifyNeighborhood({
          ...parseNeighborhoodToObject(found),
          ...changedObject,
        })
      : stringifyNeighborhood(changedObject);

    if (areRangesSelectedSameAsInitial(neighborhood, changedValue)) {
      setValue([...otherNeighborhoods, abbreviation]);
      return;
    }

    setValue([...otherNeighborhoods, changedValue]);
  };

  const getRangesValues = (neighborhood: Neighborhood): RangesValues => {
    const { abbreviation } = neighborhood;
    const found = value.find((n) => n.includes(abbreviation));

    const neighborhoodToObject = parseNeighborhoodToObject(found as string);

    const values: RangesValues = {};
    const populateDropdownValues = (key: RangesKey) => {
      const val: Value | undefined = neighborhoodToObject[key] && [
        neighborhood[key].findIndex(
          (s) => s === neighborhoodToObject[key]?.min,
        ),
        neighborhood[key].findIndex(
          (s) => s === neighborhoodToObject[key]?.max,
        ),
      ];

      if (val) {
        values[`${key}Values` as keyof RangesValues] = val;
      }
    };

    populateDropdownValues('streets');
    populateDropdownValues('avenues');

    return values;
  };

  return (
    <FormControl {...radioCheckboxRow}>
      <FormLabel>{label}</FormLabel>

      <Flex alignItems={'baseline'} flexWrap={'wrap'}>
        {neighborhoods.map((neighborhood) => {
          const { abbreviation, avenues, streets } = neighborhood;
          const isChecked = value
            .map((v) => getAbbreviationFromString(v))
            .includes(abbreviation);

          const checkbox = getCheckboxProps({
            value: abbreviation,
            //FIXME: Read below at <RangesDropdown> component
            isChecked,
          });

          const foundValue = value.find((v) => v.includes(abbreviation));

          const rangesValues = getRangesValues(neighborhood);
          const rangesValuesKeys = Object.keys(rangesValues);

          const hasRangesSelected =
            rangesValuesKeys.length > 0 && foundValue !== abbreviation;

          return streets.length > 1 || avenues.length > 1 ? (
            <RangesDropdown
              key={abbreviation}
              label={abbreviation}
              streets={streets}
              avenues={avenues}
              hasRangesSelected={hasRangesSelected}
              onDropdownRangeChange={(k, v) =>
                onRangeChange(neighborhood, k, v)
              }
              {...rangesValues}
              {...checkbox}
              onChange={(e) => {
                // Check if clicked checkbox has any streets or avenues included, and if so reset this state.
                const abbr = e.target.value;
                const found = value.find((v) => {
                  const neighborhoodToObject = parseNeighborhoodToObject(v);
                  return (
                    abbr === neighborhoodToObject.abbreviation &&
                    (!!neighborhoodToObject.avenues ||
                      !!neighborhoodToObject.streets)
                  );
                });
                if (found) {
                  const restNeighborhoods = value.filter(
                    (n) => !n.includes(abbr),
                  );
                  setValue(restNeighborhoods);
                  return;
                }

                // Otherwise return do the default onChange action.
                checkbox.onChange(e);
              }}
              // Not sure why, but 'isChecked' prop is not passed throuh above 'getCheckboxProps()' function so untill then we have to pass it manually
              isChecked={isChecked}
            />
          ) : (
            <CheckboxCard key={abbreviation} {...checkbox}>
              {abbreviation}
            </CheckboxCard>
          );
        })}
      </Flex>
    </FormControl>
  );
};
