import React, { ReactElement } from 'react';
import { Box, Button, Text, useBoolean } from '@chakra-ui/react';
import { useMessage } from 'contexts/MessageContext';
import dayjs from 'dayjs';
import { DotsIcon } from 'icons/icons';

interface PreviousMessagesProps {
  previousMessages: string;
}

export const PreviousMessages = ({
  previousMessages,
}: PreviousMessagesProps): ReactElement | null => {
  const [isTrimmedContentShown, setIsTrimmedContentShown] = useBoolean(false);
  const {
    data: {
      message: { time_created, from_email },
    },
  } = useMessage();

  const prefixedPreviousMessages = `<blockquote>
  <p>On: ${dayjs(time_created).format(
    'MMM DD YYYY h:mm a',
  )} ${from_email} wrote:</p>
  <div style="border-left: 1px solid;border-left-color: pbBlue.100;">
    <div style="margin-left: 24px;">
      ${previousMessages}
    </div>
  </div>
</blockquote>`;

  return (
    <Box alignSelf={'flex-start'}>
      <Button
        display={previousMessages ? 'block' : 'none'}
        onClick={() => setIsTrimmedContentShown.toggle()}
        mb={4}
        size={'xs'}
        py={0}
      >
        <DotsIcon fontSize={'16px'} transform={'rotate(90deg)'} />
      </Button>
      {isTrimmedContentShown && (
        <Text dangerouslySetInnerHTML={{ __html: prefixedPreviousMessages }} />
      )}
    </Box>
  );
};
