import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { Accordion, Box, Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import { Message } from '../Message';
import { useLocation, useParams } from 'react-router-dom';
import {
  MailboxLocationState,
  MailboxRouteParams,
} from 'types/MailboxRouteParams';
import { useGmailThread } from 'services/hooks/api/gmail/useGmailThread';
import { ThreadHeading } from './components/ThreadHeading';
import { MessageContextProvider } from 'contexts/MessageContext';

export const ThreadView = (): ReactElement => {
  const location = useLocation<MailboxLocationState>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { threadId } = useParams<MailboxRouteParams>();
  const { data: thread, isLoading } = useGmailThread(threadId);

  useEffect(() => {
    if (!containerRef?.current) {
      return;
    }

    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, []);

  const messages = thread?.messages || [];

  const messageToOpenIndex = useMemo(() => {
    if (!location.state?.messageToOpen) {
      return [messages.length - 1];
    }
    const message_ids = thread?.messages.map((message) => message.message_id);

    return message_ids?.indexOf(location.state?.messageToOpen);
  }, [location.state?.messageToOpen, messages.length, thread?.messages]);

  if (isLoading) {
    return (
      <HStack alignItems={'center'} spacing={5}>
        <Spinner size={'sm'} />
        <Text>Loading thread...</Text>
      </HStack>
    );
  }

  if (!thread) {
    return <Box>No thread</Box>;
  }

  return (
    <Flex flexDir={'column'} flexGrow={1}>
      <ThreadHeading />

      <Accordion
        align={'stretch'}
        mb={'auto'}
        mt={2}
        ref={containerRef}
        allowToggle
        reduceMotion={true}
        defaultIndex={messageToOpenIndex}
        _last={{ borderBottom: 'white' }}
      >
        {messages?.map((message) => (
          <MessageContextProvider key={message.id} message={message}>
            <Message />
          </MessageContextProvider>
        ))}
      </Accordion>
    </Flex>
  );
};
