import { Button, Center, Text, useDisclosure } from '@chakra-ui/react';
import { ConfirmPopover } from 'components/Global/ConfirmPopover';
import { useMailbox } from 'contexts/MailboxContext';
import React, { ReactElement } from 'react';
import { useGmailTrash } from 'services/hooks/api/gmail/useGmailTrash';

export const ClearTrash = (): ReactElement | null => {
  const {
    onOpen: onTrashClearOpen,
    onClose: onTrashClearClose,
    isOpen: isTrashClearConfirmVisible,
  } = useDisclosure();
  const {
    actions: { refetchThreads },
  } = useMailbox();
  const {
    clearTrash: { mutate: clearTrash, isLoading: isClearingTrash },
  } = useGmailTrash();
  const handleClearTrash = (): void => {
    clearTrash(null, { onSuccess: refetchThreads });
  };

  return (
    <Center
      p={3}
      h={70}
      mb={3}
      borderRadius={'lg'}
      backgroundColor={'pbGray.80'}
      fontSize={'sm'}
    >
      {isClearingTrash ? (
        <Text color={'pbBlue.100'}>Emptying Trash...</Text>
      ) : (
        <>
          <Text color={'pbBlue.100'}>
            Messages that have been in Trash more than 30 days will be
            automatically deleted.
          </Text>
          <ConfirmPopover
            onClose={onTrashClearClose}
            onConfirm={handleClearTrash}
            confirmLabel={'Yes, clear trash'}
            isOpen={isTrashClearConfirmVisible}
          >
            <Button ml={2} variant={'link'} onClick={onTrashClearOpen}>
              Empty trash now
            </Button>
          </ConfirmPopover>
        </>
      )}
    </Center>
  );
};
