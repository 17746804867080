import { HStack, Text } from '@chakra-ui/react';
import { PriceDetailsFormatted } from '../../../services/apiTypes';
import React, { ReactElement } from 'react';

interface SalePriceTextProps {
  priceDetailsFormatted: PriceDetailsFormatted;
}

export const SalePriceText = ({
  priceDetailsFormatted,
}: SalePriceTextProps): ReactElement => {
  const priceDetailsKeys = Object.keys(priceDetailsFormatted);

  return (
    <HStack spacing={1}>
      {priceDetailsFormatted &&
        priceDetailsKeys?.map(
          (price_detail_key, i) =>
            priceDetailsFormatted[
              price_detail_key as keyof PriceDetailsFormatted
            ] && (
              <Text
                fontWeight={'bold'}
                fontSize={10}
                key={price_detail_key}
                textTransform={'uppercase'}
              >
                {`${price_detail_key} ${
                  priceDetailsFormatted[
                    price_detail_key as keyof PriceDetailsFormatted
                  ]
                }`}
                {i < priceDetailsKeys?.length - 1 && ','}
              </Text>
            ),
        )}
    </HStack>
  );
};
