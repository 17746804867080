import React, { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { ThreadView } from '../../Mailbox/ThreadView';
import { Inbox } from '../../Mailbox/Inbox';
import { Route, Switch } from 'react-router-dom';
import { InboxPagination } from 'components/Global/Pagination';

dayjs.extend(isToday);

export const Messages = (): ReactElement => {
  return (
    <Box px={{ base: 2, xl: 5 }}>
      <Switch>
        <Route path={'/clients/:clientType/:clientId/:tab/:threadId'}>
          <ThreadView />
        </Route>

        <Route path={'/clients/:clientType/:clientId/:tab'}>
          <Inbox />
          <Flex justifyContent={'flex-end'}>
            <InboxPagination />
          </Flex>
        </Route>
      </Switch>
    </Box>
  );
};
