import React, { ReactElement } from 'react';
import ItemActions, { Action } from 'components/Global/ItemActions';
import { useMessage } from 'contexts/MessageContext';

export const QuickActions = (): ReactElement => {
  const {
    actions: {
      quickForwardMessage,
      quickReplyToMessage,
      quickReplyToMessageWithAll,
    },
  } = useMessage();

  const actions: Action[] = [
    {
      name: 'Reply',
      fn: quickReplyToMessage,
    },
    {
      name: 'Reply To All',
      fn: quickReplyToMessageWithAll,
    },
    {
      name: 'Forward',
      fn: quickForwardMessage,
    },
  ];

  return <ItemActions actions={actions} isLoading={false} />;
};
