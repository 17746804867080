/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useCallback } from 'react';
import { ActionModal } from './ActionModal';
import {
  ClientsSentListingBody,
  FormClient,
  SearchListingResponse,
} from '../../services/apiTypes';
import { SimpleGrid, Text, Textarea, VStack, useToast } from '@chakra-ui/react';
import { DashboardNewListing } from '../../services/apiTypes/dashboard';
import { ApartmentTile } from './ApartmentTile';
import { mediaServer } from '../../services/mediaServer';
import { FormProvider, useForm } from 'react-hook-form';
import { useSendListing } from 'services/hooks/useSendListing';
import FormField from 'components/Form/FormField';
import { pluralize } from 'services/pluralize';
import generateSuccessToast from 'services/generateToast';

interface SendListingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  clients: FormClient[];
  listings?: (DashboardNewListing | SearchListingResponse)[];
}

export const SendListingsModal = ({
  onClose,
  isOpen,
  clients,
  listings,
}: SendListingsModalProps): ReactElement => {
  const toast = useToast();
  const methods = useForm<ClientsSentListingBody>({
    mode: 'onChange',
    defaultValues: {
      listing_ids: listings?.map((listing) => listing.advertisement_id),
    },
  });
  const { send, isSendingListing } = useSendListing();

  const onSubmit = useCallback(async () => {
    if (isSendingListing || !listings?.length) {
      return;
    }

    await send({
      listingIds: listings?.map((listing) => listing.advertisement_id),
      clientIds: clients.map((c) => c.user_id),
      subject: methods.getValues().subject,
      message: methods.getValues().message,
    });
    toast(
      generateSuccessToast(
        `${pluralize(listings.length, 'Listing')} sent to client`,
      ),
    );
    onClose();
  }, [clients, isSendingListing, listings, methods, onClose, send, toast]);

  return (
    <ActionModal
      isOpen={isOpen}
      isDisabled={isSendingListing || !methods.formState.isValid}
      isLoading={isSendingListing}
      onClose={onClose}
      onSubmit={methods.handleSubmit(onSubmit)}
      title={`Send ${pluralize(listings?.length || 1, 'listing')}`}
      submitLabel={'Send'}
      size={'6xl'}
    >
      <VStack spacing={6} align={'stretch'}>
        <FormProvider {...methods}>
          <FormField
            placeholder={'Subject'}
            name={'subject'}
            required={true}
            requiredMessage={'Subject is required'}
          />
          <FormField
            as={Textarea}
            minHeight={'150px'}
            resize={'vertical'}
            placeholder={'Message'}
            name={'message'}
            required={true}
            requiredMessage={'Message is required'}
          />
        </FormProvider>

        <Text fontWeight={'bold'}>Listings: </Text>
        <SimpleGrid
          gap={{ base: 2, md: 5 }}
          columns={{ base: 1, md: 2, lg: 3 }}
          mt={8}
        >
          {listings?.map(
            ({
              advertisement_id,
              photo_featured,
              price,
              address,
              open_house_info,
              bathrooms,
              bedrooms,
              ad_type,
            }) => (
              <ApartmentTile
                key={advertisement_id}
                imageSrc={photo_featured && mediaServer(photo_featured)}
                price={price}
                title={address}
                openHouseInfo={open_house_info}
                baths={bathrooms}
                beds={bedrooms}
                // rental={ad_type === 'rental'}
              />
            ),
          )}
        </SimpleGrid>
      </VStack>
    </ActionModal>
  );
};
