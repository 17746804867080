import React from 'react';
import { Flex, Switch, Text, VStack, useToast } from '@chakra-ui/react';
import { FC } from 'react';
import { useAgentProfilePreferences } from 'services/hooks/api/agent-profile/useAgentProfilePreferences';

type SettingProps = {
  apiProp: string;
  label: string;
  description?: string;
  isActive: boolean;
};

const Setting: FC<SettingProps> = ({
  apiProp,
  label,
  description,
  isActive,
}: SettingProps) => {
  const toast = useToast();
  const { mutate, isLoading }: { mutate: () => void; isLoading: boolean } =
    useAgentProfilePreferences(
      { [apiProp]: +!isActive },
      {
        onError: async () =>
          toast({
            title: 'Server error - could not update settings',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-left',
          }),
      },
    );

  return (
    <Flex justify={'space-between'} maxW={'390px'}>
      <VStack spacing={0} align={'flex-start'}>
        <Text
          fontSize={'16px'}
          fontWeight={'bold'}
          color={'pbGray.500'}
          letterSpacing={'area'}
        >
          {label}
        </Text>
        {description && (
          <Text fontSize={'14px'} letterSpacing={'tighter'}>
            {description}
          </Text>
        )}
      </VStack>
      <Switch
        id={'notifications'}
        size={'md'}
        isChecked={isActive}
        onChange={mutate}
        isDisabled={isLoading}
      />
    </Flex>
  );
};

export default Setting;
