import { CustomFile } from 'components/MyListings/MyListingMedia/services/types';
import { SearchType } from '../../services/apiTypes';

export enum MyListingsActionType {
  SET_UPLOAD_MEDIA_MODE,
  SET_LISTING,
  ADD_FILES_TO_UPLOAD,
  REMOVE_FILE_TO_UPLOAD,
  SET_AD_TYPE,
}

export type MyListingsAction =
  | { type: MyListingsActionType.SET_UPLOAD_MEDIA_MODE; isVisible: boolean }
  | { type: MyListingsActionType.SET_LISTING; listingId?: string }
  | { type: MyListingsActionType.ADD_FILES_TO_UPLOAD; files: CustomFile[] }
  | { type: MyListingsActionType.REMOVE_FILE_TO_UPLOAD; uid: string }
  | { type: MyListingsActionType.SET_AD_TYPE; adType: SearchType };

export const setUploadMediaMode = (isVisible: boolean): MyListingsAction => ({
  type: MyListingsActionType.SET_UPLOAD_MEDIA_MODE,
  isVisible,
});

export const setListingAction = (listingId?: string): MyListingsAction => ({
  type: MyListingsActionType.SET_LISTING,
  listingId,
});

export const addFilesToUploadAction = (
  files: CustomFile[],
): MyListingsAction => ({
  type: MyListingsActionType.ADD_FILES_TO_UPLOAD,
  files,
});

export const removeFileToUploadAction = (uid: string): MyListingsAction => ({
  type: MyListingsActionType.REMOVE_FILE_TO_UPLOAD,
  uid,
});

export const setAdType = (adType: SearchType): MyListingsAction => ({
  type: MyListingsActionType.SET_AD_TYPE,
  adType,
});
