import React, {
  Dispatch,
  FC,
  createContext,
  useContext,
  useReducer,
} from 'react';
import { clientsReducer } from './reducer';
import { ClientsAction } from './actions';
import { ChildrenProps } from 'types/ChildrenProps';

export interface ClientsState {
  clientId: string;
  isClientSearchActive: boolean;
}

export type ClientContextProps = {
  clientsState: ClientsState;
  clientsDispatch: Dispatch<ClientsAction>;
};

const initialState: ClientsState = {
  clientId: '',
  isClientSearchActive: false,
};

export const ClientContext = createContext<ClientContextProps>({
  clientsState: initialState,
  clientsDispatch: () => {
    throw new Error('dispatch withing clients context is not defined');
  },
});

export const ClientProvider: FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [clientsState, clientsDispatch] = useReducer(
    clientsReducer,
    initialState,
  );

  return (
    <ClientContext.Provider value={{ clientsState, clientsDispatch }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = (): ClientContextProps => {
  const context = useContext<ClientContextProps>(ClientContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};
