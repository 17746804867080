import React, { ReactElement } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const Card = ({
  isActive,
  isDisabled,
  ...rest
}: FlexProps & { isActive?: boolean; isDisabled?: boolean }): ReactElement => (
  <Flex
    borderRadius={'lg'}
    borderWidth={1}
    letterSpacing={'tighter'}
    p={{ base: 2, sm: 3, md: 4, lg: 5 }}
    borderColor={isActive ? 'transparent' : 'pbGray.100'}
    bg={isActive ? 'pbBlue.510' : 'white'}
    pointerEvents={isDisabled ? 'none' : 'all'}
    opacity={isDisabled ? '.5' : '1'}
    w={'100%'}
    data-testid={'card'}
    {...rest}
  />
);
