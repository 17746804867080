import React, { ReactElement } from 'react';
import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import { Card } from 'components/Global/Card';
import { Notification as NotificationType } from '../../services/hooks/api/notifications/apiTypes';
import { useHistory } from 'react-router-dom';
import { useMarkNotificationAsRead } from 'services/hooks/api/notifications/useNotifications';
import reactStringReplace from 'react-string-replace';
import { DotBadge } from 'components/Global/DotBadge';

interface NotificationProps {
  text: string;
  name: string;
  avatar?: string;
  time: string;
  isHighlighted?: boolean;
  payload?: NotificationType['payload'];
  read: boolean;
  alertId: string;
}

type TextWithLinksProps = Pick<
  NotificationProps,
  'text' | 'payload' | 'alertId' | 'read'
>;

const TextWithLinks = ({
  text,
  payload,
  alertId,
  read,
}: TextWithLinksProps): ReactElement => {
  const { mutate: markAsRead } = useMarkNotificationAsRead();
  const history = useHistory();
  if (!payload) return <Text>{text}</Text>;

  const {
    user_id,
    listing_ids,
    user: { name },
  } = payload;

  const handleMarkAsRead = () => !read && markAsRead({ alert_id: alertId });

  const handleRedirectToClient = () => {
    handleMarkAsRead();
    history.push(`/clients/rental/${user_id}/details`);
  };

  const handleRedirectToResults = () => {
    handleMarkAsRead();
    history.push('/results/new-listings', { listing_ids, clientId: user_id });
  };

  let replacedText = reactStringReplace(text, name, (match, i) => (
    <Text
      key={match + i}
      _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
      color={'pbBlue.500'}
      as={'span'}
      onClick={handleRedirectToClient}
    >
      {match}
    </Text>
  ));

  const searchValue =
    listing_ids.length === 1
      ? 'one new listing'
      : `${listing_ids.length} new listings`;

  replacedText = reactStringReplace(replacedText, searchValue, (match, i) => (
    <Text
      key={match + i}
      _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
      color={'pbBlue.500'}
      as={'span'}
      onClick={handleRedirectToResults}
    >
      {match}
    </Text>
  ));

  return <>{replacedText}</>;
};

export const Notification = ({
  avatar,
  isHighlighted,
  name,
  text,
  time,
  read,
  payload,
  alertId,
}: NotificationProps): ReactElement => {
  const { mutate: markAsRead } = useMarkNotificationAsRead();

  const handleMarkAsRead = () => !read && markAsRead({ alert_id: alertId });

  return (
    <Card isActive={isHighlighted} onClick={handleMarkAsRead}>
      <Avatar src={avatar} name={name} mr={3} />

      <Box width={'100%'}>
        <Text
          mt={1}
          fontSize={14}
          lineHeight={'20px'}
          letterSpacing={'tighter'}
          fontWeight={read ? 'normal' : 'bold'}
        >
          <TextWithLinks
            read={read}
            text={text}
            payload={payload}
            alertId={alertId}
          />
        </Text>

        <HStack justifyContent={'flex-end'}>
          <DotBadge ml={'auto'} hidden={read} />
          <Text
            as={'span'}
            display={'inline-flex'}
            alignItems={'center'}
            fontWeight={'bold'}
            fontSize={12}
            color={'pbBlue.100'}
          >
            {time}
          </Text>
        </HStack>
      </Box>
    </Card>
  );
};
