export const fetchFile = async (url: string): Promise<Response> => {
  const headers = {
    'Adm-App-Auth': `${process.env.REACT_APP_APP_KEY}-${process.env.REACT_APP_APP_SECRET}`,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Adm-Session-ID': localStorage.getItem('session_id') || '',
  };

  return fetch(url, { headers });
};
