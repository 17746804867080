import { Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Card } from '../Global/Card';
import { NumberBadge } from '../Global/NumberBadge';
import { MailboxNavItemProps } from '../../scenes/Mailbox';
import { Link } from 'react-router-dom';
import { useMailbox } from 'contexts/MailboxContext';

interface InboxNavItemProps extends MailboxNavItemProps {
  count?: number;
  isDisabled?: boolean;
}

export const MailboxNavItem = ({
  count = 0,
  description,
  icon,

  isDisabled = false,
  label,
  param,
}: InboxNavItemProps): ReactElement => {
  const {
    data: { currentFolder },
  } = useMailbox();

  const isActive = currentFolder.toUpperCase() === param;
  return (
    <Link
      to={{ pathname: `/mailbox/${param?.toLowerCase()}`, state: { page: 1 } }}
    >
      <Card
        isActive={isActive}
        isDisabled={isDisabled}
        align={'center'}
        px={7}
        cursor={'pointer'}
      >
        <Flex
          align={'center'}
          mr={5}
          sx={{
            'svg, svg *': {
              fill: isActive ? 'pbBlue.500' : 'pbGray.150',
            },
          }}
        >
          {icon}
        </Flex>
        <VStack align={'left'} spacing={2}>
          <Heading
            as={'h3'}
            color={isActive ? 'pbBlue.500' : 'pbGray.500'}
            fontSize={14}
          >
            {label}
          </Heading>

          <Text color={'pbGray.150'} fontSize={12}>
            {description}
          </Text>
        </VStack>

        <NumberBadge
          number={count}
          color={isActive ? 'white' : 'pbGray.150'}
          border={isActive ? 0 : '1px'}
          backgroundColor={isActive ? 'pbBlue.500' : 'transparent'}
          ml={'auto'}
          minW={25}
        />
      </Card>
    </Link>
  );
};
