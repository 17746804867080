import { DealsTableSortSettings } from '.';

export enum DealsActionType {
  SET_YEAR,
  SET_AGENT_ID,
  SET_MONTH,
  SET_SEARCH,
  SET_DEAL_STATUS,
  SET_TABLE_SORT,
}

export type DealsAction =
  | { type: DealsActionType.SET_YEAR; year: string }
  | { type: DealsActionType.SET_AGENT_ID; agentId: string }
  | { type: DealsActionType.SET_MONTH; month: string }
  | { type: DealsActionType.SET_SEARCH; search: string }
  | { type: DealsActionType.SET_DEAL_STATUS; dealStatus: string }
  | {
      type: DealsActionType.SET_TABLE_SORT;
      tableSortSettings?: DealsTableSortSettings;
    };

export const onSetYear = (year: string): DealsAction => ({
  type: DealsActionType.SET_YEAR,
  year,
});

export const onSetAgentId = (agentId: string): DealsAction => ({
  type: DealsActionType.SET_AGENT_ID,
  agentId,
});

export const onSetMonth = (month: string): DealsAction => ({
  type: DealsActionType.SET_MONTH,
  month,
});

export const onSetSearch = (search: string): DealsAction => ({
  type: DealsActionType.SET_SEARCH,
  search,
});

export const onSetDealStatus = (dealStatus: string): DealsAction => ({
  type: DealsActionType.SET_DEAL_STATUS,
  dealStatus,
});

export const onSetTableSort = (
  tableSortSettings?: DealsTableSortSettings,
): DealsAction => ({
  type: DealsActionType.SET_TABLE_SORT,
  tableSortSettings,
});
