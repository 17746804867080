import React, { ReactElement } from 'react';
import { Main } from '../components/Global/Main';
import { PageHeading } from '../components/Global/PageHeading';
import { PageTitle } from '../components/Global/PageTitle';
import { SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import { Weather } from '../components/Dashboard/Weather/Weather';
import { LeftSidebar } from '../components/Dashboard/LeftSidebar';
import { DashboardTabs } from '../components/Dashboard/DashboardTabs';
import { RightSidebar } from '../components/Dashboard/RightSidebar';
import { RoundedBox } from '../components/Global/RoundedBox';
import { QuickActions } from 'components/Dashboard/QuickActions';

export const Dashboard = (): ReactElement => {
  const [isLessThan1440] = useMediaQuery('(max-width: 1439px)');

  return (
    <Main>
      <PageHeading>
        <PageTitle whiteSpace={'nowrap'}>Dashboard</PageTitle>
        <QuickActions />
        <Weather />
      </PageHeading>

      <SimpleGrid
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: 'minmax(0, 1fr) 380px',
          xl: '380px minmax(0, 1fr) 380px',
        }}
        gap={{ base: '0 12px', xl: 5 }}
        maxW={'100%'}
      >
        <RoundedBox isSide>
          <LeftSidebar />
        </RoundedBox>

        <RoundedBox
          gridColumn={{
            sm: '1/2',
            xl: 'initial',
          }}
          gridRow={{
            sm: '1/3',
            xl: 'initial',
          }}
          order={{
            base: -1,
            xl: 'initial',
          }}
        >
          <DashboardTabs />
        </RoundedBox>

        <RoundedBox isSide hidden={isLessThan1440}>
          <RightSidebar />
        </RoundedBox>
      </SimpleGrid>
    </Main>
  );
};
