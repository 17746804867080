import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlProps,
  FormLabel,
  HStack,
  Input,
  ModalProps,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { AutoCompleteInput } from 'components/Form/AutoCompleteInput';
import { RadioRow } from 'components/Form/RadioRow';
import { ActionModal } from 'components/Global/ActionModal';
import dayjs from 'dayjs';
import { DayPicker } from '../Global/DayPicker';
import { OpInvoicesPostBody, SuggestClientsResponse } from 'services/apiTypes';
import 'react-day-picker/lib/style.css';
import { useSuggestCompanyQuery } from 'services/hooks/api/suggest/useSuggestCompany';
import { useSuggestClients } from 'services/hooks/api/suggest/useSuggestClients';
import { useOpInvoicesPost } from 'services/hooks/api/op-invoices/useOpInvoicesPost';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import { useOpInvoicesPut } from '../../services/hooks/api/op-invoices/useOpInvoicesPut';
import { useOpInvoice } from '../../services/hooks/api/op-invoices/useOpInvoice';
import { machineDateFormat } from '../../services/dateFormat';

export type InvoiceModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  editableInvoice?: string;
};

const InvoiceFormControl = ({ children }: FormControlProps) => (
  <FormControl
    display={'flex'}
    flexWrap={{ base: 'wrap', md: 'nowrap' }}
    w={'100%'}
    alignItems={'center'}
    py={2}
  >
    {children}
  </FormControl>
);

const InvoiceFormDivider = () => <Divider my={3} />;

const adTypeLabels = [
  {
    label: 'Rental',
    value: 'rental',
  },
  {
    label: 'For Sale',
    value: 'sale',
  },
];

const defaultState = {
  address: '',
  agent_id: '',
  comission: '',
  comission_amount: '',
  contact_email: '',
  contact_name: '',
  contact_phone: '',
  management_address_1: '',
  management_address_2: '',
  management_company: '',
  move_in: '',
  price: '',
  unit: '',
};

const InvoiceModal = ({
  isOpen,
  onClose,
  editableInvoice,
}: InvoiceModalProps): ReactElement => {
  const toast = useToast();
  const [invoiceState, setInvoiceState] =
    useState<OpInvoicesPostBody>(defaultState);

  const { data: editableOpInvoiceData, isLoading: isLoadingSingleInvoice } =
    useOpInvoice(editableInvoice);

  useEffect(
    () =>
      setInvoiceState(
        editableOpInvoiceData ? editableOpInvoiceData.data : defaultState,
      ),
    [editableOpInvoiceData],
  );

  const authContext = useContext(AuthContext);
  const { data: agentData } = useAgentProfile(authContext?.sessionId);

  const [invoiceType, setInvoiceType] = useState('');

  //#region Autocomplete Companies State
  const [companySearch, setCompanySearch] = useState('');
  const { data: suggestedCompanies } = useSuggestCompanyQuery(companySearch);
  //#endregion

  //#region Autocomplete Clients State
  const [clientSearch, setClientSearch] = useState('');
  const [selectedClients, setSelectedClients] =
    useState<SuggestClientsResponse>();
  const { data: suggestedClients } = useSuggestClients(clientSearch);
  //#endregion

  const { mutate: invoiceAdd, isLoading: isLoadingAdd } = useOpInvoicesPost();
  const { mutate: invoiceEdit, isLoading: isLoadingEdit } = useOpInvoicesPut();

  const onEdit = useCallback(
    (invoiceId: string) =>
      invoiceEdit(
        {
          invoiceId,
          body: {
            data: invoiceState,
          },
        },
        {
          onSuccess: () => {
            // Reset all states and close popup
            onClose();
            setInvoiceState(defaultState);

            toast({
              status: 'success',
              description: editableInvoice
                ? 'Invoice has been updated'
                : 'New Invoice has been created',
              duration: 1000,
              position: 'top',
            });
          },
          onError: () => {
            toast({
              status: 'error',
              description: 'Server Error',
              duration: 3000,
              position: 'top',
            });
          },
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceState],
  );

  const onAdd = useCallback(
    () =>
      invoiceState.agent_id &&
      invoiceAdd(
        {
          body: invoiceState,
          type: invoiceType,
        },
        {
          onSuccess: (r) => onEdit(r.op_invoice_id),
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceState, invoiceType],
  );

  useEffect(() => {
    if (!agentData?.user_id) return;
    setInvoiceState((prev) => ({
      ...prev,
      agent_id: agentData.user_id,
    }));
  }, [agentData]);

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={editableInvoice ? () => onEdit(editableInvoice) : onAdd}
      size={'3xl'}
      title={'Add New Invoice'}
      submitLabel={'Create Invoice'}
      modalBodyProps={{
        px: { base: 5, md: '50px' },
      }}
      modalFooterProps={{
        justifyContent: 'center',
      }}
      isDisabled={isLoadingSingleInvoice}
      isLoading={isLoadingAdd || isLoadingEdit || isLoadingSingleInvoice}
    >
      <VStack>
        <RadioRow
          label={'Type'}
          onChange={(e) => {
            setInvoiceType(
              adTypeLabels.find((o) => o.label === e)?.value || 'rental',
            );
          }}
          value={adTypeLabels.find((o) => o.value === invoiceType)?.label}
          options={adTypeLabels.map((o) => o.label)}
        />

        <InvoiceFormControl>
          <FormLabel pb={{ base: 2, md: 0 }}>Address</FormLabel>

          <HStack w={'100%'} mb={3}>
            <Input
              placeholder={'Address'}
              onChange={(e) =>
                setInvoiceState((prev) => ({
                  ...prev,
                  address: e.target.value,
                }))
              }
              value={invoiceState.address}
              flex={1}
              required
            />

            <Input
              placeholder={'Apt #'}
              w={100}
              onChange={(e) =>
                setInvoiceState((prev) => ({
                  ...prev,
                  unit: e.target.value,
                }))
              }
              value={invoiceState.unit}
              required
            />
          </HStack>
        </InvoiceFormControl>
        <InvoiceFormControl>
          <FormLabel pb={{ base: 2, md: 0 }}>Details</FormLabel>

          <Stack direction={{ base: 'column', md: 'row' }} w={'100%'}>
            <Input
              placeholder={'Price'}
              w={'100%'}
              onChange={(e) =>
                setInvoiceState((prev) => ({
                  ...prev,
                  price: e.target.value,
                }))
              }
              value={invoiceState.price}
              required
            />
            <Box
              w={'100%'}
              sx={{
                '.DayPickerInput-Overlay': { zIndex: 99 },
              }}
            >
              <DayPicker
                style={{
                  width: '100%',
                }}
                placeholder={'Move in Date'}
                inputProps={{
                  fontWeight: 'normal',
                  h: { base: '38px', xl: '38px' },
                }}
                onDayChange={(a) =>
                  setInvoiceState((prev) => ({
                    ...prev,
                    move_in: dayjs(a).format('YYYY-MM-DD'),
                  }))
                }
                value={
                  invoiceState?.move_in
                    ? dayjs(invoiceState.move_in).format(machineDateFormat)
                    : undefined
                }
                formatDate={(date) => dayjs(date).format(machineDateFormat)}
              />
            </Box>

            <Input
              placeholder={'Commission'}
              w={'100%'}
              onChange={(e) =>
                setInvoiceState((prev) => ({
                  ...prev,
                  comission_amount: e.target.value,
                }))
              }
              value={invoiceState.comission_amount}
              required
            />
          </Stack>
        </InvoiceFormControl>
        <InvoiceFormDivider />
        <InvoiceFormControl>
          <FormLabel pb={{ base: 2, md: 0 }}>Clients</FormLabel>
          <AutoCompleteInput
            onChange={(itemsList) => {
              setSelectedClients(
                suggestedClients?.find((c) => c.name === itemsList[0]),
              );
            }}
            onSearch={setClientSearch}
            value={selectedClients?.name ? [selectedClients?.name] : []}
            placeholder={'Choose Your Client'}
            required
            items={suggestedClients?.map(({ name, email }) => ({
              name,
              email,
            }))}
          />
        </InvoiceFormControl>
        <InvoiceFormDivider />
        <InvoiceFormControl>
          <FormLabel alignSelf={'flex-start'} pb={{ base: 2, md: 0 }}>
            Billing
          </FormLabel>
          <VStack w={'100%'} align={'stretch'}>
            <AutoCompleteInput
              onChange={(company) =>
                setInvoiceState((prev) => ({
                  ...prev,
                  management_company: company[0],
                }))
              }
              onSearch={setCompanySearch}
              value={
                invoiceState.management_company
                  ? [invoiceState.management_company]
                  : []
              }
              placeholder={'Management Company'}
              required
              items={suggestedCompanies?.map((company) => ({ name: company }))}
              isSingleInput
            />
            <Input
              placeholder={'Contact Name'}
              onChange={(e) =>
                setInvoiceState((prev) => ({
                  ...prev,
                  contact_name: e.target.value,
                }))
              }
              value={invoiceState?.contact_name}
              required
            />
            <HStack>
              <Input
                placeholder={'Email'}
                onChange={(e) =>
                  setInvoiceState((prev) => ({
                    ...prev,
                    contact_email: e.target.value,
                  }))
                }
                value={invoiceState.contact_email}
                required
              />
              <Input
                placeholder={'Phone'}
                onChange={(e) =>
                  setInvoiceState((prev) => ({
                    ...prev,
                    contact_phone: e.target.value,
                  }))
                }
                value={invoiceState.contact_phone}
                required
              />
            </HStack>
          </VStack>
        </InvoiceFormControl>
      </VStack>
    </ActionModal>
  );
};

export default InvoiceModal;
