import React from 'react';
import {
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps,
  HStack,
  StackProps,
  useEditableControls,
} from '@chakra-ui/react';
import { PencilGrayIcon, PencilGrayShadowIcon } from 'icons/icons';
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

type EditableProviderProps = {
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isHover: boolean;
};

const EditableProvider: FC<EditableProviderProps> = ({
  setIsEditing,
  isHover,
}: EditableProviderProps) => {
  const { isEditing } = useEditableControls();

  useEffect(() => {
    setIsEditing(isEditing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  return (
    <>
      {isEditing && <PencilGrayShadowIcon boxSize={'25px'} />}
      {isHover && !isEditing && <PencilGrayIcon boxSize={'15px'} />}
    </>
  );
};

export type InputEditorProps = {
  onSubmit: EditableProps['onSubmit'];
  isDisabled?: EditableProps['isDisabled'];
  apiValue?: string;
  isPassword?: boolean;
  placeholder?: string;
  stackProps?: StackProps;
  disableHover?: boolean;
  rightIcon?: ReactElement;
};

const Editor: FC<InputEditorProps> = ({
  isDisabled,
  onSubmit,
  apiValue,
  placeholder,
  stackProps,
  rightIcon,
  disableHover = false,
}: InputEditorProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [inputText, setInputText] = useState(apiValue);

  useEffect(() => {
    if (apiValue === inputText) return;

    setInputText(apiValue);

    /**
     *  Disabled, because we should only rely on API value
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiValue]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleSubmit = (value: string) => {
    // If provided value is equal to value from API we won't submit it
    if (!onSubmit || value === apiValue) return;

    onSubmit(value);
  };

  return (
    <Editable
      isDisabled={isDisabled}
      value={inputText}
      placeholder={placeholder}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onChange={setInputText}
      onSubmit={handleSubmit}
    >
      <HStack
        justifyContent={'space-between'}
        px={5}
        py={'5px'}
        fontSize={'14px'}
        letterSpacing={'area'}
        color={'pbBlue.200'}
        textAlign={'left'}
        bg={'pbGray.60'}
        borderRadius={'lg'}
        fontWeight={'bold'}
        w={'100%'}
        ml={'auto'}
        {...stackProps}
      >
        <EditablePreview opacity={isEditing ? 1.0 : 0.5} w={'100%'} />
        <EditableInput w={'100%'} />
        <EditableProvider
          setIsEditing={setIsEditing}
          isHover={!disableHover && isHover}
        />
        {rightIcon}
      </HStack>
    </Editable>
  );
};

const InputEditor: FC<InputEditorProps> = ({
  stackProps,
  ...rest
}: InputEditorProps) => {
  return (
    <Editor
      stackProps={{
        bg: 'white',
        h: '50px',
        w: '100%',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 'area',
        border: '1px solid',
        borderColor: 'pbGray.90',
        ...stackProps,
      }}
      {...rest}
    />
  );
};

export default InputEditor;
