import React, { ReactElement } from 'react';
import { HStack, VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import SectionHeading from '../shared/SectionHeading';
import LicenseBox from './LicenseBox';

const Licenses = (): ReactElement => {
  return (
    <>
      <HStack minW={'100%'} justifyContent={'space-between'} mb={4}>
        <SectionHeading mb={0}>Licenses</SectionHeading>
        <Button variant={'blueLight'} height={'38px'}>
          Add License
        </Button>
      </HStack>
      <VStack spacing={'10px'} className={'notImplemented'}>
        <LicenseBox text={'Salesperson License# 12345603833 - New York'} />
        <LicenseBox text={'REBNY# 12345603833 - New York'} />
      </VStack>
    </>
  );
};

export default Licenses;
