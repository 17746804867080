import React, { ReactElement } from 'react';
import { Avatar, Flex, Text, VStack } from '@chakra-ui/react';
import { Participant } from 'services/apiTypes';
import { mediaServer } from 'services/mediaServer';
import { useParticipantMutationDelete } from 'services/hooks/api/clients/useParticipantMutation';
import { useModal } from 'contexts/ModalContext';
import EditClientModal from 'components/Clients/EditClientModal';
import ItemActions, { Action } from 'components/Global/ItemActions';

interface Props {
  participant: Participant;
  clientId: string;
}

const ParticipantTile = ({ clientId, participant }: Props): ReactElement => {
  const { mutate: participantDelete, isLoading: isDeleting } =
    useParticipantMutationDelete();
  const { name, photo, phone, email } = participant;
  const { openModal, defaultModalProps } = useModal();

  const handleRemoveParticipant = () => {
    participantDelete({
      clientId,
      participantId: participant.user_id,
    });
  };

  const participantActions: Action[] = [
    {
      name: 'Edit participant',
      fn: () =>
        openModal(
          <EditClientModal
            {...defaultModalProps}
            clientId={participant.user_id}
            title={'Edit participant'}
          />,
        ),
    },
    {
      name: 'Delete participant',
      fn: handleRemoveParticipant,
    },
  ];

  return (
    <Flex
      border={'1px'}
      borderColor={'pbGray.100'}
      borderRadius={'lg'}
      w={'100%'}
      p={{ base: 3, md: 5 }}
      pb={{ base: 2, md: 4 }}
      pos={'relative'}
    >
      <Avatar
        src={photo ? mediaServer(photo, 'jango-contact') : undefined}
        name={name}
        mr={'13px'}
      />
      <VStack spacing={0} flexGrow={1} alignItems={'flex-start'}>
        {name && (
          <Text color={'pbGray.500'} fontSize={16} fontWeight={'bold'}>
            {name}
          </Text>
        )}
        {phone && (
          <Text color={'pbGray.400'} fontSize={14}>
            {phone}
          </Text>
        )}
        {email && (
          <Text color={'pbGray.400'} fontSize={14} wordBreak={'break-all'}>
            {email}
          </Text>
        )}
      </VStack>
      <ItemActions actions={participantActions} isLoading={isDeleting} />
    </Flex>
  );
};

export default ParticipantTile;
