import React, { ReactElement } from 'react';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { RoundedBox } from 'components/Global/RoundedBox';
import { AuthContext } from 'contexts/AuthContext/AuthContext';
import dayjs from 'dayjs';
import { EnvelopEmptyIcon, PhoneIcon } from 'icons/icons';
import { useContext, useState } from 'react';
import { useAgentProfile } from 'services/hooks/api/agent-profile/useAgentProfile';
import AgentAvatar from './components/AgentAvatar';
import AvatarChanger from './components/AvatarChanger';
import ContactInfoBox from './components/ContactInfoBox';

const Sidebar = (): ReactElement => {
  const authContext = useContext(AuthContext);

  const [openAvatarChanger, setOpenAvatarChanger] = useState<boolean>(false);
  const { data } = useAgentProfile(authContext?.sessionId);

  return (
    <RoundedBox pt={{ base: 5, lg: 10, xl: '68px' }} pb={'38px'} px={5} isSide>
      <Flex flexDirection={'column'} align={'center'}>
        <AgentAvatar openModal={setOpenAvatarChanger} />
        <AvatarChanger
          isOpen={openAvatarChanger}
          onModalClose={setOpenAvatarChanger}
        />
        <Text fontWeight={'bold'} mt={'22px'} fontSize={'20px'}>
          {data?.name || '-'}
        </Text>
        <Text color={'pbGray.800'} fontWeight={'medium'}>
          {`PB Office user since ${dayjs
            .unix(data?.time_created || 0)
            .format('MMMM  YYYY')}`}
        </Text>

        <VStack mt={'28px'} mb={6} spacing={'10px'} w={'100%'} maxW={'400px'}>
          <ContactInfoBox
            text={data?.phone}
            icon={<PhoneIcon boxSize={'19px'} />}
          />
          <ContactInfoBox
            text={data?.email}
            icon={<EnvelopEmptyIcon boxSize={'19px'} />}
          />
        </VStack>

        <Text
          w={'100%'}
          fontWeight={'bold'}
          fontSize={'16px'}
          alignSelf={'flex-start'}
          color={'pbGray.500'}
          textAlign={{ base: 'center', lg: 'left' }}
        >
          About Me
        </Text>

        <Text
          w={'100%'}
          mt={'22px'}
          fontSize={'14px'}
          alignSelf={'flex-start'}
          textAlign={{ base: 'center', lg: 'left' }}
        >
          {data?.description || 'No description set'}
        </Text>

        <Text
          mt={{ base: 5, lg: '164px' }}
          fontStyle={'italic'}
          fontSize={'14px'}
          color={'pbGray.800'}
          fontWeight={'medium'}
        >
          This is how clients see your profile
        </Text>
      </Flex>
    </RoundedBox>
  );
};

export default Sidebar;
