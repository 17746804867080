import React, { ReactElement } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { SearchListingTile } from './SearchListingTile';
import { MergedListingInfo } from '../../services/apiTypes';

interface SearchResultsListProps {
  data?: MergedListingInfo[];
}

export const SearchResultsList = ({
  data,
}: SearchResultsListProps): ReactElement => {
  return (
    <Flex flexDirection={'column'}>
      <Skeleton isLoaded={!!data}>
        {data?.map((el) => (
          <SearchListingTile key={el.advertisement_id} element={el} />
        ))}
      </Skeleton>
    </Flex>
  );
};
