import React, { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Center,
  Divider,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ListingInquiry } from './ListingInquiry';
import { useListingInquiries } from '../../services/hooks/api/listing-inquiries/useListingInquiries';
import ErrorFallback from 'components/Global/ErrorFallback';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ListingInquiries = (): ReactElement => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useListingInquiries();

  const listingInquiries = data?.pages.flatMap((page) => page.collection) || [];
  const listingInquiriesToDisplay = listingInquiries.filter(
    (listingInquiry) => listingInquiry.needs_response,
  );

  if (isLoading) {
    return (
      <SkeletonCircle
        isLoaded={false}
        borderRadius={'lg'}
        w={'100%'}
        h={'76px'}
      />
    );
  }

  if (!listingInquiriesToDisplay.length) {
    return (
      <Center>
        <Text>No listing inquiries to answer</Text>
      </Center>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <InfiniteScroll
        dataLength={listingInquiries.length}
        next={fetchNextPage}
        hasMore={hasNextPage || false}
        loader={''}
      >
        <VStack
          alignItems={'stretch'}
          spacing={5}
          divider={<Divider my={5} />}
          mt={3}
        >
          {listingInquiriesToDisplay.map((listingInquiry) => (
            <ListingInquiry
              key={listingInquiry.inquiry_id}
              inquiry={listingInquiry}
            />
          ))}
        </VStack>
      </InfiniteScroll>
      {isFetching && (
        <SkeletonCircle borderRadius={'lg'} w={'100%'} h={'76px'} my={5} />
      )}
    </ErrorBoundary>
  );
};
