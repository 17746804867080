import { exhaustive } from 'services/exhaustive';
import { MyListingsState } from '.';
import { MyListingsAction, MyListingsActionType } from './actions';

export const myListingsReducer = (
  prevState: MyListingsState,
  action: MyListingsAction,
): MyListingsState => {
  switch (action.type) {
    case MyListingsActionType.SET_AD_TYPE: {
      return {
        ...prevState,
        adType: action.adType,
      };
    }
    case MyListingsActionType.SET_LISTING: {
      return {
        ...prevState,
        listingId: action.listingId,
      };
    }
    case MyListingsActionType.SET_UPLOAD_MEDIA_MODE: {
      return {
        ...prevState,
        uploadMediaMode: action.isVisible,
        filesToUpload: [],
      };
    }
    case MyListingsActionType.ADD_FILES_TO_UPLOAD: {
      return {
        ...prevState,
        filesToUpload: prevState.filesToUpload.concat(action.files),
      };
    }
    case MyListingsActionType.REMOVE_FILE_TO_UPLOAD: {
      return {
        ...prevState,
        filesToUpload: prevState.filesToUpload.filter(
          (x) => x.uid !== action.uid,
        ),
      };
    }
    default:
      exhaustive(action);
  }
};
