/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { PreviewListings, PreviewListingsPostBody } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

export const usePreviewListings = (
  advertisementId: string,
  options?: UseQueryOptions<PreviewListings>,
) => {
  return useQuery<PreviewListings>(
    ['preview-listings', advertisementId],
    () =>
      pbAgentFetcher(
        `v1/preview-listings/${advertisementId}?load_rooms_data=true&load_rooms_advice=true`,
      ),
    { refetchOnWindowFocus: false, ...options },
  );
};

export function usePreviewListingsPost() {
  const queryClient = useQueryClient();

  return useMutation(
    (body: PreviewListingsPostBody) =>
      pbAgentFetcher('v1/preview-listings', {
        method: 'POST',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: (_, { apartment_id }) => {
        queryClient.invalidateQueries('search-created-by-me');
        queryClient.invalidateQueries(['preview-listings', apartment_id]);
      },
    },
  );
}

export type PreviewListingsPutBody = Partial<
  Omit<PreviewListings, typeof putBodyOmit[number]>
> & { price: number }; // price is required

export const putBodyOmit = [
  '360',
  'advertisement_id',
  'apartment_id',
  'building_id',
  'photos',
  'rooms_advice',
  'rooms_details',
  'vt',
] as const;

export function usePreviewListingsPut() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      advertisementId,
      body,
    }: {
      advertisementId: string;
      body: PreviewListingsPutBody;
    }) =>
      pbAgentFetcher(`v1/preview-listings/${advertisementId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
      }),
    {
      onSuccess: (_, { advertisementId }) => {
        queryClient.invalidateQueries('search-created-by-me');
        queryClient.invalidateQueries(['preview-listings', advertisementId]);
      },
    },
  );
}
