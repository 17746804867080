export enum BadgeColors {
  SALE = '#36C678',
  RENTAL = '#9013FE',
  GENERATED = 'pbBlue.100',
}

export interface BadgeBackgroundColors {
  [key: string]: string;
}

export interface ListingTypeBadgeProps {
  type?: string;
  text?: string;
  color?: string;
}
