import { UseQueryResult, useQuery } from 'react-query';
import { DealSheetsAdvice } from 'services/apiTypes';
import { pbAgentFetcher } from 'services/fetcher';

export const useDealSheetsAdvice = (): UseQueryResult<DealSheetsAdvice> =>
  useQuery<DealSheetsAdvice>(
    ['deal-sheets-advice'],
    () => pbAgentFetcher('v1/deal-sheets-advice'),
    {
      refetchOnWindowFocus: false,
    },
  );
