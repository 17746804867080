import React, { ReactElement, useState } from 'react';
import { OwnTabs, TabsType } from '../Global/OwnTabs';
import { StringOrNumber } from '../../services/apiTypes';
import { ListingInquiries } from './ListingInquiries';
import { NewApartments } from './NewApartments';
import { useDashboardNewListings } from '../../services/hooks/api/useDashboardNewListings';
import { DashboardComps } from './DashboardComps';
import { DashboardClients } from './Clients';
import { Box } from '@chakra-ui/react';
import { useHeight } from 'contexts/HeightContext';

export const DashboardTabs = (): ReactElement => {
  const { availableHeight } = useHeight();
  const [activeTab, setActiveTab] =
    useState<StringOrNumber>('listingInquiries');
  const { data: listings, isLoading: isFetchingListings } =
    useDashboardNewListings();

  const tabs: TabsType[] = [
    {
      label: 'Listing Inquiries',
      abbreviation: 'listingInquiries',
      content: (
        <Box maxHeight={`${availableHeight}px`} overflowY={'scroll'}>
          <ListingInquiries />
        </Box>
      ),
    },
    {
      label: 'New Apartments',
      abbreviation: 'newApartments',
      content: (
        <Box maxHeight={`${availableHeight}px`} overflowY={'scroll'}>
          <NewApartments listings={listings} isLoading={isFetchingListings} />
        </Box>
      ),
      badgeCount: listings?.length || 0,
      disabled: !listings || listings.length === 0,
    },
    {
      label: 'Clients',
      abbreviation: 'clients',
      content: (
        <Box maxHeight={`${availableHeight}px`} overflowY={'scroll'}>
          <DashboardClients />
        </Box>
      ),
    },
    {
      label: 'Comps',
      abbreviation: 'comps',
      content: (
        <Box maxHeight={`${availableHeight}px`} overflowY={'scroll'}>
          <DashboardComps />,
        </Box>
      ),
    },
  ];

  const onTabChange = (e: StringOrNumber) => setActiveTab(e);

  return <OwnTabs tabs={tabs} value={activeTab} onChange={onTabChange} />;
};
