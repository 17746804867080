import { Button, Flex, HStack } from '@chakra-ui/react';
import { Dropdown } from 'components/Global/Dropdown';
import { Main } from 'components/Global/Main';
import { PageHeading } from 'components/Global/PageHeading';
import { PageTitle } from 'components/Global/PageTitle';
import { RoundedBox } from 'components/Global/RoundedBox';
import InvoiceModal from 'components/OpInvoices/InvoiceModal';
import OpTable from 'components/OpInvoices/OpTable';
import { useModal } from 'contexts/ModalContext';
import { PlusInCircleIcon } from 'icons/icons';
import React, { ReactElement, useState } from 'react';
import { getLastYearsInRangeOf } from 'services/getLastYearsInRangeOf';
import {
  OpInvoiceParams,
  useOpInvoices,
} from '../services/hooks/api/op-invoices/useOpInvoices';
import { months } from '../services/monthsArray';

type Type = 'rental' | 'sale';

const tabs: { label: string; value: Type }[] = [
  { label: 'Rentals', value: 'rental' },
  { label: 'Sales', value: 'sale' },
];

const OpInvoices = (): ReactElement => {
  const [type, setType] = useState<Type>('rental');
  const [year, setYear] = useState<string>();
  const [month, setMonth] = useState<string>();

  const params: OpInvoiceParams = {
    type,
  };

  if (year) {
    params['year'] = year;
  }

  if (month) {
    params['month'] = month;
  }

  const { data: opInvoices } = useOpInvoices(params);
  const [editableInvoice, setEditableInvoice] = useState<string | undefined>();
  const { openModal, defaultModalProps } = useModal();

  const onView = (invoice_id: string) => {
    setEditableInvoice(invoice_id);
    openModal(
      <InvoiceModal {...defaultModalProps} editableInvoice={editableInvoice} />,
    );
  };

  return (
    <Main>
      <PageHeading justify={'space-between'}>
        <PageTitle>Op Invoices</PageTitle>
        <Button
          borderRadius={'md'}
          color={'pbBlue.500'}
          bgColor={'pbBlue.510'}
          leftIcon={<PlusInCircleIcon boxSize={'18px'} />}
          onClick={() => {
            setEditableInvoice(undefined);
            openModal(
              <InvoiceModal
                {...defaultModalProps}
                editableInvoice={editableInvoice}
              />,
            );
          }}
        >
          New Invoice
        </Button>
      </PageHeading>
      <RoundedBox>
        <Flex justify={'space-between'} w={'100%'}>
          <HStack>
            {tabs.map((tab) => (
              <Button
                key={tab.value}
                variant={'tab'}
                size={'tab'}
                onClick={() => setType(tab.value)}
                isActive={tab.value === type}
              >
                {tab.label}
              </Button>
            ))}
          </HStack>
          <HStack pr={2}>
            <Dropdown
              options={getLastYearsInRangeOf(3)}
              onValueChange={setYear}
              value={year}
              fontSize={'14px'}
              variant={'grayDropdown'}
              placeholder={'Year'}
              menuProps={{
                placement: 'bottom-end',
              }}
            />
            <Dropdown
              options={months}
              onValueChange={setMonth}
              value={month}
              fontSize={'14px'}
              variant={'grayDropdown'}
              placeholder={'Month'}
              menuProps={{
                placement: 'bottom-end',
              }}
            />
          </HStack>
        </Flex>

        <OpTable invoices={opInvoices} onView={onView} />
      </RoundedBox>
    </Main>
  );
};

export default OpInvoices;
