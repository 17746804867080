import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { SavedSearchNotificationToggler } from 'components/Clients/SavedSearchNotificationToggler';
import { Main } from 'components/Global/Main';
import { PageHeading } from 'components/Global/PageHeading';
import { TagCloud } from 'components/Global/TagCloud';
import { NotificationResults } from 'components/NotificationResults';
import { ViewToggler } from 'components/SearchResults/ViewToggler';
import { useClient } from 'services/hooks/api/clients/useClient';
import { useSearchListingsAdvice } from 'services/hooks/api/search-listings/useSearchListingsAdvice';
import { useSearchListingsTagCloud } from 'services/hooks/api/search-listings/useSearchListingsTagCloud';
import { useURLSearchParams } from 'services/hooks/useURLSearchParams';
import { pluralizeWithCount } from 'services/pluralize';
import { SearchResults } from './SearchResults';
import { SimpleClientTile } from 'components/Clients/ClientsList/components/ClientTile';
import { PaperPlaneIcon } from 'icons/icons';
import { useSendListing } from 'services/hooks/useSendListing';
import { useResults } from 'contexts/ResultsContext';
import { useHistory, useParams } from 'react-router-dom';
import { ResultsHistoryState } from 'types/History';

export type LayoutType = 'grid' | 'list';

const Results = (): ReactElement => {
  const { type } = useParams<{ type: string }>();

  const history = useHistory<ResultsHistoryState>();
  const notificationListingIds = history.location.state?.listing_ids;
  const notificationClientId = history.location.state?.clientId || '';

  const {
    data: {
      selectedListingIds,
      areAllListingsChecked,
      filtersVisible,
      listingIds: availableListingIds,
      clientId,
    },
    actions: {
      setSelectedListingIds,
      toggleCheckAll,
      setFiltersVisible,
      setListingIds,
      setClientId,
    },
  } = useResults();

  useEffect(() => {
    if (notificationListingIds) {
      setListingIds(notificationListingIds);
    }
    if (notificationClientId) {
      setClientId(notificationClientId);
    }
  }, [
    notificationClientId,
    notificationListingIds,
    setClientId,
    setListingIds,
  ]);

  const toast = useToast();
  const { searchParams } = useURLSearchParams();
  const { data: dataAdvice } = useSearchListingsAdvice(searchParams);

  const { send, isSendingListing } = useSendListing();
  const tagCloudEnabled = !!dataAdvice && dataAdvice.count < 500;
  const { data: searchListingsTagCloud } = useSearchListingsTagCloud(
    searchParams,
    {
      enabled: tagCloudEnabled,
    },
  );

  const { data: clientData } = useClient(notificationClientId || clientId);
  const clientSearch = clientData?.searches?.find(
    (s) => s.search?.ad_type === type,
  );
  const isNotSearchResults = type !== 'sale' && type !== 'rental';

  const handleClickSendListings = useCallback(async () => {
    if (isSendingListing) return;

    await send({
      listingIds: selectedListingIds,
      clientIds: [clientId],
      onSuccess: () => {
        toast({
          title: 'Listings sent',
          description: `${pluralizeWithCount(
            selectedListingIds.length,
            'listing',
          )} sent to ${clientData?.name}`,
          status: 'success',
          duration: 5000,
          position: 'bottom-right',
        });
        setSelectedListingIds([]);
      },
    });
  }, [
    clientData?.name,
    clientId,
    isSendingListing,
    selectedListingIds,
    send,
    setSelectedListingIds,
    toast,
  ]);

  return (
    <Main minHeight={700}>
      <PageHeading justifyContent={'space-between'}>
        <HStack
          spacing={{ base: 5, md: 10, xl: 15, '2xl': 84 }}
          flexDirection={{ base: 'column', md: 'row' }}
          flex={1}
        >
          <Box fontWeight={'bold'} fontSize={'2xl'} whiteSpace={'nowrap'}>
            {isNotSearchResults ? (
              <Flex alignItems={'center'}>
                <Text as={'span'} mr={6}>{`${pluralizeWithCount(
                  notificationListingIds.length,
                  'active result',
                )} for `}</Text>
                {clientData && (
                  <SimpleClientTile
                    user_id={clientData.user_id}
                    photo={clientData.photo}
                    name={clientData.name}
                  />
                )}
              </Flex>
            ) : (
              <Skeleton
                height={7}
                width={60}
                isLoaded={!!dataAdvice}
                as={'span'}
                mr={2}
              >
                {`${pluralizeWithCount(dataAdvice?.count || 0, 'result')}`}
              </Skeleton>
            )}
          </Box>

          {tagCloudEnabled && (
            <Skeleton
              isLoaded={!!searchListingsTagCloud}
              minW={12}
              minH={7}
              py={1}
            >
              {searchListingsTagCloud && (
                <TagCloud tags={searchListingsTagCloud} />
              )}
            </Skeleton>
          )}
        </HStack>

        <HStack
          spacing={19}
          align={'center'}
          ml={{ base: '0', md: 'auto' }}
          py={{ base: 2, xl: 0 }}
        >
          {clientId && (
            <>
              <Checkbox
                label={'Check all'}
                role={'checkbox'}
                onChange={toggleCheckAll}
                isDisabled={!availableListingIds?.length}
                isChecked={areAllListingsChecked}
                mx={5}
                data-testid={'check-all-clients'}
              >
                <Text fontSize={'sm'}>Check all</Text>
              </Checkbox>

              <Button
                size={'sm'}
                variant={'blueLight'}
                leftIcon={<PaperPlaneIcon />}
                disabled={!selectedListingIds.length}
                onClick={handleClickSendListings}
                isLoading={isSendingListing}
                loadingText={'Sending listings...'}
              >
                Send selected listings
              </Button>

              {!isNotSearchResults && (
                <SavedSearchNotificationToggler
                  searchId={clientSearch?.search_id}
                  clientId={clientId}
                  isSuspended={clientSearch?.suspended}
                  isDisabled={!clientData || !clientSearch}
                />
              )}
            </>
          )}
          <Button
            size={'md'}
            variant={'blue'}
            onClick={setFiltersVisible.toggle}
            hidden={isNotSearchResults}
          >
            {`${filtersVisible ? 'Hide' : 'Show'} Filters`}
          </Button>

          <ViewToggler />
        </HStack>
      </PageHeading>

      {isNotSearchResults ? <NotificationResults /> : <SearchResults />}
    </Main>
  );
};

export default Results;
