import { DottedRow } from 'components/DottedRow';
import React, { ComponentProps, ReactElement } from 'react';
import { Text } from '@chakra-ui/react';
import { InfoRowTextElement } from './InfoRow';
import { currencyFormat } from 'services/currencyFormat';
import { StringOrNumber } from '../../services/apiTypes';
import hasBedroomsCount from 'services/hasBedroomsCount';

type Props = {
  isRental?: boolean;
  price: number;
  address?: string;
  zip?: string;
  furnished?: string;
  bedrooms: StringOrNumber;
  bathrooms: StringOrNumber;
  size?: StringOrNumber;
} & ComponentProps<typeof DottedRow>;

export const ShortListingSummary = ({
  price,
  bedrooms,
  bathrooms,
  size,
  address,
  zip,
  furnished,
  items,
  ...rest
}: Props): ReactElement => {
  let bedroomsLabel = 'Bed';
  const bedroomsNum = Number(bedrooms);
  if (!isNaN(bedroomsNum) && bedroomsNum > 1) {
    bedroomsLabel += 's';
  }

  let bathroomsLabel = 'Bath';
  const bathroomsNum = Number(bathrooms);
  if (!isNaN(bathroomsNum) && bathroomsNum > 1) {
    bathroomsLabel += 's';
  }

  return (
    <DottedRow
      fontSize={{ base: 12, md: 14 }}
      color={'pbBlue.100'}
      dotMx={{ base: 1.5, md: 2, xl: 2.5 }}
      items={[
        <Text as={'span'} fontWeight={'bold'} key={'price'}>
          {currencyFormat(price)}
        </Text>,
        address && (
          <Text key={'address'}>
            {[address, zip].filter(Boolean).join(', ')}
          </Text>
        ),
        <InfoRowTextElement
          key={'bedrooms'}
          label={hasBedroomsCount(bedrooms.toString()) ? bedroomsLabel : ''}
          number={bedrooms}
        />,
        <InfoRowTextElement
          key={'bathrooms'}
          label={bathroomsLabel.toString()}
          number={bathrooms}
        />,
        size && (
          <InfoRowTextElement key={'sqft'} label={'sqft'} number={size} />
        ),
        furnished && <Text key={'furnished'}>Furnished</Text>,
        ...(items ?? []),
      ].filter(Boolean)}
      {...rest}
    />
  );
};
