export const fonts = `
    @font-face {
        font-family: BwNistaInternational;
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url("/fonts/BwNistaInternationalDEMO-Bold.otf");
    }
    @font-face {
        font-family: BwNistaInternational;
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        src: url("/fonts/BwNistaInternationalDEMO-BoldItalic.otf");
    }
    @font-face {
        font-family: BwNistaInternational;
        font-weight: 500 600;
        font-style: normal;
        font-display: swap;
        src: url("/fonts/BwNistaInternationalDEMO-Medium.otf");
    }
    @font-face {
        font-family: BwNistaInternational;
        font-weight: 500 600;
        font-style: italic;
        font-display: swap;
        src: url("/fonts/BwNistaInternationalDEMO-MediumItalic.otf");
    }
    @font-face {
        font-family: BwNistaInternational;
        font-weight: 300 400;
        font-style: normal;
        font-display: swap;
        src: url("/fonts/BwNistaInternationalDEMO-Light.otf");
    }
    @font-face {
        font-family: BwNistaInternational;
        font-weight: 300 400;
        font-style: italic;
        font-display: swap;
        src: url("/fonts/BwNistaInternationalDEMO-LightItalic.otf");
    }
`;
