import { Heading, HeadingProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const SectionSubTitle = ({
  children,
  ...props
}: HeadingProps): ReactElement => (
  <Heading
    as={'h3'}
    fontSize={16}
    color={'pbGray.500'}
    fontWeight={'bold'}
    pl={{ xl: 3 }}
    letterSpacing={'-.4px'}
    {...props}
  >
    {children}
  </Heading>
);
