import { Clients, ClientsSearchParams } from './apiTypes';
import { UseQueryResult, useQuery } from 'react-query';
import { pbAgentFetcher } from '../../../fetcher';
import { objectToUrlParams } from '../../../objectToUrlParams';

export const defaultValues: ClientsSearchParams = {
  mode: 'CURRENT',
};

export const useClients = (
  params?: ClientsSearchParams,
): UseQueryResult<Clients[]> => {
  const clientParams = new URLSearchParams(
    objectToUrlParams({ ...defaultValues, ...params }),
  );

  return useQuery<Clients[]>(
    ['clients', clientParams.toString()],
    () => pbAgentFetcher(`v1/clients?${clientParams.toString()}`),
    {
      refetchOnWindowFocus: false,
    },
  );
};
