import { Flex, Skeleton, Text, useToast } from '@chakra-ui/react';
import { useResults } from 'contexts/ResultsContext';
import React, { ReactElement, useEffect } from 'react';
import { useClientListingsInfo } from 'services/hooks/api/search-listings/useClientListingsInfo';
import { useSearchListings } from 'services/hooks/api/search-listings/useSearchListings';
import { mergeListingsData } from 'services/mergeListingData';
import { objectToUrlParams } from 'services/objectToUrlParams';
import { pluralizeWithCount } from 'services/pluralize';
import { SearchResultGrid } from './SearchResults/SearchResultsGrid';
import { SearchResultsList } from './SearchResults/SearchResultsList';

export const NotificationResults = (): ReactElement => {
  const {
    data: { layoutType, filtersVisible, clientId, listingIds },
  } = useResults();

  const toast = useToast();
  const notificationResultsParams = new URLSearchParams(
    objectToUrlParams({
      ad_type: 'rental',
      listing_ids: listingIds,
      ignore_filters: 1,
    }),
  );

  const { data: listingData, isLoading: isLoadingNotificationResults } =
    useSearchListings(notificationResultsParams, 1);

  const { data: clientListingsInfo } = useClientListingsInfo({
    clientId,
    listingIds,
  });

  const mergedListingData = !isLoadingNotificationResults
    ? mergeListingsData(clientListingsInfo, listingData)
    : [];
  const ListComponent =
    layoutType === 'grid' ? SearchResultGrid : SearchResultsList;
  const message = !listingData?.length ? 'No listings' : '';

  useEffect(() => {
    const diffCount =
      (listingData && listingIds.length - listingData?.length) || 0;
    const listingsInactiveMessage = `${pluralizeWithCount(
      diffCount,
      'listing',
    )} is not active anymore`;

    if (listingData && diffCount) {
      toast({
        title: 'Inactive Listings',
        description: listingsInactiveMessage,
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }, [listingData, listingIds.length, toast]);

  return (
    <Flex pos={'relative'}>
      <Skeleton isLoaded={!isLoadingNotificationResults} width={'100%'}>
        <Text>{message}</Text>
        <ListComponent
          isLoading={isLoadingNotificationResults}
          data={mergedListingData}
          isShrinked={filtersVisible}
        />
      </Skeleton>
    </Flex>
  );
};
